import {
  GET_ATTACHMENTS_REQUESTED,
  GET_ATTACHMENTS_SUCCEEDED,
  POST_ATTACHMENT_REQUESTED,
  POST_ATTACHMENT_SUCCEEDED,
  GET_MEDIAFILES_SUCCEEDED,
  GET_MEDIAFILES_REQUESTED
} from './actions';

const initialState = {
  attachments: [],
  isFetching: false
};

export default function attachments(state = initialState, action) {
  switch (action.type) {
    case GET_ATTACHMENTS_REQUESTED:
      return Object.assign({}, state, {
        attachments: [],
        isFetching: true
      });
    case GET_ATTACHMENTS_SUCCEEDED:
      return Object.assign({}, state, {
        attachments: action.attachments,
        isFetching: false
      });
    case GET_MEDIAFILES_REQUESTED:
      return Object.assign({}, state, {
        mediafiles: [],
        isFetching: false
      });
    case GET_MEDIAFILES_SUCCEEDED:
      return Object.assign({}, state, {
        mediafiles: action.mediafiles,
        isFetching: false
      });
    case POST_ATTACHMENT_REQUESTED:
      return Object.assign({}, state, {
        isFetching: true
      });
    case POST_ATTACHMENT_SUCCEEDED:
      return Object.assign({}, state, {
        isFetching: false
      });
    default:
      return state;
  }
}
