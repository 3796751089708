import { ComboPriceProduct } from "./combo-price-product";

export class FixedPriceProduct extends ComboPriceProduct {
    getLeasingPrice = (vat: boolean = true) => {
        return 0;
    };

    getFixedPrice = (includeVat: boolean = true) => {
        return this.calculatePrice(this.getBasePrice(true), includeVat);
    };

    getDisplayPrice = (includeVat: boolean = true) => {
        return this.getFixedPrice(includeVat);
    };

    getEditPrice = () => {
        return this.getFixedPrice();
    };
}