import React, { Component } from 'react';
import './list.css';

export interface IListProps {
    array: any[];
    itemTemplate: (item: any, key: number) => any;
    filter?: (item: any) => boolean;
    noItemsTemplate?: () => any;
    className?: string;
}

export class List extends Component<IListProps> {

    render() {
        if (this.props.array && this.props.array['length']) {
            return (
                <div className="list-container">
                    <ul className={this.props.className}>
                        {this.props.filter ?
                            this._mapToTemplate(this._filter(this.props.array, this.props.filter), this.props.itemTemplate) :
                            this._mapToTemplate(this.props.array, this.props.itemTemplate)}
                    </ul>
                </div>
            )

        } else if (this.props.noItemsTemplate) {
            return <div className="list-container">{this.props.noItemsTemplate()}</div>

        } else {
            return <div className="list-container"></div>
        }
    }

    private _filter(list: Array<any>, filterFunc: (any) => boolean): Array<any> {
        if (list['length']) {
            return list.filter(item => {
                const res = filterFunc(item);
                if (res === null) {
                    return true;
                } else {
                    return res;
                }
            });
        } else {
            return list;
        }
    }

    private _mapToTemplate(list: Array<any>, template: (item: any, key: number) => any): Array<any> {
        return list.map((item, key) => template(item, key));
    }
}

export default List;