import React, {Component} from 'react';
import {connect} from 'react-redux';
import PropTypes from 'prop-types';

import './messages.css';
import Message from './message';
import {REMOVE_ERROR} from '../app/actions';

class Messages extends Component {
  static propTypes = {
    dispatchAction: PropTypes.func.isRequired,
    messages: PropTypes.array.isRequired,
    isError: PropTypes.bool
  }

  removeError = (index) => {
    this.props.dispatchAction({
      type: REMOVE_ERROR
    })
  }

  render() {
    const {messages, isError} = this.props;
    return (
      <ul className="messages">
        {
          messages.map((message, i) => {
            return <Message
              key={i}
              removeError={this.removeError}
              message={message}
              isError={isError}/>
          })
        }
      </ul>
    )
  }
}


const mapStateToProps = ({}) => ({});

const mapDispatchToProps = (dispatch) => ({
  dispatchAction: (action) => dispatch(action)
});

export default connect(mapStateToProps, mapDispatchToProps)(Messages);
