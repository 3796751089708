// @ts-nocheck
// TODO remove above
import * as React from 'react';
import ProductListItem from './product-list-item';
import { Scrollbars } from 'react-custom-scrollbars-2';

class ProjectProducts extends React.Component<any, any> {

    getProductItems() {
        var customSort = function(a,b) {
            return a.position - b.position;
        }

        return this.props.projectProducts
            .sort(customSort)
            .map(product => {
            return (
                <ProductListItem
                    key={product.project_product_id}
                    product={product}
                    deleteProductListener={() => this.props.handleDeleteProduct(product)}
                    adjustAmountListener={this.props.handleAmountListener}
                    addProductListener={() => this.props.handleAddProduct(product)}/>
            )
        });
    }

    render() {
        let items = this.getProductItems();
        return (
            <Scrollbars style={{ 'backgroundColor': '#ffffff', height: `calc(100vh - 10rem)` }}>
                <hr/>
                {items}
            </Scrollbars>
        );
    }
}

export default ProjectProducts;
