import React, { Component } from 'react';
import RiskEvaluation from "./risk-evaluation";
import LevelIndicationTooltip from "./level-indication-tooltip";
import t from '../i18n/index';

interface IRiskEvaluationContainerProps {
    risk: any;
    evalKey: string;
    disabled?: boolean;
    handleOnClickRiskEvaluation: any;
}

export default class RiskEvaluationContainer extends Component<IRiskEvaluationContainerProps, {}> {

    private riskEvaluations: string[] = [t('web.risk.evaluationLow'), t('web.risk.evaluationMedium'), t('web.risk.evaluationHigh'), t('web.risk.evaluationVeryHigh')];

    public render() {
        return (
            <div>
                <div className="edit-risk__evaluation-container">
                    {
                        this.riskEvaluations.map((name, index) =>
                            <RiskEvaluation
                                key={index}
                                evalKey={this.props.evalKey}
                                risk={this.props.risk}
                                index={index}
                                name={name}
                                selectedIndex={this.props.risk[this.props.evalKey]}
                                handleOnClickRiskEvaluation={this.props.handleOnClickRiskEvaluation}
                                disabled={this.props.disabled}/>
                        )
                    }
                </div>
                <div style={{ width: "100%", position: "relative" }}>
                    <LevelIndicationTooltip
                        type={this.props.evalKey}
                        level={this.props.risk[this.props.evalKey]}
                        definition_key={this.props.risk.definition_key}
                    />
                </div>
            </div>
        );
    }
}
