import callApi, {callApiRaw, Method} from './api-client';
import {
  callAttachmentUploader,
  callContactImageUploader,
  callFetchImageBlob,
  callProjectImageUploader,
  callRiskProjectImageUploader,
  callSurveyImageUploader,
  fetchSurveyImage,
  callRiskProjectImageEdit,
  callSurveyImageEdit
} from './attachment-client';

export const API_ROOT = process.env.REACT_APP_API_ROOT;

export const fetchPDFHelper = (url) =>
  callApiRaw(url, Method.GET, null, 'application/json', 'application/pdf');

export const fetchProjects = () =>
  callApi('projects', Method.GET);

export const fetchProject = (id) =>
  callApi(`projects/${id}`, Method.GET);

export const getProjectImageUrl = (id) =>
  `${API_ROOT}projects/${id}/projectimage`;

export const addNewProject = (body) =>
  callApi('projects', Method.POST, body);

export const editProject = (id, body) =>
  callApi(`projects/${id}`, Method.PUT, body);

export const deleteProject = (id, body) =>
  callApi(`projects/${id}`, Method.DELETE, body);

export const editContact = (projectId, contactId, body) =>
  callApi(`projects/${projectId}/contacts/${contactId}`, Method.PUT, body);

export const deleteContact = (projectId, contactId, body) =>
  callApi(`projects/${projectId}/contacts/${contactId}`, Method.DELETE, body);

export const addNewContact = (projectId, body) =>
  callApi(`projects/${projectId}/contacts`, Method.POST, body);

export const uploadAttachmentMetadata = (id, body) =>
  callApi(`projects/${id}/attachments`, Method.POST, body);

export const uploadAttachment = (id, attachmentId, file) =>
  callAttachmentUploader(id, attachmentId, file);

export const projectImageUploader = (projectId, file) =>
  callProjectImageUploader(projectId, file);

export const contactImageUploader = (project_id, contact_id, file) =>
  callContactImageUploader(project_id, contact_id, file);

export const getAttachments = (id) =>
  callApi(`projects/${id}/attachments`, Method.GET);

export const fetchProducts = () =>
  callApi('products', Method.GET);

export const fetchProjectProducts = (projectId) =>
  callApi(`projects/${projectId}/products`, Method.GET);

export const addProductToProject = (projectId, body) =>
  callApi(`projects/${projectId}/products`, Method.POST, body);

export const deleteProductForProject = (projectId, projectProductId, body) =>
  callApi(`projects/${projectId}/products/${projectProductId}`, Method.DELETE, body);

export const editProductForProject = (projectId, projectProductId, body) =>
  callApi(`projects/${projectId}/products/${projectProductId}`, Method.PUT, body);

export const updateProjectProductsOrder = (projectId, body) =>
  callApi(`projects/${projectId}/products/order`, Method.PUT, body);  

export const fetchCategories = (country_id) =>
  callApi(`categories?country_id=${country_id}`, Method.GET);

export const fetchProposalPDF = (projectId, email) =>
  fetchPDFHelper(`projects/${projectId}/offerletter/pdf?recipient=${email}`);

export const fetchContractPDF = (project_id, contract_id) =>
  fetchPDFHelper(`projects/${project_id}/contracts/${contract_id}/pdf`);

export const cancelOfferletterSigning = (projectId) =>
  callApi(`projects/${projectId}/pdf`, Method.DELETE);

export const getMediaFiles = (projectId) =>
  callApi(`projects/${projectId}/mediafiles`, Method.GET);

export const addMediaFile = (projectId, mediaId) =>
  callApi(`projects/${projectId}/mediafiles/${mediaId}`, Method.POST)

export const removeMediaFile = (projectId, mediaId) =>
  callApi(`projects/${projectId}/mediafiles/${mediaId}`, Method.DELETE)

export const getAllMediaFiles = () =>
  callApi(`mediafiles`, Method.GET);

export const fetchOfferletters = () =>
  callApi('offerlettertemplates', Method.GET);

export const fetchOfferletterData = (projectId) =>
  callApi(`projects/${projectId}/offerletter`, Method.GET);

export const signOfferletter = (projectId) =>
  callApi(`projects/${projectId}/offerletter/sign`, Method.POST);

export const signContract = (project_id, contract_id) =>
  callApi(`projects/${project_id}/contracts/${contract_id}/sign`, Method.POST);

export const setNewOfferletter = (projectId, body) =>
  callApi(`projects/${projectId}/offerletter`, Method.POST, body);

export const fetchCurrentSignedInUserInfo = (userName) =>
  callApi(`sellers?upn=${userName}`, Method.GET);

export const fetchSellersFromCountry = (countryId) =>
    callApi(`sellers?country_id=${countryId}`, Method.GET)

export const updateUserInfo = (user) =>
  callApi(`sellers/${user.id}`, Method.PUT, user);

export const fetchContractTemplates = (projectId) =>
  callApi(`contracts`, Method.GET);

export const fetchProjectContracts = (projectId) =>
  callApi(`projects/${projectId}/contracts`, Method.GET);

export const addContract = (project_id, contract_id) =>
  callApi(`projects/${project_id}/contracts/${contract_id}`, Method.POST);

export const deleteContract = (project_id, contract_id) =>
  callApi(`projects/${project_id}/contracts/${contract_id}`, Method.DELETE);

export const fetchSurvey = () =>
  callApi(`risks/survey`, Method.GET);

export const fetchSurveySummaries = (projectId) =>
  callApi(`projects/${projectId}/survey/summary`, Method.GET);

export const fetchSurveyAnswers = (projectId) =>
  callApi(`projects/${projectId}/survey`, Method.GET);

export const fetchSurveyImages = (projectId) =>
  callApi(`projects/${projectId}/survey/images`, Method.GET);

export const fetchSurveyImageBlob = (projectId, attachmentId) =>
  fetchSurveyImage(projectId, attachmentId);

export const fetchImageBlob = (apiUrl, clearCache = false) =>
  callFetchImageBlob(apiUrl, clearCache);

export const setSurveyAnswers = (projectId, body) =>
  callApi(`projects/${projectId}/survey`, Method.POST, body);

export const uploadSurveyImage = (projectId, questionId, file) =>
  callSurveyImageUploader(projectId, questionId, file);

export const updateSurveySummary = (projectId, summary) => 
  callApi(`projects/${projectId}/survey/summary`, Method.PUT, summary);

export const updateSurveyImage = (projectId, attachmentId, file) =>
  callSurveyImageEdit(projectId, attachmentId, file);

export const deleteSurveyImage = (projectId, attachmentId) =>
  callApi(`projects/${projectId}/survey/images/${attachmentId}`, Method.DELETE);

export const fetchRisks = (segmentId) =>
  callApi(`risks/${segmentId}`, Method.GET);

export const fetchSegmentRisks = (segmentId) =>
  callApi(`risks/segments/${segmentId}/groups`);

export const fetchSegmentDefinition = (segmentId) =>
  callApi('risks/definitions', Method.GET);  

export const fetchProjectRisks = (projectId) =>
  callApi(`projects/${projectId}/risks`, Method.GET);

export const fetchRiskSegments = () =>
  callApi(`risks/segments`, Method.GET);

export const fetchProjectAreas = (projectId) =>
  callApi(`projects/${projectId}/risks/areas`, Method.GET);

export const addProjectArea = (projectId, body) =>
  callApi(`projects/${projectId}/risks/areas`, Method.POST, body);

export const deleteProjectArea = (projectId, areaId) =>
  callApi(`projects/${projectId}/risks/areas/${areaId}`, Method.DELETE);

export const fetchRiskScenarios = () =>
  callApi(`risks/scenarios`, Method.GET);

export const fetchRiskProjectImages = (projectId) =>
  callApi(`projects/${projectId}/risks/images`, Method.GET);

export const uploadRiskProjectImage = (projectId, riskId, file) =>
  callRiskProjectImageUploader(projectId, riskId, file);

export const updateRiskProjectImage = (projectId, riskId, attachmentId, file) =>
  callRiskProjectImageEdit(projectId, riskId, attachmentId, file);

export const deleteRiskProjectImage = (projectId, attachmentId) =>
  callApi(`projects/${projectId}/risks/images/${attachmentId}`, Method.DELETE);


export const addRiskScenario = (projectId, riskId, scenario) =>
  callApi(`projects/${projectId}/risks/${riskId}/scenarios`, Method.POST, scenario);


export const createCustomScenarioForProject = (name, projectId) =>
  callApi(`risks/scenarios`, Method.POST, {
    Name: name,
    ProjectId: projectId
  });


export const updateRiskScenario = (projectId, scenario) =>
  callApi(`projects/${projectId}/risks/${scenario.risk_id}/scenarios`, Method.PUT, scenario);


export const deleteRiskScenario = (projectId, riskId, scenarioId) =>
  callApi(`projects/${projectId}/risks/${riskId}/scenarios/${scenarioId}`, Method.DELETE);


export const fetchRiskSolutions = () =>
  callApi(`risks/solutions`, Method.GET);

export const addRiskSolution = (projectId, riskId, scenarioId, solution) =>
  callApi(`projects/${projectId}/risks/${riskId}/scenarios/${scenarioId}/solutions`, Method.POST, solution);

export const deleteRiskSolution = (projectId, riskId, scenarioId, solutionId) =>
  callApi(`projects/${projectId}/risks/${riskId}/scenarios/${scenarioId}/solutions/${solutionId}`, Method.DELETE);

export const addProjectRisk = (projectId, risk) =>
  callApi(`projects/${projectId}/risks`, Method.POST, risk);

export const editProjectRisk = (projectId, risk) =>
  callApi(`projects/${projectId}/risks`, Method.POST, risk);

export const deleteProjectRisk = (projectId, riskId) =>
  callApi(`projects/${projectId}/risks/${riskId}`, Method.DELETE);

export const createCustomArea = (customArea) =>
  callApi(`risks/areas`, Method.POST, customArea);
