// @ts-nocheck
// TODO remove above
import React, { Component } from 'react';
import t from '../i18n';
import './send-email.css';
import callApi from '../services/api-client';
import 'react-select/dist/react-select.css';
import Spinner from '../spinner/spinner';
import { IPDFType } from './proposal.container';
import {trackEvent} from "../utils/google-analytics";

interface ISendEmailProps {
    // attachments: string[];
    mediaFiles: any;
    project: any;
    getAttachmentIds: () => string;
    offerletter?: boolean;
    contractId?: number;
    onRecipientChange: (email) => void;
    handleSubmit: (IPDFType) => void;
    enableEmailButton: boolean;
    enableSubmitButton: boolean;
}

export default class SendEmail extends Component<ISendEmailProps> {

    recipient;
    blindcopyEmail;
    copyEmail;
    subject;
    attachments;
    mediaFiles;

    state = {
        recipient: this.props.project.client.contacts[0].email,
        blindcopyEmail: "",
        copyEmail: "",
        value: [],
        attachments: [],
        isFetchingEmailFiles: false
    };

    inputStyle = {};

    getOfferLetterAsLink = () => {
        return new Promise((resolve) => {
            callApi(`projects/${this.props.project.project_id}/offerletter/pdf?link=true&recipient=${this.state.recipient}`, 'GET').then(url => {
                resolve(url);
            }).catch(e => {
                    resolve("");
            });
        });
    };

    getContractAsLink = (contractId) => {
        return new Promise((resolve) => {
            callApi(`projects/${this.props.project.project_id}/contracts/${contractId}/pdf?link=true`).then(url => {
                resolve(url);
            }).catch(e => {
                resolve("");
            });
        });
    };

    sendToEmailClient = (recipient, copyEmail, blindcopyEmail, subject) => {
        this.setState({isFetchingEmailFiles: true});
        
        const file = this.props.offerletter ? this.getOfferLetterAsLink() : this.getContractAsLink(this.props.contractId || 0);
        const documents = this.props.getAttachmentIds();
        let getLinkToZipByStringName;

        getLinkToZipByStringName = new Promise((resolve, reject) => {
            callApi(`projects/${this.props.project.project_id}/getmailattachments?filetype=${documents}`)
                .then(result => resolve(result))
                .catch(e => e.status === 417 ? resolve() : reject());
        });

        Promise.all(
            [
                file,
                getLinkToZipByStringName
            ]
        ).then(result => {
            this.setState({isFetchingEmailFiles: false});
            let mailtoString = `mailto:${recipient}?subject=${subject}&body=${result.filter(element => element !== "").join("%0D%0A%0D%0A")}`;
            if (copyEmail && copyEmail.trim().length !== 0) {
                mailtoString += `&cc=${copyEmail}`;
            }
            if (blindcopyEmail && blindcopyEmail.trim().length !== 0) {
                mailtoString += `&bcc=${blindcopyEmail}`;
            }
            window.location = mailtoString;
            // Track event
            trackEvent(this.props.offerletter ? 'Offer letter sent' : 'Contract sent')
        })
    };

    getDefaultRecipient = () => {
        return (Array.from(this.props.project.client.contacts).shift() || { "email": null }).email; // Checks if the array is empty, and gets email if not... not my proudest moment
    };

    handleSelectChange = (value) => {
        this.setState({ value });
    };

    handleRecipientChanged = e => {
        this.setState({ recipient: e.target.value });
        this.props.onRecipientChange(e.target.value);
    }

    handleBlindcopyChanged = e => {
        this.setState({ blindcopyEmail: e.target.value });
    }

    handleCopyChanged = e => {
        this.setState({ copyEmail: e.target.value });
    }        

    public render() {

        const recipients = this.props.project.client.contacts
            .filter(contact => contact.email !== "")
            .map(contact => {
                return { segment_id: contact.email, segment_name: `${contact.firstName} ${contact.lastName} (${contact.email})` }
            });
        
        return (
            <div>

                <div className="left recipient-container">
                    <div>
                    <img src={this.state.recipient.image} role="presentation"/>
                    <label htmlFor="email">To</label>
                    <select name="email" value={this.state.recipient} onChange={this.handleRecipientChanged} required={true}>
                    {recipients.map(recipient => {
                        return <option value={recipient.segment_id} key={recipient.segment_id}
                                    id={recipient.segment_id}>{recipient.segment_name}</option>
                    })}
                    </select>
                    </div>
                    <div>
                    <label htmlFor="cc">Cc</label>
                    <input name="cc"
                        value={this.state.copyEmail}
                        onChange={this.handleCopyChanged}
                    />   
                    </div>
                    <div>                 
                    <label htmlFor="bcc">Bcc</label>
                    <input name="bcc"
                        value={this.state.blindcopyEmail}
                        onChange={this.handleBlindcopyChanged}
                    />
                    </div>
                </div>

                <div className="right">
                    {this.state.isFetchingEmailFiles && 
                        <button title="Email is being prepared..." className="btn fetching-files-loader">
                            <Spinner/>
                        </button>
                    }
                    {!this.state.isFetchingEmailFiles &&
                        <button
                            className={this.props.enableEmailButton ? "btn" : "btn btn-disabled"}
                            onClick={this.props.enableEmailButton ? (e) => {
                                this.sendToEmailClient(this.state.recipient, this.state.copyEmail, this.state.blindcopyEmail, this.props.offerletter ? t('web.proposal.offer.defaultMailSubject') : t('web.proposal.contract.defaultMailSubject'))
                            } : null}>
                            {`${t('web.projects.info.sendEmail')}`}
                        </button>
                    }
                    {   this.props.offerletter ? 
                        <button className={this.props.enableSubmitButton ? "btn" : "btn btn-disabled"}
                            onClick={this.props.enableSubmitButton ? () => this.props.handleSubmit(IPDFType.OFFER) : null}
                        >{t('web.proposal.signingButton')}</button> :
                        <button className={this.props.enableSubmitButton ? "btn" : "btn btn-disabled"}
                            onClick={this.props.enableSubmitButton ? () => this.props.handleSubmit(IPDFType.CONTRACT) : null}
                        >{t('web.proposal.signingButton')}</button>
                    }
                </div>
            </div>
        );
    }
}
