import React, { Component } from 'react';
import { Checkbox } from '../../input/standard-input';
import { IEditRiskListItemProps } from '../interfaces';
import Modal from '../../common/modal/modal';
import t, { d } from '../../i18n/index';

interface IEditRiskListItemState {
    warningModalVisible: boolean;
}

export default class EditRiskListItem extends Component<IEditRiskListItemProps, {}> {

    state = {
        warningModalVisible: false
    }

    isRiskEdited = (): boolean => {
        const risk = this.props.risk;
        return risk.probability !== -1 || risk.impact !== -1 || risk.note !== '';
    }

    toggleWarningModal = () => {
        this.setState({ warningModalVisible: !this.state.warningModalVisible });
    }

    warningModalOnYes = (e) => {
        this.props.onSelectHandler(e, this.props.risk);
        this.toggleWarningModal();
    }

    warningModalOnNo = (e) => {
        this.toggleWarningModal();
    }

    public render() {
        return (
            <li className="edit-risk-list-item">
                <Checkbox
                    label={d('risk.risk_group', this.props.risk.group_name)}
                    checked={this.props.risk.checked}
                    onChange={(e) => {
                        if (this.isRiskEdited() && this.props.risk.checked) {
                            this.toggleWarningModal();
                        } else {
                            this.props.onSelectHandler(e, this.props.risk);
                        }
                    }}/>
                {
                    this.state.warningModalVisible &&
                    <Modal
                        title={t('web.risk.editRisks.uncheckingEditedRisk')}
                        closeOnClickOutside={true}
                        onClose={(e) => this.toggleWarningModal()}>
                        <p>
                            <strong>{d('risk.risk_group', this.props.risk.group_name)}</strong> {t('web.risk.editRisks.riskHasBeenEdited')}
                        </p>
                        <button
                            className="btn"
                            style={{ marginRight: '8px' }}
                            onClick={this.warningModalOnYes}>
                            {t('web.risk.editRisks.yes')}
                        </button>
                        <button
                            className="btn"
                            onClick={this.warningModalOnNo}>
                            {t('web.risk.editRisks.no')}
                        </button>
                    </Modal>
                }
            </li>
        );
    }
}
