export const FETCH_SCENARIO_REQUESTED = 'FETCH_SCENARIO_REQUESTED';
export const FETCH_SCENARIO_SUCCEEDED = 'FETCH_SCENARIO_SUCCEEDED';
export const ADD_RISK_SCENARIO_REQUESTED = 'ADD_RISK_SCENARIO_REQUESTED';
export const ADD_RISK_SCENARIO_SUCCEEDED = 'ADD_RISK_SCENARIO_SUCCEEDED';
export const REMOVE_RISK_SCENARIO_REQUESTED = 'REMOVE_RISK_SCENARIO_REQUESTED';
export const REMOVE_RISK_SCENARIO_SUCCEEDED = 'REMOVE_RISK_SCENARIO_SUCCEEDED';
export const ADD_RISK_SOLUTION_REQUESTED = 'ADD_RISK_SOLUTION_REQUESTED';
export const ADD_RISK_SOLUTION_SUCCEEDED = 'ADD_RISK_SOLUTION_SUCCEEDED';
export const REMOVE_RISK_SOLUTION_REQUESTED = 'REMOVE_RISK_SOLUTION_REQUESTED';
export const REMOVE_RISK_SOLUTION_SUCCEEDED = 'REMOVE_RISK_SOLUTION_SUCCEEDED';
export const UPDATE_RISK_SCENARIO_REQUESTED = 'UPDATE_RISK_SCENARIO_REQUESTED';
export const UPDATE_RISK_SCENARIO_SUCCEEDED = 'UPDATE_RISK_SCENARIO_SUCCEEDED';
