import React, { Component } from 'react';
import {translate_d} from '../i18n';

export default class LevelIndicationTooltip extends Component<any, any> {

    private levelKeys = [
        ['low'],
        ['medium'],
        ['high'],
        ['very_high']
    ];

    generateDefinitionKey(definitionName) {
        if (definitionName) {
            let text = definitionName.trim().toLowerCase()
            text = text.replace(/\s/g, '_')
            text = text.replace(/(\&|\(|\)|-|[0-9]|[^a-z_])/g, '')
            text = text.replace(/(\_{1,})/i, '_')
            return text
        }
        return definitionName;
    }

    public render() {
        let definitionKey = 'standard';
        if (typeof this.props.definition_key === 'string' && this.props.definition_key.length !== 0) {
            definitionKey = this.generateDefinitionKey(this.props.definition_key);
        }
        // console.log('Def key: ' + this.props.definition_key + ' generated: ' + definitionKey);
        let translatedDefinition = translate_d(['risk.definition.' + definitionKey + '.' + this.props.type + '.' + this.levelKeys[this.props.level]]);
        var separateLines = translatedDefinition ? translatedDefinition.split(/\r?\n|\r|\n/g) : null;
        // console.log('Separate lines: ' + translatedDefinition);
        return (
            <div className="risk-indicator-text">
                {separateLines ? separateLines.map((line, index ) => <div key={index}> {line} </div>) : ""}
            </div>
        );
    }
}