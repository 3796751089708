// @ts-nocheck
// TODO remove above
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { browserHistory } from 'react-router';
import Scrollbars from 'react-custom-scrollbars-2';

import NoProjects from './no-projects';
import Projects from './projects';
import callApi from "../services/api-client";
import {
    GET_PROJECTS_REQUESTED,
    GET_SELLERS_REQUESTED,
    CHANGE_PROJECTS_VIEW_STYLE_REQUESTED
} from './actions';
import './projects.css';
import t from '../i18n';
import { getSortedProjects } from './reducer';
import { fetchPDFHelper } from "../services/api";
import { saveAs } from "file-saver";
import { ADD_ERROR } from '../app/actions';
import Modal from "../common/modal/modal";

export interface ProjectsContainerProps {
    dispatchAction: (action: any) => any;
    isFetching: boolean;
    errorMessage?: string;
    projects: any[];
    showList: boolean;
    sellers: any[];
}

class ProjectsContainer extends Component<ProjectsContainerProps, any> {

    state = {
        downLoadProgress: {}, // used to track download progress on project summaries
        isSharingProject: false,
        projectToShare: {},
        projectDetail: null
    }

    constructor(props) {
        super(props);
        this.submitShareProject = this.submitShareProject.bind(this);
    }

    componentDidMount() {
        this.props.dispatchAction({ type: GET_PROJECTS_REQUESTED });
        this.props.dispatchAction({ type: GET_SELLERS_REQUESTED, country_id: this.props.countryId })
    }

    didClickOnItem = (id) => {
        browserHistory.push(`/project/${id}/details`);
    };

    didClickNewProject = () => {
        browserHistory.push(`/project/new`);
    };

    didClickEditProject = (project) => {
        browserHistory.push(`/project/${project.project_id}/edit`);
    };

    addDownloadProgress = (project_id) => {
        let newDownloadProgress = { ...this.state.downLoadProgress };
        newDownloadProgress[project_id] = true;
        this.setState({
            downLoadProgress: newDownloadProgress
        });
    };

    removeDownloadProgress = (project_id) => {
        let newDownloadProgress = { ...this.state.downLoadProgress };
        newDownloadProgress[project_id] = false;
        this.setState({
            downLoadProgress: newDownloadProgress
        });
    };

    getDownloadProgress = (project_id) => {
        let progress = this.state.downLoadProgress[project_id];
        return progress ? true : false;
    };

    didClickDownloadProject = (project) => {
        let url = `projects/${project.project_id}/allpdfs`;
        this.addDownloadProgress(project.project_id);
        fetchPDFHelper(url).then((result) => {
            this.removeDownloadProgress(project.project_id);
            var blob = new Blob([result], { type: "application/zip" });
            saveAs(blob, `${project.name} - ` + t('web.projects.sellerscopy'));
        }).catch((error) => {
            this.removeDownloadProgress(project.project_id);
            this.props.dispatchAction({
                type: ADD_ERROR,
                payload: {
                    title: t('web.common.errorTitleGeneric'),
                    message: "Failed to download sellers copy"
                }
            });
        });
    };

    didClickSwitchPresentationToList = () => {
        this.props.dispatchAction({
            type: CHANGE_PROJECTS_VIEW_STYLE_REQUESTED,
            payload: {
                showList: true
            }
        });
    };

    didClickSwitchPresentationToGrid = () => {
        this.props.dispatchAction({
            type: CHANGE_PROJECTS_VIEW_STYLE_REQUESTED,
            payload: {
                showList: false
            }
        });
    };

    shareClickListener = (project) => {
        this.setState({...this.state, isSharingProject: true, projectToShare: project})
    };

    submitShareProject = async (e) => {
        e.preventDefault();
        let sellerId = e.target[0].value;
        let response = callApi(`projects/${this.state.projectToShare.project_id}/generatelink/${e.target[0].value}`, "POST");
        response.then((r) => {
            let seller = this.props.sellers.filter(s => parseInt(s.id) == parseInt(sellerId))[0];
            window.location = `mailto:${seller.email}?subject=${this.state.projectToShare.name}&body=${window.location.origin}/project/copy/${r.split("=")[1]}`;
        });
    };

    render() {
        let { projects, isFetching, showList } = this.props;
        // add downloading info to projects
        projects = projects.map(project => {
            project.isDownloading = this.getDownloadProgress(project.project_id);
            return project;
        });

        return (
            <Scrollbars style={{ height: `calc(100vh - 5rem)` }}>
                <div className="container">
                    {projects.length === 0 && !isFetching &&
                        <NoProjects clickListener={this.didClickNewProject} />
                    }
                    {projects.length > 0 &&
                        <Projects
                            projects={this.props.projects}
                            showList={showList}
                            didClickNewProject={this.didClickNewProject}
                            didClickOnItem={this.didClickOnItem}
                            didClickEditProject={this.didClickEditProject}
                            didClickDownloadProject={this.didClickDownloadProject}
                            switchPresentationListenerToGrid={this.didClickSwitchPresentationToGrid}
                            switchPresentationListenerToList={this.didClickSwitchPresentationToList}
                            shareClickListener={this.shareClickListener}
                        />
                    }
                    {this.state.isSharingProject &&
                        <Modal title={`${t('web.projects.share')} ${this.state.projectToShare.name}`} onClose={() => { this.setState({...this.state, isSharingProject: false}) }}>
                            <form onSubmit={this.submitShareProject}>
                                <select
                                    className="input-form__select"
                                    name="seller"
                                >
                                    {this.props.sellers.sort((a, b) => {
                                        let nameA = a.name.toUpperCase();
                                        let nameB = b.name.toUpperCase();
                                        if (nameA < nameB) {
                                            return -1;
                                        }
                                        if (nameA > nameB) {
                                            return 1;
                                        }

                                        return 0;
                                    }).map((seller) =>
                                        <option key={seller.id} value={seller.id}>{seller.name}</option>
                                    )}
                                </select>
                                <input type="submit" className="btn" value={t('web.projects.share')} />
                            </form>
                        </Modal>
                    }
                </div>
            </Scrollbars>
        );
    }
}

const mapStateToProps = ({ projects, app, user }) => ({
    isFetching: app.isFetching,
    projects: getSortedProjects(projects),
    showList: projects.showList,
    countryId: user.user.country.id,
    sellers: projects.sellers
});

const mapDispatchToProps = (dispatch) => ({
    dispatchAction: (action) => dispatch(action)
});

export default connect(mapStateToProps, mapDispatchToProps)(ProjectsContainer);
