import React from "react";
import './tabs-container.css';

// ------------ Tab Container ------------

export interface ITabProps {
    name: string,
    isActive?: boolean,
    className?: string
}

export interface ITabsContainerProps {
    activeTab?: string,
    onChangeHandler?: Function,
    className?: string 
}

interface ITabsContainerState {
    hasActiveTabProp: boolean,
    tabs: string[],
    activeChild: number,
    hasMoreThanOneChild: boolean
}

export class TabsContainer extends React.Component<ITabsContainerProps, ITabsContainerState> {

    constructor(props) {
        super(props);

        const tabs = this._createTabsFromChildren(props.children);

        this.state = {
            hasActiveTabProp: props.activeTab !== undefined,
            tabs: tabs,
            activeChild: this._tabNameToIndex(props.activeTab, tabs),
            hasMoreThanOneChild: this.props.children['length'] ? true : false
        }
    }

    public componentWillReceiveProps(nextProps) {
        if (this.state.hasActiveTabProp) {
            this.setState({...this.state, activeChild: this._tabNameToIndex(nextProps.activeTab, this.state.tabs)});
        }
    }

    public render() {
        return (
            <div className={"tabs-container " + this.props.className}>
                <div className="tabs-header full-width-container">
                    {this.state.tabs.map((tab, index) => 
                        <div className={'tab-head '+ (index === this.state.activeChild ? 'active' : '') } key={index} onClick={() => this._handleOnClick(tab)}>{tab}</div>)
                    }
                </div>
                <div className="tabs-body">
                    {this.state.hasMoreThanOneChild ? this.props.children[this.state.activeChild] : this.props.children}
                </div>
            </div>
        )
    }

    private _tabNameToIndex(name: string, tabs: Array<string>): number {
        if (!name || !tabs) return 0;
        // will default to 0 if no match was found
        const index = tabs.findIndex(tab => tab.toString().toLowerCase() === name.toString().toLowerCase());
        return index === -1 ? 0 : index;
    }

    private _handleOnClick(index): void {
        if (this.props.onChangeHandler) {
            this.props.onChangeHandler(index, this.state.tabs[index]);
        }
    }

    private _createTabsFromChildren(children: any): Array<string> {
        return children['length'] ?
            this._getChildrenNames(children) :
            [children.props.name]
    }

    private _getChildrenNames(children): Array<string> {
        return children.map(child => child.props.name);
    }
}

export class Tab extends React.Component<ITabProps, any> {
    constructor(props) {
        super(props);

        if (!props.name) throw Error('Tab.constructor: Required property "name" is missing.');
    }

    public render() {
        return (
            <div className={'tab-body ' + this.props.className} key={this.props.name}>{this.props.children}</div>
        )
    }
}
