import * as React from 'react';

import Input from '../input/input';
import close from '../img/close.svg';
import t from '../i18n';
import user from '../img/login-username.svg';
import mail from '../img/mail.svg';
import phone from '../img/phone.svg';
import './new-customers.css';
import CustomerImage from './customer-image';
import { isEqual } from '../utils/utils';

export interface NewCustomerProps {
    showDelete: boolean;
    customer: any;

    customerUpdated(updatedCustomer): void;
}

const NewCustomer = ({ customer, showDelete, customerUpdated }: NewCustomerProps) => {
    const update = function (updateFunction) {
        return function (event) {
            event.preventDefault();
            let updatedCustomer = { ...customer };
            updateFunction(event, updatedCustomer);
            if (!isEqual(customer, updatedCustomer)) {
                customerUpdated({ ...updatedCustomer, isModified: true });
            }
        };
    };

    const updateImage = function (updateFunction) {
        return function (imageData) {
            let updatedCustomer = { ...customer };
            updateFunction(imageData[0], updatedCustomer);
            updatedCustomer.photo_url = imageData[0].preview
            customerUpdated(updatedCustomer);
        };
    };

    return (
        <div className="customers__customer-container">
            <div className="customers__image">
                <CustomerImage
                    imageUrl={customer.photo_url}
                    postAttachment={updateImage((imageFile, customer) => customer.imageFile = imageFile)}/>
            </div>
            <div className="customers__form">
                {showDelete &&
                <img className="customers__delete-contact" src={close} onClick={(e) => customerUpdated(undefined)}
                     alt={t('web.newCustomer.contact.removeContactAlt')}/>
                }
                <div style={{ display: "flex" }}>
                    <Input
                        placeholder={t("web.newCustomer.contact.firstname")}
                        supressEnter={true}
                        value={customer.firstName}
                        name="firstName"
                        onBlur={update((e, customer) => customer.firstName = e.target.value)}
                        image={user}/>
                    <Input
                        placeholder={t("web.newCustomer.contact.lastname")}
                        supressEnter={true}
                        value={customer.lastName}
                        name="lastName"
                        onBlur={update((e, customer) => customer.lastName = e.target.value)}
                    />
                </div>
                <div style={{ display: "flex" }}>
                    <Input
                        placeholder={t("web.newCustomer.contact.formalTitle")}
                        supressEnter={true}
                        value={customer.formal_title}
                        name="formalTitle"
                        onBlur={update((e, customer) => customer.formal_title = e.target.value)}
                        image={user}/>
                    <Input
                        placeholder={t("web.newCustomer.contact.position")}
                        supressEnter={true}
                        value={customer.position}
                        name="position"
                        onBlur={update((e, customer) => customer.position = e.target.value)}
                    />
                </div>
                <div style={{ display: "flex" }}>
                    <Input
                        style={{ flexGrow: "" }}
                        placeholder={t("web.newCustomer.contact.phone")}
                        supressEnter={true}
                        value={customer.phone1}
                        name="phone1"
                        type="tel"
                        onBlur={update((e, customer) => customer.phone1 = e.target.value)}
                        image={phone}/>
                    <Input
                        placeholder={t("web.newCustomer.contact.additionalPhone")}
                        supressEnter={true}
                        value={customer.phone2}
                        name="phone2"
                        type="tel"
                        onBlur={update((e, customer) => customer.phone2 = e.target.value)}
                    />
                </div>
                <Input
                    placeholder={t("web.newCustomer.contact.email")}
                    supressEnter={true}
                    value={customer.email}
                    name="email"
                    type="email"
                    onBlur={update((e, customer) => customer.email = e.target.value)}
                    image={mail}/>
            </div>
        </div>
    );
}

export default NewCustomer;
