import { call, put, takeEvery, select } from 'redux-saga/effects';
import { browserHistory } from 'react-router';
import {
    GET_PROJECTS_REQUESTED,
    GET_PROJECTS_SUCCEEDED,
    CLEAR_PROJECTS_REQUESTED,
    ADD_NEW_PROJECT_REQUESTED,
    ADD_NEW_PROJECT_SUCCEEDED,
    EDIT_PROJECT_REQUESTED,
    EDIT_PROJECT_SUCCEEDED,
    DELETE_PROJECT_REQUESTED,
    DELETE_PROJECT_SUCCEEDED,
    GET_SEGMENTS_REQUESTED,
    GET_SEGMENTS_SUCCEEDED,
    GET_SELLERS_REQUESTED,
    GET_SELLERS_SUCCEEDED
} from './actions';
import { ADD_ERROR, REMOVE_ERRORS, SHOW_SPINNER, REMOVE_SPINNER } from '../app/actions';
import t from '../i18n';
import {
    fetchProjects,
    addNewProject,
    editProject,
    deleteProject,
    projectImageUploader,
    fetchRiskSegments,
    contactImageUploader,
    editContact,
    addNewContact,
    deleteContact,
    fetchSellersFromCountry
} from '../services/api';
import { valuePromise } from '../utils/utils';

function* getProjectsRequested(action) {
    try {
        let existingProjects = undefined;
        if (action.payload && !action.payload.reload) {
            existingProjects = yield select((state: any) => state.projects.projects);
        }
        if (existingProjects && existingProjects.length > 0) {
            yield put({ type: GET_PROJECTS_SUCCEEDED, payload: { projects: existingProjects } });
        }
        else {
            yield put({ type: SHOW_SPINNER });
            yield put({ type: REMOVE_ERRORS });

            const projects = yield call(fetchProjects);

            yield put({ type: GET_PROJECTS_SUCCEEDED, payload: { projects: projects } });
            yield put({ type: REMOVE_SPINNER });
        }
    } catch (e) {
        yield put({
            type: ADD_ERROR, payload: {
                title: t('web.common.errorTitleGeneric'),
                message: t('web.common.errorMessageGeneric')
            }
        });
    }
}

function* getSellersRequested(action) {
    try {
       let sellers = yield call(fetchSellersFromCountry, action.country_id);
       yield put({type: GET_SELLERS_SUCCEEDED, payload: { sellers } })
    } catch (e) {
        yield put({
            type: ADD_ERROR, payload: {
                title: t('web.common.errorTitleGeneric'),
                message: t('web.common.errorMessageGeneric')
            }
        });
    }
}

function* addNewProjectRequested(action) {
    try {
        let contacts = action.payload.client.contacts || [];
        action.payload.client.contacts = []; // contacts are updated via contact api's
        const project = yield call(addNewProject, action.payload);
        if (action.payload.imageFile) {
            yield call(
                projectImageUploader,
                project.project_id,
                action.payload.imageFile
            );
            // add image to project so that images will reload correctly
            if (!project.image) {
                project.image = `${project.project_id}/${action.payload.imageFile.name}`;
            }
        }
        // add client id from created project to all contacts
        contacts = contacts.map(contact => {
            contact.client_id = project.client_id;
            return contact;
        });
        let updatedContacts = yield call(updateContacts, project.project_id, contacts);
        project.client.contacts = updatedContacts;
        yield put({ type: ADD_NEW_PROJECT_SUCCEEDED, payload: { project: project } });
        browserHistory.push(`/project/${project.project_id}`);
    } catch (e) {
        yield put({
            type: ADD_ERROR, payload: {
                title: t('web.common.errorTitleGeneric'),
                message: t('web.newProject.errorCreatingProject')
            }
        });
        // yield put({ type: SET_NOT_FETCHING });
    }
}

function* editProjectRequested(action) {
    try {
        let contacts = action.payload.client.contacts;
        action.payload.client.contacts = []; // contacts are updated via contact api's
        const updatedProject = yield call(editProject, action.payload.project_id, action.payload);
        if (action.payload.imageFile) {
            yield call(
                projectImageUploader,
                action.payload.project_id,
                action.payload.imageFile
            );
            // add image to project so that images will reload correctly
            if (!updatedProject.image) {
                updatedProject.image = `${updatedProject.project_id}/${action.payload.imageFile.name}`;
            }
        }
        let updatedContacts = yield call(updateContacts, updatedProject.project_id, contacts);
        updatedProject.client.contacts = updatedContacts;
        yield put({ type: EDIT_PROJECT_SUCCEEDED, payload: { project: updatedProject } });
        browserHistory.push(`/project/${updatedProject.project_id}`);
    } catch (e) {
        yield put({
            type: ADD_ERROR, payload: {
                title: t('web.common.errorTitleGeneric'),
                message: t('web.newProject.errorEditingProject')
            }
        });
        // yield put({ type: SET_NOT_FETCHING });
    }
}

function* deleteProjectRequested(action) {
    let response = yield call(deleteProject, action.payload.project_id, {});
    yield put({ type: DELETE_PROJECT_SUCCEEDED, payload: { project_id: action.payload.project_id } });
    browserHistory.push(`/`);
}    

function* updateContacts(project_id: number, contacts: any[]) {
    let updateContacts = [];
    for (let contact of contacts) {
        let apiContact = { ...contact, imageFile: undefined }; // don't send image when updating/adding contact
        let imageFile = contact.imageFile;
        if (apiContact.contact_id && apiContact.isModified) {
            apiContact = yield call(editContact, project_id, apiContact.contact_id, apiContact);
        }
        if (!apiContact.contact_id) {
            apiContact.project_id = project_id;
            apiContact = yield call(addNewContact, project_id, apiContact);
        }
        if (imageFile) {
            let result = yield call(updateContactImage, project_id, apiContact.contact_id, imageFile);
            apiContact.photo_url = result.message;
        }
        updateContacts.push(apiContact);
    }
    return updateContacts;
}

function* updateContactImage(project_id: number, contact_id: number, imageFile) {
    if (project_id && contact_id && imageFile) {
        return yield call(contactImageUploader, project_id, contact_id, imageFile);
    }
}

function* getSegmentsRequested() {
    try {
        let segments = yield select((state: any) => state.projects.segments);
        if (!segments || segments.length === 0) {
            segments = yield call(fetchRiskSegments);
        }
        yield put({ type: GET_SEGMENTS_SUCCEEDED, payload: { segments } });
    } catch (e) {
        yield put({
            type: ADD_ERROR, payload: {
                title: t('web.common.errorTitleGeneric'),
                message: t('Couldnt fetch project to edit. Please try to refresh'),
                status: e.status
            }
        });
    }
}

export function* watchProjectsRequests() {
    yield takeEvery(GET_PROJECTS_REQUESTED, getProjectsRequested);
    yield takeEvery(EDIT_PROJECT_REQUESTED, editProjectRequested);
    yield takeEvery(ADD_NEW_PROJECT_REQUESTED, addNewProjectRequested);
    yield takeEvery(DELETE_PROJECT_REQUESTED, deleteProjectRequested);
    yield takeEvery(GET_SEGMENTS_REQUESTED, getSegmentsRequested);
    yield takeEvery(GET_SELLERS_REQUESTED, getSellersRequested);
}
