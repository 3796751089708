// @ts-nocheck
// TODO remove above
import React from 'react';
import { Component } from 'react';
import ReactDOM from 'react-dom';

import arson from '../img/arson.svg';
import explosion from '../img/explosion.svg';
import fire from '../img/fire.svg';
import pollution from '../img/pollution.svg';
import accidents from '../img/accidents.svg';

import circleLow from '../img/risk/circle-low.svg';
import circleMedium from '../img/risk/circle-medium.svg';
import circleHigh from '../img/risk/circle-high.svg';
import circleVeryHigh from '../img/risk/circle-very-high.svg';
import circleEmpty from '../img/risk/circle-empty.svg';

import scenarioIndicator from '../img/content-indicator/scenario.svg';
import photoIndicator from '../img/content-indicator/camera.svg';
import noteIndicator from '../img/content-indicator/doc.svg';
import { flatten } from '../utils/utils';
import t, { d } from '../i18n';

import { getTranslatedSolutionName } from '../scenario/scenario';

// Data type definitions

export interface IRisk {
    id: number;
    risk_id?: number;
    checked?: boolean;
    area_id: number;
    area_name: string;
    group_id: number;
    group_name: string;
    impact: number;
    note: string;
    probability: number;
    project_id: number;
    seller_id: number;
    segment_name: string;
    scenarios: IScenario[];
    definition_key: string;
}

export interface IScenario {
    id: number;
    name: string;
    occurence: number[];
    risk_id: number;
    scenario_id: number;
    solutions: ISolution[];
    status: string;
}

export interface ISolution {
    id: number;
    name: string;
    scenario_id: number;
    solution_id: number;
    cat_name?: string;
}

// Components

interface IRiskProps {
    risk: IRisk;
    hasPicture: boolean;
    onClick: () => void;
    allSolutions?: any[];
}

interface IRiskState {}

export class Risk extends Component<IRiskProps, IRiskState> {
    private get riskImage() {
        const risk = this.props.risk;

        if (risk.group_id === 43) return <img src={arson} alt="arson" />;
        if (risk.group_id === 2) return <img src={explosion} alt="explosion" />;
        if (risk.group_id === 42) return <img src={fire} alt="fire" />;
        if (risk.group_id === 4) return <img src={explosion} alt="explosion" />;
        if (risk.group_id === 47)
            return <img src={pollution} alt="pollution" />;
        if (risk.group_id === 6) return <img src={accidents} alt="accidents" />;

        return <img src={accidents} alt="accidents" />;
    }

    private get circleIndicatorImage() {
        const risk = this.props.risk;
        const level = (risk.impact + 1) * (risk.probability + 1);

        if (level === 0) {
            return (
                <img
                    src={circleEmpty}
                    className="risk-indicator"
                    alt="risk indicator"
                />
            );
        }

        if (level > 9)
            return (
                <img
                    src={circleVeryHigh}
                    className="risk-indicator"
                    alt="risk indicator"
                />
            );

        if (level > 4)
            return (
                <img
                    src={circleHigh}
                    className="risk-indicator"
                    alt="risk indicator"
                />
            );

        if (level > 2)
            return (
                <img
                    src={circleMedium}
                    className="risk-indicator"
                    alt="risk indicator"
                />
            );

        return (
            <img
                src={circleLow}
                className="risk-indicator"
                alt="risk indicator"
            />
        );
    }

    private get indicatorText() {
        const { risk } = this.props;
        if (risk.impact === -1 || risk.probability === -1) {
            return '';
        }

        const level = (risk.impact + 1) * (risk.probability + 1);

        if (level > 9) return t('web.risk.matrixVeryHigh');

        if (level > 4) return t('web.risk.matrixHigh');

        if (level > 2) return t('web.risk.matrixMedium');

        return t('web.risk.matrixLow');
    }

    private get solutions(): ISolution[] {
        return flatten(
            this.props.risk.scenarios.map((scenario) => scenario.solutions)
        );
    }

    getImpactColor(level) {
        if (level === -1) {
            return '';
        }

        if (level >= 3) return 'red';

        if (level >= 2) return 'orange';

        if (level >= 1) return 'yellow';

        return 'green';
    }

    getImpactFraction(level) {
        if (level === -1) {
            return '';
        }

        if (level >= 3) return '100%';

        if (level >= 2) return '75%';

        if (level >= 1) return '50%';

        return '25%';
    }

    render() {
        return (
            <div
                className="risk pure-u-1 pure-u-md-1-2"
                onClick={this.props.onClick}
            >
                <span className="risk__title">
                    {d('risk.risk_group', this.props.risk.group_name)}
                </span>
                <div className="risk__content">
                    <div className={'risk__icons'}>
                        <div className="risk__image">
                            <span className="risk__warning">
                                {this.indicatorText}
                            </span>
                            {this.circleIndicatorImage}
                        </div>
                        <div className="risk__indicators">
                            <Risk.ContentIndicators
                                risk={this.props.risk}
                                hasPicture={this.props.hasPicture}
                            />
                        </div>
                    </div>
                    <div className="risk__bars">
                        <p>{t('web.risk.probability')}</p>
                        <div
                            style={{
                                backgroundColor: 'lightgray',
                                height: '14px',
                                width: '80%',
                                borderRadius: '150px',
                            }}
                        >
                            <div
                                style={{
                                    width: this.getImpactFraction(
                                        this.props.risk.probability
                                    ),
                                    height: '14px',
                                    backgroundColor: this.getImpactColor(
                                        this.props.risk.probability
                                    ),
                                    borderRadius: '150px',
                                }}
                            />
                        </div>
                        <div className="bar" />
                        <p>{t('web.risk.impact')}</p>
                        <div
                            style={{
                                backgroundColor: 'lightgray',
                                height: '14px',
                                width: '80%',
                                borderRadius: '150px',
                            }}
                        >
                            <div
                                style={{
                                    width: this.getImpactFraction(
                                        this.props.risk.impact
                                    ),
                                    height: '14px',
                                    backgroundColor: this.getImpactColor(
                                        this.props.risk.impact
                                    ),
                                    borderRadius: '150px',
                                }}
                            />
                        </div>
                        <div className="bar" />
                    </div>
                    <div className="risk__assement" />
                    <div className={'risk__solutions'}>
                        <Risk.Solutions
                            solutions={this.solutions}
                            allSolutions={this.props.allSolutions}
                        />
                    </div>
                </div>
            </div>
        );
    }
}

export default Risk;

export namespace Risk {
    interface IContentIndicatorsProps {
        risk: IRisk;
        hasPicture: boolean;
    }

    interface IContentIndicatorsState {}

    export class ContentIndicators extends Component<
        IContentIndicatorsProps,
        IContentIndicatorsState
    > {
        render() {
            const hasScenario = this.props.risk.scenarios.length > 0;
            const hasPicture = this.props.hasPicture;
            const hasNote = this.props.risk.note;

            let indicators = [];

            if (hasScenario) indicators.push(scenarioIndicator);
            if (hasPicture) indicators.push(photoIndicator);
            if (hasNote) indicators.push(noteIndicator);

            return (
                <div>
                    {indicators.map((indicator, i) => {
                        return (
                            <img
                                className="risk__indicator"
                                src={indicator}
                                key={i}
                            />
                        );
                    })}
                </div>
            );
        }
    }

    interface ISolutionsProps {
        solutions: ISolution[];
        allSolutions: any[];
    }

    interface ISolutionsState {
        solutionsWidthOverflows: boolean;
    }

    export class Solutions extends Component<ISolutionsProps, ISolutionsState> {
        constructor(props, state) {
            super(props, state);

            this.state = { solutionsWidthOverflows: false };
        }

        componentDidMount() {
            window.addEventListener('resize', this.onResize.bind(this));
            this.onResize();
        }

        componentWillUnmount() {
            window.removeEventListener('resize', this.onResize.bind(this));
        }

        onResize() {
            this.setState({
                solutionsWidthOverflows: this.solutionsWidthOverflows,
            });
        }

        private get uniqueSolutionNames(): string[] {
            const solutions = this.props.solutions.map((sol) => {
                const cat = this.props.allSolutions.find(
                    (s) => s.id === sol.solution_id
                );
                sol.cat_name = cat ? cat.cat_name : '';
                return sol;
            });

            return Array.from(
                new Set<string>(
                    solutions.map((s) => getTranslatedSolutionName(s))
                )
            );
        }

        private get solutionsWidthOverflows(): boolean {
            const $container = ReactDOM.findDOMNode(this.refs.container);
            if (!$container) return false;

            const $parentContainer = $container.parentElement;
            if (!$parentContainer) return false;

            return $parentContainer.scrollWidth > $parentContainer.clientWidth;
        }

        render() {
            return (
                <div
                    ref="container"
                    className={
                        this.state.solutionsWidthOverflows
                            ? ''
                            : 'centered-content'
                    }
                >
                    {this.uniqueSolutionNames.map((name, i) => {
                        return <span key={i}>{name}</span>;
                    })}
                </div>
            );
        }
    }
}
