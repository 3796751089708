
import React from 'react';

class GrowableTextArea extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      hasFocus: false,
      internalValue: this.props.value || this.props.defaultValue
    }
  }

  componentDidMount = () => {
    this.setState({}); // Trigger rerender when the DOM is loaded, since this component relies on $refs
  }

  handleOnFocus = e => {
    this.setState({ hasFocus: true});

    if(this.props.onFocus) {
      this.props.onFocus(e);
    }
  }

  handleOnBlur = e => {

    this.setState({ hasFocus: false});
    
    if(this.props.onBlur) {
      this.props.onBlur(e);
    }
  }

  handleOnChange = e => {

    this.setState({
      internalValue: e.target.value
    });

    if(this.props.onChange) {
      this.props.onChange(e);
    }
  }

  getRowSize = () => {
    const defaultSize = this.props.defaultSize || 4;

    const focus = this.props.keepExpanded || this.state.hasFocus;

    if(!this.refs.textArea || !focus || !this.state.internalValue) {
        return defaultSize;
    }

    const width = this.refs.textArea.offsetWidth;
    const charSize = parseInt(window.getComputedStyle(this.refs.textArea).fontSize.replace('px', ''));
    const charsPerRow = (width / charSize) + 15;
    let rows = this.state.internalValue.length / charsPerRow;

    let newlines = (this.state.internalValue.match(/\n/g) || []).length;

    return Math.max(defaultSize, newlines, rows);
  }

  getFilteredProps = () => {
    let props = { ...this.props };
    delete props['defaultSize'];
    delete props['keepExpanded'];
    return props;
  }

  render = () => {

    let props = this.getFilteredProps();

    return (
      <textarea
        ref={"textArea"}
        rows={this.getRowSize()}
        { ...props }
        onChange={this.handleOnChange}
        onFocus={this.handleOnFocus}
        onBlur={this.handleOnBlur}
      ></textarea>
    );
  }

}

export default GrowableTextArea;