import * as React from 'react';

import NewCustomer from './new-customer';
import t from '../i18n';
import { deleteContact } from '../services/api';

export interface NewCustomersProps {
    customers: any[];
    client_id: number;
    project_id: number;

    customersUpdated(updatedCustomers: any[]): void;
}

const NewCustomers = ({ customers, customersUpdated, client_id, project_id }: NewCustomersProps) => {
    const update = function (index) {
        return function (updatedCustomer) {
            let newCustomers = [...customers];
            if (updatedCustomer === undefined) { // undefined in updated is treated as deleted customer
                let deleted = newCustomers.splice(index, 1)[0];
                deleteContact(deleted.project_id, deleted.contact_id, deleted);
            }
            else {
                newCustomers[index] = updatedCustomer;
            }
            customersUpdated(newCustomers);
        };
    };

    const addNewCustomer = (event) => {
        event.preventDefault();
        let newCustomer: any = {
            client_id: client_id ? client_id : undefined,
            project_id: project_id,
            firstName: '',
            lastName: '',
            position: '',
            formal_title: '',
            email: '',
            phone1: '',
            phone2: ''
        };
        customersUpdated([...customers, newCustomer]);
    };

    return (
        <div className="customers">
            <div className="customers__new-customer">
                <button type="button" className="btn customers__new-customer-button"
                        onClick={addNewCustomer}>{t('web.newCustomer.additionalContact')}</button>
            </div>
            {
                customers.map((customer, index) =>
                    <NewCustomer
                        key={index}
                        showDelete={index > 0}
                        customer={customer}
                        customerUpdated={update(index)}/>
                )
            }
        </div>
    )
}


export default NewCustomers;
