import React from 'react';
import PropTypes from 'prop-types';

import t from '../i18n';

const NewProjectButton = ({ clickListener }) =>
    <div className="projects__new-project">
        <button className="btn projects__new-project-button" onClick={clickListener}>{t('web.projects.newProject')}</button>
    </div>

NewProjectButton.propTypes = {
    clickListener: PropTypes.func.isRequired
}

export default NewProjectButton;
