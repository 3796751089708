import React from 'react'

const Calc = ({fillColor}) => {
  return (
    <svg style={{margin: '0 auto'}} xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
      <title>Calc</title>
      <path fill={fillColor} id="a"
            d="M2.291 2C2.131 2 2 2.13 2 2.291V21.71c0 .16.13.291.291.291H21.71c.16 0 .291-.13.291-.291V2.29c0-.16-.13-.291-.291-.291H2.29zM2 0h20a2 2 0 0 1 2 2v20a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V2a2 2 0 0 1 2-2zm4 7h4a1 1 0 0 1 0 2H6a1 1 0 1 1 0-2zm2 7.195l-.709-.708a1 1 0 1 0-1.414 1.414l.708.708-.706.707a1 1 0 0 0 1.414 1.414l.706-.707.707.707a1 1 0 1 0 1.414-1.414l-.707-.707.708-.708a1 1 0 1 0-1.414-1.414L8 14.195zM9.085 6v4a1 1 0 0 1-2 0V6a1 1 0 1 1 2 0zm5 1h4a1 1 0 1 1 0 2h-4a1 1 0 0 1 0-2zM14 16h4a1 1 0 0 1 0 2h-4a1 1 0 0 1 0-2zm0-3h4a1 1 0 0 1 0 2h-4a1 1 0 0 1 0-2z"/>
    </svg>
  )
}

export default Calc;
