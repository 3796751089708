export const FETCH_ADVANCED_RISK_SCAN_REQUESTED = 'FETCH_ADVANCED_RISK_SCAN_REQUESTED';
export const FETCH_ADVANCED_RISK_SCAN_SUCCEEDED = 'FETCH_ADVANCED_RISK_SCAN_SUCCEEDED';

export const EDIT_ADVANCED_RISKS_CAN_REQUESTED = 'EDIT_ADVANCED_RISKS_CAN_REQUESTED';
export const EDIT_ADVANCED_RISKS_CAN_SUCCEEDED = 'EDIT_ADVANCED_RISKS_CAN_SUCCEEDED';

export const EDIT_QUESTIONARIES_REQUESTED = 'EDIT_QUESTIONARIES_REQUESTED';
export const EDIT_QUESTIONARIES_SUCCEEDED = 'EDIT_QUESTIONARIES_SUCCEEDED';

export const POST_SURVEY_ATTACHMENT_REQUESTED = 'POST_SURVEY_ATTACHMENT_REQUESTED';
export const POST_SURVEY_ATTACHMENT_SUCCEEDED = 'POST_SURVEY_ATTACHMENT_SUCCEEDED';

export const EDIT_SURVEY_ATTACHMENT_REQUESTED = 'EDIT_SURVEY_ATTACHMENT_REQUESTED';
export const EDIT_SURVEY_ATTACHMENT_SUCCEEDED = 'EDIT_SURVEY_ATTACHMENT_SUCCEEDED';

export const DELETE_SURVEY_ATTACHMENT_REQUESTED = 'DELETE_SURVEY_ATTACHMENT_REQUESTED';
export const DELETE_SURVEY_ATTACHMENT_SUCCEEDED = 'DELETE_SURVEY_ATTACHMENT_SUCCEEDED';

export const EDIT_SUMMARY_REQUESTED = 'EDIT_SUMMARY_REQUESTED';
export const EDIT_SUMMARY_SUCCEEDED = 'EDIT_SUMMARY_SUCCEEDED';

