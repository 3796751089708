// @ts-nocheck
// TODO remove above
import React from 'react';
import Spinner from '../spinner/spinner';

interface PdfPageProps {
    index: number;
    pdf: any;
    scale: number;
}

export default class PdfPage extends React.Component<PdfPageProps, any> {

    state = {
        status: 'N/A',
        page: null,
        width: 0,
        height: 0
    }    

    shouldComponentUpdate(nextProps, nextState) {
        return this.props.pdf !== nextProps.pdf || this.state.status !== nextState.status;
    }

    componentDidUpdate(nextProps, nextState) {
        this.update(nextProps.pdf);
    }

    componentDidMount() {
        this.update(this.props.pdf);
    }

    update(pdf) {
        if (pdf) {
            this.loadPage(pdf);
        } else {
            this.setState({ status: 'loading' });
        }
    }

    loadPage(pdf) {
        if (this.state.status === 'rendering' || this.state.page != null) {
            return;
        }
        pdf.getPage(this.props.index).then(this.renderPage.bind(this));
        this.setState({ status: 'rendering' });
    }

    renderPage(page) {
        const view = page.view;
        const viewport = page.getViewport({ scale: this.props.scale });
        const { width, height } = viewport;
        const canvas = this.refs.canvas as any;
        const context = canvas.getContext('2d');

        canvas.width = width;
        canvas.height = height;

        page.render({
            canvasContext: context,
            viewport
        });
        this.setState({ status: 'rendered', page, width, height });
    }

    render() {
        const { width, height, status } = this.state;
        return (
            <div className={`proposal__pdf-page`} style={{width, height}}>
                {(status === 'loading' || status === 'rendering') &&
                <Spinner/>
                }
                <canvas ref='canvas'/>
            </div>
        );
    }
}
