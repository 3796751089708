// @ts-nocheck
// TODO remove above
import React, { Component } from 'react';
import PDFViewer from './pdf-viewer';
import t from '../i18n';
import classNames from 'classnames';

import download_icon from '../img/download.svg';
import edit_icon from '../img/edit.svg';
import { IPDFType } from './proposal.container';


interface ISignablePDFProps {
    pdf: any;
    signingStatus: string;
    downloadPdfHandler: Function;
    downloadOfferHandler?: Function;
    refreshPdf: Function;
    documentName: string;
    project: any;
    offerletter?: boolean;
    contract?: any;
    showRefreshButton: boolean;
}

interface ISignablePDFState {
    isSubmitting: boolean;
    submitButtonDots: string;
    submitButtonDotsIntervalId: number;
    project: any;
    showRefreshButton: boolean;
}

export default class SignablePDF extends Component<ISignablePDFProps, ISignablePDFState> {

    state = {
        isSubmitting: false,
        submitButtonDots: '',
        submitButtonDotsIntervalId: -1,
        project: null,
    };

    messages = {
        WAITING: t('web.proposal.signingProcessWaiting'),
        COMPLETE: t('web.proposal.signingProcessComplete'),
        MISSING_CONTACTINFO: t('web.proposal.signingProcessMissingContactinfo')
    };

    get signMessage(): string {
        return this.messages[this.props.signingStatus] || t('web.proposal.signingProcessNotStarted');
    }

    get signButtonDisabled(): boolean {
        return this.props.signingStatus in this.messages;
    }

    public handleDownloadForEdit = () => {
        this.props.downloadOfferHandler ? this.props.downloadOfferHandler() : this.props.downloadPdfHandler();
    }

    public render() {
        const { signingStatus, pdf } = this.props;
        
        return (
            <div className="proposal">
                {pdf && pdf !== 404 && 
                <div>
                    <div className="tabs__container">
                        <div className="container">
                            <div className="pdf-edit-menu">
                                <button 
                                    className="btn download-btn" 
                                    onClick={this.handleDownloadForEdit}
                                    ><object className="svg-icon" data={download_icon} type="image/svg+xml"></object><span>{t('web.proposal.downloadPdf')}</span>
                                </button>
                                <label>{this.props.documentName}</label>
                                {this.props.showRefreshButton && 
                                    <button style={{ marginLeft: '10px'}} className="btn download-btn" onClick={this.props.refreshPdf}>
                                        <object className="svg-icon" data={edit_icon} type="image/svg+xml"></object><span>Update document</span>
                                    </button>
                                }
                            </div>
                            
                            <PDFViewer pdf={pdf} scale={1.1}/>
                        </div>
                    </div>
                </div>
                }
            </div>
        );
    }
}
