import React, { Component } from 'react';
import EditArea from './edit-area';
import t from '../i18n/index';
import './area.css';
import Modal from '../common/modal/modal';
import { connect } from 'react-redux';

import {
    CREATE_CUSTOM_AREA_REQUESTED,
    CREATE_CUSTOM_AREA_SUCCEEDED,
} from './actions';

export interface ICustomArea {
    id: number;
    name: string;
    segmentId: number;
    projectId: number;
    areaId: number;
}

export class EditAreas extends Component<IEditAreas, {}> {
    state = {
        isAddingCustomArea: false,
        newAreaName: '',
        error: false,
        errorMessage: '',
        shouldClose: false,
    };

    constructor(props) {
        super(props);

        this.onChangeHandler = this.onChangeHandler.bind(this);
        this.onSubmitHandler = this.onSubmitHandler.bind(this);
    }

    toggleModal = () => {
        this.setState({ isAddingCustomArea: !this.state.isAddingCustomArea });
        this.removeError();
    };

    closeModal = () => {
        this.setState({ isAddingCustomArea: false });
    };

    onChangeHandler(event) {
        this.setState({ newAreaName: event.target.value });
    }

    onSubmitHandler() {
        this.props.dispatchAction({
            type: CREATE_CUSTOM_AREA_REQUESTED,
            payload: {
                name: this.state.newAreaName,
                project_id: this.props.projectId,
                segment_id: this.props.segment_id,
            },
        });
        this.setState({ shouldClose: true });
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.createCustomAreaState) {
            const props = nextProps.createCustomAreaState;
            this.setState({
                error: props.error,
                errorMessage: props.message,
            });
            if (props.error === false && this.state.shouldClose) {
                this.closeModal();
            }
        }
    }

    removeError() {
        this.setState({
            error: false,
            errorMessage: '',
        });
    }

    public render() {
        return (
            <div className="edit-areas">
                <div
                    style={{
                        height: '3em',
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                    }}
                >
                    <h3>Areas</h3>
                    {this.props.projectAreas.length > 0 && (
                        <button
                            className="close btn"
                            onClick={this.props.onClose}
                        >
                            {t('web.common.close')}
                        </button>
                    )}
                </div>
                <div className="areas-container">
                    {this.props.areas.map((area, i) => {
                        let checked =
                            this.props.projectAreas.find(
                                (area2) => area2.area_id === area.area_id
                            ) != null;
                        return (
                            <EditArea
                                area={area}
                                onSelectHandler={this.props.onSelectHandler}
                                checked={checked}
                                key={i}
                            />
                        );
                    })}
                    {this.state.isAddingCustomArea && (
                        <Modal
                            title={t('web.risk.addCustomArea')}
                            onClose={this.toggleModal}
                        >
                            {this.state.error && (
                                <span className="error-message">
                                    {this.state.errorMessage}
                                </span>
                            )}
                            <input
                                type="text"
                                className="input-form__input"
                                onChange={this.onChangeHandler}
                                placeholder={t('web.risk.newAreaName')}
                            />
                            <div className="custom-area-buttons">
                                <button
                                    className="btn"
                                    onClick={this.onSubmitHandler}
                                >
                                    {t('web.proposal.savePdf')}
                                </button>
                                <button
                                    className="btn"
                                    onClick={this.toggleModal}
                                >
                                    {t('web.common.cancel')}
                                </button>
                            </div>
                        </Modal>
                    )}
                    <a className="custom-area" onClick={this.toggleModal}>
                        {t('web.risk.addCustomArea')}
                    </a>
                </div>
            </div>
        );
    }
}

const mapStateToProps = ({ risks }) => {
    return {
        ...risks,
    };
};

const mapDispatchToProps = (dispatch) => ({
    dispatchAction: (action) => dispatch(action),
});

export default connect(mapStateToProps, mapDispatchToProps)(EditAreas as any);
