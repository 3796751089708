// @ts-nocheck
// TODO remove above
import * as React from 'react';

import placeholder from '../img/placeholder-dark.svg';
import './projects.css';
import phone from '../img/phone.svg';
import mail from '../img/mail.svg';
import edit from '../img/edit.svg';
import download from '../img/download.svg';
import share from '../img/share.svg';
import profile from '../img/profile.svg';
import t from '../i18n';
import { getProjectImageUrl, fetchImageBlob} from '../services/api';
import { Image } from '../image/image';
import Spinner from '../spinner/spinner';
import BlurredBackground from '../common/background/blurred-background';


export interface ProjectsGridItemProps {
    project: any;
    clickListener: (e) => any;
    editClickListener: (e) => any;
    downloadClickListener: (e) => any;
    shareClickListener: any;
}

function getStatus(status: number) {
    let className = "projects__list-inline-row--right-status";
    switch (status) {
        case 0:
            return <span className={className}>{t('web.projects.status.signingnotstarted')}</span>;
        case 1:
            return <span className={className}>{t('web.projects.status.signingstarted')}</span>;
        case 2:
            return <span className={className}>{t('web.projects.status.signingcompleted')}</span>;
    }
}

class ProjectsGridItem extends React.Component<ProjectsGridItemProps> {

    state = {
        projectImage: null
    }

    componentDidMount() {
        let projectImageUrl = this.props.project.image ? getProjectImageUrl(this.props.project.project_id) : undefined;
        if(projectImageUrl) {
            fetchImageBlob(projectImageUrl).then(blobSrc => {
                this.setState({ projectImage: blobSrc });
            });
        }
    }
    
    
    public render() {
        
        if (!this.props.project.client.contacts) {
            return null;
        }

        const contact = this.props.project.client.contacts[0] || {};
        const showPhone = !!contact.phone1;

        return (
            <div className="projects__grid-item">
                <div className="projects__image-container" title={t('web.projects.info.open')} onClick={() => this.props.clickListener(this.props.project.project_id)}>
                    {this.state.projectImage && 
                        <BlurredBackground backgroundImage={this.state.projectImage}>
                            <Image className="projects__image projects__image--placeholder" src={this.state.projectImage}
                                alt={t('web.common.project')} onClick={() => this.props.clickListener(this.props.project.project_id)}/> 
                        </BlurredBackground>
                    }
                    {!this.state.projectImage &&
                    <div className="project__text-placeholder" onClick={() => this.props.clickListener(this.props.project.project_id)}>{t('web.projects.no_image')}</div>
                        
                    }
                    <div className="projects__profile-image">
                        <Image src={contact.photo_url || profile} className={contact.photo_url ? "profile-image" : ""}
                            alt={t('web.projects.download')} onClick={this.props.editClickListener} />
                    </div>
                    <div className="projects__edit-image">
                        <div>
                            <img title={t('web.projects.info.share')} src={share} alt={t('web.projects.share')} onClick={(e) => { e.stopPropagation(); this.props.shareClickListener(this.props.project) }} />
                        </div>
                        {
                            this.props.project.isDownloading &&
                            <div className="projects__download-spinner">
                                <Spinner/>
                            </div>
                        }
                        {
                            !this.props.project.isDownloading &&
                            <div>
                                <img title={t('web.projects.info.download')} src={download} alt={t('web.projects.download')} onClick={this.props.downloadClickListener}/>
                            </div>
                        }
                        <div>
                            <img title={t('web.projects.info.edit')} className="" src={edit} alt={t('web.projects.edit')} onClick={this.props.editClickListener}/>
                        </div>
                    </div>
                </div>
                <div className="projects__grid-item-inner-container" onClick={() => this.props.clickListener(this.props.project.project_id)}>
                    <div className="projects__list-left-column">
                        <h4 className="projects__title">{this.props.project.name}</h4>
                        <div>
                            <span>{this.props.project.client.streetAddressOne}</span><br/>
                            <span>{this.props.project.client.postalCodeOne}</span> <span>{this.props.project.client.cityOne}</span>
                        </div>
                    </div>
                    <div className="projects__list-right-column">
                        <div>
                            {getStatus(this.props.project.progress_status)}
                        </div>
                        <div>{contact.firstName} {contact.lastName}
                            <a title={t('web.projects.info.sendEmail')} href={"mailto:" + contact.email}>
                                <img src={mail} alt={t('web.common.phone')} className="projects__list-inline-row-image"/>
                            </a>
                        </div>
                        {showPhone &&
                        <div>
                            <span>{contact.phone1}</span>
                            <a title={t('web.projects.info.call')} href={"tel:" + contact.phone1}>
                                <img src={phone} alt={t('web.common.phone')} className="projects__list-inline-row-image"/>
                            </a>
                        </div>
                        }
                    </div>
                </div>
            </div>
        );
    }
}

export default ProjectsGridItem;
