// @ts-nocheck
// TODO remove above

import React from 'react';
import { connect } from 'react-redux';
import { Image } from '../image/image';
import phone from '../img/phone.svg';
import mail from '../img/mail.svg';
import { browserHistory } from 'react-router';
import { getProjectImageUrl } from '../services/api';
import { getProjectById } from '../projects/reducer';
import t, { d } from '../i18n';
import Page from '../common/page/page';
import {GET_SEGMENTS_REQUESTED} from '../projects/actions';
import BlurredBackground from '../common/background/blurred-background';

class ProjectDetails extends React.Component {

  state = {
    segments: []
  }

  componentWillMount() {
    this.props.dispatchAction({
        type: GET_SEGMENTS_REQUESTED
    });
}

  handleEditClick = () => {
    browserHistory.push(`/project/${this.props.project.project_id}/edit`);
  }

  public render() : any {

    const project = this.props.project;
    const projectImageUrl = project.image ? getProjectImageUrl(project.project_id) : undefined;


    let segmentName = project.segment;
    const segment = this.props.segments.find(segment => segment.segment_id === project.segment_id);
    if(segment) {
      segmentName = d('risk', segment.segment_name);
    }

    const address = `${project.client.streetAddressOne}, ${project.client.postalCodeOne} ${project.client.cityOne}`;
    const billingAddress = `${project.client.streetAddressTwo}, ${project.client.postalCodeTwo} ${project.client.cityTwo}`;

    const editButton = (
      <span className="project__details--edit" onClick={this.handleEditClick}>
        <button className="btn">{t('web.common.edit')}</button>
      </span>
    );

    return (
      <Page includePadding={false} title={""} showSpinner={false} >
        <div className="project__details">
          <div>{editButton}</div>  
          <div className="project__details--image">
            {projectImageUrl &&
              <BlurredBackground
                backgroundImage={projectImageUrl}
                height="20rem"
              >
                <Image className="" src={projectImageUrl}
                    alt={t('web.common.project')}/> 
              </BlurredBackground>
            }
          </div>
          <div className="project__details--title">
            <div className="project__details--title--address">
              {project.client.streetAddressOne &&
                <div>
                  <span>{t('web.newProject.visitingAddress')}: </span>
                  <a href={`https://maps.google.com/?q=${address}`} target="_blank">
                    {address}
                  </a>
                </div>
              }
              {project.client.streetAddressTwo &&
                <div>
                  <span>{t('web.newProject.billingAddress')}: </span>
                  <a href={`https://maps.google.com/?q=${billingAddress}`} target="_blank">
                    {billingAddress}
                  </a>
                </div>
              }
              {project.client.organisationNumber &&
                <div><span>{t('web.newProject.companyRegistrationNumber')}: </span>{project.client.organisationNumber}</div>
              }
            </div>
            <h1>{project.name}</h1>
            <p className="project__details--title--segment">{d('risk', segmentName)}</p>
            <div style={{ clear: 'both' }} />
          </div>
          <div className="project__details--contacts">
            {project.client.contacts.map(contact => {
              return (
                <div key={contact.client_id + contact.contact_id} className="project__details--contacts--item">
                  <div className="item--contact--image">
                    <Image src={contact.photo_url} />
                  </div>
                  <p className="item--contact">{contact.firstName} {contact.lastName} <em>{contact.position && `- ${contact.position}`}</em></p>
                  <p className="item--phone"><a href={"tel:"+contact.phone}><img className="" src={phone} /> {contact.phone1}</a></p>
                  <p className="item--email">
                    <a href={"mailto:"+contact.email}><img className="" src={mail} /> {contact.email}</a>
                  </p>
                </div>
              );
            })}
          </div>
        </div>
      </Page>
    );
  }
}

const mapStateToProps = ({ projects, user}, { routeParams }) => {
  let project = getProjectById(projects, routeParams.id);
  return {
      project,
      segments: projects.segments || [],
      user
  }
};

const mapDispatchToProps = (dispatch) => ({
  dispatchAction: (action) => dispatch(action)
});

export default connect(mapStateToProps, mapDispatchToProps)(ProjectDetails);