import React, {Component} from 'react';
import {connect} from 'react-redux';
import PropTypes from 'prop-types';
import {getAuthorizedUser} from "../auth/auth-helper";
import {startAnalytics, trackPage} from "../utils/google-analytics";

import './app.css';
import BottomMenu from '../bottom-menu/bottom-menu-container';
import Messages from '../message/messages';
import Spinner from '../spinner/spinner';
import classNames from 'classnames';
import {REMOVE_ERRORS} from '../app/actions';
import {getUserActions} from '../user/actions';
import {getCurrentLanguage, setLanguage, loadTranslationsIfNotPresent} from '../i18n/index';

class App extends Component {
  static propTypes = {
    dispatchAction: PropTypes.func.isRequired,
    isFetching: PropTypes.bool.isRequired,
    errorMessages: PropTypes.array
  }

  state = {
    translationsLoading: true,
    trackers: []
  }

  componentWillMount() {
    this.props.dispatchAction(getUserActions.started())
  }

  componentDidMount() {
    this.setUserLanguage(this.props);
  }

  componentWillReceiveProps(nextProps) {
    this.setUserLanguage(nextProps);
    const trackers = startAnalytics(this.state.trackers, this.props.user, this.props.location.pathname, this.props.location.search);
    if (trackers) {
      this.setState({ trackers });
    }
  }

  componentDidUpdate(prevProps) {

    if(this.state.trackers.length > 0 && prevProps.location.pathname !== this.props.location.pathname) {
      trackPage(this.state.trackers, this.props.location.pathname, this.props.location.search);
    }

    if ((prevProps.errors && prevProps.errors.length > 0) && prevProps.errors === this.props.errors) {
      this.props.dispatchAction({
        type: REMOVE_ERRORS
      });
    }
  }

  setUserLanguage(props) {
    let currentLanguage = getCurrentLanguage();

    loadTranslationsIfNotPresent(currentLanguage);


    if (props.user && props.user.language === currentLanguage) {
      this.setState({translationsLoading: false});
    }

    if (props.user && props.user.language !== currentLanguage) {
      if (this.state.translationsLoading) {
        setLanguage(props.user.language).then(() => {
          this.setState({translationsLoading: false});
        }).catch(() => {
          this.setState({translationsLoading: false});
        });
      }
    }
  }

  render() {
    const {isFetching, errorMessages,} = this.props;
    const showErrorMessages = errorMessages.length > 0;
    return (
      <div className={classNames("app", {"app--faded-background": showErrorMessages})}>
        {showErrorMessages && <div className='container-faded-background'/>}
        <div className={classNames('app__content-container')} style={{'minHeight': `calc(100vh - 5rem)`}}>
          {errorMessages.length > 0 &&
          <Messages messages={errorMessages} isError={true}/>
          }
          {isFetching &&
          <div className='app__spinner'>
            <Spinner/>
          </div>
          }
          {!this.state.translationsLoading && this.props.children}
          <BottomMenu params={this.props.params} location={this.props.location}/>
        </div>
      </div>
    );
  }
}

const mapStateToProps = ({app, user}) => ({
  isFetching: app.isFetching,
  errorMessages: app.errorMessages,
  user: user.user
});

const mapDispatchToProps = (dispatch) => ({
  dispatchAction: (action) => dispatch(action)
});


export default connect(mapStateToProps, mapDispatchToProps)(App);
