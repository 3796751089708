import React from 'react'

const Risk = ({fillColor}) => {
  return (
    <svg style={{margin: 'auto'}} xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
      <title>Risk</title>
      <path fill={fillColor || '#000000'} id="a"
            d="M15.99 10.637a.895.895 0 0 1-.004 1.25l-4.652 4.701a.859.859 0 0 1-1.227-.005l-2.088-2.142a.895.895 0 0 1 .005-1.25.855.855 0 0 1 1.226.005l1.477 1.515 4.037-4.079a.855.855 0 0 1 1.227.005zm5.144-8.078c.476 0 .866.397.866.882v19.677c0 .485-.39.882-.866.882H2.866A.877.877 0 0 1 2 23.118V3.44c0-.485.39-.882.866-.882h2.19c.475 0 .865.397.865.882s-.39.883-.866.883H3.732v17.911h16.54V4.324h-4.647a.877.877 0 0 1-.866-.883V1.765H9.25v2.877h4.484c.477 0 .866.397.866.883 0 .485-.39.882-.866.882h-5.35a.877.877 0 0 1-.866-.882V.882c0-.485.39-.882.866-.882h7.236c.477 0 .866.397.866.882V2.56h4.648z"/>
    </svg>
  )
}

export default Risk;
