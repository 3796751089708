import * as React from 'react';
import t from '../../i18n';
import Modal from '../modal/modal';
import './confirm-dialog.css';

const ConfirmDialog = ({ title, message = "", onCancel, onOK, ...props }) => {

    return (
        <Modal title={title} onClose={onCancel} closeOnClickOutside={false}>
            <div className="confirm-dialog">
                {message &&
                <p>{message}</p>
                }
                {props.children}
                <div className="confirm-dialog__buttonrow">
                    <button className="btn" onClick={onOK}>{t('web.common.ok')}</button>
                    <button className="btn" onClick={onCancel}>{t('web.common.cancel')}</button>
                </div>
            </div>
        </Modal>
    )
}

export default ConfirmDialog;
