import React from 'react'

import checkmark2 from '../img/checkmarkv2.svg';
import uncheckmark2 from '../img/uncheckmarkedv2.svg';
import checkmark from '../img/checkmark.svg';
import uncheckmark from '../img/uncheckmarked.svg';
import './standard-input.css';

let currentId = 0;
const getNextId = (prefix = "form-id") => {
  return `${prefix}-${currentId++}`;
}

export const RadioButton = ({...props}) => {
  let id = getNextId();

  return <div>
    <input
      {...props}
      type="radio"
      id={id}
    />
    <label htmlFor={id}>{props.label}</label>
  </div>
}

export const Checkbox = ({...props}) => {
  let image = props.checked ? checkmark2 : uncheckmark2;
  let id = getNextId();
  return <div style={props.style} className="standard-input__checkbox-container">
    <input {...props} type="checkbox" id={id}/>
    <label htmlFor={id}><img className="standard-input__checkbox" src={image} alt=""/>{props.label}</label>
  </div>
}

export const Checkbox2 = ({...props}) => {
  let image = props.checked ? checkmark : uncheckmark;
  let id = getNextId();
  return <div className="standard-input__checkbox-container">
    <input {...props} type="checkbox" id={id} name={props.name || props.label}/>
    <label htmlFor={id}><img className="standard-input__checkbox" src={image} alt=""/>{props.label}</label>
  </div>
}

export class TextInput extends React.Component {
  state = {text: ""}

  componentDidMount = () => {
    this.setState({text: this.props.defaultValue || this.props.value});
  }
  onChange = (event) => {
    this.setState({text: event.target.value});
    if (this.props.onChange) {
      this.props.onChange(event);
    }
  }

  componentWillReceiveProps(nextProps) {
    this.setState({text: nextProps.defaultValue || nextProps.value});
  }

  render() {
    const {className, disabled, getRef, ...props} = this.props;
    
    return <input
      className={"place__input place__input--standard-input" + (className ? ' ' + className : '')}
      type="text"
      {...props}
      ref={ref => getRef !== undefined ? this.props.getRef(ref) : null}
      value={this.state.text}
      disabled={disabled === undefined ? false : disabled}
      onChange={this.onChange}/>
  }
}
