import { SurveyState } from './reducer';

/**
 * Function that returns a tree representation of the survey
 * @param {*} survey
 */
export function getPlacesTree(survey) {
    if (!survey) return [];

    let rootElements = survey.filter(place => !place.parent_id);
    rootElements.forEach(place => {
        addChildren(place, survey);
    });
    return rootElements;
}

function addChildren(place, allPlaces) {
    let childPlaces = allPlaces.filter(childPlace => childPlace.parent_id === place.id);

    if(place.isQuestionSet) {
        place.childPlaces = [];
        return;
    }

    place.childPlaces = childPlaces.map(childPlace => {
        childPlace.parentPlace = place;
        return childPlace;
    });
    place.childPlaces.forEach(place => {
        addChildren(place, allPlaces);
    })
}

export function getAnswers(surveyState: SurveyState, projectId: number) {
    return surveyState.answers[projectId];
}

export function getImages(surveyState: SurveyState, projectId: number) {
    return surveyState.images[projectId];
}

export function getSummaries(surveyState: SurveyState, projectId: number) {
    return surveyState.summaries[projectId];
}

export function getTreeNode(treeNodes, id, parentId = undefined) {

    let filter = place => place.id === id;
    if(parentId) {
        filter = place => place.id === id && place.parent_id === parentId;
    }

    let node = treeNodes.find(filter);
    if (node) {
        return node;
    }
    for (let place of treeNodes) {
        let n = getTreeNode(place.childPlaces, id, parentId);
        if (n) return n;
    }
    return undefined
}

export function getAnswersForQuestions(questions, allAnswers) {
    let answers = allAnswers.filter(answer => {
        let connectedQuestion = questions.filter(question => {
            return question.id === answer.questions_id;
        });
        return connectedQuestion.length > 0;
    })
    return answers;
}

export function getQuestionsInPlace(place) {
    let questions = getQuestionsInGroups(place.question_groups);
    place.childPlaces.forEach(childPlace => {
        let childQuestions = getQuestionsInPlace(childPlace);
        questions.push(...childQuestions);
    });
    return questions;
}

function getQuestionsInGroups(questionGroups) {
    if (!questionGroups) {
        return [];
    }
    let groups = questionGroups || [];
    let questions = groups.reduce((acc, currentGroup) => {
        let questions = currentGroup.questions || [];
        let childQuestions = getQuestionsInGroups(currentGroup.question_groups);
        return [...acc, ...questions, ...childQuestions];
    }, []);
    return questions;
}

export function getSummary(groupId, summaries) {
    return summaries.find(s => s.questionGroup_id === groupId);
}
