// @ts-nocheck
// TODO remove above
import * as React from 'react';
import Scrollbars from 'react-custom-scrollbars-2';
import t from '../../i18n';
import close from '../../img/close.svg';

import "./modal.css";

export interface ModalProps {
    title: any;
    children: any;
    closeOnClickOutside?: boolean;
    maxHeight?: number;
    minHeight?: number;
    titleElement?: any;
    onClose(e: any): void;
}

class Modal extends React.Component<ModalProps, any> {

    state = {
        maxHeight: this.props.maxHeight ? this.props.maxHeight : 600,
        minHeight: this.props.minHeight ? this.props.minHeight : 150,
    }

    setHeight = (element) => {
        if (element) {
            let header = element.getElementsByTagName("h3")[0];
            let headerHeight = header ? header.offsetHeight : 48;
            let maxHeight = element.clientHeight - headerHeight;
            maxHeight = Math.max(maxHeight, this.state.minHeight);
            this.setState({ maxHeight });
        }
    }

    render() {
        let title = this.props.title ? this.props.title : <span>&nbsp; </span>;
        let closeOnClickOutside = this.props.closeOnClickOutside === undefined ? true : this.props.closeOnClickOutside;
        return (
            <div className="modal" onClick={(e) => {
                if (closeOnClickOutside) this.props.onClose(e)
            }}>
                <div className="modal__container" ref={this.setHeight} onClick={(e) => e.stopPropagation()}>
                    <h3>{this.props.titleElement} {title}</h3>
                    <img className="modal__close" style={{ zIndex: 5 }} onClick={(e) => this.props.onClose(e)}
                         src={close}
                         alt={t('web.common.close')}/>
                    <Scrollbars autoHeight={true} autoHeightMin={this.state.minHeight}
                                autoHeightMax={this.state.maxHeight}>
                        <div className="modal__inner-container" style={{ height: "auto" }}>
                            {this.props.children}
                        </div>
                    </Scrollbars>
                </div>
            </div>
        )
    }
}

export default Modal;
