// @ts-nocheck
// TODO remove above
import React, { Component } from 'react';
import * as calcUtils from './calcUtils';
import information from '../img/information.svg';
import t from '../i18n';
import placeholder from '../img/placeholder-dark.svg';
import ProductDetailedItem from '../products/product-detailed-item';
import ProductImageDialog from '../products/product-image-dialog';
import { endsWith } from '../utils/utils';
import { LeasingProduct } from "./leasing-product";
import { FixedPriceProduct } from "./fixed-price-product";
import { ComboPriceProduct } from "./combo-price-product";
import { formatPriceToCurrency } from './calculation';

export interface IProduct {
    project_id: number;
    project_product_id: number;
    name: string;
    quantity: number;
    price_unit?: string;

    [label: string]: any;
}

interface IProductProps {
    product: IProduct;
    key?: number;
    currency: string;
}

interface IProductState {
    showInfoDialog: boolean;
    showImageDialog: boolean;
}

export default class Product extends Component<IProductProps, IProductState> {

    state = {
        showInfoDialog: false,
        showImageDialog: false
    }

    infoClick = (product) => this.setState({ showInfoDialog: true });

    imageClick = (product) => this.setState({ showImageDialog: true });

    closeInfoModal = () => this.setState({ showInfoDialog: false });

    closeImageModal = () => this.setState({ showImageDialog: false });

    getProductImageUrl = (product) => {
        let allowedFormats = ['.png', '.gif', '.jpg', '.jpeg', '.bmp', '.tiff', '.tif'];
        let returnValue = placeholder;
        if (product.main_photo) {
            allowedFormats.forEach(format => {
                if (endsWith(product.main_photo.url, format)) {
                    returnValue = product.main_photo.url;
                }
            });
        }
        return returnValue;
    }

    getProductImage = (product) => {
        let url = this.getProductImageUrl(product)
        let type = "";
        if (endsWith(url, ".tiff")) {
            type = "image/tiff";
        }
        let onClick = (e) => {
            e.preventDefault();
            // only allow click if we don't fall back to the default image
            if (!endsWith(e.target.currentSrc, placeholder)) {
                this.imageClick(this.props.product);
            }
        };

        return (
            <picture onClick={onClick} className="calculation__item-image">
                <source srcSet={url} type={type}></source>
                <source srcSet={placeholder}></source>
                <img className="calculation__item-image" src={url}/>
            </picture>
        )
    }

    getProductTypeText = (product, country) => {
        let durationInterval = product.lease_period;

        if (country === 'Poland') {
            if (durationInterval == 12) {
                durationInterval = 3;
            } else if (durationInterval == 24) {
                durationInterval = 13;
            } else if (durationInterval == 36) {
                durationInterval = 24;
            } else if (durationInterval == 60) {
                durationInterval = 36;
            }
        }
        if (product instanceof FixedPriceProduct) {
            return t('web.calculation.singlePurchase');
        } else {
            if (!(product instanceof LeasingProduct)) {
                return `${durationInterval} ${t('web.calculation.months')} / ${t('web.calculation.fixedFee')}`;
            }
            return `${durationInterval} ${t('web.calculation.months')}`;
        }
    }

    getAmountOrUnit = (product: IProduct) => {
        if (product.price_unit)
            return product.price_unit;
        else
            return t('web.calculation.amountLabel');
    }

    public render() {
        const price = this.props.product.getDisplayPrice();
        const formatPrice = price => this.props.currency !== '' ?
            formatPriceToCurrency(price, this.props.currency):
            price;
        const country = this.props.country;
        const product = this.props.product.data;

        return (
            <li className="calculation__item">
                {this.state.showImageDialog &&
                <ProductImageDialog product={product} closeDialog={this.closeImageModal}/>
                }
                {this.state.showInfoDialog &&
                <ProductDetailedItem product={product} closeView={this.closeInfoModal} showMedia={true}/>
                }
                <div className="calculation__item-name-container">
                    {this.getProductImage(product)}
                    <div>
                        <img onClick={(e) => this.infoClick(product)}
                             className="calculation__item-information" src={information}/>
                        <span>{product.name}</span><br/>
                        <span
                            className="calculation__item-short-description">{product.short_description}</span><br/>
                    </div>
                </div>
                <div className="calculation__item-sum">
                    <span className="calculation__item_amount--label">{this.getAmountOrUnit(product)}:</span>
                    <span className="calculation__item-amount">{product.quantity}</span>
                    <br/>
                    {(product.fixed_fee && this.product.is_leased) &&
                        <span>a{formatPrice(calcUtils.getTotalPriceforProduct(product))}
                        / {formatPrice(calcUtils.getFixedFeeForProduct(product))}</span>
                    }
                    {!(product.fixed_fee && product.is_leased) &&
                    <span>{
                        price instanceof Array ?
                        `${formatPrice(price[0] * product.quantity)} / ${formatPrice(price[1] * product.quantity) || 0}` :
                        `${formatPrice(price * product.quantity)}`
                    }</span>

                    }
                    <span
                        className="calculation__item-producttype"><br/>{this.getProductTypeText(product, country)}</span>
                </div>
            </li>
        );
    }
}
