import React from 'react';

import Scenario from './scenario';
import { fetchScenarios } from './reducer';
import { connect } from 'react-redux';
import t from '../i18n';
import ScenarioSearch from './scenario-search';
import * as scenarioActions from './actions';
import * as riskActions from '../risk/actions';

class ScenarioContainer extends React.Component {
    state = {
        newSolutionViewExpanded: false,
        scenarioIndex: -1,
    };

    componentDidMount() {
        fetchScenarios(this.props.dispatchAction);
    }

    handleToggleNewSolutionViewExpanded = (index) => {
        this.setState({
            newSolutionViewExpanded: !this.state.newSolutionViewExpanded,
            scenarioIndex: index,
        });
    };

    solutionToggled = (solution, scenario) => {
        let shouldAdd =
            scenario.solutions.filter((sol) => sol.solution_id === solution.id)
                .length < 1;
        let { risk } = this.props;
        if (shouldAdd) {
            this.props.dispatchAction({
                type: scenarioActions.ADD_RISK_SOLUTION_REQUESTED,
                payload: {
                    projectId: risk.project_id,
                    riskId: risk.id,
                    scenarioId: scenario.id,
                    solution: {
                        scenario_id: scenario.id,
                        solution_id: solution.id,
                    },
                },
            });
        } else {
            let solutionToRemove = scenario.solutions.filter(
                (sol) => sol.solution_id === solution.id
            )[0];
            this.props.dispatchAction({
                type: scenarioActions.REMOVE_RISK_SOLUTION_REQUESTED,
                payload: {
                    projectId: risk.project_id,
                    riskId: risk.id,
                    scenarioId: scenario.id,
                    solutionId: solutionToRemove.id,
                },
            });
        }
    };

    clickOnListItemHandler = (...e) => {};

    scenarioToggled = (scenarioId, isSelected) => {
        let { risk } = this.props;

        let existingRiskScenario = risk.scenarios.filter(
            (scenario) => scenario.scenario_id == scenarioId
        )[0];
        if (isSelected && !existingRiskScenario) {
            this.props.dispatchAction({
                type: scenarioActions.ADD_RISK_SCENARIO_REQUESTED,
                payload: {
                    projectId: risk.project_id,
                    riskId: risk.id,
                    scenario: {
                        scenario_id: scenarioId,
                        risk_id: risk.id,
                        status: '',
                        occurence: [],
                        solutions: [],
                    },
                },
            });
        } else if (!isSelected && existingRiskScenario) {
            this.props.dispatchAction({
                type: scenarioActions.REMOVE_RISK_SCENARIO_REQUESTED,
                payload: {
                    projectId: risk.project_id,
                    riskId: risk.id,
                    scenarioId: existingRiskScenario.id,
                    solutions: existingRiskScenario.solutions,
                },
            });
        }
    };

    removeScenario = (scenarioId) => {
        let { risk } = this.props;
        let existingRiskScenario = risk.scenarios.filter(
            (scenario) =>
                parseInt(scenario.scenario_id) === parseInt(scenarioId)
        )[0];
        this.props.dispatchAction({
            type: scenarioActions.REMOVE_RISK_SCENARIO_REQUESTED,
            payload: {
                projectId: risk.project_id,
                riskId: risk.id,
                scenarioId: existingRiskScenario.id,
                solutions: existingRiskScenario.solutions,
            },
        });
    };

    scenarioUpdated = (scenario, comment, occurence) => {
        this.props.dispatchAction({
            type: scenarioActions.UPDATE_RISK_SCENARIO_REQUESTED,
            payload: {
                projectId: this.props.risk.project_id,
                scenario: {
                    ...scenario,
                    status: comment,
                    occurence: occurence,
                    solutions: undefined,
                },
            },
        });

        if (this.props.onChange) {
            this.props.onChange();
        }
    };

    createScenario = (name) => {
        this.props.dispatchAction({
            type: riskActions.CREATE_CUSTOM_SCENARIO_REQUESTED,
            payload: {
                name: name,
                projectId: this.props.risk.project_id,
                riskId: this.props.risk.id,
            },
        });
    };

    render() {
        let { allScenarios, risk, isFetching, allSolutions } = this.props; //[{ title: "A man walks into a bar" }, { title: "A man fsfsfs" }];
        let riskScenarios = risk.scenarios.map((riskScenario) => {
            return { ...riskScenario, scenario_name: riskScenario.name };
        });
        if (isFetching) {
            return null;
        }
        return (
            <div className="scenario">
                <div className="scenario__inner-container">
                    <span className="title scenario__title">
                        {t('web.risk.scenario')}{' '}
                        <span className={'asterisk'}>*</span>
                    </span>
                    <ScenarioSearch
                        allScenarios={allScenarios}
                        riskScenarios={risk.scenarios}
                        scenarioToggled={this.scenarioToggled}
                        createScenario={this.createScenario}
                    />
                    <Scenario
                        scenarios={riskScenarios}
                        risk={risk}
                        allSolutions={allSolutions}
                        toggleNewSolutionViewExpanded={
                            this.handleToggleNewSolutionViewExpanded
                        }
                        solutionToggled={this.solutionToggled}
                        scenarioUpdated={this.scenarioUpdated}
                        removeScenario={this.removeScenario}
                        newSolutionViewExpanded={
                            this.state.newSolutionViewExpanded
                        }
                        scenarioIndex={this.state.scenarioIndex}
                    />
                </div>
            </div>
        );
    }
}

const mapStateToProps = ({ risks }) => {
    let allScenarios = risks.scenarios.map((scenario) => ({
        ...scenario,
        title: scenario.name,
    }));
    return {
        allScenarios: allScenarios || [],
        allSolutions: risks.solutions || [],
        isFetching: false, //scenario.isFetching
    };
};

const mapDispatchToProps = (dispatch) => ({
    dispatchAction: (action) => dispatch(action),
});

export default connect(mapStateToProps, mapDispatchToProps)(ScenarioContainer);
