import React from 'react';

import t, { d } from '../i18n';
import {Checkbox2} from '../input/standard-input';
import Search from '../search/search';

export default class ScenarioSearch extends React.Component {
  state = {
    searchValue: '',
    suggestions: []
  }


  componentWillMount = () => {
    document.addEventListener('click', this.handleClick, false);
  }

  componentWillUnmount = () => {
    document.removeEventListener('click', this.handleClick, false);
  }

  handleClick = e => {
    if (this.node.contains(e.target)) {
      return;
    } else {
      this.setState({
        ...this.state,
        searchValue: '',
        suggestions: [],
      });
    }
  }
  searchValueChanged = (e) => {
    let searchValue = e.target.value;
    let suggestions = this.getSuggestions(searchValue);
    suggestions.push({
      id: -1,
      translated: searchValue,
      name: searchValue,
      title: searchValue,
      checked: false
    });

    this.setState({
      ...this.state,
      searchValue,
      suggestions,
    });
  }
  scenarioToggled = (e) => {

    // noinspection EqualityComparisonWithCoercionJS
    if (e.target.value == -1) {
      this.props.createScenario(e.target.name);
      return;
    }

    this.props.scenarioToggled(e.target.value, e.target.checked);
    let suggestions = this.state.suggestions.map(suggestion => {
      if (suggestion.id == e.target.value) {
        suggestion.checked = e.target.checked;
        // set to readonly. Will be set to false again when we get new props after value is stored
        suggestion.readonly = true;
      }
      return suggestion;
    });
    this.setState({
      ...this.state,
      suggestions,
    });
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.riskScenarios.length !== this.props.riskScenarios.length) {
      let suggestions = this.getSuggestions(this.state.searchValue, nextProps);
      this.setState({
        ...this.state,
        suggestions,
      });
    }
  }

  getSuggestions(searchValue, props = this.props) {
    
    let suggestions = props.allScenarios.map(scenario => { return { ...scenario, translated: d('risk.scenario', scenario.name)}});
    
    // Add the custom scenarios we have created to the suggestions list
    props.riskScenarios
      .map(scenario => {
        const exists = suggestions.find(suggestion => suggestion.id === scenario.scenario_id);
        if(!exists) {
          suggestions.push({ id: scenario.scenario_id, name: scenario.name, title: scenario.name, translated: scenario.name });
        }
      });
      
    suggestions = searchValue.length > 2 ? suggestions.filter(scenario => scenario.translated.toLowerCase().includes(searchValue.toLowerCase())) : [];
      
    suggestions = suggestions.map(suggestion => {
      let isChecked = props.riskScenarios.filter(riskScenario => riskScenario.scenario_id === suggestion.id).length > 0;
      return {...suggestion, checked: isChecked}
    });
    return suggestions
  }

  render() {
    return (
      <div style={{position: "relative"}} ref={node => this.node = node}>
        <Search searchValue={this.state.searchValue} handleChange={this.searchValueChanged}
                placeholder={t('web.risk.scenarioSearch')}/>
        {
          this.state.suggestions.length > 0 &&
          <div
              className={'search-suggestions'}
              style={{
            position: "absolute",
            backgroundColor: "#656465",
            borderRadius: 4,
            color: "#fff",
            width: "100%",
            zIndex: 100,
            top: 40,
            padding: 10
          }}>
            {
              this.state.suggestions.map((scenario) => {
                return <div key={scenario.id} style={{padding: 4}}>
                  <Checkbox2
                    readOnly={scenario.readonly}
                    disabled={scenario.readonly}
                    checked={scenario.checked}
                    onChange={this.scenarioToggled}
                    label={scenario.translated}
                    value={scenario.id}/>
                </div>
              })
            }
          </div>
        }
      </div>
    )
  }
}
