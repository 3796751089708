import * as React from 'react';

import Alternatives from './alternatives';
import '../scenario/scenario.css';
import t from '../i18n/index';
import './places.css';
import ImageList, { IImage } from '../image/imagelist';
import { API_ROOT } from '../services/api';
import TextCounter from '../input/text-counter';
import GrowableTextArea from '../input/growable-textarea';
import { getSummary } from './selectors';

const Questions = ({ questions, answers, images, handleAnswerChanged, handleImageEvent, onSelectSketchImage }) => {
    return (
        <div>
            {
                questions && questions
                            .sort(customSort)
                            .map((question, index) => {
                    let questionImages = images.filter((image) => {
                        return image.questions_id == question.id;
                    });
                    let answer = answers.filter(answer => answer.questions_id === question.id)[0];
                    let imageList = questionImages.map(image => {
                        let src = `${API_ROOT}projects/${image.project_id}/survey/images/${image.id}/content`;
                        return { ...image, src, isApiUrl: true }
                    });
                    return <div className="question__group" key={index} style={{ marginBottom: "20px" }}>
                        <span className="place__subtitle">{question.question}</span>
                            <div className="question__group__content">
                            <Alternatives {...question}
                                        answer={answer}
                                        handleAnswerChanged={handleAnswerChanged}/>
                            <GrowableTextArea 
                                className="place__input place__input__textarea"
                                defaultValue={answer ? answer.note : ""}
                                onBlur={(e) => handleAnswerChanged(question, undefined, answer, e.target.value)}
                                name="comment"
                                placeholder={t('web.surveyLibrary.additonalNotes')}
                                defaultSize={2}
                                keepExpanded={true}
                                maxLength={4000}
                            />
                            <div style={{ paddingLeft: '7px'}}><TextCounter length={answer ? answer.note.length : 0} limit={4000}></TextCounter></div>
                            {
                                question.images_enabled &&
                                <ImageList
                                    images={imageList}
                                    onSelectSketchImage={(image: IImage) => onSelectSketchImage(image)}
                                    onDrop={(data) => handleImageEvent(data, question.id)}
                                    onDelete={(image) => handleImageEvent(null, question.id, "DELETE", image.id)}/>
                            }
                        </div>
                    </div>
                })
            }
        </div>
    )
}

    var customSort = function(a,b) {
        return a.position - b.position;
    }

const SummaryRating = ({ rating, onChange }) => {

    const values = [1, 2, 3, 4, 6, 9];

    return (
        <div className="summary-slider">
            {values.map(v => {
                return (
                    <div 
                        key={"slider"+v} 
                        className={`slider-rating slider-grad-${v} ${v<=rating && rating !== 0 ? 'slider-active' : ''}`} 
                        onClick={() => onChange(v)} 
                    >
                    {rating === 0 && v === 1 && <span className="no-rating-low">{t('web.risk.matrixLow')}</span>}
                    {rating === 0 && v === 9 && <span className="no-rating-high">{t('web.risk.matrixVeryHigh')}</span>}
                    </div>
                ) 
            })}
        </div>
    );
}

const Summary = ({group, summary, updateSummary}) => {

    if(!summary) {
        summary = {
            rating: 0,
            comment: "",
            questionGroup_id: group.id
        }
    }

    const rating = summary.rating;
    const ratingMap = {
        0: { label: '', color: ''},
        1: { label: t('web.risk.matrixLow'), color: '#7fb069'},
        2: { label: t('web.risk.matrixLow'), color: '#7fb069'},
        3: { label: t('web.risk.matrixMedium'), color: '#ffc914'},
        4: { label: t('web.risk.matrixMedium'), color: '#ffc914'},
        6: { label: t('web.risk.matrixHigh'), color: '#f9a03f'},
        9: { label: t('web.risk.matrixVeryHigh'), color: '#e4572e'}
    }

    const onRatingChange = r => {
        summary.rating = r !== summary.rating ? r : 0;
        updateSummary(summary);
    }

    const onSummaryChange = e => {
        summary.comment = e.target.value;
        updateSummary(summary);
    }

    return (
        <div className="question__group">
            <span className="place__subtitle">{t('web.risk.riskAssessment')}</span>
            <div className="question__group__content">
                <div className="summary-rating" style={{ backgroundColor: ratingMap[rating].color}}>{ratingMap[rating].label}</div>
                <SummaryRating rating={rating} onChange={onRatingChange} />
                <GrowableTextArea 
                    className="place__input place__input__textarea"
                    defaultValue={summary.comment}
                    placeholder={t('web.risk.comment')}
                    onBlur={onSummaryChange}
                    keepExpanded={true}
                    maxLength={1100}
                />
                <div style={{ paddingLeft: '7px'}}><TextCounter length={summary.comment.length} limit={1100}></TextCounter></div>
            </div>
        </div>
    );
}

class Group extends React.Component<any>{
    state = {isVisible: this.props.isVisible}
    render() {
        return <div key={this.props.index} className={`question_group_container ${this.state.isVisible ? 'active' : ''}`}>
        <h4 className="place__group__name"
            onClick={() => this.setState({isVisible: !this.state.isVisible})}>{this.props.questionGroup.name}<span className="icon icon-arrow"></span></h4>
        {
            this.props.questionGroup && this.state.isVisible &&
            <div>
                <Questions
                    answers={this.props.answers}
                    images={this.props.images}
                    questions={this.props.questionGroup.questions}
                    handleImageEvent={this.props.handleImageEvent}
                    onSelectSketchImage={this.props.onSelectSketchImage}
                    handleAnswerChanged={this.props.handleAnswerChanged}
                />
                {this.props.questionGroup.summary_enabled &&
                    <Summary 
                        group={this.props.questionGroup} 
                        summary={ getSummary(this.props.questionGroup.id, this.props.summaries)}
                        updateSummary={this.props.handleSummaryChanged}
                    />
                }
            </div>
        }
    </div>;
    }
}
const Place = (props: { place, answers, images, summaries, handleImageEvent, handleAnswerChanged, handleSummaryChanged, onSelectSketchImage }) => {
    return (
        <div className="place">
            <span className="place__title">{props.place.name}</span>
            <span className="place__subtitle">{props.place.description}</span>
            <ul className="list">
                {
                    props.place.question_groups && props.place.question_groups.sort(customSort).map((questionGroup, index) => {
                        return <Group 
                            isVisible={false} 
                            questionGroup={questionGroup}
                            key={index}
                            index={index}
                            {...props}
                            />
                        }
                    )
                }
            </ul>
        </div>
    )
}

export default Place
