import * as React from 'react';
import classNames from 'classnames';

import Breadcrumbs from '../breadcrumbs/breadcrumbs';
import './categories.css';
import security from '../img/security.svg';
import cctv from '../img/cctv.svg';
import lock from '../img/lock.svg';
import more from '../img/more.svg';

const SubCategories = ({ categories, handleNavigationStackCategoriesPush }) => {
    categories.sort((a,b) => a.position - b.position);
    return (
        <div className="categories__sub-categories">
            {
                categories.map((category, index) =>
                    <button key={index}
                            className={classNames("btn btn--dark-gray", "categories__category")}
                            onClick={() => handleNavigationStackCategoriesPush(category.id, category.name)}>
                        {category.name}
                    </button>
                )
            }
        </div>
    );
}

const RootCategories = ({ categories, handleFilterOnCategoryRoot, navigationStackCategories }) => {
    const rootCategories = categories ?
        categories
            .filter((category) => !category.parent_id)
            .sort((category1, category2) => category1.position < category2.position ? -1 : 1) : [];

    const currentRootToggledCategoryId = navigationStackCategories[0] ? navigationStackCategories[0].id : -1;

    if (rootCategories.length <= 0) {
        return null
    }
    ;

    const getImage = (index) => {
        if (index === 0) {
            return security
        }
        if (index === 1) {
            return cctv
        }
        if (index === 2) {
            return lock
        }
        if (index === 3) {
            return more
        }
    }

    return (
        <nav className="categories__root-categories">
            {
                rootCategories.map((category, index) =>
                    <button
                        key={index}
                        className={classNames("btn btn--gray btn--small categories__category", { "btn--toggled": currentRootToggledCategoryId === category.id })}
                        onClick={() => handleFilterOnCategoryRoot(category.id, category.name)}>
                        <img src={getImage(index)} role="presentation"/>
                    </button>
                )
            }
        </nav>
    )
}

export interface CategoriesProps {
    categories: any[];
    filterCategoryId: number;
    isFetchingCategories: boolean;
    navigationStackCategories: any[];

    handleNavigationStackCategoriesPush(categoryId: any, name: any): any;

    handleNavigationStackCategoriesPop(indexForItemClicked: any): any;

    handleFilterOnCategoryRoot(categoryId: any, name: any): any;
}

const Categories = (props: CategoriesProps) => {
    const { categories, filterCategoryId, handleNavigationStackCategoriesPop, navigationStackCategories } = props;

    const filteredCategories =
        categories.filter((category, item) => {
            return filterCategoryId ? category.parent_id === filterCategoryId : false;
        });

    return (
        <div className="categories">
            <RootCategories {...props} />
            <div className="highlight"/>
            <div className="categories__breadcrumbs">
                <Breadcrumbs
                    navigationStack={navigationStackCategories}
                    handleNavigationStackPop={handleNavigationStackCategoriesPop}/>
            </div>
            <SubCategories categories={filteredCategories}
                           handleNavigationStackCategoriesPush={props.handleNavigationStackCategoriesPush}/>
        </div>
    );
}

export default Categories;
