// @ts-nocheck
// TODO remove above
import * as React from 'react';
import placeholderDark from '../img/placeholder-dark.svg';
import information from '../img/information.svg';
import ProductDetailedItem from './product-detailed-item';
import ProductImageDialog from './product-image-dialog';
import trash from '../img/trash.svg';
import cartActive from '../img/cart-active.svg';
import { endsWith, getFileExtension } from '../utils/utils';
import { IProduct } from '../calculation/product';
import t from '../i18n';

interface IProductsListItemProps {
    product: IProduct;
    addProductListener: (object) => void;
    deleteProductListener: (project_id: number, project_product_id: number) => void;
    adjustAmountListener: (product: object, quantity: number) => void;
}

interface IProductsListItemState {
    expanded: boolean;
    tabIndex: number;
    showImageDialog: boolean;
    showAddButton: boolean;
}

class ProductsListItem extends React.Component<IProductsListItemProps, IProductsListItemState> {
    toggleExpandedView = () => {
        this.setState({ expanded: !this.state.expanded,
                        tabIndex: 0 })
    }
    tabIndexChanged = (tabIndex) => {
        this.setState({ tabIndex: tabIndex });
    }
    imageClick = (product) => {
        this.setState({ showImageDialog: true });
    }
    closeImageModal = () => {
        this.setState({ showImageDialog: false });
    }
    getProductImageUrl = (product) => {
        let allowedFormats = ['.png', '.gif', '.jpg', '.jpeg', '.bmp', '.tiff', '.tif'];
        let returnValue = placeholderDark;
        if (product.main_photo) {
            allowedFormats.forEach(format => {
                if (endsWith(product.main_photo.url, format)) {
                    returnValue = product.main_photo.url;
                }
            });
        }
        return returnValue;
    }
    getImageMimeType = (urlOrFilename: string) => {
        urlOrFilename = urlOrFilename.toLowerCase();
        let extension = getFileExtension(urlOrFilename);
        let mimeTypes = {
            "png": "image/png",
            "gif": "image/gif",
            "jpg": "image/jpeg",
            "jpeg": "image/jpeg",
            "bmp": "image/bmp",
            "tiff": "image/tiff",
            "tif": "image/tiff",
        }
        return mimeTypes[extension] || "";
    }
    getProductImage = (product) => {
        let url = this.getProductImageUrl(product)
        let type = this.getImageMimeType(url);

        let onClick = (e) => {
            e.preventDefault();
            // only allow click if we don't fall back to the default image
            if (!endsWith(e.target.currentSrc, placeholderDark)) {
                this.imageClick(this.props.product);
            }
        };
        return (
            <picture onClick={onClick}>
                <source srcSet={url} type={type}></source>
                <source srcSet={placeholderDark} type="image/svg+xml"></source>
                <img className="products__image" src={url}/>
            </picture>
        )
    }
    addProduct = () => {
        this.setState({ amount: this.state.amount + 1 });        
        this.saveProjectProducts(false, this.state.amount + 1);
    }

    subtractProduct = () => {
        if (this.state.amount == 0) return;
        this.setState({ amount: this.state.amount -1 });        
        this.saveProjectProducts(true, this.state.amount -1);
    }

    deleteProduct = () => {
        if (this.state.amount == 0) return;
        this.setState({ amount: 0 });        
        this.saveProjectProducts(true, 0);
    }

    saveProjectProducts(subtracting, amount) {
        if (subtracting && amount == 0) {
            this.props.deleteProductListener({ ...this.props.product });       
        }
        else if (!subtracting && amount == 1) {
            // Product added
            this.props.addProductListener({ ...this.props.product, quantity: amount });
            this.showCartIcon(this.props.product);                   
        }
        else {
            // Product updated
            this.props.adjustAmountListener({ ...this.props.product, quantity: amount });
            this.showCartIcon(this.props.product);
        } 
       
    }

    showCartIcon(product) {
        //show cart button for 400 milliseconds to give user feedback that cart were updated
        this.setState({ showAddButton: false });
        document.getElementById("addButton" + product.product_id).style.visibility = "hidden";
        document.getElementById("subtractButton" + product.product_id).style.visibility = "hidden";

        setTimeout(() => {
            this.setState({ showAddButton: true });
            document.getElementById("addButton" + product.product_id).style.visibility = "visible";
            document.getElementById("subtractButton" + product.product_id).style.visibility = "visible";
        }, 400);         
    }    

    componentWillMount() {
        if (this.props.product.quantity) {
            this.setState({ amount: this.props.product.quantity });
        }
    }

    handleInputAmountChange(e) {
        if (this.state.amount != 0 && e.target.value == 0) {
            // Product deleted
            this.props.deleteProductListener({ ...this.props.product });       

        } else if (this.state.amount == 0 && e.target.value > 0) {
            // Product added
            this.props.addProductListener({ ...this.props.product, quantity: e.target.value });
        } else {
            // Product updated
            this.props.adjustAmountListener({ ...this.props.product, quantity: e.target.value });
        }

        this.setState({ amount: e.target.value }); 
    }       

    constructor(props) {
        super(props);
        this.state = {
            expanded: false,
            showImageDialog: false,
            showAddButton: true,
            tabIndex: 0,
            amount: 0,
        };

        this.handleInputAmountChange = this.handleInputAmountChange.bind(this);
    }

    render() {
        const { product, addProductListener, deleteProductListener, adjustAmountListener } = this.props;
        return (
            <div>
                {this.state.showImageDialog &&
                <ProductImageDialog product={product} closeDialog={this.closeImageModal}/>
                }
                {
                    this.state.expanded &&
                    <ProductDetailedItem product={product} closeView={this.toggleExpandedView} tabIndexChanged={this.tabIndexChanged} tabIndex={this.state.tabIndex} showMedia={true}/>
                }
                <div className="products__list-item">
                    <div className="products__left-container">
                        {this.getProductImage(product)}
                        <img onClick={this.toggleExpandedView} className="products__item-information"
                             src={information}/>
                        <div className="products__title-container">
                            <span className="title products__title">{product.name}</span>
                            <span className="products__description">{product.short_description}</span>
                        </div>
                    </div>

                    {!product.is_informational &&
                    <div className="products__add-container">
                        <span className="products__price_unit">{product.price_unit ? product.price_unit +":" : t('web.calculation.amountLabel') + ":"}</span>
                        <input
                            className="products__amount"
                            name="amount"
                            type="number"
                            value={this.state.amount}
                            onChange={this.handleInputAmountChange}
                        />

                        <button className="products__subtract-button" onClick={this.subtractProduct} id={"subtractButton" + product.product_id}> - </button>
                        <button className="products__add-button" onClick={this.addProduct} id={"addButton" + product.product_id}> + </button>                    
                        {this.state.showAddButton &&
                            <div>
                                <button className="btn--background-image products__delete-button" onClick={this.deleteProduct} id={"deleteButton" + product.product_id}>
                                    <img src={trash} alt="delete"/>
                                </button>                                
                            </div>
                        }
                        {!this.state.showAddButton &&
                        <img src={cartActive} className="products__in-cart"/>
                        }                       
                    </div>
                    }
                </div>
            </div>
        );
    }
}

export default ProductsListItem;
