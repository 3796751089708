import {
    GET_PROJECTS_REQUESTED,
    GET_PROJECTS_SUCCEEDED,
    EDIT_PROJECT_SUCCEEDED,
    ADD_NEW_PROJECT_SUCCEEDED,
    DELETE_PROJECT_SUCCEEDED,
    CLEAR_PROJECTS_REQUESTED,
    GET_SEGMENTS_SUCCEEDED,
    GET_SELLERS_SUCCEEDED
} from './actions';

const initialState = {
    projects: [],
    segments: [],
    showList: false
};

export const getProjectById = (projects, id) => {
    id = Number(id);
    return projects.projects.filter(project => project.project_id === id)[0];
}

export const getSortedProjects = (projects) => {
    return projects.projects.sort((a, b) => a.project_id - b.project_id);
}

export default function projects(state = initialState, action) {
    switch (action.type) {
        case GET_PROJECTS_REQUESTED:
            return Object.assign({}, state, {
                // projects: []
            });
        case GET_PROJECTS_SUCCEEDED:
            return { ...state, projects: action.payload.projects };
        case CLEAR_PROJECTS_REQUESTED:
            return { ...state, projects: [] };
        case GET_SEGMENTS_SUCCEEDED:
            return { ...state, ...action.payload }
        case ADD_NEW_PROJECT_SUCCEEDED: {
            let newProjects = [...state.projects, action.payload.project];
            return { ...state, projects: newProjects }
        }
        case EDIT_PROJECT_SUCCEEDED: {
            let updatedProject = action.payload.project;
            let otherProjects = state.projects.filter(otherProject => otherProject.project_id !== updatedProject.project_id);
            let newProjects = [...otherProjects, updatedProject];
            return { ...state, projects: newProjects }
        }
        case DELETE_PROJECT_SUCCEEDED: {
            let notDeletedProjects = state.projects.filter(project => project.project_id !== action.payload.project_id);
            return { ...state, projects: notDeletedProjects }
        }
        case GET_SELLERS_SUCCEEDED: {
            return { ...state, sellers: action.payload.sellers }
        }
        default:
            return state;
    }
}
