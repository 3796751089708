import {getAccessToken} from '../auth/auth-helper';
import {getImageBlobUrl} from "../utils/utils"

const API_ROOT = process.env.REACT_APP_API_ROOT;

export const callAttachmentUploader = (projectId, attachmentId, file) => {
  var data = new FormData()
  data.append(file.name, file);

  return fetch(`${API_ROOT}projects/${projectId}/attachments/${attachmentId}/content`, {
    method: 'POST',
    body: data
  });
}

const uploadImage = (apiUrl, file) => {
  var data = new FormData()
  data.append(file.name, file);

  return getAccessToken().then(token =>
    fetch(apiUrl, {
      method: 'POST',
      headers: {
        'Authorization': `Bearer ${token}`
      },
      body: data
    }).then(response => response.json())
  );
}

const uploadEditedImage = (apiUrl, file) => {
  var data = new FormData()
  data.append(file.name, file);

  return getAccessToken().then(token =>
    fetch(apiUrl, {
      method: 'PUT',
      headers: {
        'Authorization': `Bearer ${token}`
      },
      body: data
    }).then(response => response.json())
  );
}

export const callFetchImageBlob = (apiUrl, clearCache = false) => {
  return getAccessToken().then(token => {
    return new Promise((resolve, reject) => {
      fetch(apiUrl, {
        method: 'GET',
        headers: {
          'Authorization': `Bearer ${token}`
        },
        cache: clearCache ? "reload" : "default"
      }).then(response => response.arrayBuffer())
        .then(response => resolve(getImageBlobUrl(response)))
    })
  })
}

export const callProjectImageUploader = (projectId, file) => {
  let apiUrl = `${API_ROOT}projects/${projectId}/projectimage`;
  return uploadImage(apiUrl, file);
}
export const callContactImageUploader = (project_id, contact_id, file) => {
  let apiUrl = `${API_ROOT}projects/${project_id}/contacts/${contact_id}/photo`;
  return uploadImage(apiUrl, file);
}

export const fetchProjectImage = (projectId, clearCache = false) => {
  let apiUrl = `${API_ROOT}projects/${projectId}/projectimage`;
  return callFetchImageBlob(apiUrl, clearCache);
}

export const fetchSurveyImage = (projectId, attachmentId) => {
  let apiUrl = `${API_ROOT}projects/${projectId}/survey/images/${attachmentId}/content`;
  return callFetchImageBlob(apiUrl);
}

export const callSurveyImageUploader = (projectId, questionId, file) => {
  let apiUrl = `${API_ROOT}projects/${projectId}/survey/images/${questionId}`;
  return uploadImage(apiUrl, file);
}

export const callSurveyImageEdit = (projectId, attachmentId, file) => {
  let apiUrl = `${API_ROOT}projects/${projectId}/survey/images/${attachmentId}`;
  return uploadEditedImage(apiUrl, file);
}

export const callRiskProjectImageUploader = (projectId, riskId, file) => {
  let apiUrl = `${API_ROOT}projects/${projectId}/risks/${riskId}/images   `;
  return uploadImage(apiUrl, file);
}

export const callRiskProjectImageEdit = (projectId, riskId, attachmentId, file) => {
  let apiUrl = `${API_ROOT}projects/${projectId}/risks/${riskId}/images/${attachmentId}`;
  return uploadEditedImage(apiUrl, file);
}

export const getAttachment = (projectId, attachmentId) =>
  `${API_ROOT}projects/${projectId}/attachments/${attachmentId}/content`;
