// @ts-nocheck
// TODO remove above
import * as React from 'react';
import Scrollbars from 'react-custom-scrollbars-2';

import Search from '../search/search';
import Categories from '../categories/categories';
import SearchResults from './search-results';
import t from '../i18n';

export interface ProductsProps {
    products?: any[];
    projectProducts?: any[];
    project: any;
    categories: any[];
    navigationStackCategories: any[];
    isFetching: boolean;
    isFetchingCategories: boolean;
    searchValue: string;

    handleNavigationStackCategoriesPush(categoryId: any, name: any): any;

    handleNavigationStackCategoriesPop(indexForItemClicked: any): any;

    handleFilterOnCategoryRoot(categoryId: any, name: any): any;

    handleSearchInputChange(e: any): any;

    handleAddProduct(product: any): any;

    handleDeleteProduct(projectProduct: any): any;

    handleAmountListener(): any;
}

const Products = ({
                      products, projectProducts, isFetching, categories, navigationStackCategories, isFetchingCategories, handleSearchInputChange, handleAddProduct,
                      handleDeleteProduct, handleAmountListener, searchValue, handleFilterOnCategoryRoot, handleNavigationStackCategoriesPush, handleNavigationStackCategoriesPop
                  }: ProductsProps) => {

    const filterCategory = navigationStackCategories.length > 0 ?
        navigationStackCategories[navigationStackCategories.length - 1] :
        {};

    const onFilterSearchResult = (product) => {

        if(searchValue.length === 0 && isAtTopLevel()) {
            return false;
        }

        if (searchValue.length === 0) {
            return true;
        }
        return product.name.toLowerCase().startsWith(searchValue.toLowerCase());
    }

    const onFilterOnCategory = (product) => {
        
        if(isAtTopLevel()) {
            return true;
        }

        if (filterCategory.id)
            return product.product_category_id === filterCategory.id;

        return true;
    };

    const isAtTopLevel = () => {
        return navigationStackCategories.length <= 1;
    }

    const showCategories = () => {
        return !(isAtTopLevel() && searchValue.length > 0);
    }

    const filteredProducts = products ? products.filter(onFilterOnCategory).filter(onFilterSearchResult.bind(searchValue)) : [];
    return (
        <Scrollbars style={{ 'backgroundColor': '#ffffff', height: `calc(100vh - 10rem)` }}>
            <div className="products__search">
                <Search searchValue={searchValue} handleChange={handleSearchInputChange}
                        placeholder={t('web.productSearch.placeholder')}/>
            </div>

            {showCategories() && <Categories
                filterCategoryId={filterCategory.id}
                categories={categories}
                navigationStackCategories={navigationStackCategories}
                isFetchingCategories={isFetchingCategories}
                handleFilterOnCategoryRoot={handleFilterOnCategoryRoot}
                handleNavigationStackCategoriesPush={handleNavigationStackCategoriesPush}
                handleNavigationStackCategoriesPop={handleNavigationStackCategoriesPop}/>}
            <SearchResults
                products={products}
                projectProducts={projectProducts}
                searchValue={searchValue}
                isFetching={isFetching}
                filterCategoryId={filterCategory.id}
                filteredProducts={filteredProducts}
                handleAddProduct={handleAddProduct}
                handleDeleteProduct={handleDeleteProduct}
                handleAmountListener={handleAmountListener}/>
        </Scrollbars>
    );
}

export default Products;
