// @ts-nocheck
// TODO remove above
import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import classnames from 'classnames';
import t from '../../i18n';
import { IImage } from '../../image/imagelist';
import update from 'immutability-helper';
import { DrawingShapeType, IImageEditor, ObjectProps, Option } from './tui-image-editor-types';
import TuiImageEditor from 'tui-image-editor';
import { capitalize } from '../../utils/utils';
import { getAccessToken } from '../../auth/auth-helper';
import './sketcher.css';
import 'whatwg-fetch';
import Modal from "../modal/modal";

function hexToRgba(hexColor: string): string {

    if(hexColor.includes("rgba")) {
        return hexColor;
    }

    const r = parseInt(hexColor.slice(1, 3), 16);
    const g = parseInt(hexColor.slice(3, 5), 16);
    const b = parseInt(hexColor.slice(5, 7), 16);
    const a = 0.98;
    return `rgba(${r},${g},${b},${a})`;
}

const supportsFileAPI = !!((window as any).File && (window as any).FileList && (window as any).FileReader);

function base64ToBlob(data) {
    let mimeString = '';
    let raw, uInt8Array, i, rawLength;

    raw = data.replace(/data:(image\/.+);base64,/, function (header, imageType) {
        mimeString = imageType;
        return '';
    });

    raw = atob(raw);
    rawLength = raw.length;
    uInt8Array = new Uint8Array(rawLength); // eslint-disable-line

    for (i = 0; i < rawLength; i += 1) {
        uInt8Array[i] = raw.charCodeAt(i);
    }

    return new Blob([uInt8Array], { type: mimeString });
}

type ImageSize = { width: number, height: number };
type MenuTool = 'select' | 'crop' | 'flip' | 'rotate' | 'line' | 'shape' | 'icon' | 'mask' | 'filter';
type MenuToolButton = { tool: MenuTool, name: string };

interface ISketcherProps {
    image: IImage;
    onSave: (file: File) => void;
    onClose: () => void;
}

interface ISketcherState {
    imageName: string;
    selectedMenuTool: MenuTool;
}

/**
 * Dialog for drawing sketches on top of images that can be saved afterward.
 */
export default class Sketcher extends Component<ISketcherProps, ISketcherState> {

    state: ISketcherState = {
        imageName: '',
        selectedMenuTool: 'line'
    }

    imageEditor: IImageEditor;

    menuToolButtons: MenuToolButton[] = [
        { tool: 'select', name: t('web.common.sketcher.select') },
        { tool: 'crop', name: t('web.common.sketcher.crop') },
        { tool: 'flip', name: t('web.common.sketcher.flip') },
        { tool: 'rotate', name: t('web.common.sketcher.rotate') },
        { tool: 'line', name: t('web.common.sketcher.line') },
        { tool: 'shape', name: t('web.common.sketcher.shape') },
        // { tool: 'icon', name: t('web.common.sketcher.icon') },
        { tool: 'text', name: t('web.common.sketcher.text') },
        // { tool: 'mask', name: t('web.common.sketcher.mask') },
    ]

    container;

    // Events

    componentDidMount() {
        this.imageEditor = new TuiImageEditor(this.refs.imageEditor, {
            cssMaxWidth: 0,
            cssMaxHeight: 0,
            selectionStyle: {
                cornerSize: 20,
                rotatingPointOffset: 70
            }
        });

        const { src, name, content_type } = this.props.image;

        getAccessToken()
            .then(token => {
                return fetch(src, {
                    method: 'GET',
                    headers: [
                        ['Authorization', `Bearer ${token}`]
                    ]
                });
            })
            .then(response => response.arrayBuffer())
            .then(buffer => {
                const imageFile = new File([buffer], name, { type: content_type });
                return this.imageEditor.loadImageFromFile(imageFile, name);
            })
            .then(sizeValue => {
                this.imageEditor.clearUndoStack();
                this.setState({ imageName: `${new Date().getTime()}-${this.imageEditor.getImageName()}` });
                this.forceUpdate();
                this.updateDimensions();
            })
            .catch(err => console.error(err));

        this.container = ReactDOM.findDOMNode(this.refs.container);

        window.addEventListener('resize', this.updateDimensions.bind(this), false);
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.updateDimensions.bind(this));
    }

    updateDimensions() {
        setTimeout(() => {
            this.imageEditor.resizeCanvasDimension({width: this.container.clientWidth, height: this.container.clientHeight})
        },0) // call is added at the end of the macro task queue
    }

    handleError(err) {
        console.log(err);
    }

    // Render methods

    renderSelectedMenuTool() {
        const menuTool = this.state.selectedMenuTool;
        if (!menuTool)
            return null;
        const MenuTool = MenuTools[capitalize(menuTool)];
        return <MenuTool imageEditor={this.imageEditor}/>;
    }

    // Actions

    undo() {
        this.imageEditor.undo()
            .catch(this.handleError);
    }

    redo() {
        this.imageEditor.redo()
            .catch(this.handleError);
    }

    clearObjects() {
        this.imageEditor.clearObjects()
            .catch(this.handleError);
    }

    save() {
        const dataURL = this.imageEditor.toDataURL();
        let { imageName } = this.state;

        if (supportsFileAPI) {
            const blob = base64ToBlob(dataURL);
            const type = blob.type.split('/')[1];
            if (imageName.split('.').pop() !== type) {
                imageName += '.' + type;
            }
            const file = new File([blob], imageName, { type });
            this.props.onSave(file);
        } else {
            console.error('Browser lacks File API support');
            /*alert('This browser needs a file-server');
            const w = window.open();
            w.document.body.innerHTML = '<img src=' + dataURL + '>';*/
        }
    }

    public render() {
        return (
            <div className="sketcher">
                <div className="sketcher__image-editor-container" ref="container">
                    <div className="sketcher__image-editor" ref="imageEditor"/>
                </div>
                <div className="sketcher__menu">
                    <a className="sketcher__menu-close-btn" onClick={this.props.onClose}/>
                    <div className="sketcher__menu-actions">
                        <button className="sketcher__menu-action"
                                onClick={this.undo.bind(this)}>{t('web.common.sketcher.undo')}</button>
                        <button className="sketcher__menu-action"
                                onClick={this.redo.bind(this)}>{t('web.common.sketcher.redo')}</button>
                        <button className="sketcher__menu-action"
                                onClick={this.clearObjects.bind(this)}>{t('web.common.sketcher.clearAll')}</button>
                        <button className="sketcher__menu-action"
                                onClick={this.save.bind(this)}>{t('web.common.sketcher.save')}</button>
                    </div>
                    <div className="sketcher__menu-tools">
                        {this.imageEditor && this.menuToolButtons.map((menuToolButton, i) => {
                            const selected = menuToolButton.tool === this.state.selectedMenuTool;
                            return (
                                <button
                                    key={i}
                                    className={classnames('sketcher__menu-tool', selected ? 'selected' : '')}
                                    onClick={(e) => this.setState({ selectedMenuTool: menuToolButton.tool })}>
                                    {menuToolButton.name}
                                </button>
                            );
                        })}
                    </div>
                    <div className="sketcher__menu-selected-tool">
                        {this.imageEditor && this.renderSelectedMenuTool()}
                    </div>
                </div>
            </div>
        );
    }
}

type ColorShape = 'stroke' | 'fill';

interface IColorPickerProps {
    strokeColor: string;
    fillColor?: string;
    colorShape?: ColorShape;
    onColorSelect: (color: string, colorShape: ColorShape) => void;
    onTransparentChange?: (transparent: boolean, colorShape: ColorShape) => void;
}

interface IColorPickerState {
    strokeColor: string;
    fillColor: string;
    strokeTransparent: boolean;
    fillTransparent: boolean;
    colorShape: ColorShape;
}

export class ColorPicker extends Component<IColorPickerProps, IColorPickerState> {

    public static defaultProps: Partial<IColorPickerProps> = {
        strokeColor: '#000000',
        colorShape: 'stroke' as ColorShape
    }

    state = {
        strokeColor: this.props.strokeColor,
        fillColor: this.props.fillColor,
        strokeTransparent: false,
        fillTransparent: false,
        colorShape: this.props.colorShape
    }

    colors = [
        "rgb(51, 0, 0)", "rgb(51, 25, 0)", "rgb(51, 51, 0)", "rgb(25, 51, 0)",
        "rgb(0, 51, 0)", "rgb(0, 51, 25)", "rgb(0, 51, 51)", "rgb(0, 25, 51)",
        "rgb(0, 0, 51)", "rgb(25, 0, 51)", "rgb(51, 0, 51)", "rgb(51, 0, 25)",
        "rgb(0, 0, 0)", "rgb(102, 0, 0)", "rgb(102, 51, 0)", "rgb(102, 102, 0)",
        "rgb(51, 102, 0)", "rgb(0, 102, 0)", "rgb(0, 102, 51)", "rgb(0, 102, 102)",
        "rgb(0, 51, 102)", "rgb(0, 0, 102)", "rgb(51, 0, 102)", "rgb(102, 0, 102)",
        "rgb(102, 0, 51)", "rgb(32, 32, 32)", "rgb(153, 0, 0)", "rgb(153, 76, 0)",
        "rgb(153, 153, 0)", "rgb(76, 153, 0)", "rgb(0, 153, 0)", "rgb(0, 153, 76)",
        "rgb(0, 153, 153)", "rgb(0, 76, 153)", "rgb(0, 0, 153)", "rgb(76, 0, 153)",
        "rgb(153, 0, 153)", "rgb(153, 0, 76)", "rgb(64, 64, 64)", "rgb(204, 0, 0)",
        "rgb(204, 102, 0)", "rgb(204, 204, 0)", "rgb(102, 204, 0)", "rgb(0, 204, 0)",
        "rgb(0, 204, 102)", "rgb(0, 204, 204)", "rgb(0, 102, 204)", "rgb(0, 0, 204)",
        "rgb(102, 0, 204)", "rgb(204, 0, 204)", "rgb(204, 0, 102)", "rgb(96, 96, 96)",
        "rgb(255, 0, 0)", "rgb(255, 128, 0)", "rgb(255, 255, 0)", "rgb(128, 255, 0)",
        "rgb(0, 255, 0)", "rgb(0, 255, 128)", "rgb(0, 255, 255)", "rgb(0, 128, 255)",
        "rgb(0, 0, 255)", "rgb(127, 0, 255)", "rgb(255, 0, 255)", "rgb(255, 0, 127)",
        "rgb(128, 128, 128)", "rgb(255, 51, 51)", "rgb(255, 153, 51)", "rgb(255, 255, 51)",
        "rgb(153, 255, 51)", "rgb(51, 255, 51)", "rgb(51, 255, 153)", "rgb(51, 255, 255)",
        "rgb(51, 153, 255)", "rgb(51, 51, 255)", "rgb(153, 51, 255)", "rgb(255, 51, 255)",
        "rgb(255, 51, 153)", "rgb(160, 160, 160)", "rgb(255, 102, 102)", "rgb(255, 178, 102)",
        "rgb(255, 255, 102)", "rgb(178, 255, 102)", "rgb(102, 255, 102)", "rgb(102, 255, 178)",
        "rgb(102, 255, 255)", "rgb(102, 178, 255)", "rgb(102, 102, 255)", "rgb(178, 102, 255)",
        "rgb(255, 102, 255)", "rgb(255, 102, 178)", "rgb(192, 192, 192)", "rgb(255, 153, 153)",
        "rgb(255, 204, 153)", "rgb(255, 255, 153)", "rgb(204, 255, 153)", "rgb(153, 255, 153)",
        "rgb(153, 255, 204)", "rgb(153, 255, 255)", "rgb(153, 204, 255)", "rgb(153, 153, 255)",
        "rgb(204, 153, 255)", "rgb(255, 153, 255)", "rgb(255, 153, 204)", "rgb(224, 224, 224)",
        "rgb(255, 204, 204)", "rgb(255, 229, 204)", "rgb(255, 255, 204)", "rgb(229, 255, 204)",
        "rgb(204, 255, 204)", "rgb(204, 255, 229)", "rgb(204, 255, 255)", "rgb(204, 229, 255)",
        "rgb(204, 204, 255)", "rgb(229, 204, 255)", "rgb(255, 204, 255)", "rgb(255, 204, 229)",
        "rgb(255, 255, 255)"
    ];

    get selectedColor(): string {
        if (this.props.colorShape === 'stroke' as ColorShape) {
            return this.state.strokeColor;
        } else {
            return this.state.fillColor;
        }
    }

    set selectedColor(color: string) {
        color = this.rgb2hex(color);
        if (this.props.colorShape === 'stroke' as ColorShape) {
            this.setState({ strokeColor: color });
        } else {
            this.setState({ fillColor: color });
        }

        this.props.onColorSelect(color, this.state.colorShape);
    }
    rgb2hex = (rgb) => {
        rgb = rgb.match(/^rgba?[\s+]?\([\s+]?(\d+)[\s+]?,[\s+]?(\d+)[\s+]?,[\s+]?(\d+)[\s+]?/i);
        return (rgb && rgb.length === 4) ? "#" +
            ("0" + parseInt(rgb[1],10).toString(16)).slice(-2) +
            ("0" + parseInt(rgb[2],10).toString(16)).slice(-2) +
            ("0" + parseInt(rgb[3],10).toString(16)).slice(-2) : '';
    };

    get transparent(): boolean {
        if (this.state.colorShape === 'stroke' as ColorShape) {
            return this.state.strokeTransparent;
        } else {
            return this.state.fillTransparent;
        }
    }

    set transparent(value: boolean) {
        if (this.state.colorShape === 'stroke' as ColorShape) {
            this.setState({ strokeTransparent: value });
            this.props.onTransparentChange(value, 'stroke');
        } else {
            this.setState({ fillTransparent: value });
            this.props.onTransparentChange(value, 'fill');
        }
    }

    get defaultColor(): string {
        if (this.props.colorShape === 'stroke' as ColorShape) {
            return this.props.strokeColor;
        } else {
            return this.props.fillColor;
        }
    }

    componentDidMount() {
        if (this.props.colorShape) {
            this.setState({ colorShape: this.props.colorShape });
        }
    }

    renderColorButton(color: string, key: string | number) {
        return (
            <button
                key={key}
                className={classnames(
                    'sketcher__color-btn',
                    color === this.selectedColor ?
                        'selected' : ''
                )}
                style={{ backgroundColor: color }}
                onClick={(e) => {
                    this.selectedColor = color;
                }}/>
        );
    }

    public render() {
        return (
            <div className="sketcher__tool-color-picker">
                <div className="sketcher__colors">
                    {this.colors.map(this.renderColorButton.bind(this))}
                </div>
                {/*<div>
                    <input className="sketcher__color-input" type="text"
                        value={this.selectedColor}
                        placeholder={this.defaultColor}
                        style={{border: `2px solid ${this.selectedColor}`}}
                        onChange={(e) => {
                            const color = e.target.value.length === 7 ? e.target.value : this.defaultColor;
                            this.selectedColor = color;
                        }}/>
                    {this.props.fillColor &&
                        <select
                            className="sketcher__color-shape"
                            defaultValue={this.state.colorShape}
                            onChange={(e) => {
                                this.setState({ colorShape: e.target.value as ColorShape });
                            }}>
                            <option value="stroke">Stroke</option>
                            <option value="fill">Fill</option>
                        </select>
                    }
                    {this.props.fillColor &&
                        <label>
                            <input
                                type="checkbox"
                                checked={this.transparent}
                                onChange={(e) => {
                                    this.transparent = !this.transparent;
                                }}/>
                                Transparent
                        </label>
                    }
                </div>*/}
            </div>
        );
    }
}

export namespace MenuTools {

    interface IMenuTool {
        imageEditor: IImageEditor;
    }

    export class Select extends Component<IMenuTool, {}> {

        componentDidMount() {
            this.props.imageEditor.stopDrawingMode();
        }

        public render() {
            return <div style={{ fontSize: '1rem' }}>{t('web.common.sketcher.selectInfo')}</div>;
        }
    }

    export class Crop extends Component<IMenuTool, {}> {

        componentDidMount() {
            if (this.props.imageEditor.getDrawingMode() !== 'CROPPER')
                this.props.imageEditor.startDrawingMode('CROPPER');
        }

        apply() {
            this.props.imageEditor.crop(this.props.imageEditor.getCropzoneRect());
        }

        cancel() {
            this.props.imageEditor.stopDrawingMode();
            this.props.imageEditor.startDrawingMode('CROPPER');
        }

        public render() {
            return (
                <div className="sketcher__tool-crop">
                    <button onClick={this.apply.bind(this)}>{t('web.common.sketcher.apply')}</button>
                    <button onClick={this.cancel.bind(this)}>{t('web.common.sketcher.cancel')}</button>
                </div>
            );
        }
    }

    export class Flip extends Component<IMenuTool, {}> {

        componentDidMount() {
            this.props.imageEditor.stopDrawingMode();
        }

        flipX() {
            this.props.imageEditor.flipX();
        }

        flipY() {
            this.props.imageEditor.flipY();
        }

        reset() {
            this.props.imageEditor.resetFlip();
        }

        public render() {
            return (
                <div className="sketcher__tool-flip">
                    <button onClick={this.flipX.bind(this)}>{t('web.common.sketcher.flipX')}</button>
                    <button onClick={this.flipY.bind(this)}>{t('web.common.sketcher.flipY')}</button>
                    <button onClick={this.reset.bind(this)}>{t('web.common.sketcher.reset')}</button>
                </div>
            );
        }
    }

    interface IRotateState {
        angle: number;
    }

    export class Rotate extends Component<IMenuTool, IRotateState> {

        state = {
            angle: 0
        }

        presetAngles = [0, 90, 180, 270]

        componentDidMount() {
            this.props.imageEditor.stopDrawingMode();
        }

        public render() {
            return (
                <div className="sketcher__tool-rotate">
                    <div className="sketcher__tool" style={{ margin: '10px' }}>{this.state.angle}°</div>
                    {/*<input min="0" max="360" value={this.state.angle} type="range"
                        onChange={(e) => {
                            const angle = e.target.valueAsNumber;
                            this.setState({ angle });
                            this.props.imageEditor.setAngle(angle);
                        }}/>*/}
                    {this.presetAngles.map((angle, i) => {
                        return (
                            <button
                                key={i}
                                onClick={(e) => {
                                    this.setState({ angle });
                                    this.props.imageEditor.setAngle(angle);
                                }}>
                                {angle}°
                            </button>
                        );
                    })}
                </div>
            );
        }
    }

    interface ILineState {
        color: string;
        brushWidth: number;
        drawingMode: string;
    }

    export class Line extends Component<IMenuTool, ILineState> {

        state = {
            color: '#00000',
            brushWidth: 5,
            drawingMode: 'FREE_DRAWING'
        }

        get brushSettings(): Option {
            return { width: this.state.brushWidth, color: hexToRgba(this.state.color) };
        }

        componentDidMount() {
            this.updateImageEditor();
        }

        componentDidUpdate(oldProps, oldState) {
            this.updateImageEditor();
        }

        updateImageEditor() {
            this.props.imageEditor.stopDrawingMode();
            this.props.imageEditor.setBrush(this.brushSettings);
            this.props.imageEditor.startDrawingMode(this.state.drawingMode, this.brushSettings);
        }

        handleChange(e) {
            const { value } = e.target;
            this.setState({ drawingMode: value });
        }

        public render() {
            return (
                <div className="sketcher__tool-line">
                    <div className="sketcher__tool-radio-group">
                        <label>
                            <input
                                checked={this.state.drawingMode === 'FREE_DRAWING'}
                                type="radio"
                                value="FREE_DRAWING"
                                name="line-type"
                                onChange={this.handleChange.bind(this)}/>
                            {t('web.common.sketcher.freeDrawing')}
                        </label>
                        <label>
                            <input
                                checked={this.state.drawingMode === 'LINE_DRAWING'}
                                type="radio"
                                value="LINE_DRAWING"
                                name="line-type"
                                onChange={this.handleChange.bind(this)}/>
                            {t('web.common.sketcher.straightLine')}
                        </label>
                    </div>

                    <ColorPicker
                        strokeColor={this.state.color}
                        onColorSelect={(color, colorShape) => this.setState({ color })}/>

                    {/*<div className="sketcher__tool-width">
                        <span>Brush width: {this.state.brushWidth}px</span>
                        <input min="1" max="30" value={this.state.brushWidth} type="range"
                            onChange={(e) => {
                                const brushWidth = e.target.valueAsNumber;
                                this.setState({ brushWidth });
                            }}/>
                    </div>*/}
                </div>
            );
        }
    }

    interface IShapeState {
        strokeColor: string;
        fillColor: string;
        strokeTransparent: boolean;
        fillTransparent: boolean;
        strokeWidth: number;
        selectedShape: DrawingShapeType;
        selectedColorShape: ColorShape;
    }

    export class Shape extends Component<IMenuTool, IShapeState> {

        state = {
            strokeColor: '#000000',
            fillColor: '#ffffff',
            strokeTransparent: false,
            fillTransparent: true,
            strokeWidth: 5,
            selectedShape: 'rect' as DrawingShapeType,
            selectedColorShape: 'fill' as ColorShape
        }

        drawingShapes = [
            { shape: 'rect', name: t('web.common.sketcher.rectangle') },
            { shape: 'circle', name: t('web.common.sketcher.circle') },
            // { shape: 'triangle', name: t('web.common.sketcher.triangle') }
        ]

        componentDidMount() {
            this.updateImageEditor();
        }

        componentDidUpdate(oldProps: IMenuTool, oldState: IShapeState) {
            this.updateImageEditor();
        }

        updateImageEditor() {
            this.props.imageEditor.stopDrawingMode();
            this.props.imageEditor.setDrawingShape(this.state.selectedShape as DrawingShapeType, {
                stroke: this.state.strokeTransparent ? 'transparent' : hexToRgba(this.state.strokeColor),
                fill: this.state.fillTransparent ? 'transparent' : hexToRgba(this.state.fillColor),
                strokeWidth: this.state.strokeWidth
            });
            this.props.imageEditor.startDrawingMode('SHAPE');
        }

        public render() {
            return (
                <div className="sketcher__tool-shape">
                    <div className="sketcher__tool-radio-group">
                        {this.drawingShapes.map((drawingShape, i) => {
                            return (
                                <label key={i}>
                                    <input
                                        checked={this.state.selectedShape === drawingShape.shape}
                                        type="radio"
                                        value={drawingShape.shape}
                                        name="line-type"
                                        onChange={(e) => {
                                            this.setState({ selectedShape: e.target.value as DrawingShapeType })
                                        }}/>
                                    {drawingShape.name}
                                </label>
                            );
                        })}
                    </div>

                    <ColorPicker
                        strokeColor={this.state.strokeColor}
                        fillColor={this.state.fillColor}
                        onColorSelect={(color, colorShape) => {
                            if (colorShape === 'stroke' as ColorShape) {
                                this.setState({ strokeColor: color });
                            } else {
                                this.setState({ fillColor: color });
                            }
                            this.setState({ selectedColorShape: colorShape });
                        }}
                        onTransparentChange={(transparent, colorShape) => {
                            if (colorShape === 'stroke' as ColorShape) {
                                this.setState({ strokeTransparent: transparent });
                            } else {
                                this.setState({ fillTransparent: transparent });
                            }
                            console.log(this.state);
                        }}/>

                    {/*<div className="sketcher__tool-width">
                        <span>Stroke width: {this.state.strokeWidth}px</span>
                        <input min="1" max="30" value={this.state.strokeWidth} type="range"
                            onChange={(e) => {
                                const strokeWidth = e.target.valueAsNumber;
                                this.setState({ strokeWidth });
                            }}/>
                    </div>*/}
                </div>
            );
        }
    }

    /*export class Icon extends Component<IMenuTool, {}> {

        public render() {
            return <div>Not implemented yet.</div>;
        }
    }*/

    type TextStyle = { id: string, name: string, checked: boolean };
    type TextAlignment = 'left' | 'center' | 'right';

    interface ITextState {
        activeObjectId: number;
        textStyles: TextStyle[];
        textAlignment: TextAlignment;
        color: string;
        fontSize: number;
        displayInfo: boolean;
    }

    export class Text extends Component<IMenuTool, ITextState> {

        state = {
            activeObjectId: -1,
            textAlignment: 'left' as TextAlignment,
            textStyles: [
                { id: 'bold', name: t('web.common.sketcher.bold'), checked: false },
                { id: 'italic', name: t('web.common.sketcher.italic'), checked: false },
                { id: 'underline', name: t('web.common.sketcher.underline'), checked: false }
            ],
            color: '#000000',
            fontSize: 40,
            displayInfo: true
        }
        objectActivated = (obj: ObjectProps) => {
             this.setState({
                 activeObjectId: obj.id,
                 textAlignment: obj.textAlign as TextAlignment,
                 textStyles: [
                     { id: 'bold', name: 'Bold', checked: obj.fontWeight === 'bold' },
                     { id: 'italic', name: 'Italic', checked: obj.fontStyle === 'italic' },
                     { id: 'underline', name: 'Underline', checked: obj.textDecoration === 'underline' }
                 ],
                 color: obj.fill,
                 fontSize: obj.fontSize
             });
        }
        addText = (pos) => {
            this.props.imageEditor.addText(t('web.common.sketcher.doubleClick'), {
                 styles: {
                     textAlign: this.state.textAlignment,
                     fontWeight: this.state.textStyles[0].checked ? 'bold' : 'normal',
                     fontStyle: this.state.textStyles[1].checked ? 'italic' : 'normal',
                     textDecoration: this.state.textStyles[2].checked ? 'underline' : undefined,
                     fill: hexToRgba(this.state.color),
                     fontSize: this.state.fontSize,
                     fontFamily: 'SecuritasSansTT'
                 },
                position: pos.originPosition
            }).then(objectProps => {
                // console.log(objectProps);
            });
        }
        changeText = (id: number, text: string) => {

        }

        componentDidMount() {
            const { objectActivated, addText } = this;
            this.props.imageEditor.on({
                objectActivated,
                addText
            });
            this.props.imageEditor.stopDrawingMode();
            this.props.imageEditor.startDrawingMode('TEXT');
            // this.updateTextStyle();
        }

        componentDidUpdate(oldProps: IMenuTool, oldState: ITextState) {
            //console.log(this.state);
            //this.updateTextStyle();
        }

        updateTextStyle() {
            if (this.state.activeObjectId === -1)
                return;

            const styles = {
                textAlign: this.state.textAlignment,
                fontWeight: this.state.textStyles[0].checked ? 'bold' : 'normal',
                fontStyle: this.state.textStyles[1].checked ? 'italic' : 'normal',
                textDecoration: this.state.textStyles[2].checked ? 'underline' : 'none',
                fill: hexToRgba(this.state.color),
                fontSize: this.state.fontSize,
                fontFamily: 'SecuritasSansTT'
            };

            this.props.imageEditor.changeTextStyle(this.state.activeObjectId, styles);
        }

        public render() {
            return (
                <div className="sketcher__tool-text">
                    <div className="sketcher__tool-text-alignment">
                        <button
                            className={this.state.textAlignment === 'left' && 'selected'}
                            onClick={(e) => this.setState({ textAlignment: 'left' })}>
                            {t('web.common.sketcher.left')}
                        </button>
                        <button
                            className={this.state.textAlignment === 'center' && 'selected'}
                            onClick={(e) => this.setState({ textAlignment: 'center' })}>
                            {t('web.common.sketcher.center')}
                        </button>
                        <button
                            className={this.state.textAlignment === 'right' && 'selected'}
                            onClick={(e) => this.setState({ textAlignment: 'right' })}>
                            {t('web.common.sketcher.right')}
                        </button>
                    </div>

                    <div className="sketcher__tool-checkbox-group">
                        {this.state.textStyles.map((textStyle, i) => {
                            return (
                                <label key={i}>
                                    <input
                                        id={textStyle.id}
                                        type="checkbox"
                                        checked={textStyle.checked}
                                        onChange={(e) => {
                                            const updatedTextStyle = { ...textStyle, checked: !textStyle.checked };
                                            this.setState({
                                                textStyles: update(this.state.textStyles,
                                                    { $splice: [[i, 1, updatedTextStyle]] }
                                                )
                                            });
                                        }}/>
                                    {textStyle.name}
                                </label>
                            );
                        })}
                    </div>

                    <ColorPicker
                        strokeColor={this.state.color}
                        onColorSelect={(color, colorShape) => this.setState({ color })}/>

                    <div className="sketcher__tool-width">
                        <span>{t('web.common.sketcher.fontSize')}: {this.state.fontSize}px</span>
                        <input min="10" max="200" value={this.state.fontSize} type="range"
                               onChange={(e) => {
                                   const fontSize = e.target.valueAsNumber;
                                   this.setState({ fontSize });
                               }}/>
                    </div>

                    {this.state.displayInfo &&
                        <Modal title="How to add text" onClose={() => { this.setState({...this.state, displayInfo: false}) }}>
                            <div>
                                <h3> How to add text to the image: </h3>
                                <ol>
                                    <li>Select text alignment</li>
                                    <li>Select text style</li>
                                    <li>Select text color</li>
                                    <li>Select text size</li>
                                </ol>
                                <b>Step 1-4 can not be changed after placing the text.</b>
                                <ol start="5">
                                    <li>Click on the image where you want to add the text</li>
                                    <li> Double click to overwrite the initial text</li>
                                </ol>
                                <p>At anytime you can click outside the textbox to have the textbox adjust its size according to your text.</p>
                                <p>You can move the text by clicking on the textbox and drag it around..</p>
                                <p>You can resize the textbox by dragging the green squares in the corners</p>
                                <p>You can rotate the textbox by draging the upper green square.</p>

                            </div>
                        </Modal>
                    }

                </div>
            );
        }
    }

    /*export class Mask extends Component<IMenuTool, {}> {

        public render() {
            return <div>Not implemented yet.</div>;
        }
    }*/
}
