import React from 'react';

import './right-side-container.css';

const RightSideContainer = ({children}) => {
  return (
    <div className="right-side-container">
      {children}
    </div>
  );
}

export default RightSideContainer;
