import {call, put, takeEvery, takeLatest} from 'redux-saga/effects';
import {
  GET_ATTACHMENTS_REQUESTED,
  GET_ATTACHMENTS_SUCCEEDED,
  GET_MEDIAFILES_REQUESTED,
  GET_MEDIAFILES_SUCCEEDED,
  POST_ATTACHMENT_REQUESTED,
  POST_ATTACHMENT_SUCCEEDED,
  POST_PROJECT_IMAGE_REQUESTED
} from './actions';
import {ADD_ERROR} from '../app/actions';
import t from '../i18n';
import {getAttachments, projectImageUploader, uploadAttachment, uploadAttachmentMetadata, getMediaFiles} from '../services/api';

function* postAttachmentRequsted(action) {
  try {
    const metadata = {
      project_id: action.payload.projectId,
      name: action.payload.file.name,
      content_type: action.payload.file.type,
      notes: 'HD'
    };

    const attachmentMetadata = yield call(
      uploadAttachmentMetadata,
      action.payload.projectId,
      metadata
    );

    yield call(
      uploadAttachment,
      action.payload.projectId,
      attachmentMetadata.attachment_id,
      action.payload.file
    )

    yield put({type: POST_ATTACHMENT_SUCCEEDED});
  } catch (e) {
    yield put({
      type: ADD_ERROR, payload: {
        title: t('web.common.errorTitleGeneric'),
        message: t('Something unexpected happened')
      }
    });
  }
}


function* postProjectImageRequsted(action) {
  try {
    yield call(
      projectImageUploader,
      action.payload.projectId,
      action.payload.file
    );

    yield put({type: POST_ATTACHMENT_SUCCEEDED});
  } catch (e) {
    yield put({
      type: ADD_ERROR, payload: {
        title: t('web.common.errorTitleGeneric'),
        message: t('Something unexpected happened')
      }
    });
  }
}

function* getAttachmentsRequested(action) {
  try {
    const attachments = yield call(
      getAttachments,
      action.payload.projectId
    );

    yield put({type: GET_ATTACHMENTS_SUCCEEDED, attachments: attachments});
  } catch (e) {

  }
}

function* getMediafilesRequested(action) {
  try {
    const mediafiles = yield call(
      getMediaFiles,
      action.payload.projectId
    );

    yield put({type: GET_MEDIAFILES_SUCCEEDED, mediafiles: mediafiles});
  } catch (e) {

  }
}

export default function* root() {
  yield [
    takeLatest(GET_ATTACHMENTS_REQUESTED, getAttachmentsRequested),
    takeEvery(POST_ATTACHMENT_REQUESTED, postAttachmentRequsted),
    takeEvery(GET_MEDIAFILES_REQUESTED, getMediafilesRequested),
    takeEvery(POST_PROJECT_IMAGE_REQUESTED, postProjectImageRequsted)
  ]
}
