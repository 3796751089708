// @ts-nocheck
// TODO remove above
import { call, put, select, takeEvery } from 'redux-saga/effects';
import {
    addProductActions,
    deleteProductActions,
    editProductActions,
    getCategoriesActions,
    getProductsActions,
    getProjectProductsActions,
    updateProjectProductsOrderActions
} from './actions';
import { BEGIN, COMMIT, REVERT } from 'redux-optimist';

import t from '../i18n';
import {
    addProductToProject,
    deleteProductForProject,
    editProductForProject,
    fetchCategories,
    fetchProducts,
    fetchProjectProducts,
    updateProjectProductsOrder
} from '../services/api';
import { ADD_ERROR } from '../app/actions';
import { Failure, Success } from 'typescript-fsa';
import { AnyAction } from 'redux';

function getFsaSuccessPayload<S, P=string>(payload: S, params?: P): Success<any, S> {
    return {
        params: params || "",
        result: payload
    }
}

function getFsaFailurePayload<S=string, P=string>(payload: S, params?: P): Failure<any, S> {
    return {
        params: params || "",
        error: payload
    }
}

let productsNextTransactionId = 0;
const getNextTransactionId = () => productsNextTransactionId++;

function* getProductsRequested(action: AnyAction) {
    try {
        let products = yield select((state: any) => state.products.products);
        if (!products || products.length === 0) {
            products = yield call(fetchProducts);
        }
        let payload = getFsaSuccessPayload({ products });
        yield put(getProductsActions.done(payload));
    } catch (e) {
        let payload = getFsaFailurePayload(t('Something unexpected happened'));
        yield put(getProductsActions.failed(payload));
    }
}

function* getCategoriesRequested(action: AnyAction) {
    try {
        let categories = yield select((state: any) => state.products.categories);
        if (!categories || categories.length === 0) {
            categories = yield call(fetchCategories, action.payload.country_id);
        }
        let payload = getFsaSuccessPayload({ categories });
        yield put(getCategoriesActions.done(payload));
    } catch (e) {
        let payload = getFsaFailurePayload(t('Something unexpected happened'));
        yield put(getCategoriesActions.failed(payload));
    }
}

function* getProjectProductsRequested(action: AnyAction) {
    try {
        let projectProducts = yield select((state: any) => state.products.allProjectProducts[action.payload.project_id]);
        if (!projectProducts || projectProducts.length === 0) {
            projectProducts = yield call(fetchProjectProducts, action.payload.project_id);
        }
        let payload = getFsaSuccessPayload({ projectProducts })
        yield put(getProjectProductsActions.done(payload));
    } catch (e) {
        let payload = getFsaFailurePayload(t('Something unexpected happened'));
        yield put(getProjectProductsActions.failed(payload));
    }
}

function* addProductRequested(action: AnyAction) {
    try {
        const product = yield call(addProductToProject, action.payload.productLink.project_id, action.payload.productLink);
        let payload = getFsaSuccessPayload({ product });
        yield put(addProductActions.done(payload));
    } catch (e) {
        let payload = getFsaFailurePayload(t('Something unexpected happened'));
        yield put(addProductActions.failed(payload));
    }
}

function* deleteProductRequested(action: AnyAction) {
    let { project_id, project_product_id } = action.payload.projectProduct;
    try {
        yield call(deleteProductForProject,
            project_id,
            project_product_id,
            action.payload.projectProduct);
        let payload = getFsaSuccessPayload({ project_id, project_product_id });
        yield put(deleteProductActions.done(payload));
    } catch (e) {
        let payload = getFsaFailurePayload(t('Something unexpected happened'));
        yield put(deleteProductActions.failed(payload));
    }
}

function* editProductRequested(action: AnyAction) {
    let { project_id, project_product_id } = action.payload.product;
    try {
        let product = yield call(editProductForProject, project_id, project_product_id, action.payload.product);
        let payload = getFsaSuccessPayload({ product });
        yield put(editProductActions.done(payload));
    } catch (e) {
        let payload = getFsaFailurePayload(t('Something unexpected happened'));
        yield put(editProductActions.failed(payload));
    }
}

function* updateProjectProductsOrderRequested(action: AnyAction) {
    let { project_id, product_ids } = action.payload.projectProducts;
    try {
        let products = yield call(updateProjectProductsOrder, project_id, product_ids);
        let payload = getFsaSuccessPayload({ products });
        yield put(updateProjectProductsOrderActions.done(payload));
    } catch (e) {
        let payload = getFsaFailurePayload(t('Something unexpected happened'));
        yield put(updateProjectProductsOrderActions.failed(payload));
    }
}

export const dispatchAddAction = (dispatch, project_id, product) => {
    let productLink = {
        quantity: (product.quantity ? product.quantity : 1),
        product_id: product.product_id,
        project_id: project_id
    };
    dispatch(addProductActions.started({ productLink }));
}

export const dispatchEditAction = (dispatch, product) => {
    // let productLink = {
    //     quantity: (product.quantity ? product.quantity : 1),
    //     product_id: product.product_id,
    //     project_id: project_id
    // };
    dispatch(editProductActions.started({ product }));
}

export const dispatchDeleteAction = (dispatch, projectId, productId, projectProductId) => {
    const transactionId = getNextTransactionId();
    let projectProduct = {
        product_id: productId,
        project_id: projectId,
        project_product_id: projectProductId
    };
    dispatch(deleteProductActions.started({ projectProduct }));
}

export const dispatchUpdateProjectProductsOrderAction = (dispatch, project_id, product_ids) => {
    let projectProducts = {
        project_id: project_id,
        product_ids: product_ids
    };
    dispatch(updateProjectProductsOrderActions.started({ projectProducts}));
}

export default function* root() {
    yield [
        takeEvery(getProductsActions.started, getProductsRequested),
        takeEvery(getCategoriesActions.started, getCategoriesRequested),
        takeEvery(getProjectProductsActions.started, getProjectProductsRequested),
        takeEvery(addProductActions.started, addProductRequested),
        takeEvery(deleteProductActions.started, deleteProductRequested),
        takeEvery(editProductActions.started, editProductRequested),
        takeEvery(updateProjectProductsOrderActions.started, updateProjectProductsOrderRequested)
    ]
}
