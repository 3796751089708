import * as React from 'react';
import { fetchImageBlob } from "../services/api";

export let imageCache = {};

export function clearImageCache(imageUrl?: string) {
    if (imageUrl)
        imageUrl ? imageCache[imageUrl] = undefined : imageCache = {};
}

export function setImageCache(imageUrl: string, value: any) {
    imageCache[imageUrl] = value;
}

export class Image extends React.Component<any, any> {

    state = { src: "" };

    componentDidMount() {
        this.setImageState(this.props);
    }

    isApiUrl(src) {
        let a = document.createElement("a");
        a.href = src;
        return a.pathname.lastIndexOf("/api/", 0) === 0;
    }

    setImageState = (props) => {
        let { src, ...rest } = props;
        if (this.isApiUrl(src)) {
            if (imageCache[src]) {
                this.setState({ src: imageCache[src] });
                return;
            }

            fetchImageBlob(src).then(blobSrc => {
                setImageCache(src, blobSrc);
                this.setState({ src: blobSrc });
            });
            
        }
        else {
            this.setState({ src: props.src });
        }
    }

    render() {
        let { src, ...rest } = this.props;
        return (
            <img src={this.state.src} {...rest} />
        )
    }
}
