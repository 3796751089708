// @ts-nocheck
// TODO remove above
import * as React from 'react'
import { connect } from 'react-redux';

import {
    FETCH_ADVANCED_RISK_SCAN_REQUESTED,
    EDIT_QUESTIONARIES_REQUESTED,
    POST_SURVEY_ATTACHMENT_REQUESTED,
    DELETE_SURVEY_ATTACHMENT_REQUESTED,
    EDIT_SURVEY_ATTACHMENT_REQUESTED,
    EDIT_SUMMARY_REQUESTED
} from './actions';
import t from '../i18n/index';
import Places from './places';
import Breadcrumbs from '../breadcrumbs/breadcrumbs';
import { getProjectById } from '../projects/reducer';
import { getPlacesTree, getAnswers, getImages, getTreeNode, getSummaries } from './selectors';
import { SurveyState } from './reducer';
import callApi, { callApiRaw } from '../services/api-client';
import { browserHistory } from 'react-router';
import Page from '../common/page/page';
import { IImage } from '../image/imagelist';
import Sketcher from '../common/sketcher/sketcher';
import Spinner from '../spinner/spinner';

class SurveyContainer extends React.Component<any, any> {

    state = {
        selectedSketchImage: null,
        isDownloadingSurvey: false
    }

    componentDidMount() {
        this.props.dispatchAction({
            type: FETCH_ADVANCED_RISK_SCAN_REQUESTED,
            payload: { project_id: this.props.params.id }
        });
    }

    handleClickOnPlace = (place) => {
        browserHistory.push(this.props.location.pathname + "/" + place.id);
    }

    handleClickOnBack = (indexForItemClicked) => {
        let surveyPaht = `/project/${this.props.params.id}/survey`;
        let stack = this.getNavigationsStack(this.props.currentPlace);
        let clickedPlace = stack[indexForItemClicked];
        let newPath = "";
        while (clickedPlace && clickedPlace.id) {
            newPath = "/" + clickedPlace.id + newPath;
            clickedPlace = clickedPlace.parentPlace;
        }
        browserHistory.push(surveyPaht + newPath);
    }

    handleImageEvent = (data, questionId, type = "ADD", attachmentId = undefined) => {
        switch (type){

            case "ADD": {
                const file = data[0];
                this.props.dispatchAction({
                    type: POST_SURVEY_ATTACHMENT_REQUESTED,
                    payload: {
                        projectId: this.props.params.id,
                        questionId: questionId,
                        file: file
                    }
                });
                break;
            }
            
            case "DELETE": {
                this.props.dispatchAction({
                    type: DELETE_SURVEY_ATTACHMENT_REQUESTED,
                    payload: {
                        projectId: this.props.params.id,
                        attachmentId: attachmentId
                    }
                });
                break;
            }

            
            case "UPDATE": {
                const file = data[0];
                this.props.dispatchAction({
                    type: EDIT_SURVEY_ATTACHMENT_REQUESTED,
                    payload: {
                        projectId: this.props.params.id,
                        attachmentId: attachmentId,
                        file: file
                    }
                });
                break;
            }
            
            default: return
        }
    }

    onSaveSketchImage = (file: File) => {
        this.handleImageEvent([file], null, 'UPDATE', this.state.selectedSketchImage.id);
        this.setState({ selectedSketchImage: null });
    }

    onSelectSketchImage = (image: IImage) => {
        this.setState({ selectedSketchImage: image });
    }

    getNavigationsStack(place) {
        let stack = [];
        while (place) {
            stack.unshift(place);
            place = place.parentPlace;
        }
        stack.unshift({ name: t('web.surveyLibrary.home') });
        return stack;
    }

    handleAnswerChanged = (question, alternative, answer, note) => {
        let project_id = this.props.project.project_id;
        let seller_id = this.props.project.seller_id;
        this.props.dispatchAction({
            type: EDIT_QUESTIONARIES_REQUESTED,
            payload: { question, alternative, answer, project_id, seller_id, note }
        });
    }

    handleSummaryChanged = summary => {
        let project_id = this.props.project.project_id;
        this.props.dispatchAction({
            type: EDIT_SUMMARY_REQUESTED,
            payload: { project_id, summary }
        });
    }

    surveyDownloaded = (e, id = false) => {

        this.setState({ isDownloadingSurvey: true });
        
        return callApiRaw(`surveys/projects/${this.props.params.id}/questionsset/${id ? id : this.props.currentPlace.id}/`, 'GET', null, 'application/pdf', 'application/pdf').then(response => {
            var element = document.createElement('a');
            let blob = new Blob([response], {type: "application/pdf"});
            let url = window.URL.createObjectURL(blob);
            element.setAttribute('href', url);
            element.setAttribute('download', `${this.props.project.name} - survey.pdf`);
            element.style.display = 'none';
            document.body.appendChild(element);
            element.click();
            document.body.removeChild(element);
            this.setState({ isDownloadingSurvey: false });
        });
    };

    surveySent = (e) => {
        return callApi(`surveys/projects/${this.props.params.id}/questionsset/${this.props.currentPlace.id}?link=true`, 'GET', null).then(response => {
            window.location = `mailto:?subject=${this.props.currentPlace.name}&body=${this.props.currentPlace.name}: ${response}`
        });
    };

    static isDisplayingSurvey(currentPlace): boolean {
        if (currentPlace) {
            return currentPlace.childPlaces.length === 0;
        }
        return false;
    }

    render() {
        const { isFetching, places, currentPlace, survey, answers, summaries, images } = this.props;
        let navigationsStack = this.getNavigationsStack(currentPlace);
        const currentPlaces = currentPlace ? currentPlace.childPlaces : places;
        return (
            <Page title={t('web.surveys.title')} includePadding={false} showSpinner={isFetching || !survey}>
                <div className="survey__breadcrumbs">
                    <Breadcrumbs
                        navigationStack={navigationsStack}
                        handleNavigationStackPop={this.handleClickOnBack} />
                </div>
                {!SurveyContainer.isDisplayingSurvey(currentPlace) ||
                    <div className="survey__download_buttons">
                        {this.state.isDownloadingSurvey && 
                            <button style={{margin: '0 3rem'}} title="Document is being prepared..." className="btn fetching">
                                <Spinner/>
                            </button>
                        }
                        {!this.state.isDownloadingSurvey &&
                            <button style={{margin: '0 3rem'}} className="btn" onClick={(e) => {this.surveyDownloaded(e, currentPlace.id)}} >{t('web.surveys.button.download')}</button>
                        }
                        <button style={{margin: '0 3rem'}} className="btn" onClick={this.surveySent} >{t('web.surveys.button.send')}</button>
                    </div>
                }
                <Places
                    answers={answers}
                    images={images}
                    summaries={summaries}
                    handleClickOnPlace={this.handleClickOnPlace}
                    places={currentPlaces}
                    selectedPlace={currentPlace}
                    downloadPdf={this.surveyDownloaded.bind(this)}
                    handleAnswerChanged={this.handleAnswerChanged}
                    handleImageEvent={this.handleImageEvent}
                    handleSummaryChanged={this.handleSummaryChanged}
                    onSelectSketchImage={this.onSelectSketchImage} />
                {   this.state.selectedSketchImage &&
                    <Sketcher
                        image={this.state.selectedSketchImage}
                        onSave={this.onSaveSketchImage}
                        onClose={() => this.setState({ selectedSketchImage: null })}/>
                }
            </Page>
        );
    }
}

const mapStateToProps = ({ survey, projects }, { routeParams, ...ownProps }) => {
    let project = getProjectById(projects, routeParams.id);
    let places = getPlacesTree(survey.survey);
    let currentPlace = undefined;
    if (routeParams.splat) {
        let pathItems = routeParams.splat.split("/");
        let currentPlaceId = pathItems[pathItems.length - 1];
        let currentParentId = pathItems[pathItems.length - 2];
        currentPlace = getTreeNode(places, Number(currentPlaceId), Number(currentParentId));
    }
    return {
        ...survey,
        project,
        places,
        currentPlace,
        summaries: getSummaries(survey, project.project_id) || [],
        answers: getAnswers(survey, project.project_id) || [],
        images: getImages(survey, project.project_id) || [],
    }
};

const mapDispatchToProps = (dispatch) => ({
    dispatchAction: (action) => dispatch(action)
});

export default connect(mapStateToProps, mapDispatchToProps)(SurveyContainer);
