// @ts-nocheck
// TODO remove above
import React, { Component } from 'react';
import RiskMatrixRow from './risk-matrix-row';
import t, { d } from '../../i18n';
import Modal from '../../common/modal/modal';
import Button from './button';
import { IRisk } from '../risk';
import circleVeryHigh from '../../img/risk/circle-very-high.svg';
import circleHigh from '../../img/risk/circle-high.svg';
import circleMedium from '../../img/risk/circle-medium.svg';
import circleLow from '../../img/risk/circle-low.svg';
import classNames from 'classnames';
import { array } from 'prop-types';
import TableRow from './table/table-row';
import getRiskRatingColor from '../../utils/get-risk-rating-color';
import { getCorrectRiskSorting } from '../../utils/get-correct-risk-list-sorting';

interface IRiskMatrixProps {
    risks: IRisk[];
    modalValues?: any[];
    toggleEditRisks: (risk_id: number) => void;
    segment: string;
}

interface IRiskMatrixState {
    modalContent: any;
    selectedRisks: number[];
}

export default class RiskMatrix extends Component<IRiskMatrixProps, any> {
    // private modal;

    // noinspection JSUnusedGlobalSymbols
    public static defaultProps: Partial<IRiskMatrixProps> = {
        modalValues: [],
    };
    state: IRiskMatrixState = { modalContent: null, selectedRisks: [] };
    public updateModalContent = (risks: IRisk[]) => {
        const groupNames = Array.from(
            new Set<string>(risks.map((risk) => risk.group_name))
        );
        if (groupNames.length > 0) {
            this.setState({
                modalContent: groupNames.map((groupName, i) => (
                    <div key={i}>
                        <h3 style={{ margin: '28px 0 8px 0' }}>
                            {d('risk.risk_group', groupName)}
                        </h3>
                        {risks
                            .filter((risk) => risk.group_name === groupName)
                            .map((risk, i) => {
                                return (
                                    <Button
                                        key={i}
                                        risk={risk}
                                        toggleEditRisks={
                                            this.props.toggleEditRisks
                                        }
                                        segment={this.props.segment}
                                    />
                                );
                            })}
                    </div>
                )),
            });
        } else {
            this.setState({ modalContent: null });
        }
    };
    public closeModal = () => {
        this.setState({
            modalContent: null,
        });
    };

    constructor(props) {
        super(props);
    }

    private static getMatrixRowLayout(row): Array<string> {
        return [
            ['yellow', 'orange', 'red', 'red'],
            ['yellow', 'orange', 'orange', 'red'],
            ['green', 'yellow', 'orange', 'orange'],
            ['green', 'green', 'yellow', 'yellow'],
        ][row];
    }

    setSelectedRisks = (risks: number[]) => {
        this.setState({ selectedRisks: risks });
    };

    public render() {
        const [
            selectedRisks,
            sameLevelAsSelected,
            differentLevelFromSelected,
        ]: Array<Array<IRisk>> = getCorrectRiskSorting(
            this.props.risks,
            this.state.selectedRisks
        );

        return (
            <div className="risk-matrix">
                <div
                    className="risk-matrix__content"
                    data-probability-text={t('web.risk.probability')}
                >
                    <RiskMatrixRow
                        impact={'4\n' + t('web.risk.matrixVeryHigh')}
                        data={this.getRisksByProbability(3)}
                        layout={RiskMatrix.getMatrixRowLayout(0)}
                        onElementClicked={this.setSelectedRisks}
                        selectedRisks={this.state.selectedRisks}
                    />
                    <RiskMatrixRow
                        impact={'3\n' + t('web.risk.matrixHigh')}
                        data={this.getRisksByProbability(2)}
                        layout={RiskMatrix.getMatrixRowLayout(1)}
                        onElementClicked={this.setSelectedRisks}
                        selectedRisks={this.state.selectedRisks}
                    />
                    <RiskMatrixRow
                        impact={'2\n' + t('web.risk.matrixMedium')}
                        data={this.getRisksByProbability(1)}
                        layout={RiskMatrix.getMatrixRowLayout(2)}
                        onElementClicked={this.setSelectedRisks}
                        selectedRisks={this.state.selectedRisks}
                    />
                    <RiskMatrixRow
                        impact={'1\n' + t('web.risk.matrixLow')}
                        data={this.getRisksByProbability(0)}
                        layout={RiskMatrix.getMatrixRowLayout(3)}
                        onElementClicked={this.setSelectedRisks}
                        selectedRisks={this.state.selectedRisks}
                    />
                    <div
                        className="risk-matrix__bottom-legend risk-matrix__row"
                        data-impact-text={t('web.risk.impact')}
                    >
                        <div className="risk-matrix__element">
                            1<br />
                            {t('web.risk.matrixLow')}
                        </div>
                        <div className="risk-matrix__element">
                            2<br />
                            {t('web.risk.matrixMedium')}
                        </div>
                        <div className="risk-matrix__element">
                            3<br />
                            {t('web.risk.matrixHigh')}
                        </div>
                        <div className="risk-matrix__element">
                            4<br />
                            {t('web.risk.matrixVeryHigh')}
                        </div>
                    </div>
                    {this.state.modalContent && (
                        <Modal onClose={this.closeModal} title="">
                            {this.state.modalContent}
                        </Modal>
                    )}
                </div>
                <div className="risk-matrix__table">
                    <table>
                        <tbody>
                            <tr>
                                <th>{t('web.risk.matrix.rating')}</th>
                                <th>{t('web.risk.matrix.risk')}</th>
                                <th>{t('web.risk.matrix.area')}</th>
                            </tr>
                            {[
                                ...selectedRisks,
                                ...sameLevelAsSelected,
                                ...differentLevelFromSelected,
                            ].map((risk) => {
                                return (
                                    <TableRow
                                        key={risk.id}
                                        risk={risk}
                                        toggleEditRisks={
                                            this.props.toggleEditRisks
                                        }
                                        segment={this.props.segment}
                                        selectedRisks={this.state.selectedRisks}
                                    />
                                );
                            })}
                        </tbody>
                    </table>
                </div>
            </div>
        );
    }

    private getRisksByProbability(prob): Array<any> {
        return this.props.risks.filter((risk) => {
            return risk.probability === prob;
        });
    }
}
