import { put, takeEvery, call } from 'redux-saga/effects';
import * as riskActions from "./actions";
import * as areaActions from "../area/actions";
import {
    fetchRisks,
    fetchSegmentRisks,
    fetchProjectRisks,
    addProjectRisk,
    fetchRiskScenarios,
    fetchRiskSolutions,
    deleteProjectRisk,
    editProjectRisk,
    fetchProjectAreas,
    addProjectArea,
    deleteProjectArea,
    fetchRiskProjectImages,
    uploadRiskProjectImage,
    updateRiskProjectImage,
    deleteRiskProjectImage,
    createCustomScenarioForProject,
    createCustomArea,
    fetchSegmentDefinition
} from '../services/api'
import * as scenarioActions from "../scenario/actions";

function* getRisksRequested(action) {
    try {
        let risks = yield fetchRisks(action.payload.segment_id);
        yield put({ type: riskActions.GET_RISKS_SUCCEEDED, payload: { risks } });
    } catch (e) {

    }
}

function* getSegmentRisksRequested(action) {
    try {
        let risks = yield fetchSegmentRisks(action.payload.segment_id);
        yield put({ type: riskActions.GET_SEGMENT_RISKS_SUCCEEDED, payload: { risks } })
    } catch (e) {

    }
}

function* getProjectRisksRequested(action) {
    try {
        let projectRisks = yield call(fetchProjectRisks, action.payload.project_id);
        yield put({ type: riskActions.GET_PROJECT_RISKS_SUCCEEDED, payload: { projectRisks } });
    } catch (e) {
        yield put({ type: riskActions.GET_PROJECT_RISKS_SUCCEEDED, payload: { projectRisks: [] } });
    }
}

function* getProjectAreasRequested(action) {
    try {
        let projectAreas = yield call(fetchProjectAreas, action.payload.project_id);
        yield put({ type: riskActions.GET_PROJECT_AREAS_SUCCEEDED, payload: { projectAreas } });
    } catch (e) {
        yield put({ type: riskActions.GET_PROJECT_AREAS_SUCCEEDED, payload: { projectAreas: [] } });
    }
}

function* addProjectRisksRequested(action) {
    try {
        let addedRisk = yield addProjectRisk(action.payload.risk.project_id, action.payload.risk);
        yield put({ type: riskActions.ADD_RISKS_SUCCEEDED, payload: { addedRisk } });
    } catch (e) {
        // yield put({ type: GET_PROJECT_RISKS_SUCCEEDED, payload: { projectRisks: [] } });
    }
}

function* editProjectRisksRequested(action) {
    try {
        //dont send scenarios when updating risk
        let submitRisk = { ...action.payload.risk, scenarios: undefined }
        let changedRisk = yield editProjectRisk(action.payload.risk.project_id, submitRisk);
        changedRisk = { ...changedRisk, scenarios: action.payload.risk.scenarios }
        yield put({ type: riskActions.EDIT_RISKS_SUCCEEDED, payload: { changedRisk } });
    } catch (e) {
        // yield put({ type: GET_PROJECT_RISKS_SUCCEEDED, payload: { projectRisks: [] } });
    }
}

function* deleteProjectRisksRequested(action) {
    try {
        yield deleteProjectRisk(action.payload.risk.project_id, action.payload.risk.id);
        yield put({ type: riskActions.REMOVE_RISKS_SUCCEEDED, payload: { deletedRisk: action.payload.risk } });
    } catch (e) {
        // yield put({ type: GET_PROJECT_RISKS_SUCCEEDED, payload: { projectRisks: [] } });
    }
}

function* addProjectAreasRequested(action) {
    try {
        let projectArea = yield call(addProjectArea, action.payload.project_id, action.payload);
        yield put({ type: riskActions.ADD_PROJECT_AREAS_SUCCEEDED, payload: { projectArea: projectArea } });
    } catch (e) {
        yield put({ type: riskActions.ADD_PROJECT_AREAS_SUCCEEDED, payload: { projectArea: [] } });
    }
}

function* removeProjectAreasRequested(action) {
    try {
// eslint-disable-next-line no-unused-vars
        let projectArea = yield call(deleteProjectArea, action.payload.project_id, action.payload.id);
        yield put({ type: riskActions.REMOVE_PROJECT_AREAS_SUCCEEDED, payload: { projectArea: action.payload } });
    } catch (e) {
        yield put({ type: riskActions.REMOVE_PROJECT_AREAS_SUCCEEDED, payload: { projectArea: [] } });
    }
}


function* createNewScenarioForProjectRequested(action) {
    try {
        let result = yield call(createCustomScenarioForProject, action.payload.name, action.payload.projectId);
        const scenario = {
            scenario_id: `${result.id}`,
            risk_id: action.payload.riskId,
            status: "",
            occurence: [],
            solutions: []
        };
        yield put({ type: scenarioActions.ADD_RISK_SCENARIO_REQUESTED, payload: { scenario, riskId: action.payload.riskId, projectId: action.payload.projectId } })
    } catch (e) {
        yield put({ type: riskActions.CREATE_CUSTOM_SCENARIO_SUCCEEDED, payload: { scenario: null, riskId: action.riskId } })
    }
}

function* getRiskScenarios() {
    try {
        let scenarios = yield fetchRiskScenarios();
        yield put({ type: riskActions.GET_SCENARIOS_SUCCEEDED, payload: { scenarios } });
    } catch (e) {

    }
}

function* getRiskSolutions() {
    try {
        let solutions = yield fetchRiskSolutions();
        yield put({ type: riskActions.GET_SOLUTIONS_SUCCEEDED, payload: { solutions } });
    } catch (e) {

    }
}

function* getProjectImagesRequested(action) {
    try {
        let projectImages = yield fetchRiskProjectImages(action.payload.project_id);
        yield put({ type: riskActions.GET_PROJECT_IMAGES_SUCCEEDED, payload: { projectImages } });
    } catch (e) {

    }
}

function* addProjectImagesRequested(action) {
    try {
        let response = yield call(uploadRiskProjectImage, action.payload.projectId, action.payload.riskId, action.payload.file);
        yield put({ type: riskActions.GET_PROJECT_IMAGES_REQUESTED, payload: { project_id: action.payload.projectId} });
    } catch (e) {

    }
}

function* updateProjectImagesRequested(action) {
    try {
        let response = yield call(updateRiskProjectImage, action.payload.projectId, action.payload.riskId, action.payload.attachmentId, action.payload.file);
        yield put({ type: riskActions.GET_PROJECT_IMAGES_REQUESTED, payload: { project_id: action.payload.projectId} });
    } catch (e) {

    }
}

function* removeProjectImagesRequested(action) {
    try {
        let response = yield call(deleteRiskProjectImage, action.payload.projectId, action.payload.attachmentId);
        yield put({ type: riskActions.GET_PROJECT_IMAGES_REQUESTED, payload: { project_id: action.payload.projectId} });
    } catch (e) {

    }
}

function* createCustomAreaRequested(action) {
    try {
        let result = yield call(createCustomArea, action.payload);

        yield put({ type: areaActions.CREATE_CUSTOM_AREA_SUCCEEDED, payload: { result } })
        // yield put({ type: riskActions.GET_PROJECT_AREAS_REQUESTED })
        yield put({ type: areaActions.ADD_CUSTOM_AREA, payload: { ...result, ...action.payload } })

    } catch (e) {
        yield put({ type: areaActions.CREATE_CUSTOM_AREA_FAILED, payload: { message: 'An error has occured during the create custom area request.', action: action } })
    }
}

function* getSegmentDefinitionRequested(action) {
    try {
        let segmentDefinition = yield call(fetchSegmentDefinition, action.payload.segment_id);
        yield put({ type: riskActions.GET_SEGMENT_RISKS_SUCCEEDED, payload: { segmentDefinition } });
    } catch (e) {
    }
}



export default function* root() {
    yield [
        takeEvery(riskActions.GET_RISKS_REQUESTED, getRisksRequested),
        takeEvery(riskActions.GET_PROJECT_RISKS_REQUESTED, getProjectRisksRequested),
        takeEvery(riskActions.ADD_RISKS_REQUESTED, addProjectRisksRequested),
        takeEvery(riskActions.GET_SCENARIOS_REQUESTED, getRiskScenarios),
        takeEvery(riskActions.GET_SOLUTIONS_REQUESTED, getRiskSolutions),
        takeEvery(riskActions.REMOVE_RISKS_REQUESTED, deleteProjectRisksRequested),
        takeEvery(riskActions.EDIT_RISKS_REQUESTED, editProjectRisksRequested),
        takeEvery(riskActions.GET_PROJECT_AREAS_REQUESTED, getProjectAreasRequested),
        takeEvery(riskActions.ADD_PROJECT_AREAS_REQUESTED, addProjectAreasRequested),
        takeEvery(riskActions.REMOVE_PROJECT_AREAS_REQUESTED, removeProjectAreasRequested),
        takeEvery(riskActions.GET_PROJECT_IMAGES_REQUESTED, getProjectImagesRequested),
        takeEvery(riskActions.ADD_PROJECT_IMAGE_REQUESTED, addProjectImagesRequested),
        takeEvery(riskActions.UPDATE_PROJECT_IMAGE_REQUESTED, updateProjectImagesRequested),
        takeEvery(riskActions.REMOVE_PROJECT_IMAGE_REQUESTED, removeProjectImagesRequested),
        takeEvery(riskActions.CREATE_CUSTOM_SCENARIO_REQUESTED, createNewScenarioForProjectRequested),
        takeEvery(riskActions.GET_SEGMENT_RISKS_REQUESTED, getSegmentRisksRequested),
        takeEvery(areaActions.CREATE_CUSTOM_AREA_REQUESTED, createCustomAreaRequested),
        takeEvery(riskActions.GET_SEGMENT_DEFINITION_REQUESTED, getSegmentDefinitionRequested)
    ]
}
