import { FixedPriceProduct } from "./fixed-price-product";
import { ComboPriceProduct } from "./combo-price-product";
import { LeasingProduct } from "./leasing-product";
import { Product } from "./base-product";

export const convertProductToObject = (product, currency) => {
    if (!product ) {
        return null;
    }

    if (product instanceof Product) {
        return product;
    }
    // leasing XOR fixed price excludes comboprice
    if (product.is_leased ^ product.is_fixed_price) {
        return product.is_leased ? new LeasingProduct(product.product_id, product, currency) : new FixedPriceProduct(product.product_id, product, currency);
    }

    if (product.lease_period === 0) {
        return new FixedPriceProduct(product.product_id, product, currency);
    }

    return new ComboPriceProduct(product.product_id, product, currency);
};

