// @ts-nocheck
// TODO remove above
import {
    addProductActions,
    deleteProductActions,
    editProductActions,
    getCategoriesActions,
    getProductsActions,
    getProjectProductsActions,
    updateProjectProductsOrderActions
} from './actions';
import { isType } from 'typescript-fsa';
import { AnyAction } from "redux";

export interface ProductsState {
    products: any[];
    categories: any[];
    isFetching: boolean;
    allProjectProducts: { [project_id: number]: any[]; },
}


const initialState: ProductsState = {
    products: [],
    categories: [],
    isFetching: false,
    allProjectProducts: {},
};

export function getProjectProducts(state: ProductsState, project_id: number) {
    return state.allProjectProducts[project_id] || [];
}

function getUpdatedProjectProducts(project_id, projectProducts) {

}

export default function products(state = initialState, action: AnyAction) {
    if (isType(action, getProductsActions.started)) {
        return { ...state, isFetching: true };
    }
    if (isType(action, getProductsActions.done)) {
        return { ...state, isFetching: false, products: action.payload.result.products };
    }
    if (isType(action, getProductsActions.failed)) {
        return { ...state, isFetching: false, products: [], errorMessage: action.error };
    }
    if (isType(action, getCategoriesActions.done)) {
        return { ...state, categories: action.payload.result.categories }
    }
    if (isType(action, getProjectProductsActions.done)) {
        let resultProjectProducts = action.payload.result.projectProducts;
        if (resultProjectProducts && resultProjectProducts.length > 0) {
            let project_id = resultProjectProducts[0].project_id;
            let newProjectProducts = { ...state.allProjectProducts };
            newProjectProducts[project_id] = resultProjectProducts;
            return { ...state, allProjectProducts: newProjectProducts };
        }
        return state;
    }
    if (isType(action, addProductActions.done)) {
        let project_id = action.payload.result.product.project_id;
        let existingProducts = getProjectProducts(state, project_id);
        let projectProducts = [...existingProducts, action.payload.result.product];
        let newAllProjectProducts = { ...state.allProjectProducts };
        newAllProjectProducts[project_id] = projectProducts;
        return { ...state, allProjectProducts: newAllProjectProducts };
    }
    if (isType(action, deleteProductActions.started)) {
        return state;
    }
    if (isType(action, deleteProductActions.done)) {
        let existingProducts = getProjectProducts(state, action.payload.result.project_id);
        let projectProducts = existingProducts.filter(product => product.project_product_id !== action.payload.result.project_product_id);
        let newAllProjectProducts = { ...state.allProjectProducts };
        newAllProjectProducts[action.payload.result.project_id] = projectProducts;
        return { ...state, allProjectProducts: newAllProjectProducts };
    }
    if (isType(action, editProductActions.started)) {

    }
    if (isType(action, editProductActions.done)) {
        let updatedProduct = action.payload.result.product;
        let existingProducts = getProjectProducts(state, updatedProduct.project_id);
        let projectProducts = existingProducts.filter(product => {
            return product.project_product_id !== updatedProduct.project_product_id;
        });
        projectProducts.push(updatedProduct);
        projectProducts = projectProducts.sort((a, b) => {
            return a.project_product_id > b.project_product_id ? 1 : -1;
        });
        let newAllProjectProducts = { ...state.allProjectProducts };
        newAllProjectProducts[updatedProduct.project_id] = projectProducts;
        return { ...state, allProjectProducts: newAllProjectProducts };
    }
    if (isType(action, updateProjectProductsOrderActions.done)) {
        let resultProjectProducts = action.payload.result.products;
        if (resultProjectProducts && resultProjectProducts.length > 0) {
            let project_id = resultProjectProducts[0].project_id;
            let newProjectProducts = { ...state.allProjectProducts };
            newProjectProducts[project_id] = resultProjectProducts;
            return { ...state, allProjectProducts: newProjectProducts };
        }
        return state;
    }   

    return state;
}
