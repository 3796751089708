import React from 'react'

import t from '../i18n';
import './calculation.css';
import * as calcUtils from './calcUtils';
import { formatPriceToCurrency } from './calculation';

const SumTable = ({ products, currency }) => {
    let priceTotals = calcUtils.getPriceTotals(products);
    // const _toPriceString = price => (price/100).toFixed(2).replace('.',',');
    const formatPrice = price => currency ? formatPriceToCurrency(price, currency) : price
    
    return (
        <table className="calculation-edit__sum-table">
            <tbody>
            <tr>
                <th></th>
                <th>{t('web.calculation.initialCost')}</th>
                <th>{t('web.calculation.monthlyCost')}</th>
            </tr>
            <tr>
                <td>{t('web.calculation.cost')}</td>
                <td>{formatPrice(products.reduce((acc, product) => { 
                        return acc + (product.data.quantity * product.getFixedPrice(false)) 
                    }, 0))} 
                </td>
                <td>{formatPrice(products.reduce((acc, product) => { 
                    return acc + (product.data.quantity * product.getLeasingPrice(false)) 
                    }, 0))} 
                </td>
            </tr>
            <tr>
                <td>{t('web.calculation.valueAddedTax')}</td>
                <td>{formatPrice(products.reduce((acc, product) => { 
                    return acc + (product.data.quantity * product.getFixedVatAmount()) 
                    }, 0)) || "- -"} 
                </td>
                <td>{formatPrice(products.reduce((acc, product) => { 
                    return acc + (product.data.quantity * product.getMonthlyVatAmount()) 
                    }, 0)) || "- -"} 
                </td>
            </tr>
            <tr>
                <th>{t('web.calculation.total')}</th>
                <td>{formatPrice(products.reduce((acc, product) => { 
                    return acc + (product.data.quantity * (product.getFixedPrice(false) + product.getFixedVatAmount())) 
                    }, 0))} 
                </td>
                <td>{formatPrice(products.reduce((acc, product) => { 
                    return acc + (product.data.quantity * (product.getLeasingPrice(false) + product.getMonthlyVatAmount()))
                    }, 0))} 
                </td>
            </tr>
            </tbody>
        </table>
    )
}

export default SumTable;
