// @ts-nocheck
// TODO remove above
import React, { Component } from 'react';
import t from '../i18n';
import './calculation.css';
import pen from '../img/pen.svg';
import * as calcUtils from './calcUtils';
import Product, { IProduct } from './product';
import SumTable from './sum-table';
import { DragDropContext } from 'react-dnd';
import { convertProductToObject } from "./calculator";

// Data type definitions

export interface IProject {
    project_id: number;
    seller_id: number;
    products: IProduct[];
}

// Components

interface ICalculationProps {
    project: IProject;
    currency: string;
    toggleEditProducts: () => void;
}

export const getLocaleFromCurrency = (currency: string): string => {
    // TODO: Add more locales. Alternatively make a better system that doesnt rely on manually associating currencies and language codes.
    const locales = {
        NOK: 'nb-NO',
        USD: 'en-US',
        SEK: 'sv-SE',
        GBP: 'en-GB',
        EUR: 'de-DE',
        TRY: 'tr-TR',
        EUR2: 'fr-FR'
    };

    return locales[currency] ? locales[currency] : locales.USD;
}

export const formatPriceToCurrency = (price, currency) => {
    return price !== null ?
        (price / 100).toLocaleString(
        getLocaleFromCurrency(currency),
        { style: 'currency', currency: currency }
    ) :
    ""
}

export class Calculation extends Component<ICalculationProps, {}> {

    state = {
        products: []
    };

    componentWillReceiveProps(nextProps: ICalculationProps) {
        this.updateProductList(nextProps.project.products, nextProps.currency);
    }

    componentWillMount() {
        if(this.state.products.length === 0 && this.props.project.products.length > 0) {
            this.updateProductList(this.props.project.products, this.props.currency);
        }
    }

    updateProductList(productList: Array<any>, currency: string){
        this.setState({
            products: productList
                .map(product => convertProductToObject(product, currency))
        });
    }

    public render() {
        const sumSolutions = calcUtils.getPriceTotals(this.props.project.products);
        return (
            <div className="calculation">
                <div className="calculation__header" onClick={this.props.toggleEditProducts}>
                    <div>
                        <span className="h4">{t('web.common.edit')}</span>
                        <img src={pen} style={{ marginLeft: '1em', marginBottom: "-0.3em" }}/>
                    </div>
                    {/* <span className="calculation__header-amount">{sumSolutions.totalPriceGross},- {this.props.currency}</span> */}
                </div>
                <ul className="list">
                    {
                        this.state.products && this.state.products.map((product: IProduct, index: number) =>
                            <Product
                                key={product.data.project_product_id}
                                currency={this.props.currency}
                                product={product}
                                country={this.props.country}/>
                        )
                    }
                </ul>
                <SumTable products={this.state.products} currency={this.props.currency}/>
            </div>
        );
    }
}

export default Calculation;
