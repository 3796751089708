import { ComboPriceProduct } from "./combo-price-product";

export class LeasingProduct extends ComboPriceProduct {
    getFixedPrice = (vat: boolean = true) => {
        return 0;
    }

    getDisplayPrice = (includeVat: boolean = true) => {
        return this.getLeasingPrice(includeVat);
    };
}