export const getImageBlobUrl = (image) => {
    const blob = new Blob([image]);
    const urlCreator = window.URL || window.webkitURL;
    return urlCreator.createObjectURL(blob);
}

export const isEqual = (objectA, objectB) => {
    return JSON.stringify(objectA) === JSON.stringify(objectB);
}

export async function valuePromise(value) {
    return await value;
}


let currencies = {
    "norway": "NOK",
    "sweden": "SEK",
    "germany": "EUR",
    "united kingdom": "GBP",
    "netherlands": "EUR",
    "denmark": "DKK",
    "spain": "EUR",
    "france": "EUR",
    "belgium": "EUR",
    "finland": "EUR",
    "switzerland": "CHF",
    "austria": "EUR",
    "democountry": "EUR",
    "usa": "USD",
    "ireland": "EUR",
    "estonia": "EUR",
    "czech republic": "CZK",
    "latin america": "USD",
    "portugal": "EUR",
    "tomorrowland": "USD",
    "uruguay": "UYI",
    "turkey": "TRY",
    "sensormatic": "USD",
    "poland": "PLN",
    "hungary": "HUF",
    "south africa": "ZAR",
    "bosnia-herzegovina": "BAM",
    "montenegro": "EUR",
    "croatia": "HRK",
    "serbia": "RSD",
    "hong kong": "HKD",
    "united arab emirates": "AED",
    "thailand": "THB",
    "india": "INR",
}

export function getCurrency(countryName: string) {
    countryName = countryName || "germany";
    return currencies[countryName.toLowerCase()] || "EUR";
}

export const endsWith = (str: string, suffix: string) => {
    return str.indexOf(suffix, str.length - suffix.length) !== -1;
}

export const getFileExtension = (filename: string) => {
    return (/[.]/.exec(filename)) ? /[^.]+$/.exec(filename)[0] : undefined;
}

// concat :: ([a],[a]) -> [a]
export const concat = (xs: any[], ys: any[]) => xs.concat(ys);

// flatMap :: (a -> [b]) -> [a] -> [b]
export const flatMap = (f: (x: any) => any) => (xs: any[]) => xs.map(f).reduce(concat, []);

// flatten :: [[a]] -> [a]
export const flatten = flatMap((x: any[]) => x);

// Capitalizes the first letter in the string
export const capitalize = (text: string) => text.charAt(0).toUpperCase() + text.slice(1);
