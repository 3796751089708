import callApi from './../services/api-client';

const cache = window.sessionStorage;

const codeConversions = {
  dynamic: {
    nb: 'no',
  },
  static: {
    en: 'en-gb'
  }
};

export function clearTranslationCache() {
  cache.clear();
}

export async function getTranslations(languageCode = 'en', mode = 'static') {

  keepCacheFresh();

  const key = `${languageCode}/${mode}`;
  let translations = null;

  if(translations = cache.getItem(key)) {
    return JSON.parse(translations);
  }

  if(codeConversions[mode].hasOwnProperty(languageCode)) {
    languageCode = codeConversions[mode][languageCode];
  }
  
  const result = await downloadTranslation(languageCode, mode);
  cache.setItem(key, JSON.stringify(result));

  return result;
}

async function downloadTranslation(languageCode, mode) {
  return await callApi(`translations/${languageCode}/${mode}`);
}

// Reload the cache every 24 hours
function keepCacheFresh() {

  const timestampKey = 'translations_last_updated';
  const hours = 24;

  if(cache.getItem(timestampKey)) {
    const timestamp = new Date(cache.getItem(timestampKey));
    const now = new Date();
    if((Math.abs(now - timestamp) / 36e5) < hours) {
      return;
    }
  }
  
  cache.clear();
  cache.setItem(timestampKey, new Date().toUTCString());

}