import * as React from 'react'
import List from "../common/list/list";

export class OffersList extends React.Component<{ templates, currentOfferId, addOfferHandler, deleteOfferHandler }> {
    state = {
        search: ''
    };

    constructor(props){
        super(props);
        this._updateSearch = this._updateSearch.bind(this);
    }

    private _updateSearch(event: any) {
        this.setState({search: event.target.value});
    }

    render() {
        
        const templates = this.props.templates || [];
        let uniqueTemplates = [];

        return (
            <div>
                <div className="search-container">
                    <input type="text" onChange={this._updateSearch} placeholder="Search..." />
                </div>
                <List array={templates} itemTemplate={(item, key) => {
                        if (uniqueTemplates.includes(item.offer_letter_id)) {
                            return null;
                        }
                        uniqueTemplates.push(item.offer_letter_id);
                        const matchedWithCurrentOffer = this.props.currentOfferId === item.offer_letter_id;
                        return (
                            <li
                                onClick={() => matchedWithCurrentOffer ?
                                    this.props.deleteOfferHandler(matchedWithCurrentOffer) :
                                    this.props.addOfferHandler(item.offer_letter_id)}
                                className={matchedWithCurrentOffer ? 'active' : null}
                                key={key}>
                                {item.title}
                                <div className="icon icon-check"/>
                            </li>
                        )}}
                    filter={item => this.state.search === '' ? true : item.title.toLowerCase().includes(this.state.search.toLowerCase())}
                />
            </div>
        );
    }
}

export default OffersList;

