export const GET_RISKS_REQUESTED = 'GET_RISKS_REQUESTED';
export const GET_RISKS_SUCCEEDED = 'GET_RISKS_SUCCEEDED';
export const GET_SCENARIOS_REQUESTED = 'GET_SCENARIOS_REQUESTED';
export const GET_SCENARIOS_SUCCEEDED = 'GET_SCENARIOS_SUCCEEDED';
export const GET_SOLUTIONS_REQUESTED = 'GET_SOLUTIONS_REQUESTED';
export const GET_SOLUTIONS_SUCCEEDED = 'GET_SOLUTIONS_SUCCEEDED';
export const GET_PROJECT_RISKS_REQUESTED = 'GET_PROJECT_RISKS_REQUESTED';
export const GET_PROJECT_RISKS_SUCCEEDED = 'GET_PROJECT_RISKS_SUCCEEDED';
export const GET_PROJECT_AREAS_REQUESTED = 'GET_PROJECT_AREAS_REQUESTED';
export const GET_PROJECT_AREAS_SUCCEEDED = 'GET_PROJECT_AREAS_SUCCEEDED';
export const EDIT_RISKS_REQUESTED = 'EDIT_RISK_REQUESTED';
export const EDIT_RISKS_SUCCEEDED = 'EDIT_RISK_SUCCEEDED';
export const ADD_RISKS_REQUESTED = 'ADD_RISKS_REQUESTED';
export const ADD_RISKS_SUCCEEDED = 'ADD_RISKS_SUCCEEDED';
export const REMOVE_RISKS_REQUESTED = 'REMOVE_RISKS_REQUESTED';
export const REMOVE_RISKS_SUCCEEDED = 'REMOVE_RISKS_SUCCEEDED';
export const ADD_PROJECT_AREAS_REQUESTED = 'ADD_PROJECT_AREAS_REQUESTED';
export const ADD_PROJECT_AREAS_SUCCEEDED = 'ADD_PROJECT_AREAS_SUCCEEDED';
export const REMOVE_PROJECT_AREAS_REQUESTED = 'REMOVE_PROJECT_AREAS_REQUESTED';
export const REMOVE_PROJECT_AREAS_SUCCEEDED = 'REMOVE_PROJECT_AREAS_SUCCEEDED';
export const GET_PROJECT_IMAGES_REQUESTED = 'GET_PROJECT_IMAGES_REQUESTED';
export const GET_PROJECT_IMAGES_SUCCEEDED = 'GET_PROJECT_IMAGES_SUCCEEDED';
export const ADD_PROJECT_IMAGE_REQUESTED = 'ADD_PROJECT_IMAGE_REQUESTED';
export const ADD_PROJECT_IMAGE_SUCCEEDED = 'ADD_PROJECT_IMAGE_SUCCEEDED';
export const UPDATE_PROJECT_IMAGE_REQUESTED = 'UPDATE_PROJECT_IMAGE_REQUESTED';
export const UPDATE_PROJECT_IMAGE_SUCCEEDED = 'UPDATE_PROJECT_IMAGE_SUCCEEDED';
export const REMOVE_PROJECT_IMAGE_REQUESTED = 'REMOVE_PROJECT_IMAGE_REQUESTED';
export const REMOVE_PROJECT_IMAGE_SUCCEEDED = 'REMOVE_PROJECT_IMAGE_SUCCEEDED';
export const EXIT_EDIT_RISK_REQUESTED = 'EXIT_EDIT_RISK_REQUESTED';
export const SET_ACTIVE_AREA = 'SET_ACTIVE_AREA';
export const CREATE_CUSTOM_SCENARIO_REQUESTED = 'CREATE_CUSTOM_SCENARIO_REQUESTED';
export const CREATE_CUSTOM_SCENARIO_SUCCEEDED = 'CREATE_CUSTOM_SCENARIO_SUCCEEDED';
export const GET_SEGMENT_RISKS_REQUESTED = 'GET_SEGMENT_RISKS_REQUESTED';
export const GET_SEGMENT_RISKS_SUCCEEDED = 'GET_SEGMENT_RISKS_SUCCEEDED';
export const GET_SEGMENT_DEFINITION_REQUESTED = 'GET_SEGMENT_DEFINITION_REQUESTED';
export const GET_SEGMENT_DEFINITION_SUCCEEDED = 'GET_SEGMENT_DEFINITION_SUCCEEDED ';