// @ts-nocheck
// TODO remove above
import React, { Component } from 'react';
import classNames from 'classnames';
import t from '../../i18n';
import Modal from '../../common/modal/modal';
import { IRiskNavigationProps } from '../interfaces';
import { fetchPDFHelper } from '../../services/api';
import { saveAs } from 'file-saver';
import callApi from '../../services/api-client';
import Spinner from '../../spinner/spinner';
import doc from '../../img/doc.svg';
import { trackEvent } from '../../utils/google-analytics';

export default class RiskNavigation extends Component<
    IRiskNavigationProps,
    {
        showShareModal: boolean;
        showModal: boolean;
        isDownloading: boolean;
        isSharing: boolean;
    }
> {
    constructor(props) {
        super(props);
        this.state = {
            showShareModal: false,
            showModal: false,
            isDownloading: false,
            isSharing: false,
        };
    }

    private navigationItems = {
        riskManagement: {
            onClick: this.props.updateFunction,
            label: 'web.risk.navigation.management',
        },
        riskMatrix: {
            onClick: this.props.updateFunction,
            label: 'web.risk.navigation.matrix',
        },
    };

    openShareModal = () => {
        this.setState({ showShareModal: true });
    };

    closeShareModal = () => {
        this.setState({ showShareModal: false });
    };

    openModal = () => {
        this.setState({ showModal: true });
    };

    closeModal = () => {
        this.setState({ showModal: false });
    };

    downloadRiskAssessment = (e) => {
        this.setState({ isDownloading: true, showModal: false });

        let project = this.props.project;
        let url = `projects/${project.project_id}/pdf/risksummary/language/${this.props.user.user.language}`;
        fetchPDFHelper(url)
            .then((result) => {
                var blob = new Blob([result], { type: 'application/pdf' });
                saveAs(
                    blob,
                    `${project.name} - ${t('web.risk.riskAssessment')}.pdf`
                );
                this.setState({ isDownloading: false });
                trackEvent('Risk assessment downloaded');
            })
            .catch((error) => {
                this.setState({ isDownloading: false });
            });
    };

    downloadRiskAreaAssessment = (e) => {
        this.setState({ isDownloading: true, showModal: false });

        let project = this.props.project;
        let url = `projects/${project.project_id}/pdf/risksummary/language/${this.props.user.user.language}/area`;
        fetchPDFHelper(url)
            .then((result) => {
                var blob = new Blob([result], { type: 'application/pdf' });
                saveAs(
                    blob,
                    `${project.name} - ${t('web.risk.riskAssessment')}.pdf`
                );
                this.setState({ isDownloading: false });
                trackEvent('Risk assessment downloaded');
            })
            .catch((error) => {
                this.setState({ isDownloading: false });
            });
    };

    downloadExecutiveSummary = (e) => {
        this.setState({ isDownloading: true, showModal: false });

        let project = this.props.project;
        let url = `projects/${project.project_id}/pdf/executivesummary`;
        fetchPDFHelper(url)
            .then((result) => {
                var blob = new Blob([result], { type: 'application/pdf' });
                saveAs(
                    blob,
                    `${project.name} - ${t('web.risk.executive_summary')}.pdf`
                );
                this.setState({ isDownloading: false });
                trackEvent('Risk assessment downloaded');
            })
            .catch((error) => {
                this.setState({ isDownloading: false });
            });
    };

    shareRiskAssessment = (e) => {
        this.setState({ isSharing: true, showShareModal: false });

        let project = this.props.project;
        let url = `projects/${project.project_id}/pdf/risksummary/language/${this.props.user.user.language}?link=true`;

        callApi(url, 'GET')
            .then((result) => {
                this.setState({ isSharing: false });
                window.location = `mailto:?subject=${t(
                    'web.risk.riskAssessment'
                )}&body=${result}`;
                trackEvent('Risk assessment sent');
            })
            .catch((e) => {
                this.setState({ isSharing: false });
            });
    };

    shareRiskAreaAssessment = (e) => {
        this.setState({ isSharing: true, showShareModal: false });

        let project = this.props.project;
        let url = `projects/${project.project_id}/pdf/risksummary/language/${this.props.user.user.language}/area?link=true`;

        callApi(url, 'GET')
            .then((result) => {
                this.setState({ isSharing: false });
                window.location = `mailto:?subject=${t(
                    'web.risk.riskAssessment'
                )}&body=${result}`;
                trackEvent('Risk assessment sent');
            })
            .catch((e) => {
                this.setState({ isSharing: false });
            });
    };

    shareExecutiveSummary = (e) => {
        this.setState({ isSharing: true, showShareModal: false });

        let project = this.props.project;
        let url = `projects/${project.project_id}/pdf/executivesummary?link=true`;

        callApi(url, 'GET')
            .then((result) => {
                this.setState({ isSharing: false });
                window.location = `mailto:?subject=${t(
                    'web.risk.executive_summary'
                )}&body=${result}`;
                trackEvent('Risk assessment sent');
            })
            .catch((e) => {
                this.setState({ isSharing: false });
            });
    };

    public render() {
        return (
            <div className="risks__navigation">
                <div>
                    {Object.keys(this.navigationItems).map((key, i) => {
                        const item = this.navigationItems[key];
                        return (
                            <button
                                onClick={item.onClick}
                                data-pane={key}
                                key={i}
                                className={classNames(
                                    'risks__navigation-item',
                                    this.props.activePane === key
                                        ? 'active'
                                        : ''
                                )}
                            >
                                {t(item.label)}
                            </button>
                        );
                    })}
                </div>
                <div className="risks__actions--right">
                    {this.state.isSharing && (
                        <button
                            title="Document is being prepared..."
                            className="btn fetching"
                        >
                            <Spinner />
                        </button>
                    )}
                    {!this.state.isSharing && (
                        <button className="btn" onClick={this.openShareModal}>
                            {t('web.projects.share')}{' '}
                            {t('web.risk.riskAssessment')}
                        </button>
                    )}
                    &nbsp;
                    {this.state.isDownloading && (
                        <button
                            title="Document is being prepared..."
                            className="btn fetching"
                        >
                            <Spinner />
                        </button>
                    )}
                    {!this.state.isDownloading && (
                        <button className="btn" onClick={this.openModal}>
                            {t('web.projects.download')}{' '}
                            {t('web.risk.riskAssessment')}
                        </button>
                    )}
                </div>
                {this.state.showModal && (
                    <DownloadRiskModal
                        onClose={this.closeModal}
                        downloadRiskAssessment={this.downloadRiskAssessment}
                        downloadRiskAreaAssessment={
                            this.downloadRiskAreaAssessment
                        }
                        downloadExecutiveSummary={this.downloadExecutiveSummary}
                    />
                )}
                {this.state.showShareModal && (
                    <DownloadRiskModal
                        onClose={this.closeShareModal}
                        downloadRiskAssessment={this.shareRiskAssessment}
                        downloadRiskAreaAssessment={
                            this.shareRiskAreaAssessment
                        }
                        downloadExecutiveSummary={this.shareExecutiveSummary}
                    />
                )}
            </div>
        );
    }
}

const DownloadRiskModal = ({
    onClose,
    downloadRiskAssessment,
    downloadRiskAreaAssessment,
    downloadExecutiveSummary,
}) => {
    return (
        <Modal
            title={
                t('web.common.sketcher.select') +
                ' ' +
                t('web.calculation.document').toLowerCase()
            }
            onClose={onClose}
            minHeight={260}
            closeOnClickOutside={true}
        >
            <ul className="risk-documents">
                <li onClick={downloadRiskAssessment}>
                    <img src={doc} /> {t('web.risk.riskAssessment')} -{' '}
                    {t('web.risk.sortByRisk')}
                </li>
                <li onClick={downloadRiskAreaAssessment}>
                    <img src={doc} /> {t('web.risk.riskAssessment')} -{' '}
                    {t('web.risk.sortByArea')}
                </li>
                <li onClick={downloadExecutiveSummary}>
                    <img src={doc} /> {t('web.risk.executive_summary')}
                </li>
            </ul>
        </Modal>
    );
};
