/**
 * Webpack is set up so that en.json is required in to the bundle
 * while all other json files are extracted with file-loader and has
 * to be fetched from server
 */

import { getTranslations } from './translations';

import bs from './bs.json';
import cs from './cs.json';
import da from './da.json';
import de from './de.json';
import de_ch from './de-ch.json';
import de_at from './de-at.json';
import en from './en.json';
import es from './es.json';
import et from './et.json';
import fi from './fi.json';
import fr from './fr.json';
import fr_be from './fr-be.json';
import fr_ch from './fr-ch.json';
import hr from './hr.json';
import hu from './hu.json';
import it_ch from './it-ch.json';
import nb from './nb.json';
import nl from './nl.json';
import nl_be from './nl-be.json';
import pl from './pl.json';
import pt from './pt.json';
import tr from './tr.json';
import sv from "./sv.json";
import sk from "./sk.json";
import ro from "./ro.json";

import bs_dyn from './dynamic/bs.json';
import cs_dyn from './dynamic/cs.json';
import da_dyn from './dynamic/da.json';
import de_dyn from './dynamic/de.json';
import de_ch_dyn from './dynamic/de-ch.json';
import de_at_dyn from './dynamic/de-at.json';
import en_dyn from './dynamic/en.json';
import es_dyn from './dynamic/es.json';
import et_dyn from './dynamic/et.json';
import fi_dyn from './dynamic/fi.json';
import fr_dyn from './dynamic/fr.json';
import fr_be_dyn from './dynamic/fr-be.json';
import fr_ch_dyn from './dynamic/fr-ch.json';
import hr_dyn from './dynamic/hr.json';
import hu_dyn from './dynamic/hu.json';
import it_ch_dyn from './dynamic/it-ch.json';
import nb_dyn from './dynamic/nb.json';
import nl_dyn from './dynamic/nl.json';
import nl_be_dyn from './dynamic/nl-be.json';
import pl_dyn from './dynamic/pl.json';
import pt_dyn from './dynamic/pt.json';
import tr_dyn from './dynamic/tr.json';
import sv_dyn from "./dynamic/sv.json";
import sk_dyn from "./dynamic/sk.json";
import ro_dyn from "./dynamic/ro.json";

declare var require: any

const snake_case = require('to-snake-case');
const sha1 = require('js-sha1');

let defaultLanguage = 'en';
let defaultTranslations: { [key: string]: string; } = en;
let currentTranslations = { 
    language: defaultLanguage, 
    translations: defaultTranslations, 
    dynamicTranslations: {}, 
    fuzzySearch: {},
    web: {
        static: {},
        dynamic: {}
    }
};

const translationFileNames = {
    "bs": bs,
    "cs": cs,
    "da": da,
    "de": de,
    "de-at": de_at,
    "de-ch": de_ch,
    "en": en,
    "en-gb": en,
    "en-au": en,
    "es": es,
    "et": et,
    "fi": fi,
    "fr": fr,
    "fr-be": fr_be,
    "fr-ch": fr_ch,
    "hr": hr,
    "hu": hu,
    "it-ch": it_ch,
    "nb": nb,
    "no-nb": nb,
    "nb-no": nb,
    "nl": nl,
    "nl-be": nl_be,
    "pl": pl,
    "pt": pt,
    "sk": sk,
    "sv": sv,
    "tr": tr,
    "ro": ro
};

const dynamicTranslationFileNames = {
    "bs": bs_dyn,
    "cs": cs_dyn,
    "da": da_dyn,
    "de": de_dyn,
    "de-at": de_at_dyn,
    "de-ch": de_ch_dyn,
    "en": en_dyn,
    "en-gb": en_dyn,
    "en-au": en_dyn,
    "es": es_dyn,
    "et": et_dyn,
    "fi": fi_dyn,
    "fr": fr_dyn,
    "fr-be": fr_be_dyn,
    "fr-ch": fr_ch_dyn,
    "hr": hr_dyn,
    "hu": hu_dyn,
    "it-ch": it_ch_dyn,
    "nb": nb_dyn,
    "no-nb": nb_dyn,
    "nb-no": nb_dyn,
    "nl": nl_dyn,
    "nl-be": nl_be_dyn,
    "pl": pl_dyn,
    "pt": pt_dyn,
    "sk": sk_dyn,
    "sv": sv_dyn,
    "tr": tr_dyn,
    "ro": ro_dyn
};

export const translationOptions = {
    "bs": 'Bosnian',
    "hr": "Croatian",
    "cs": "Czech",
    "da": "Danish",
    "nl": "Dutch",
    "nl-be": "Dutch (BE)",
    "en": "English",
    "et": "Estonian",
    "fi": "Finnish",
    "fr": "French",
    "fr-be": "French (BE)",
    "fr-ch": "French (CH)",
    "de": "German",
    "de-at": "German (AT)",
    "de-ch": "German (CH)",
    "hu": "Hungarian",
    "it-ch": "Italian (CH)",
    "nb": "Norwegian (bokmål)",
    "es": "Spanish",
    "pl": "Polish",
    "pt": "Portugese",
    "ro": "Romanian",
    "sk": "Slovakian",
    "sv": "Swedish",
    "tr": "Turkish"
};

// Reloads the translations to fetch updates, then reloads the page
export async function reloadTranslations(language) {
    try {
        await loadTranslationTables(language);
        window.location.href = window.location.href;
    } catch (e) {
    }

}

// Loads all translations if the object in memory does not contain them yet
export async function loadTranslationsIfNotPresent(language) {
    if(Object.keys(currentTranslations.web.static).length < 1) {
        return await loadTranslationTables(language);
    }
}

// Sets the current language and load the translationss
export async function setLanguage(language: string) {

    if (language && language !== currentTranslations.language) {
        await loadTranslationTables(language);
        currentTranslations.language = language;
    }
    return currentTranslations;
}

export function getCurrentLanguage() {
    return currentTranslations.language;
}

// Loads translations from POE and from the files on disk
async function loadTranslationTables(language) {

    let translations = await loadTranslations(language);
    let dynamicTranslations = await loadDynamicTranslations(language);

    let web = { static: {}, dynamic: {} };
    try {
        web = {
            static: await getTranslations(language, 'static'),
            dynamic: await getTranslations(language, 'dynamic')
        }
    } catch (e) {
        web = web;
    }

    // Create a lookup table for all keys without the hash
    let fuzzySearch = {};
    Object.keys(web.dynamic).forEach(key => {
        fuzzySearch[key.slice(0, key.length-8)] = web.dynamic[key];
    });

    currentTranslations = { ...currentTranslations, translations, dynamicTranslations, web, fuzzySearch }
    return currentTranslations;
}


// todo: add code to not try to load failed languages more than once
async function loadTranslations(language: string) {
    let fileName = translationFileNames[language] || `/static/media/i18n/${language}.json`;
    try {
        let response = await fetch(fileName);
        return await response.json();
    }
    catch (e) {
        return defaultTranslations;
    }
}

async function loadDynamicTranslations(language: string) {
    let fileName = dynamicTranslationFileNames[language] || `/static/media/i18n/dynamic/${language}.json`;
    try {
        let response = await fetch(fileName);
        return await response.json();
    }
    catch (e) {
        return defaultTranslations;
    }
}

export function translate_d(keys : string[]) : string {

    // Try translation file currently in memory
    for(let i = 0; i < keys.length; i++) {
        const translation = currentTranslations.web.dynamic[keys[i]];
        if(translation) {
            return translation;
        }
    }

    // Try files on disk
    for(let i = 0; i < keys.length; i++) {
        const translation = currentTranslations.dynamicTranslations[keys[i]];
        if(translation) {
            return translation;
        }
    }

    return null;
}

// we try several different hash keys for backwards compatibility
export function d(identifier, text): string {

    // dots are sometimes used to exclude certain options, but it doesn't work very well with the fuzzy search
    // so if the text is only dots, we don't try to translate it
    if(['.', '..', '...', '....'].includes(text)) {
        return text;
    }

    try {
        let textToHash = text.replace(/\//g, '');
        let key = `${identifier}.${snake_case(textToHash)}`;

        let wordPart = `${identifier}.${snake_case(textToHash).substr(0, 10)}`;

        // hash the whole word
        let hashKey = wordPart + sha1(textToHash).substr(0, 8);

        // trim the word first; the backend sometimes have trailing spaces
        let hashKey2 = wordPart + sha1(textToHash.trim()).substr(0, 8);

        // trim the word, turn it all into lowercase, then capitalize the first letter
        let hashText = textToHash.toLowerCase().trim();
        hashText = hashText.charAt(0).toUpperCase() + hashText.slice(1);
        let hashKey3 = wordPart + sha1(hashText).substr(0, 8);

        let translatedText = translate_d([key, hashKey, hashKey2, hashKey3]);

        return translatedText || text;
    } catch (e) {
        return text;
    }
}

export function makeIdentifier(identifier): string {
    let text = identifier.trim()
    text = text.replace(/\s/, '_')
    text = text.replace(/(\&|\(|\)|-|[0-9]|[^a-z_])/i, '')
    text = text.replace(/(\_{1,})/i, '_').toLowerCase()
    return text
}

export default function t(text): string {
    try {
        let translatedText = currentTranslations.web.static[text] || currentTranslations.translations[text];
        if (translatedText === "") {
            translatedText = text;
        }
        return translatedText || defaultTranslations[text] || text;
    } catch (e) {
        return text;
    }
}
