import React from 'react';
import './floating-panel.css';

interface IFloatingPanelProps {
    tabs: string[],
    activeTab: string,
    isVisible: boolean,
    closeMenuHandler?: Function,
    onClickHandler?: Function
}

export class FloatingPanel extends React.Component<IFloatingPanelProps> {

    constructor(props) {
        super(props);

        if (!props.tabs || !props.tabs.length) throw Error('FloatingPanel.constructor: Tabs property is not set or has the wrong type.');
    }

    private _onClickHandler(tab): void {
        if (this.props.onClickHandler) this.props.onClickHandler(tab);
    }

    private _isActiveTab(tab: string): boolean {
        return tab.toLowerCase() === this.props.activeTab.toLowerCase();
    }

    public render() {
        return (
            this.props.isVisible ?
            <span>
                <div onClick={() => this.props.closeMenuHandler()} className="backdrop"></div>
                <div className="floating-menu">
                    <div className="header">
                        {this.props.tabs.map((tab, index) => 
                            <h4 key={index} 
                            className={this._isActiveTab(tab) ? 'active' : ''}
                            onClick={() => this._onClickHandler(tab)}
                            >{tab.toUpperCase()}</h4>)}
                    </div>
                    <div className="divider"></div>
                    {this.props.children}
                </div>
            </span>
            : null
        )
    }
}