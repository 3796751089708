import React from 'react';
import { fetchImageBlob } from '../../services/api';
import { imageCache, setImageCache } from '../../image/image';
import './blurred-background.css';

class BlurredBackground extends React.Component<{ backgroundImage:string, height?: string }, any> {

  state = {
      backgroundImage: null
  }

  componentDidMount() {
    this.loadBlob();
  }

  componentWillReceiveProps() {
      this.loadBlob();
  }

  loadBlob = () => {

    const src = this.props.backgroundImage;

    if(!src.includes('/api/')) {
        this.setState({
            backgroundImage: src
        });
        return;
    }

    if(imageCache[src]) {
        this.setState({
            backgroundImage: imageCache[src]
        });
        return;
    }

    fetchImageBlob(this.props.backgroundImage).then(blob => {
        setImageCache(this.props.backgroundImage, blob);
        this.setState({ backgroundImage: blob });
    });
  }

  public render() {

      if(!this.state.backgroundImage) {
          return this.props.children;
      }

      return (
        <div className="background-container">
            <div className="background--image--blurred" style={{
                height: this.props.height,
                backgroundImage: `url(${this.state.backgroundImage})`
            }} />
            {this.props.children}
        </div>
      );
  }
}

export default BlurredBackground;