// @ts-nocheck
// TODO remove above
import * as React from 'react';
import { connect } from 'react-redux';

import Spinner from '../spinner/spinner';
import './new-project.css';
import { getProjectImageUrl } from '../services/api';
import callApi from "../services/api-client";

class CopyProjectContainer extends React.Component<any, any> {


    componentDidMount() {
    }

    render() {
        let response = callApi(`projects/accessrequest/${this.props.params.id}`, "GET");
        response.then((res) => {
            window.location = "/";
        }).catch(() => {
            window.location = "/";
        });

        return (
            <Spinner/>
        );
    }
}

const mapStateToProps = ({ projects }) => {
    return {
        segments: projects.segments
    }
};

const mapDispatchToProps = (dispatch) => ({
    dispatchAction: (action) => dispatch(action)
});

export default connect(mapStateToProps, mapDispatchToProps)(CopyProjectContainer);
