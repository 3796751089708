import * as React from 'react';
import { connect } from 'react-redux';

import './proposal.css';
import './tabs.css';
import { 
    GET_PDF_REQUESTED,
    GET_CONTRACT_TEMPLATES_REQUESTED,
    GET_CONTRACT_PDF_REQUESTED,
    DELETE_CONTRACT_REQUESTED,
    ADD_CONTRACT_REQUESTED,
    GET_TEMPLATES_REQUESTED,
    GET_ALL_MEDIAFILES_REQUESTED,
    SET_TEMPLATE_REQUESTED,
    ADD_PROJECT_MEDIAFILE_REQUESTED,
    REMOVE_PROJECT_MEDIAFILE_REQUESTED
} from './actions';
import { 
    GET_MEDIAFILES_REQUESTED, 
    GET_ATTACHMENTS_REQUESTED
} from '../attachment/actions';
import SignablePDF from './signable-pdf';

import { 
    dispatchProposalStatusRequested, 
    dispatchStartOfferLetterSignInProcess,
    dispatchContractsStatusRequested,
    dispatchStartContractSignInProcess
} from './saga';
import { getProjectById } from '../projects/reducer';
import t from '../i18n';
import Page from '../common/page/page';
import NoTemplateSelected from './no-template-selected';

import SendEmail from './send-email';

import { TabsContainer, Tab } from '../tabs-container/tabs-container';
import { FloatingPanel } from '../floating-panel/floating-panel';
import Show from '../show/show';
import Spinner from '../spinner/spinner';
import { fetchPDFHelper } from '../services/api';
import { saveAs } from "file-saver";
import ContractsList from './contracts-list';
import OffersList from './offers-list';
import ConfirmDialog from '../common/confirm-dialog/confirm-dialog';
import List from "../common/list/list";
import { MediafilesList } from './mediafiles-list';

// ------------ Proposal Container -------------

export enum IPDFType {
    OFFER,
    CONTRACT
}

enum TABS {
    OFFER = 'Offer',
    CONTRACT = 'Contract'
}

enum ATTACHMENT_MENU_TABS {
    DOCUMENTS = 'Documents',
    MEDIA_FILES = 'Media files'
}

interface IStaticFile {
    name: string;
    attachment_id: string;
}

interface IProposalContainerState {
    email: string;
    projectId: string;
    contractId: number;

    offerPDF: any;
    contractPDF: any;
    selectedContract: number;
    contractToDelete: string;

    attachments: {
        documents: IStaticFile[];
        mediaFiles: any[];
    }

    activeTab: string;
    attachmentMenuTab: string;
    showAttachmentMenu: boolean;
    showOfferMenu: boolean;
    showContract: boolean;
    loadingContract: boolean;
    loadingOffer: boolean;

    staticFiles: IStaticFile[];
    mediafiles: any[];
    showOfferRefreshButton: boolean;
    showContractRefreshButton: boolean;
}

class ProposalContainer extends React.Component<any, IProposalContainerState> {
    state = {
        email: "",
        projectId: this.props.params.id,
        contractId: null,

        offerPDF: this.props.proposal.pdf,
        contractPDF: null,
        selectedContract: null,
        contractToDelete: null,

        attachments: {
            documents: [],
            mediaFiles: []
        },

        activeTab: t('web.proposal.offer'),
        attachmentMenuTab: t('web.solutions.documents'),
        showAttachmentMenu: false,
        showOfferMenu: false,
        showContract: false,
        loadingContract: false,
        loadingOffer: false,

        staticFiles: [],
        mediafiles: [],

        showOfferRefreshButton: false,
        showContractRefreshButton: false,
    };

    constructor(props) {
        super(props);

        this.onTabChange = this.onTabChange.bind(this);
        this.toggleOfferMenu = this.toggleOfferMenu.bind(this);
        this.toggleAttachmentMenu = this.toggleAttachmentMenu.bind(this);
        this.onAttachmentMenuChange = this.onAttachmentMenuChange.bind(this);
    }

    // Get Offer documents and check status
    componentDidMount() {
        this.getProposalStatus();
        this.getOffers();
        this.getContractsStatus();
        this.getContracts();
        this.getAttachments();
        this.getMediafiles();
        this.getTemplates();
        this.getAllMediafiles();

        this.setState({staticFiles: this._getStaticFiles()});
    }


    static getDerivedStateFromProps(props, state) {
        const { url } = props.proposal;

        if(url && url.contract_id && url.contract_id != state.selectedContract) {
            this.setCurrentContract(url.contract_id, url.project_id);
            return { selectedContract: url.contract_id };
        }

        if (props.proposal.pdf && state.loadingOffer) {
            return{ loadingOffer: false };
        }
        
        if (props.proposal.contractPdf && state.loadingContract) {
            return { 
                loadingContract: false,
                activeTab: t('web.proposal.contract')
            };
        }
        return null;
    }

    render() {
        const { isFetching, proposal } = this.props;
        const offerPDF = this.props.proposal.pdf;
        const offerPDFName = this.props.proposal.offerLetterTemplate ? this.props.proposal.offerLetterTemplate.title : '';
        
        const signingStatus = this.getSigningStatus(proposal.offerLetterTemplate);
        const currentContract = this.getCurrentContract();
        
        let contractSigningStatus = this.getSigningStatus(currentContract);
        const contractPDF = currentContract ? this.getPdf(currentContract) : 404;
        
        const offerId = this.props.proposal.offerLetterTemplate ? 
            this.props.proposal.offerLetterTemplate.offer_letter_id :
            null;

        return (
            <Page title={t('web.proposal.proposalHeading')}>
                <div className="full-width-container send-to-recipient-container">
                    <SendEmail
                        offerletter={this.state.activeTab === t('web.proposal.offer')} 
                        contractId={this.state.contractId || 0} 
                        mediaFiles={this.props.mediaFiles} 
                        project={this.props.project}
                        onRecipientChange={this.handleRecipientChange}
                        getAttachmentIds={this.getAttachmentIds}
                        handleSubmit={this.handleSubmitPdf}
                        enableEmailButton={this.enableEmailButton()}
                        enableSubmitButton={this.enableSubmitButton()}
                    />
                </div>

                <Show if={this.state.contractToDelete || this.props.isDeletingContract}>
                    <ConfirmDialog
                    title={t('web.contracts.delete.dialogtitle')}
                    message={t('web.contracts.delete.dialogmessage')}
                    onOK={this.deleteConfirmed}
                    onCancel={this.deleteCanceled}/>
                </Show>
                
                <div className="full-width-container">
                    <TabsContainer className="attachments-panel">
                        <Tab name={t('web.proposal.attachments')}>
                            
                            <div className="btn-menu">
                                <Show if={this.state.activeTab === t('web.proposal.offer')}>
                                    <button onClick={this.toggleOfferMenu} 
                                        className={this.state.showOfferMenu ? 'btn active' : 'btn select-offer-btn'}
                                        >{t('web.proposal.selectOffer')}</button>
                                    <FloatingPanel tabs={[t('web.proposal.offers')]} activeTab={'Offers'} isVisible={this.state.showOfferMenu} closeMenuHandler={() => this.setState({showOfferMenu: false})}>
                                        
                                        <OffersList
                                            templates={this.props.proposal.templates}
                                            currentOfferId={offerId}
                                            addOfferHandler={this.addOffer}
                                            deleteOfferHandler={this.deleteOffer}/>

                                    </FloatingPanel>
                                </Show>
                                <Show if={this.state.activeTab === t('web.proposal.contract')}>
                                    <button onClick={this.toggleOfferMenu} 
                                        className="btn add-contract-btn"><div className="icon icon-add"></div>{t('web.proposal.addContract')}</button>
                                    <FloatingPanel tabs={[t('web.proposal.contracts')]} activeTab={'Contracts'} isVisible={this.state.showOfferMenu} closeMenuHandler={() => this.setState({showOfferMenu: false})}>

                                        <ContractsList
                                            templates={this.props.proposal.contractTemplates}
                                            contracts={this.props.proposal.contracts}
                                            addContractHandler={this.addContract}
                                            deleteContractHandler={this.deleteContract}/>

                                    </FloatingPanel>
                                </Show>
                            </div>

                            <div className="btn-menu">
                                <button onClick={this.toggleAttachmentMenu} className="btn add-attachments-btn"><div className="icon icon-add"></div>{t('web.proposal.addAttachments')}</button>
                                <FloatingPanel 
                                    tabs={[t('web.solutions.documents'), t('web.proposal.mediafiles')]}
                                    activeTab={this.state.attachmentMenuTab}
                                    onClickHandler={this.onAttachmentMenuChange} 
                                    isVisible={this.state.showAttachmentMenu}
                                    closeMenuHandler={() => this.setState({showAttachmentMenu: false})} >

                                    <Show if={this.state.attachmentMenuTab === t('web.solutions.documents')}>
                                        <List array={this.state.staticFiles} itemTemplate={(item, key) => {
                                            const match = this.state.attachments.documents.findIndex(doc => doc.name === item.name);
                                            return <li key={key}
                                                className={match !== -1 ? 'active' : null}
                                                onClick={() => match ? this.addDocument(item.name) : this.removeDocument(match)}
                                                >{item.name} <div className="icon icon-check" /></li>
                                            }}
                                              noItemsTemplate={() => <p>{t('web.proposal.noDocumentsAdded')}</p>}
                                        />
                                    </Show>

                                    <Show if={this.state.attachmentMenuTab === t('web.proposal.mediafiles')}>
                                        <MediafilesList 
                                            addMediafileHandler={this.handleAddMediafile}
                                            deleteMediafileHandler={this.handleDeleteMediafile}
                                            attachedMediafiles={this.props.mediafiles}
                                            mediafiles={this.props.proposal.mediafiles} />
                                    </Show>

                                </FloatingPanel>
                            </div>

                            <div className="divider"/>

                            <h3>{t('web.proposal.contracts').toUpperCase()}</h3>
                            <List array={this.props.proposal.contracts} itemTemplate={(item, key) => {
                                const css_class = "signstatus state-"+ item.document_status;
                                return <li key={key}
                                    className={this.state.selectedContract == item.contract_id ? 'active' : null}
                                    onClick={() => this.getContractPDF(item.contract_id, this.state.projectId)}
                                    ><span className={css_class}>{this.getSignStateText(item.document_status)}</span>{item.name}<i className="icon icon-cross"
                                        onClick={() => this.deleteContract(item)} /></li>
                                }}
                                noItemsTemplate={() => <p>{t('web.proposal.noContractsAdded')}</p>}
                            />

                            <h3>{t('web.solutions.documents').toUpperCase()}</h3>
                            <List array={this.state.attachments.documents} itemTemplate={(item, key) =>
                                    <li key={key}>{item.name} <i className="icon icon-cross"
                                        onClick={() => this.removeDocument(key)} /></li>
                                }
                                noItemsTemplate={() => <p>{t('web.proposal.noDocumentsAdded')}</p>}
                            />

                            <h3>{t('web.proposal.mediafiles').toUpperCase()}</h3>
                            <List array={this.props.mediafiles} itemTemplate={(item, key) =>
                                    <li key={key}>{item.name}<span className="filetype">{item.type}</span><i className="icon icon-cross"
                                    onClick={() => this.handleDeleteMediafile(item)} /></li>
                                }
                                noItemsTemplate={() => <p>{t('web.proposal.noMediafilesAdded')}</p>}
                            />

                        </Tab>
                    </TabsContainer>

                    <TabsContainer onChangeHandler={this.onTabChange} 
                        activeTab={this.state.activeTab === t('web.proposal.offer') ? t('web.proposal.offer') : t('web.proposal.contract')} 
                        className="panel-margin-right">
                        <Tab name={t('web.proposal.offer')}>

                            <Show if={isFetching || this.state.loadingOffer}>
                                <Spinner/>
                            </Show>

                            <Show if={!isFetching && !this.state.loadingOffer && offerPDF}>
                                <SignablePDF
                                    pdf={offerPDF}
                                    signingStatus={signingStatus}
                                    downloadPdfHandler={this.handleDownloadPdf}
                                    downloadOfferHandler={this.handleDownloadOffer}
                                    refreshPdf={this.refreshPdf}
                                    project={this.props.project}
                                    offerletter={true}
                                    documentName={offerPDFName}
                                    showRefreshButton={this.state.showOfferRefreshButton}
                                />
                            </Show>

                            <Show if={!isFetching && !this.state.loadingOffer && !offerPDF || (offerPDF === 404)}>
                                <NoTemplateSelected/>
                            </Show>

                        </Tab>
                        <Tab name={t('web.proposal.contract')}>
                            <Show if={(isFetching || this.state.loadingContract) && (contractPDF !== 404)}>
                                <Spinner/>
                            </Show>
                            {(!isFetching && contractPDF && !this.state.loadingContract && (contractPDF !== 404)) &&
                                <SignablePDF
                                pdf={contractPDF}
                                signingStatus={contractSigningStatus}
                                downloadPdfHandler={this.handleDownloadPdf}
                                refreshPdf={this.refreshContractPdf}
                                project={this.props.project}
                                contract={currentContract.contract_id}
                                documentName={currentContract.name}
                                showRefreshButton={this.state.showContractRefreshButton}
                                />
                            }
                            <Show if={!isFetching && !this.state.loadingContract && !contractPDF || (contractPDF === 404)}>
                                <NoTemplateSelected message={t("web.contracts.noContractsSelected")} />
                            </Show>
                        </Tab>
                    </TabsContainer>
                </div>
            </Page>
        );
    }

    private onTabChange(tab_name) {
        this.setState({activeTab: tab_name}, () => {

            if (tab_name === t('web.proposal.contract')) {
                this.setState({showContract: true});
                if (!this.props.proposal.contractPdf) {
                    this.setState({loadingContract: true});
                    this.getContractPDF();
                }
            } else {
                this.setState({showContract: false});
            }

        });
    }

    private getAttachmentIds = () => {
        return this.state.attachments.documents.map(item => item.attachment_id).join(",");
    }

    private onAttachmentMenuChange(tab_name) {
        this.setState({attachmentMenuTab: tab_name});
    }

    // ---------- Dispatchers -----------

    private getContracts() {
        this.props.dispatchAction({
            type: GET_CONTRACT_TEMPLATES_REQUESTED
        });
    }

    private getOffers() {
        this.props.dispatchAction({
            type: GET_PDF_REQUESTED, payload: {
                projectId: this.props.params.id,
                email: this.state.email
            }
        });
    }
    
    private getAttachments() {
        this.props.dispatchAction({
            type: GET_ATTACHMENTS_REQUESTED,
            payload: {
                projectId: this.props.params.id
            }
        });
    }
    
    private getMediafiles() {
        this.props.dispatchAction({
            type: GET_MEDIAFILES_REQUESTED,
            payload: {
                projectId: this.props.params.id
            }
        });
    }

    private getTemplates() {
        this.props.dispatchAction({
            type: GET_TEMPLATES_REQUESTED
        });
    }

    addOffer = (offer_letter_id) => {
        this.setState({loadingOffer: true});
        this.props.dispatchAction({
            type: SET_TEMPLATE_REQUESTED,
            payload: {
                offer_letter_id: offer_letter_id,
                project_id: this.props.params.id,
            }
        });
    }

    deleteOffer() {
        // set template to null?
    }

    private getAllMediafiles() {
        this.props.dispatchAction({
            type: GET_ALL_MEDIAFILES_REQUESTED
        })
    }

    private addDocument(document_name: string): void {
        const doc = this.state.staticFiles.find(file => file.name === document_name);
        const alreadyExists = this.state.attachments.documents.findIndex(doc => doc.name === document_name) !== -1;
        if (!alreadyExists) {
            this.setState({attachments: {...this.state.attachments,
                documents: [...this.state.attachments.documents, doc]
            }});
        }
    }

    private removeDocument(document_key: number): void {
        const newArr = this.state.attachments.documents.filter((item, index) => index !== document_key);
        this.setState({attachments: {...this.state.attachments,
            documents: newArr
        }});
    }

    private getProposalStatus() {
        dispatchProposalStatusRequested(this.props.dispatchAction, this.props.routeParams.id);
    }

    private getContractsStatus() {
        dispatchContractsStatusRequested(this.props.dispatchAction, this.props.routeParams.id);
    }

    private getContractPDF(contract_id?: number, project_id?: number) {

        if (!contract_id && !project_id) {
            let currentContract = this.getCurrentContract();
            if (currentContract) {
                contract_id = currentContract.contract_id;
                project_id = currentContract.project_id;
            }
        }
        
        if (contract_id && project_id) {
            this.setState({
                selectedContract: contract_id,
                contractPDF: null,
                loadingContract: true
            }, () => {
                this.setCurrentContract(contract_id, project_id);
                this.props.dispatchAction({
                    type: GET_CONTRACT_PDF_REQUESTED, payload: {
                        project_id: project_id,
                        contract_id: contract_id
                    }
                });
            });
        }
    }

    private projectHasContactInfo = () => {
        const contact = this.props.project.client.contacts[0];

        if(!contact) { return false; }
        
        return contact.firstName !== "" && contact.email !== "";
    }

    private contractHasBeenSubmitted = () => {

        const currentContract = this.getCurrentContract();
        if(!currentContract) {
            return false;
        }
        const status = currentContract.document_status !== "NOT_STARTED";
        return status;
    }

    private isOfferTab = () => {
        return this.state.activeTab === t('web.proposal.offer');
    }

    enableEmailButton = () => {
        return this.projectHasContactInfo();
    }

    enableSubmitButton = () => {
        return this.projectHasContactInfo() && (this.isOfferTab() || !this.contractHasBeenSubmitted());
    }
    
    // ---------- Handlers ----------------------------------------------------------------------

    handleAddMediafile = (file: any) => {
        this.props.dispatchAction({
            type: ADD_PROJECT_MEDIAFILE_REQUESTED,
            payload: {
                projectId: this.props.params.id,
                mediaId: file.id
            }
        });
    }

    handleDeleteMediafile = (file: any) => {
        this.props.dispatchAction({
            type: REMOVE_PROJECT_MEDIAFILE_REQUESTED,
            payload: {
                projectId: this.props.params.id,
                mediaId: file.id
            }
        });
    }

    handleRecipientChange = (email) => {
        this.setState({ email }, () => {
            this.refreshPdf();
        });
    }

    private toggleOfferMenu() {
        this.setState({...this.state, ...{
            showOfferMenu: !this.state.showOfferMenu,
            showAttachmentMenu: false
        }});
    }

    private toggleAttachmentMenu() {
        this.setState({...this.state, ...{
            showAttachmentMenu: !this.state.showAttachmentMenu,
            showOfferMenu: false
        }});
    }

    private handleSubmitPdf = (PDFType: IPDFType) => {

        switch(PDFType) {
            case IPDFType.OFFER: {
                dispatchStartOfferLetterSignInProcess(this.props.dispatchAction, this.props.params.id);
                break;
            }

            case IPDFType.CONTRACT: {
                let currentContract = this.getCurrentContract();
                dispatchStartContractSignInProcess(this.props.dispatchAction, currentContract.project_id, currentContract.contract_id);
                break;
            }

            default:
                return null;
        }
        
    }

    private refreshPdf = () => {
        this.props.dispatchAction({
            type: GET_PDF_REQUESTED, payload: {
                projectId: this.props.params.id,
                email: this.state.email
            }
        });
        
    }

    private refreshContractPdf = () => {
        this.props.dispatchAction({
            type: GET_CONTRACT_PDF_REQUESTED, payload: {
                project_id: this.props.params.id,
                contract_id: this.props.params.contract_id
            }
        });
    }

    // ----------- Static -------------

    private _getStaticFiles(): Array<any> {
        return [
            {
                name: t('web.risk.fullSummary'),
                attachment_id: "summary"
            },
            {
                name: t('web.risk.riskAssessment') + " - " + t('web.risk.sortByRisk'),
                attachment_id: "risk_assessment"
            },
            {
                name: t('web.risk.riskAssessment') + " - " + t('web.risk.sortByArea'),
                attachment_id: "risk_area_assessment"
            },
            {
                name: t('web.risk.executive_summary'),
                attachment_id: "executive"
            },
            {
                name: t('web.risk.aggregatedSurveys'),
                attachment_id: "surveys"
            }
        ];
    };

    private getSigningStatus = (offerletter) => {
        let contacts = this.props.project.client.contacts;
        if (!contacts || contacts.length < 1)
            return "MISSING_CONTACTINFO";

        if (!contacts[0].firstName || !contacts[0].lastName)
            return "MISSING_CONTACTINFO";

        if (!contacts[0].email || !contacts[0].phone1)
            return "MISSING_CONTACTINFO";

        return offerletter ? offerletter.document_status : "";
    }

    setCurrentContract = (contract_id, project_id) => {
        this.setState({contractId: Number(contract_id)});
        if (contract_id && project_id) {
            this.props.router.push(`/project/${project_id}/contracts/${contract_id}`);
        } else {
            this.props.router.push(`/project/${this.props.params.id}/contracts`);
        }
    }

    getCurrentContract = () => {
        let contracts = this.props.proposal.contracts || [];
        let contract_id = this.props.params.contract_id;
        
        if (contract_id || contracts.length) {
            let currentContract = contracts.filter(contract => contract.contract_id == contract_id);
            return currentContract[0] || contracts[0];
        }
        return undefined;
    }

    getPdf = (contract) => {
        return this.props.proposal.contractPdf;
    }

    handleDownloadPdf = () => {
        let currentContract = this.getCurrentContract();
        if (currentContract) {
            let url = `projects/${currentContract.project_id}/contracts/${currentContract.contract_id}/pdf?editMode=true`;
            fetchPDFHelper(url).then((result) => {
                let blob = new Blob([result], { type: "application/pdf" });
                saveAs(blob, currentContract.name + ".pdf");
            });
        }
        this.setState({ showContractRefreshButton: true });
    }

    handleDownloadOffer = () => {
        const url = `projects/${this.props.project.project_id}/offerletter/pdf?editMode=true&recipient=${this.state.email}`;
        fetchPDFHelper(url).then((result) => {
            let blob = new Blob([result], { type: "application/pdf" });
            saveAs(blob, `${this.props.project.name}_offer.pdf`);
        });
        this.setState({ showOfferRefreshButton: true });
    }

    addContract = (template) => {
        this.props.dispatchAction({
            type: ADD_CONTRACT_REQUESTED,
            payload: {
                project_id: this.props.project.project_id,
                contract_id: template.contract_id
            }
        });
        // this.setState({ isAddingContract: true });
    }

    deleteContract = (contract) => {
        this.setState({ contractToDelete: contract });
    }

    deleteConfirmed = () => {
        let contract = this.state.contractToDelete;
        this.props.dispatchAction({
            type: DELETE_CONTRACT_REQUESTED,
            payload: {
                ...contract
            }
        });

        const firstContract = this.props.proposal.contracts.filter(c => c.contract_id !== contract.contract_id)[0];
        if(firstContract) {
            this.getContractPDF(firstContract.contract_id, this.props.project.project_id);
        }
        this.deleteCanceled();
        
    }
    deleteCanceled = () => {
        this.setState({ contractToDelete: undefined });
    }

    getSignStateText(sign_state: string): string{
        const messages = {
            WAITING: t('web.proposal.signingProcessWaiting'),
            COMPLETE: t('web.proposal.signingProcessComplete'),
            MISSING_CONTACTINFO: t('web.proposal.signingProcessMissingContactinfo'),
            NOT_STARTED: t('web.proposal.signingProcessNotStarted')
        };
    
        return messages[sign_state] || t('web.proposal.signingProcessNotStarted');
    }
}

const mapStateToProps = ({ proposal, projects, attachments, isDeletingContract }, { routeParams }) => {
    let project = getProjectById(projects, routeParams.id);
    return {
        proposal,
        attachments: attachments.attachments || [],
        mediafiles: attachments.mediafiles || [],
        project,
        isDeletingContract,
        isFetching: proposal.isFetchingPdf,
    };
}

const mapDispatchToProps = (dispatch) => ({
    dispatchAction: (action) => dispatch(action)
});

export default connect(mapStateToProps, mapDispatchToProps)(ProposalContainer as any);
