import React from 'react';

import t from '../i18n';
import NoProjectsImage from '../img/no-projects.svg';

export interface NoProjectsProps {
    clickListener: (e) => void;
}

const NoProjects = ({ clickListener }: NoProjectsProps) =>
    <div className="projects__no-projects">
        <img src={NoProjectsImage} alt="no-projects"/>
        <h3 className="h3">{t('web.noProjects.noActiveProjects')}</h3>
        <button className="btn" onClick={clickListener}>{t('web.noProjects.addProject')}</button>
    </div>

export default NoProjects;
