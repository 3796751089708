import { IRisk } from '../risk/risk';
import getRiskRatingColor from './get-risk-rating-color';

export function getCorrectRiskSorting(
    risks: IRisk[],
    selectedRisksArg: number[]
): Array<Array<IRisk>> {
    const sortedRisks = [...risks].sort((a, b) => {
        const levelA = (a.impact + 1) * (a.probability + 1);
        const levelB = (b.impact + 1) * (b.probability + 1);
        if (levelA === 0) return 1;
        if (levelB === 0) return -1;

        return levelA - levelB;
    });

    const selectedRisks = [...sortedRisks].filter(({ id }) =>
        selectedRisksArg.includes(id)
    );

    const unselectedRisks = sortedRisks.filter(
        ({ id }) => !selectedRisksArg.includes(id)
    );

    const sameLevelAsSelected = [];
    const differentLevelFromSelected = [];

    if (selectedRisks.length !== 0) {
        const r = selectedRisks[0];
        const [level] = getRiskRatingColor(r);
        sameLevelAsSelected.push(
            ...unselectedRisks.filter(
                (risk) => getRiskRatingColor(risk)[0] === level
            )
        );

        differentLevelFromSelected.push(
            ...unselectedRisks.filter(
                (risk) => getRiskRatingColor(risk)[0] !== level
            )
        );
    } else {
        differentLevelFromSelected.push(...unselectedRisks);
    }

    return [selectedRisks, sameLevelAsSelected, differentLevelFromSelected];
}
