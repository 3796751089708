import React, { Component } from 'react';
import { connect } from 'react-redux';
import { GET_PROJECTS_REQUESTED } from "../projects/actions"
import { getProjectById } from '../projects/reducer';

class ProjectContainer extends Component<any, any> {

    componentDidMount() {
        if (!this.props.project) {
            this.props.dispatchAction({
                type: GET_PROJECTS_REQUESTED
            });
        }
    }

    render() {
        let children = this.props.project ? this.props.children : undefined;
        return (
            <div>{children}</div>
        );
    }
}

const mapStateToProps = ({ projects }, ownProps) => {
    let project = getProjectById(projects, ownProps.params.id);
    return { project }
};

const mapDispatchToProps = (dispatch) => ({
    dispatchAction: (action) => dispatch(action)
});

export default connect(mapStateToProps, mapDispatchToProps)(ProjectContainer as any);
