export const GET_TEMPLATES_REQUESTED = 'GET_TEMPLATES_REQUESTED';
export const GET_TEMPLATES_SUCCEEDED = 'GET_TEMPLATES_SUCCEEDED';
export const GET_TEMPLATES_FAILED = 'GET_TEMPLATES_FAILED';

export const GET_CONTRACT_TEMPLATES_REQUESTED = 'GET_CONTRACT_TEMPLATES_REQUESTED';
export const GET_CONTRACT_TEMPLATES_SUCCEEDED = 'GET_CONTRACT_TEMPLATES_SUCCEEDED';
export const GET_CONTRACT_TEMPLATES_FAILED = 'GET_CONTRACT_TEMPLATES_FAILED';

export const SET_TEMPLATE_REQUESTED = 'SET_TEMPLATE_REQUESTED';
export const SET_TEMPLATE_SUCCEEDED = 'SET_TEMPLATE_SUCCEEDED';

export const GET_PDF_REQUESTED = 'GET_PDF_REQUESTED';
export const GET_PDF_SUCCEEDED = 'GET_PDF_SUCCEEDED';
export const GET_PDF_FAILED = 'GET_PDF_FAILED';

export const GET_CONTRACT_PDF_REQUESTED = 'GET_CONTRACT_PDF_REQUESTED';
export const GET_CONTRACT_PDF_SUCCEEDED = 'GET_CONTRACT_PDF_SUCCEEDED';
export const GET_CONTRACT_PDF_FAILED = 'GET_CONTRACT_PDF_FAILED';

export const START_OFFER_LETTER_SIGN_IN_PROCESS = 'START_OFFER_LETTER_SIGN_IN_PROCESS';
export const START_OFFER_LETTER_SIGN_IN_PROCESS_SUCCEEDED = 'START_OFFER_LETTER_SIGN_IN_PROCESS_SUCCEEDED';

export const GET_PROPOSAL_STATUS_REQUESTED = 'GET_PROPOSAL_STATUS_REQUESTED';
export const GET_PROPOSAL_STATUS_SUCCEEDED = 'GET_PROPOSAL_STATUS_SUCCEEDED';

export const GET_CONTRACTS_STATUS_REQUESTED = 'GET_CONTRACTS_STATUS_REQUESTED';
export const GET_CONTRACTS_STATUS_SUCCEEDED = 'GET_CONTRACTS_STATUS_SUCCEEDED';

export const CHANGE_PROPOSAL_VIEW_REQUESTED = 'CHANGE_PROPOSAL_VIEW_REQUESTED';

export const EDIT_TEMPLATE_REQUESTED = 'EDIT_TEMPLATE_REQUESTED';
export const EDIT_TEMPLATE_SUCCEEDED = 'EDIT_TEMPLATE_SUCCEEDED';

export const ADD_CONTRACT_REQUESTED = 'ADD_CONTRACT_REQUESTED';
export const ADD_CONTRACT_SUCCEEDED = 'ADD_CONTRACT_SUCCEEDED';

export const DELETE_CONTRACT_REQUESTED = 'DELETE_CONTRACT_REQUESTED';
export const DELETE_CONTRACT_SUCCEEDED = 'DELETE_CONTRACT_SUCCEEDED';

export const START_CONTRACT_SIGN_IN_PROCESS = 'START_CONTRACT_SIGN_IN_PROCESS';
export const START_CONTRACT_SIGN_IN_PROCESS_SUCCEEDED = 'START_CONTRACT_SIGN_IN_PROCESS_SUCCEEDED';

export const GET_ALL_MEDIAFILES_REQUESTED = 'GET_ALL_MEDIAFILES_REQUESTED';
export const GET_ALL_MEDIAFILES_SUCCEEDED = 'GET_ALL_MEDIAFILES_SUCCEEDED';

export const ADD_PROJECT_MEDIAFILE_REQUESTED = 'ADD_PROJECT_MEDIAFILE';
export const REMOVE_PROJECT_MEDIAFILE_REQUESTED = 'REMOVE_PROJECT_MEDIAFILE';

export const UPDATE_CONTRACT_URL = 'UPDATE_CONTRACT_URL';