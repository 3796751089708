import React from 'react';
import classNames from 'classnames';

import './breadcrumbs.css';

const Breadcrumb = ({index, name, lastItemIndex, handleNavigationStackPop}) =>
  <a
    className={
      classNames(
        "breadcrumbs__link",
        {"breadcrumbs__link--active": index === lastItemIndex})}
    onClick={() => handleNavigationStackPop(index)}>
    {name} {index !== lastItemIndex && " / "}
  </a>

const Breadcrumbs = ({navigationStack, handleNavigationStackPop}) =>
  <nav className="breadcrumbs">
    {
      navigationStack.map((navItem, index) => {
        return (
          <Breadcrumb
            key={index}
            index={index}
            name={navItem.name}
            lastItemIndex={navigationStack.length - 1}
            handleNavigationStackPop={handleNavigationStackPop}/>
        )
      })
    }
  </nav>

export default Breadcrumbs;
