import * as React from 'react';
import './input.css';
import ClassNames from 'classnames';

class Input extends React.Component {

  state = {text: ""}

  componentDidMount = () => {
    this.setState({text: this.props.value});
  }
  onChange = (event) => {
    this.setState({text: event.target.value});
    if (this.props.onChange) {
      this.props.onChange(event);
    }
  }
  onKeyPress = (event) => {
    let key = event.which || event.keyCode
    if (key === 13 && this.props.supressEnter) {
      event.preventDefault();
    }
  }

  componentWillReceiveProps(nextProps) {
    this.setState({text: nextProps.value});
  }

  render() {
    let {conditionalClasses = "", image = null, placeholder, type = "text", required = false, supressEnter, ...props} = this.props;
    return (
      <div className={ClassNames("input-form", conditionalClasses)}>
        {image &&
        <div className="input-form__image">
          <img src={image} role="presentation"/>
        </div>
        }
        <div className="input-form__group-container">
          <div className="input-form__group">
            <input
              className="input-form__input"
              type={type || "text"}
              {...props}
              value={this.state.text}
              onChange={this.onChange}
              onKeyPress={this.onKeyPress}
              required={required ? 'required' : ''}/>
            <span className="input-form__bar"></span>
            <label className="input-form__label">{placeholder}</label>
          </div>
        </div>
      </div>
    );
  }

}

export class TextArea extends React.Component {

  state = {text: ""}

  componentDidMount = () => {
    this.setState({text: this.props.value});
    setTimeout(() => this.adjustTextarea());
  }
  onChange = (event) => {
    this.setState({text: event.target.value});
    if (this.props.onChange) {
      this.props.onChange(event);
    }
    this.adjustTextarea(event.target);
  }

  componentWillReceiveProps(nextProps) {
    this.setState({text: nextProps.value});
  }

  adjustTextarea(textarea = this.textarea) {
    if (textarea && this.props.autoResize) {
      textarea.style.height = 0;
      textarea.style.height = `${textarea.scrollHeight + 2}px`;
    }
  }

  render() {
    let {conditionalClasses = "", image = null, placeholder, required = false, autoResize, ...props} = this.props;
    return (
      <div className={ClassNames("input-form", conditionalClasses)}>
        {image &&
        <div className="input-form__image">
          <img src={image} role="presentation"/>
        </div>
        }
        <div className="input-form__group-container">
          <div className="input-form__group">
                        <textarea
                          ref={(textarea) => this.textarea = textarea}
                          className="input-form__textarea"
                          {...props}
                          value={this.state.text}
                          onChange={this.onChange}
                          required={required ? 'required' : ''}/>
            <span className="input-form__bar"></span>
            <label className="input-form__label">{placeholder}</label>
          </div>
        </div>
      </div>
    );
  }

}

export default Input;
