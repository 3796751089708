import * as React from 'react'

import t from '../i18n';
import noTemplates from '../img/no-templates.svg';
import './no-template-selected.css';

interface NoTemplateSelectedProps {
    message?: string;
}

const NoTemplateSelected = ({ message }: NoTemplateSelectedProps) => {
    message = message || t('web.proposal.noTemplateSelected');
    return (
        <div className="no-template-selected">
            <span className="no-template-selected__title">{message}</span>
            <img src={noTemplates} alt={t('web.proposal.noTemplateSelectedAlt')}/>
        </div>
    )
}

export default NoTemplateSelected
