import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import {
    GET_RISKS_REQUESTED,
    EDIT_RISKS_REQUESTED,
    ADD_RISKS_REQUESTED,
    GET_PROJECT_RISKS_REQUESTED,
    REMOVE_RISKS_REQUESTED,
    GET_SCENARIOS_REQUESTED,
    GET_SOLUTIONS_REQUESTED,
    ADD_RISK_SCENARIO_REQUESTED,
    GET_PROJECT_AREAS_REQUESTED,
    ADD_PROJECT_AREAS_REQUESTED,
    REMOVE_PROJECT_AREAS_REQUESTED,
    GET_PROJECT_IMAGES_REQUESTED,
    ADD_PROJECT_IMAGE_REQUESTED,
    UPDATE_PROJECT_IMAGE_REQUESTED,
    REMOVE_PROJECT_IMAGE_REQUESTED,
    GET_SEGMENT_RISKS_REQUESTED,
    GET_SEGMENT_DEFINITION_REQUESTED
} from './actions';
import Risks from './risks';
import Areas from './areas';
import { Scrollbars } from 'react-custom-scrollbars-2';
import RightSideContainer from '../right-side-container/right-side-container';
import Products from '../products/products-container';
import { getProjectById } from '../projects/reducer';
import t from '../i18n';
import EditAreas from '../area/edit-areas';
import EditRiskList from './edit/edit-risk-list';
import Page from '../common/page/page';

Array.prototype.unique = function (selector) {
    return this.filter(
        (e, i) =>
            this.findIndex((a) => {
                if (selector) {
                    return selector(a) === selector(e);
                }
                return a === e;
            }) === i
    );
};

class RisksContainer extends Component {
    static propTypes = {
        dispatchAction: PropTypes.func.isRequired,
        // risks: PropTypes.array,
        isFetching: PropTypes.bool.isRequired,
    };

    risksComponent;

    constructor(props) {
        super(props);
        this.state = {
            activeAreaId: undefined,
            isEditingAreas: false,
            isEditingRiskList: false,
        };
    }

    componentDidMount() {
        this.props.dispatchAction({
            type: GET_PROJECT_RISKS_REQUESTED,
            payload: { project_id: this.props.params.id },
        });
        this.props.dispatchAction({
            type: GET_SCENARIOS_REQUESTED,
        });
        this.props.dispatchAction({
            type: GET_SOLUTIONS_REQUESTED,
        });
        this.props.dispatchAction({
            type: GET_PROJECT_AREAS_REQUESTED,
            payload: { project_id: this.props.params.id },
        });
        this.props.dispatchAction({
            type: GET_PROJECT_IMAGES_REQUESTED,
            payload: { project_id: this.props.params.id },
        });
        this.props.dispatchAction({
            type: GET_SEGMENT_RISKS_REQUESTED,
            payload: { segment_id: this.props.project.segment_id },
        });
        this.props.dispatchAction({
            type: GET_SEGMENT_DEFINITION_REQUESTED,
            payload: { segment_id: this.props.project.segment_id },
        });
    }

    componentWillReceiveProps(nextProps) {
        if (
            nextProps.project &&
            nextProps.segment_id !== nextProps.project.segment_id &&
            !this.state.segmentsLoaded
        ) {
            let segmentId = nextProps.project.segment_id || 1;
            this.props.dispatchAction({
                type: GET_RISKS_REQUESTED,
                payload: { segment_id: segmentId },
            });
            this.setState({ segmentsLoaded: true });
        }
        if (nextProps.projectAreas && nextProps.projectAreas.length < 1) {
            this.setState({
                isEditingAreas: true,
            });
        }
        if (nextProps.activeAreaId) {
            this.setState({ activeAreaId: nextProps.activeAreaId });
            this.risksComponent.updatePaneStateByString('riskManagement');
        }
        if (nextProps.projectAreas) {
            nextProps.projectAreas.sort((a, b) => a.area_id - b.area_id);
        }
    }

    clickOnListItemHandler = (risk) => {
        let activeArea = this.getActiveArea();

        let projectRisksForArea = this.getProjectRisks(activeArea.area_id);
        let projectRisk = projectRisksForArea.filter(
            (projectRisk) => risk.group_id === projectRisk.group_id
        )[0];
        let actionType = projectRisk
            ? REMOVE_RISKS_REQUESTED
            : ADD_RISKS_REQUESTED;
        let area = this.getActiveArea();
        let project = this.props.project;
        let submitRisk = {
            area_id: area.area_id,
            seller_id: project.seller_id,
            project_id: project.project_id,
            group_id: risk.group_id,
            probability: -1,
            impact: -1,
            note: '',
        };
        if (actionType === REMOVE_RISKS_REQUESTED) {
            submitRisk = projectRisk;
        }
        this.props.dispatchAction({
            type: actionType,
            payload: {
                risk: submitRisk,
            },
        });
    };

    editRiskHandler = (risk) => {
        this.props.dispatchAction({
            type: EDIT_RISKS_REQUESTED,
            payload: { risk },
        });
    };

    handleImageEvent = (
        data,
        riskId,
        type = 'ADD',
        attachmentId = undefined
    ) => {
        if (type === 'ADD') {
            const file = data[0];
            this.props.dispatchAction({
                type: ADD_PROJECT_IMAGE_REQUESTED,
                payload: {
                    projectId: this.props.params.id,
                    riskId,
                    attachmentId: attachmentId,
                    file: file,
                },
            });
        }
        if (type === 'UPDATE') {
            const file = data[0];
            this.props.dispatchAction({
                type: UPDATE_PROJECT_IMAGE_REQUESTED,
                payload: {
                    projectId: this.props.params.id,
                    riskId,
                    attachmentId: attachmentId,
                    file: file,
                },
            });
        }
        if (type === 'DELETE') {
            this.props.dispatchAction({
                type: REMOVE_PROJECT_IMAGE_REQUESTED,
                payload: {
                    projectId: this.props.params.id,
                    attachmentId: attachmentId,
                },
            });
        }
    };

    changeAreaHandler = (id) => {
        this.setState({
            activeAreaId: id,
            isEditingAreas: false,
        });
    };

    toggleAreaHandler = (e, area) => {
        if (e.target.checked) {
            this.props.dispatchAction({
                type: ADD_PROJECT_AREAS_REQUESTED,
                payload: {
                    area_id: area.area_id,
                    project_id: this.props.project.project_id,
                    segment_id: this.props.project.segment_id,
                },
            });
        } else {
            let existingProjectArea = this.props.projectAreas.filter(
                (parea) => parea.area_id === area.area_id
            )[0];
            this.props.dispatchAction({
                type: REMOVE_PROJECT_AREAS_REQUESTED,
                payload: { ...existingProjectArea },
            });
        }
    };

    toggleHideRightSideBar = (hide) => {
        let newState = {
            ...this.state,
            hideRightSide: !this.state.hideRightSide,
        };
        this.setState(newState);
    };

    getActiveArea() {
        if (
            !this.state.activeAreaId &&
            this.props.projectAreas &&
            this.props.projectAreas.length > 0
        ) {
            return (
                this.props.areas.filter(
                    (area) =>
                        area.area_id === this.props.projectAreas[0].area_id
                )[0] || {
                    area_id: this.props.projectAreas[0].area_id,
                    groups: [],
                }
            );
        } else {
            return (
                this.props.areas.filter(
                    (area) => area.area_id === this.state.activeAreaId
                )[0] ||
                this.props.areas[0] || {
                    area_id: 0,
                    groups: [],
                }
            );
        }
    }

    getProjectRisks(areaId) {
        let area = this.getActiveArea();
        let projectRisks = this.props.projectRisks.filter(
            (projectRisk) => projectRisk.area_id === areaId
        );
        let risksWithName = projectRisks.map((risk) => {
            let areaRisk = area.groups.filter((areaRisk) => {
                return risk.group_id === areaRisk.group_id;
            })[0];
            return {
                ...risk,
                group_name: areaRisk ? areaRisk.group_name : risk.group_name,
            };
        });
        return risksWithName;
    }

    toggleAreaEditingState() {
        this.setState({
            isEditingAreas: !this.state.isEditingAreas,
        });
    }

    toggleRiskListEditingState(e, risk) {
        this.setState({ isEditingRiskList: !this.state.isEditingRiskList });
    }

    checkRisks = (risk) => {
        let projectRisksForArea = this.getProjectRisks(
            this.getActiveArea().area_id
        );
        const projectRisk = projectRisksForArea.filter(
            (projectRisk) => projectRisk.group_id === risk.group_id
        )[0];
        risk.checked = projectRisk !== undefined;
        if (projectRisk) {
            risk.probability = projectRisk.probability;
            risk.impact = projectRisk.impact;
            risk.note = projectRisk.note;
        }
        return risk;
    };

    render() {
        if (!this.props.project) {
            return null;
        }
        const { areas, isFetching, projectAreas, projectImages } = this.props;

        let activeArea = this.getActiveArea();
        if (!activeArea) {
            return null;
        }

        const sortedAreas = areas.sort((a, b) =>
            a.area_name_local.localeCompare(b.area_name_local)
        );

        let projectRisksForArea = this.getProjectRisks(activeArea.area_id);
        projectRisksForArea.sort((a, b) => a.id - b.id);

        let recommendedRisks = activeArea.groups.sort((a, b) =>
            a.group_name < b.group_name ? -1 : 1
        );
        recommendedRisks = recommendedRisks.map(this.checkRisks);

        let allRisks = Array.from(this.props.segmentRisks || []).map(
            this.checkRisks
        );

        return (
            <Page
                includePadding={false}
                title={t('web.risk.riskAssessment')}
                showSpinner={!areas || isFetching}
            >
                <Areas
                    areas={sortedAreas}
                    projectAreas={projectAreas}
                    isActive={!this.state.isEditingRiskList}
                    activeAreaId={activeArea.area_id}
                    changeAreaHandler={this.changeAreaHandler}
                    toggleAreaHandler={this.toggleAreaHandler}
                    toggleEditAreas={this.toggleAreaEditingState.bind(this)}
                />
                <div className="tabs__table">
                    <div className="tabs__main-container">
                        <Scrollbars style={{ height: `calc(100vh - 14rem)` }}>
                            <div className="tabs__container">
                                {this.state.isEditingAreas && (
                                    <EditAreas
                                        projectId={this.props.params.id}
                                        dispatchAction={
                                            this.props.dispatchAction
                                        }
                                        areas={sortedAreas}
                                        projectAreas={projectAreas}
                                        onSelectHandler={this.toggleAreaHandler}
                                        onClose={this.toggleAreaEditingState.bind(
                                            this
                                        )}
                                    />
                                )}
                                {this.state.isEditingRiskList && (
                                    <EditRiskList
                                        allRisks={[
                                            ...Array.from(
                                                allRisks.unique(
                                                    (item) => item.group_name
                                                )
                                            ),
                                        ]}
                                        recommendedRisks={recommendedRisks}
                                        onSelectHandler={(e, risk) =>
                                            this.clickOnListItemHandler(risk)
                                        }
                                        onClose={this.toggleRiskListEditingState.bind(
                                            this
                                        )}
                                    />
                                )}
                                {this.props.projectAreas &&
                                    this.props.projectAreas.length > 0 &&
                                    !this.state.isEditingAreas &&
                                    !this.state.isEditingRiskList &&
                                    allRisks !== 'undefined' && (
                                        <Risks
                                            project={this.props.project}
                                            user={this.props.user}
                                            allRisks={allRisks}
                                            projectAreas={projectAreas}
                                            allSolutions={
                                                this.props.allSolutions
                                            }
                                            risksForArea={projectRisksForArea}
                                            projectImages={projectImages}
                                            editRiskHandler={
                                                this.editRiskHandler
                                            }
                                            handleImageEvent={
                                                this.handleImageEvent
                                            }
                                            clickOnListItemHandler={
                                                this.clickOnListItemHandler
                                            }
                                            toggleHideRightSideBar={
                                                this.toggleHideRightSideBar
                                            }
                                            toggleRiskListEditingState={this.toggleRiskListEditingState.bind(
                                                this
                                            )}
                                            projectRisks={
                                                this.props.projectRisks
                                            }
                                            ref={(ref) => {
                                                this.risksComponent = ref;
                                            }}
                                        />
                                    )}
                            </div>
                        </Scrollbars>
                    </div>
                </div>
            </Page>
        );
    }
}

const mapStateToProps = ({ risks, projects, user }, { routeParams }) => {
    let project = getProjectById(projects, routeParams.id);
    return {
        ...risks,
        project,
        user,
        allSolutions: risks.solutions || [],
    };
};

const mapDispatchToProps = (dispatch) => ({
    dispatchAction: (action) => dispatch(action),
});

export default connect(mapStateToProps, mapDispatchToProps)(RisksContainer);
