import * as React from 'react';

import t from '../i18n';
import Modal from "../common/modal/modal";
import { endsWith } from '../utils/utils';

const getVideos = (product) => {
    let allowedFormats = ['.wmv', '.mp4', '.avi', '.mpg', '.m4v', '.mov'];
    return getMediaFilesWithExtensions(product, allowedFormats);
}

const getImages = (product) => {
    let allowedFormats = ['.png', '.gif', '.jpg', 'jpeg', '.bmp', '.tiff', '.tif'];
    return getMediaFilesWithExtensions(product, allowedFormats);
}

const getDocuments = (product) => {
    let allowedFormats = ['.doc', '.pdf', '.docx', '.txt', '.xls', '.rtf', '.pages', '.key'];
    return getMediaFilesWithExtensions(product, allowedFormats);
}


const getMediaFilesWithExtensions = (product, allowedExtensions) => {
    let mediaFiles = product.mediafiles || [];
    let files = mediaFiles.filter(file => {
        let returnValue = false;
        allowedExtensions.forEach(format => {
            if (file.filename && endsWith(file.filename, format)) {
                returnValue = true;
            }
        });
        return returnValue;
    });
    return files || [];
}

const getDescriptionOnly = (description, index) => {
    if (!description) return null;
    if (index == 0) return description;
    var reg = /(.+?)(?:\s|$)/,
    match = description.match(reg);

    if (match) {
        return description.replace(match[1], '');
    }
}

const VideoFiles = ({ product }) => {
    let videos = getVideos(product);
    if (videos.length < 1) return null;

    let videoTags = videos.map((video, index) => {
        return (
            <li key={index}>
                <video width="100%" controls>
                    <source src={video.url}/>
                    no video support
                </video>
            </li>
        );
    });
    return (
        <div>
            <h3>{t("web.solutions.videos")}</h3>
            <ul>{videoTags}</ul>
        </div>
    )
}

const ImageFiles = ({ product }) => {
    let images = getImages(product);
    if (images.length < 1) return null;

    let imageTags = images.map((image, index) => {
        return (
            <li key={index}>
                <img src={image.url}/>
            </li>
        );
    });
    return (
        <div>
            <h3>{t("web.solutions.images")}</h3>
            <ul>{imageTags}</ul>
        </div>
    )
}

const DocumentFiles = ({ product }) => {
    let documents = getDocuments(product);
    if (documents.length < 1) return null;

    let documentTags = documents.map((document, index) => {
        return (
            <li key={index}>
                <a target="_new" href={document.url}>{document.name}</a>
            </li>
        );
    })
    return (
        <div>
            <h3>{t("web.solutions.documents")}</h3>
            <ul>{documentTags}</ul>
        </div>
    )
}

const LanguageTabs = ({ product, tabIndexChanged, tabIndex }) => {
    let descriptionSplitted;
    if (product.description) {
        descriptionSplitted = product.description.split("// ");
    }
    if (descriptionSplitted.length < 1) return null;

    let languageTabs = descriptionSplitted.map((description, index) => {
        if (index == 0) {
            return (
                <button className={'tablinks' + (tabIndex == index ? ' active' : '')} onClick={() => { tabIndexChanged(index) }}>{product.name}</button>
            );
        }

        var reg = /(.+?)(?:\s|$)/,
        match = description.match(reg);

        if (match) {
            description = match[1];
        }
        return (
            <button className={"tablinks" + (tabIndex == index ? " active"  : "")} onClick={() => { tabIndexChanged(index) }}>{description}</button>
        ); 
    })

    return (
        <div className="language-tabs">
            {languageTabs}
        </div>
    )
}

export interface ProductDetailedItemProps {
    product: any,
    showMedia?: boolean,
    tabIndex: number,
    tabIndexChanged(): any;
    closeView(): any;
}

const ProductDetailedItem = ({ product, closeView, tabIndexChanged, tabIndex, showMedia = false }: ProductDetailedItemProps) => {
    let descriptionSplitted;
    if (product.description) {
        descriptionSplitted = product.description.split("// ");
    }
    return (
        <Modal title={product.name} onClose={closeView}>
            <LanguageTabs product={product} tabIndexChanged={tabIndexChanged} tabIndex={tabIndex}/>
            <div className="products__detailed-item-container">
                <p>{getDescriptionOnly(descriptionSplitted[tabIndex], tabIndex) || product.information}</p>
                {showMedia &&
                <div className="products__media-files">
                    <DocumentFiles product={product}/>
                    <VideoFiles product={product}/>
                    <ImageFiles product={product}/>
                </div>
                }
            </div>
        </Modal>
    );
}

export default ProductDetailedItem;
