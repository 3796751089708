// @ts-nocheck
// TODO remove above
export const enum ProductType {
    /** Product that supports both leasing and fixed price but not both at the same time */
    DualPrice = "DualPrice",
    /** Product that only supports fixed price */
    FixedPrice = "FixedPrice",
    /** Product that supports both leasing and fixed price, and both can be set at the same time */
    ComboPrice = "ComboPrice",
    /** Product that only supports leasing */
    Leasing = "Leasing",
    Unknown = "Unknown",
}

export const getProductType = (product) => {
    if (!product.is_leased && product.is_fixed_price) return ProductType.FixedPrice;
    if (product.is_leased && !product.is_fixed_price) return ProductType.Leasing;
    if (product.is_leased && product.is_fixed_price && !product.is_combo_price) return ProductType.ComboPrice;
    if (product.is_leased && product.is_fixed_price && product.is_combo_price) return ProductType.ComboPrice;

    return ProductType.Unknown;
}

/**
 * Returns a boolean indicating if the product is leased or not. Some products supports
 * both leasing and fixed price so it is not enough to check the "is_leased" property.
 * In products that supports both modes we set the lease_period to 0 to indicate that
 * the user has chosen fixed price
 * @param product
 */
export const isLeasing = (product) => {
    let productType = getProductType(product);
    if (productType !== ProductType.FixedPrice && productType !== ProductType.ComboPrice && product.lease_period > 0) {
        return true;
    }
    return false;
}

export const isComboPrice = (product) => {
    return getProductType(product) === ProductType.ComboPrice;
};

/**
 * Returns a boolean indicating if the product is fixed price or not. Some products supports
 * both leasing and fixed price so it is not enough to check the "is_fixed_price" property.
 * In products that supports both modes we set the lease_period to 0 to indicate that
 * the user has chosen fixed price
 * @param product
 */
export const isFixedPrice = (product) => {
    return !isLeasing(product);
}

export const getLeasePrice = (product) => {

};

export const getPriceForDurationSelected = (product): number => {
    let lease_period = product.lease_period;

    if (isComboPrice(product)) {
        return 17; //product.adjusted_fixed_price_in_cents || product.fixed_price_in_cents;
    }

    if (isFixedPrice(product)) {
        return product.adjusted_fixed_price_in_cents || product.fixed_price_in_cents;
    }

    if (lease_period === 12) {
        return product.adjusted_price_12_months_cents || product.price_12_months_cents;
    }
    if (lease_period === 24) {
        return product.adjusted_price_24_months_cents || product.price_24_months_cents;
    }
    if (lease_period === 36) {
        return product.adjusted_price_36_months_cents || product.price_36_months_cents;
    }
    if (lease_period === 60) {
        return product.adjusted_price_60_months_cents || product.price_60_months_cents;
    }

    return product.adjusted_price_12_months_cents || product.price_12_months_cents;
}

export const getPriceForProduct = (product, includeVAT: boolean = true) => {
    let price = getComputedPriceForProduct(product, includeVAT);
    ;
    return Math.round(price); //price is in cents
}

export function getTotalPriceforProduct(product: any, includeVAT: boolean = true): number {
    return product.quantity * getPriceForProduct(product, includeVAT);
}

const getMonthlyBasePrices = (product) => {
    let adjustedPrice = 0;
    let lease_period = product.lease_period;
    let price = 0;

    if (lease_period == 12) {
        adjustedPrice = product.adjusted_price_12_months_cents;
        price = product.price_12_months_cents;
    }
    if (lease_period == 24) {
        adjustedPrice = product.adjusted_price_24_months_cents;
        price = product.price_24_months_cents;
    }
    if (lease_period == 36) {
        adjustedPrice = product.adjusted_price_36_months_cents;
        price = product.price_36_months_cents;
    }
    if (lease_period == 60) {
        adjustedPrice = product.adjusted_price_60_months_cents;
        price = product.price_60_months_cents;
    }
    return { adjustedPrice, price }
};

/**
 * Gets the price and adjusted price of the product without computing vat, discount etc
 * @param product
 */
function getBasePrices(product) {
    let price = 0;
    let adjustedPrice = 0;
    let productType = getProductType(product);

    if (isFixedPrice(product) && product.is_fixed_price) {
        price = product.fixed_price_in_cents;
        adjustedPrice = product.adjusted_fixed_price_cents;
    }
    else {
        ({adjustedPrice, price} = getMonthlyBasePrices(product));
    }
    // prices from api is in "cents"
    price = Math.round(price / 100, 2);
    adjustedPrice = Math.round(adjustedPrice / 100, 2);
    return { price, adjustedPrice };
}

/**
 * Gets the base fixed fee for a product with combo pricing
 * @param product
 */
export function getBaseFixedFee(product) {
    let price = 0;
    let adjustedPrice = 0;
    let lease_period = product.lease_period || 0;
    let productType = getProductType(product);

    if (productType === ProductType.ComboPrice) {
        price = product.fixed_price_in_cents;
        adjustedPrice = product.adjusted_fixed_price_cents;
    }
    // prices from api is in "cents"
    price = Math.round(price / 100, 2);
    adjustedPrice = Math.round(adjustedPrice / 100, 2);

    return adjustedPrice > 0 ? adjustedPrice : price;
}

/**
 * Gets the price (or adjusted price) of the product without computing vat, discount etc
 * @param product
 */
export function getBasePrice(product) {
    let { price, adjustedPrice } = getBasePrices(product);
    return adjustedPrice > 0 ? adjustedPrice : price;
}

export function getComputedPriceForProduct(product, includeVAT: boolean = true): number {

    let vat: number = product.value_added_tax_percentage;
    let lease_period = product.lease_period;
    let { price, adjustedPrice } = getBasePrices(product);
    if (includeVAT) {
        if (product.value_added_tax_enabled && !product.price_includes_vat) {
            price = priceIncludingVat(price, vat);
            adjustedPrice = priceIncludingVat(adjustedPrice, vat);
        }
    } else {
        if (product.price_includes_vat) {
            price = priceExcludingVat(price, vat);
            adjustedPrice = priceExcludingVat(adjustedPrice, vat);
        }
    }
    // adjusted price takes precedence over discount. That is if the price has been adjusted
    // manually, discount percentage is not used
    if (adjustedPrice > 0) {
        return adjustedPrice;
    } else {
        let percentageAdjustment = product.percentage_adjustment || 0;
        if (percentageAdjustment > 0) {
            let factor = 1.0 - (percentageAdjustment / 100.0);
            return price * factor;
        }
        else {
            return price;
        }
    }
}

export function getFixedFeeForProduct(product, includeVAT: boolean = true): number {
    let price = 0;
    let adjustedPrice = 0;
    let vat: number = product.value_added_tax_percentage;

    if (product.is_fixed_price) {
        price = Math.round(product.fixed_price_in_cents / 100, 2);
        adjustedPrice = Math.round(product.adjusted_fixed_price_cents / 100, 2);
    }
    if (includeVAT) {
        if (product.value_added_tax_enabled && !product.price_includes_vat) {
            price = priceIncludingVat(price, vat);
            adjustedPrice = priceIncludingVat(adjustedPrice, vat);
        }
    } else {
        if (product.price_includes_vat) {
            price = priceExcludingVat(price, vat);
            adjustedPrice = priceExcludingVat(adjustedPrice, vat);
        }
    }
    // adjusted price takes precedence over discount. That is if the price has been adjusted
    // manually, discount percentage is not used
    if (adjustedPrice > 0) {
        return adjustedPrice;
    } else {
        let percentageAdjustment = product.percentage_adjustment;
        if (percentageAdjustment > 0) {
            let factor = 1.0 - (percentageAdjustment / 100.0);
            return price * factor;
        }
        else {
            return price;
        }
    }
}

export const getComboPriceForProduct = (product, includeVAT) => {
    let price = 0;
    let adjustedPrice;
    let vat: number = product.value_added_tax_percentage;

    if (!isComboPrice(product)) {
        return price;
    }

    ( {adjustedPrice, price} = getMonthlyBasePrices(product) );
    price = Math.round(price / 100, 2);
    adjustedPrice = Math.round(adjustedPrice/ 100, 2);
    if (includeVAT) {
        if (product.value_added_tax_enabled && !product.price_includes_vat) {
            price = priceIncludingVat(price, vat);
            adjustedPrice = priceIncludingVat(adjustedPrice, vat);
        }
    } else {
        if (product.price_includes_vat) {
            price = priceExcludingVat(price, vat);
            adjustedPrice = priceExcludingVat(adjustedPrice, vat);
        }
    }

    return price;
};

export const getPriceTotals = (products = []) => {
    let fixedPriceProducts = products.filter(product => isFixedPrice(product));
    let leasingProducts = products.filter(product => isLeasing(product));
    let comboPriceProducts = products.filter(product => (isComboPrice(product) || getProductType(product) == ProductType.DualPrice));
    let initialPrice: number = fixedPriceProducts.reduce(function (acc, product) {
        return product.fixed_fee ? acc + (product.quantity * getPriceForProduct(product, false)) : 0;
    }, 0);
    const fixedFees: number = leasingProducts.reduce(function (acc, product) {
        return product.fixed_fee ? acc + (product.quantity * getFixedFeeForProduct(product, false)) : 0;
    }, 0);
    let comboPrice: number = comboPriceProducts.reduce((acc, product) => {
        return acc + (product.quantity * getComboPriceForProduct(product, false));
    } , 0);
    // initial price is price for all fixed products pluss all fixed fees from leasing products
    initialPrice += fixedFees;
    let monthlyCost: number = leasingProducts.reduce(function (acc, product) {
        return acc + (product.quantity * getPriceForProduct(product, false));
    }, 0);

    monthlyCost += comboPriceProducts.reduce(function (acc, product) {
        return acc + (product.quantity * getComboPriceForProduct(product, false));
    }, 0);


    let initialPriceGross: number = fixedPriceProducts.reduce(function (acc, product) {
        return product.fixed_fee ? acc + (product.quantity * getPriceForProduct(product, true)) : 0;
    }, 0);
    const fixedFeesGross: number = leasingProducts.reduce(function (acc, product) {
        return product.fixed_fee ? acc + (product.quantity * getFixedFeeForProduct(product, true)) : 0;
    }, 0);
    initialPriceGross += fixedFeesGross;
    let monthlyCostGross: number = leasingProducts.reduce(function (acc, product) {
        return acc + (product.quantity * getPriceForProduct(product, true));
    }, 0);

    monthlyCostGross += comboPriceProducts.reduce(function (acc, product) {
        return acc + (product.quantity * getComboPriceForProduct(product, true));
    }, 0);

    const monthlyCostVAT = monthlyCostGross - monthlyCost;
    const initialPriceVAT = initialPriceGross - initialPrice;

    let totalPriceGross = initialPriceGross + monthlyCostGross;
    return {
        initialPrice,
        monthlyCost,
        initialPriceGross,
        monthlyCostGross,
        initialPriceVAT,
        monthlyCostVAT,
        totalPriceGross
    }
};

export function priceCheckedForFreeValue(price: number): number {
    return price === -100 || price === -1 ? 0 : price;

}

export function priceIncludingVat(price: number, vatPercentage: number): number {
    return price + (price * vatPercentage);
}

export function priceExcludingVat(price: number, vatPercentage: number): number {
    return price / (1.0 + vatPercentage);
}