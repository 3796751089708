import React from 'react';
import { render } from 'react-dom';
import { Router, Route, IndexRoute, browserHistory, IndexRedirect } from 'react-router';
import { createStore, applyMiddleware, compose } from 'redux';
import { Provider } from 'react-redux';
import createSagaMiddleware from 'redux-saga';
import reducers from './reducers';
import sagas from './sagas';
import RouteNotFound from './route-not-found/route-not-found';
import App from './app/app';
import { RightSideContainerState, setNewActiveView, ViewState } from './app/reducer';
import ProjectsContainer from './projects/projects-container';
import RisksContainer from './risk/risks-container';
import NewProjectContainer from './new-project/new-project-container';
import CopyProjectContainer from './new-project/copy-project-container';
import EditProjectContainer from './new-project/edit-project-container';
import SurveyContainer from './survey/survey-container';
import SolutionAssistantContainer from './solution-assistant/solution-assistant-container';
import ProposalContainer from './proposal/proposal.container';
import ProjectContainer from './projects/project-container';
import CalculationContainer from './calculation/calculation-container';
import ProjectDetails from "./projects/project-details";
import Settings from './settings/settings-container';
import './index.css';
import './button.css';
import { checkAuthorization } from './auth/auth-helper';

const sagaMiddleware = createSagaMiddleware();
let redux_devtools_composer = null;

if (process.env.NODE_ENV === 'development') {
    const devToolsExtension = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__

    if (typeof devToolsExtension === 'function') {
        redux_devtools_composer = devToolsExtension;
    }
}

const composeEnhancers = redux_devtools_composer || compose;
const store = createStore(reducers, composeEnhancers(
    applyMiddleware(sagaMiddleware)
));

sagaMiddleware.run(sagas);
const rootEl = document.getElementById('root');

render(
    <Provider store={store}>
        <Router history={browserHistory}>
            <Route path="/" component={App} onEnter={checkAuthorization}>
                <Route>
                    <IndexRoute component={ProjectsContainer} />
                    <Route path="project/new" component={NewProjectContainer} />
                    <Route path="/project/copy/:id" component={CopyProjectContainer} />
                    <Route path="/project/:id" component={ProjectContainer} >
                        <IndexRedirect to="/project/:id/risk" />
                        <Route path="/project/:id/details" component={ProjectDetails} />
                        <Route path="/project/:id/risk" component={RisksContainer} />
                        <Route path="/project/:id/proposal" component={ProposalContainer} />
                        <Route path="/project/:id/contracts(/:contract_id)" component={ProposalContainer} />
                        <Route path="/project/:id/survey*" component={SurveyContainer} />
                        <Route path="/project/:id/solutionAssistant" component={SolutionAssistantContainer} />
                        <Route path="/project/:id/edit" component={EditProjectContainer} />
                        <Route path="/project/:id/calculation" component={CalculationContainer} />
                    </Route>
                    <Route path="settings" component={Settings} onEnter={() => setNewActiveView(store.dispatch, { activeView: ViewState.None, rightSideContainerState: RightSideContainerState.None })} />
                </Route>
                <Route path="*" component={RouteNotFound} />
            </Route>
        </Router>
    </Provider>,
    rootEl
);
