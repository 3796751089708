import React from 'react'

const Survey = ({fillColor}) => {
  return (
    <svg style={{margin: 'auto'}} xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
      <title>Survey</title>
      <path fill={fillColor || '#000000'} id="a"
            d="M12 18.18a.81.81 0 0 0-.57 1.374c.151.15.357.237.57.237.213 0 .42-.087.57-.237A.81.81 0 0 0 12 18.18m2.922-7.958c.653.736.962 1.763.835 2.818-.238 1.982-1.688 2.688-2.977 2.688a.965.965 0 0 0-.112.004v.5a.826.826 0 0 1-1.652 0v-.664c0-.68.284-1.491 1.764-1.491.798 0 1.225-.415 1.324-1.234.033-.277.059-.982-.424-1.526-.392-.442-1.028-.666-1.892-.666-1.868 0-1.922 1.208-1.922 1.345a.826.826 0 0 1-1.651 0c0-.833.642-2.996 3.573-2.996 1.69 0 2.64.664 3.134 1.222zm6.212-7.663c.476 0 .866.397.866.882v19.677c0 .485-.39.882-.866.882H2.866A.877.877 0 0 1 2 23.118V3.44c0-.485.39-.882.866-.882h2.19c.475 0 .865.397.865.882s-.39.883-.866.883H3.732v17.911h16.54V4.324h-4.647a.877.877 0 0 1-.866-.883V1.765H9.25v2.877h4.484c.477 0 .866.397.866.883 0 .485-.39.882-.866.882h-5.35a.877.877 0 0 1-.866-.882V.882c0-.485.39-.882.866-.882h7.236c.477 0 .866.397.866.882V2.56h4.648z"/>
    </svg>
  )
}

export default Survey;
