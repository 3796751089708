import React from 'react'

const Contract = ({fillColor}) => {
  return (
    <svg style={{margin: '0 auto'}} xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
      <title>Template</title>
      <path fill={fillColor} id="a"
            d="M2 23.984a2 2 0 0 1-2-1.999V2A2 2 0 0 1 2 0h9.397L9.206 1.999H2v19.986h20v-10.8l2-1.998v12.798a2 2 0 0 1-2 1.999H2zm13.781-20.65l-.702-.667-6.06 5.916a1 1 0 0 1-1.397-1.431L14.37.564a1 1 0 0 1 1.386-.01l1.447 1.37 1.36-1.347a1.963 1.963 0 0 1 2.78 0l2.08 2.089a1.969 1.969 0 0 1 0 2.786l-13.41 13.551-4.99.014-.01-5.01L15.78 3.333zm-5.769 13.67h-.514L22.082 4.218l-2.16-2.26-12.91 12.578v-.528 2.998h3z"/>
    </svg>
  )
}

export default Contract;
