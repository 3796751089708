import watchUserRequests from '../user/saga';
import {watchProjectsRequests} from '../projects/saga';
import watchAttachmentsRequests from '../attachment/saga';
import watchProducts from '../products/saga';
import watchRisksRequests from '../risk/saga';
import watchScenarioRequests from '../scenario/saga';
import watchOfferLetterTemplates from '../proposal/saga';
import watchAdvancedRisk from '../survey/saga';

export default function* root() {
  yield [
    watchUserRequests(),
    watchProjectsRequests(),
    // watchNewProjectRequests(),
    watchAttachmentsRequests(),
    watchProducts(),
    watchRisksRequests(),
    // watchCategoriesRequests(),
    watchScenarioRequests(),
    watchOfferLetterTemplates(),
    watchAdvancedRisk()
  ];
}
