
import * as riskActions from "./actions";
import * as areaActions from "./../area/actions";

const initialState = {
    areas: [],
    projectRisks: [],
    isFetching: false
};

export default function risks(state = initialState, action) {
    switch (action.type) {
        case riskActions.GET_RISKS_REQUESTED:
            return { ...state, isFetching: true };
        case riskActions.GET_RISKS_SUCCEEDED:
            return { ...state, isFetching: false, ...action.payload.risks[0] };
        case riskActions.GET_SEGMENT_RISKS_SUCCEEDED:
          return {... state, segmentRisks: action.payload.risks };
        case riskActions.GET_PROJECT_RISKS_SUCCEEDED:
            return { ...state, ...action.payload };
        case riskActions.ADD_RISKS_SUCCEEDED:
            return {
                ...state,
                projectRisks: [...state.projectRisks, action.payload.addedRisk]
            };
        case riskActions.REMOVE_RISKS_SUCCEEDED: {
            let notDeletedRisks = state.projectRisks.filter(risk => risk.id !== action.payload.deletedRisk.id);
            return { ...state, projectRisks: notDeletedRisks };
        }
        case riskActions.EDIT_RISKS_SUCCEEDED: {
            let unchangedProjectRisks = state.projectRisks.filter(risk => risk.id !== action.payload.changedRisk.id);
            let newProjectRisks = [...unchangedProjectRisks, action.payload.changedRisk];
            newProjectRisks = newProjectRisks.sort((a, b) => {
                return a.id > b.id;
            });
            return {
                ...state,
                projectRisks: newProjectRisks
            };
        }
        case riskActions.ADD_PROJECT_AREAS_SUCCEEDED: {
            let projectAreas = [...state.projectAreas, action.payload.projectArea];
            return { ...state, projectAreas }
        }
        case riskActions.REMOVE_PROJECT_AREAS_SUCCEEDED: {
            let projectAreas = state.projectAreas.filter(area => area.id !== action.payload.projectArea.id);
            return { ...state, projectAreas }
        }
        case riskActions.GET_PROJECT_AREAS_SUCCEEDED:
            return { ...state, ...action.payload };
        case riskActions.GET_SCENARIOS_SUCCEEDED:
            return { ...state, ...action.payload };
        case riskActions.GET_SOLUTIONS_SUCCEEDED:
            return { ...state, ...action.payload };
        case riskActions.GET_PROJECT_IMAGES_SUCCEEDED:
            return { ...state, ...action.payload };
        case riskActions.SET_ACTIVE_AREA:
            return {...state, ...action.payload };
        case riskActions.CREATE_CUSTOM_SCENARIO_SUCCEEDED: {
          let projectRisk = state.projectRisks.filter(risk => risk.id === action.payload.riskId);
          if (projectRisk.length < 1) return {...state};
          state.projectRisks.scenarios.push(projectRisk[0]);
          return {...state, projectRisks: [...state.projectRisks.scenarios]};
        }
        case areaActions.ADD_CUSTOM_AREA:
            return { ...state, 
                areas: [...state.areas, {
                    _area_name_local: action.payload.name,
                    area_id: action.payload.area_id,
                    area_name: action.payload.name,
                    area_name_local: action.payload.name,
                    segment_name: '',
                    segment_name_local: null,
                    groups: []
                }]
            };
        case areaActions.CREATE_CUSTOM_AREA_FAILED:
            return { ...state, 
                createCustomAreaState: {
                    ...action.payload,
                    error: true
                }
            };
        case areaActions.CREATE_CUSTOM_AREA_SUCCEEDED:
            return { ...state,
                createCustomAreaState: {
                    error: false
                }
            };
        case riskActions.GET_SEGMENT_DEFINITION_SUCCEEDED:
          return {... state, segmentDefinition: action.payload.definitions };
        default:
            return state;
    }
}
