import actionCreatorFactory from 'typescript-fsa';
import { IProduct } from '../calculation/product';

const actionCreator = actionCreatorFactory();

export const getProductsActions = actionCreator.async<any, { products: IProduct[] }, any>('GET_PRODUCTS');
export const getCategoriesActions = actionCreator.async<{ country_id: number }, { categories: any[] }>('GET_CATEGORIES');
export const getProjectProductsActions = actionCreator.async<{ project_id: number }, { projectProducts: IProduct[] }>('GET_PRODUCTS_FOR_PROJECT');
export const addProductActions = actionCreator
    .async<{ productLink: any },
        { product: IProduct }>
    ('ADD_PRODUCT');
export const deleteProductActions = actionCreator.async<{ projectProduct: object }, { project_id: number, project_product_id: number }>('DELETE_PRODUCT');
export const editProductActions = actionCreator.async<{ product: IProduct }, { product: IProduct }>('EDIT_PRODUCT');
export const updateProjectProductsOrderActions = actionCreator.async<{ product_ids: number[] }, { projectProducts: IProduct[]}>('UPDATE_PROJECT_PRODUCTS_ORDER');
