import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';

import './tab.css';

const Tab = ({title, index, active, onIndexUpdated}) =>
  <a
    onClick={(e) => onIndexUpdated(e, index)}
    className={classNames("tab", {"tab--not-active": !active, "tab--second": index > 0})}>
    {title}
  </a>

Tab.propTypes = {
  title: PropTypes.string.isRequired,
  index: PropTypes.number.isRequired,
  active: PropTypes.bool.isRequired,
  onIndexUpdated: PropTypes.func.isRequired
}

export default Tab;
