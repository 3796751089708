import * as React from 'react';

import './new-project.css';
import NewProjectForm from './new-project-form';
import Camera from './camera';

const NewProject = (props) => {
    return (
        <div className="new-project">
            <div className="new-project__image-container">
                <Camera
                    imageUrl={props.imageUrl}
                    postAttachment={props.didSelectImage}/>
            </div>
            <div className="new-project__form-container">
                <NewProjectForm {...props} />
            </div>
        </div>
    );
}

export default NewProject;
