import React from 'react'
import PropTypes from 'prop-types';

import t from '../i18n';
import Spinner from '../spinner/spinner';
import ProductsListItem from './product-list-item';


const SearchResults = (props) => {

    function findProjectProduct(projectProducts, product) {
        if (!projectProducts) return product;
        projectProducts = projectProducts.filter(projectProduct => projectProduct.product_id == product.product_id);
        if (projectProducts && projectProducts.length) {
            return projectProducts[0];
        }
        return product;
    }

    return (
        <div>
            {
                props.isFetching && <Spinner/>
            }

            {
                (props.searchValue.length > 2 || props.filterCategoryId) &&
                <ul className="list">
                    {
                        props.filteredProducts.map((product, index) => 
                            <ProductsListItem
                                key={index}
                                product={findProjectProduct(props.projectProducts, product)}
                                addProductListener={props.handleAddProduct}
                                adjustAmountListener={props.handleAmountListener}
                                deleteProductListener={props.handleDeleteProduct}/>

                        )
                    }
                </ul>
            }
            {
                props.searchValue.length > 2 && !props.isFetching && props.filteredProducts.length === 0 &&
                <h4 className="h4">{t('web.productSearch.zeroResults')}</h4>
            }
        </div>
    );
}

SearchResults.propTypes = {
    products: PropTypes.array,
    projectProducts: PropTypes.array,
    isFetching: PropTypes.bool,
    searchValue: PropTypes.string,
    filterCategoryId: PropTypes.number,
    filteredProducts: PropTypes.array,
    handleAddProduct: PropTypes.func,
    handleDeleteProduct: PropTypes.func,
    adjustAmountListener: PropTypes.func
}


export default SearchResults
