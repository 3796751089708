import * as React from 'react'
import List from "../common/list/list";

class CategoryItem extends React.Component<{item: any, key: number | string, isActive: boolean, hideChildren?: boolean, onClickHandler}> {
    render () {
        const p = this.props;
        const className = p.isActive ? "category-item toggled" : "category-item"
        return this.props.hideChildren ?
            null :
            <li className={className} key={p.item.key} ><h4 onClick={() => p.onClickHandler()}>{p.item.category ? p.item.category.name : 'No category'}</h4>{p.children}</li>
    }
}

interface IMediafilesListProps {
    attachedMediafiles: any[];
    mediafiles: any[];
    addMediafileHandler: Function;
    deleteMediafileHandler: Function;
}

export class MediafilesList extends React.Component<IMediafilesListProps> {
    state = {
        search: '',
        categoriesMap: [],
        openedCategories: []
    };

    constructor(props){
        super(props);

        this.updateSearch = this.updateSearch.bind(this);
        this._filter = this._filter.bind(this);
    }

    componentDidMount(){
        this.setState({categoriesMap: this._createMap(this.props.mediafiles, '')});
    }

    render() {
        const templates = this.state.categoriesMap || [];
        
        return (
            <div>
                <div className="search-container">
                    <input type="text" onChange={this.updateSearch} placeholder="Search..."/>
                </div>

                <List array={templates} itemTemplate={(item, key) => {
                    const matchedMediafileId = this.props.mediafiles.find(mediafile => mediafile.mediafile_template_id === item.mediafile_id);
                    return this._createCategory(item, key);
                }} />
            </div>
        );
    }

    private _createCategory(item: any, key: number, parentKey?: number | string): any{
        const pathKey = parentKey === undefined ? key.toString() : parentKey+''+key;
        const hide = item.fileCount === 0;
        

        return <CategoryItem item={item} key={pathKey} hideChildren={hide} isActive={item.isOpen || item.isFiltering}
                    onClickHandler={item.isOpen ? 
                        () => this.closeCategory(item.category.name) : 
                        () => this.openCategory(item.category.name)}>

                {/* List sub categories */}
                { (item.subCategories && item.subCategories.length > 0) && (item.isOpen || item.isFiltering) &&
                    <ul>{item.subCategories.map((subcategory, subKey) => this._createCategory(subcategory, subKey, pathKey))}</ul>
                }

                {/* List all top level files */}
                { (item.mediaFiles.length > 0) && (item.isOpen || item.isFiltering) &&
                    <ul>
                        {item.mediaFiles.map((file, key) => {
                            const exists = this.props.attachedMediafiles.findIndex(item => item.id === file.id) !== -1;
                            return <li key={key} 
                                className={exists ? 'active' : ''}
                                onClick={() => exists ? this.props.deleteMediafileHandler(file) : this.props.addMediafileHandler(file)}
                                >{file.name}<span className="filetype">{file.type}</span></li>
                        }
                        )}
                    </ul>
                }

                { !item.subCategories && !item.mediaFiles.length && hide &&
                    <p>No files</p>
                }
        </CategoryItem>
    }

    private _createCategoryMapItem(item, key, filter, parentKey = '', reportFileCountToParent?): object {

        const files = item.mediaFiles.filter(file => this._filter(file, filter));
        const isOpened = this.state.openedCategories.includes(item.category ? item.category.name : '');

        // call parent with our mediafiles count
        if (reportFileCountToParent) reportFileCountToParent(files.length);

        let categoryMap = {
            category: item.category,
            mediaFiles: files,
            isFiltering: filter !== '',
            fileCount: files.length,
            isOpen: isOpened,
            key: parentKey+key.toString(),
            subCategories: []
        };

        // Add childs filecount to this filecount
        const childFileCount = (file_count) => {
            categoryMap.fileCount += file_count;
            if (reportFileCountToParent) reportFileCountToParent(file_count);
        };
        
        categoryMap.subCategories = item.subCategories ? 
            item.subCategories.map((subCategory, subKey) => this._createCategoryMapItem(subCategory, subKey, filter, key, childFileCount)) :
            null;

        return categoryMap;
    }

    private _createMap(filesArray, filter): Array<any> {
        return filesArray.map((item, key) => this._createCategoryMapItem(item, key, filter));
    }

    private _filter(item: any, filterValue: string): boolean {
        return filterValue === '' ? true : item.name.toLowerCase().includes(filterValue.toLowerCase())
    }

    private updateMap(filterValue?) {
        const newMap = this._createMap(this.props.mediafiles, filterValue ? filterValue : '');
        this.setState({categoriesMap: newMap});
    }

    private updateSearch(event: any) {
        this.setState({search: event.target.value});
        this.updateMap(event.target.value);
    }

    private openCategory(name) {
        if (!this.state.openedCategories.includes(name)) {
            this.setState({openedCategories: [...this.state.openedCategories, name]}, () => this.updateMap())
        }
    }

    private closeCategory(name) {
        const index = this.state.openedCategories.indexOf(name);
        if (index !== -1) {
            this.setState({openCategories: [...this.state.openedCategories.splice(index)]})
            this.updateMap();
        }
    }
}

export default MediafilesList;
