// @ts-nocheck
// TODO remove above
import * as React from 'react';

import Tab from '../tabs/tab';
import './areas.css';
import Scrollbars from 'react-custom-scrollbars-2';
import { Checkbox } from '../input/standard-input';
import plus from '../img/plus.svg';
import arrow from '../img/arrow.svg';
import t, { d, makeIdentifier } from '../i18n';
import add_area from '../img/risk/add-area.svg';

const EditAreaBody = ({ areas, projectAreas, toggleAreaHandler }) => {
    return (
        <Scrollbars
            style={{ borderRadius: '0.2rem' }}
            autoHeight={true}
            autoHeightMax={300}
        >
            <ul className="new-risk list">
                {areas.map((area) => {
                    let projectArea = projectAreas.filter(
                        (parea) => area.area_id === parea.area_id
                    );
                    let checked = projectArea.length > 0;
                    return (
                        <li className="new-area__list-item" key={area.area_id}>
                            <Checkbox
                                label={area.area_name_local}
                                checked={checked}
                                onChange={(e) => toggleAreaHandler(e, area)}
                            />
                        </li>
                    );
                })}
            </ul>
        </Scrollbars>
    );
};

class Areas extends React.Component<any, IAreasState> {
    toggleAddAreaDialog = () => {
        this.setState({
            ...this.state,
            addAreaDialogIsOpen: !this.state.addAreaDialogIsOpen,
        });
    };
    private navigationRightMargin: number = 0;
    private navigate = (isRight: boolean): void => {
        let newMargin =
            this.state.navigationRightMargin + (isRight ? 100 : -100);
        let tabRef = this.tabContainer;
        let tabRefRect = tabRef.getBoundingClientRect();

        let lastListItem = tabRef.children[tabRef.children.length - 1];
        let lastListItemRect = lastListItem.getBoundingClientRect();

        if (newMargin < 0) {
            newMargin = 0;
        } else if (lastListItemRect.right < tabRefRect.right + 0 && isRight) {
            return;
        }

        this.setState({ navigationRightMargin: newMargin });
    };
    private tabContainer: any;

    constructor(props) {
        super(props);
        this.state = {
            addAreaDialogIsOpen: false,
            navigationRightMargin: 0,
        };
    }

    componentDidMount(): void {
        this.setState({ navigationRightMargin: 0 });
    }

    render() {
        let {
            areas,
            projectAreas,
            activeAreaId,
            changeAreaHandler,
            toggleAreaHandler,
        } = this.props;
        let visibleAreas = [];

        // using a try/catch here because render would sometimes fail due to redux-saga
        try {
            visibleAreas = areas.filter((area) => {
                let projectArea = projectAreas.filter(
                    (parea) => parea.area_id === area.area_id
                );
                return projectArea.length > 0;
            });
        } catch (e) {
            return null;
        }
        let buttonStyle: object = {
            marginLeft: 10,
            padding: '2px',
            verticalAlign: 'middle',
        };

        return (
            <div
                className="areas"
                style={this.props.isActive ? {} : { pointerEvents: 'none' }}
            >
                {visibleAreas.length > 0 && (
                    <div className="areas__inside-container">
                        <a
                            href="javascript:void(0);"
                            onClick={() => {
                                this.navigate(false);
                            }}
                            className="navigation navigation-left"
                        >
                            <img src={arrow} alt="arrow" />
                        </a>
                        <div className="tab-container-container">
                            <div
                                className="tab-container"
                                style={{
                                    transition: 'margin-left 0.3s',
                                    marginLeft:
                                        '-' +
                                        this.state.navigationRightMargin +
                                        'px',
                                }}
                                ref={(ref) => {
                                    this.tabContainer = ref;
                                }}
                            >
                                {visibleAreas.map((area, index) => (
                                    <Tab
                                        key={index}
                                        index={index}
                                        onIndexUpdated={(e) =>
                                            this.handleAreaClick(e, area)
                                        }
                                        active={area.area_id === activeAreaId}
                                        title={d(
                                            'risk.' +
                                                makeIdentifier(area.segment_name),
                                            area.area_name
                                        )}
                                    />
                                ))}
                                <input
                                    type="image"
                                    src={add_area}
                                    onClick={this.props.toggleEditAreas}
                                    style={buttonStyle}
                                />
                                <span
                                    style={{
                                        fontSize: '1.2rem',
                                        marginLeft: '0.5em',
                                    }}
                                >
                                    {t('web.risk.addNewArea')}
                                </span>
                            </div>
                        </div>
                        <a
                            href="javascript:void(0);"
                            onClick={() => {
                                this.navigate(true);
                            }}
                            className="navigation navigation-right"
                        >
                            <img src={arrow} alt="arrow" />
                        </a>
                    </div>
                )}
            </div>
        );
    }

    private handleAreaClick(e, area): void {
        let domRef = e.target.getBoundingClientRect();
        let tabRef = this.tabContainer.getBoundingClientRect();
        let newMargin = this.state.navigationRightMargin;

        if (domRef.left < 100) {
            newMargin = newMargin - (70 - domRef.left);
        } else if (domRef.right > tabRef.right) {
            newMargin = newMargin + (domRef.right - tabRef.right) - 70;
        }

        this.setState({ navigationRightMargin: newMargin });
        this.props.changeAreaHandler(area.area_id);
    }
}

export default Areas;
