import {all, call, put, takeEvery,} from 'redux-saga/effects';
import * as scenarioActions from "./actions";
import {GET_PROJECT_RISKS_REQUESTED} from "../risk/actions";
import {
  addRiskScenario,
  addRiskSolution,
  deleteRiskScenario,
  deleteRiskSolution,
  fetchRiskScenarios,
  fetchRiskSolutions,
  updateRiskScenario
} from '../services/api';

function* getScenarioRequested(action) {
  try {
    let allScenarios = fetchRiskScenarios();
    yield put({type: scenarioActions.FETCH_SCENARIO_SUCCEEDED, scenario: [], allScenarios: allScenarios});
  } catch (e) {

  }
}

// function* getRiskScenarios() {
//     try {
//         let scenarios = yield fetchRiskScenarios();
//         yield put({ type: scenarioActions.GET_SCENARIOS_SUCCEEDED, payload: { scenarios } });
//     } catch (e) {

//     }
// }

function* addRiskScenarioRequested(action) {
  try {
    yield call(addRiskScenario, action.payload.projectId, action.payload.riskId, action.payload.scenario);
    yield put({type: GET_PROJECT_RISKS_REQUESTED, payload: {project_id: action.payload.projectId}});
  } catch (e) {

  }
}

function* updateRiskScenarioRequested(action) {
  try {
    yield call(updateRiskScenario, action.payload.projectId, action.payload.scenario);
    yield put({type: GET_PROJECT_RISKS_REQUESTED, payload: {project_id: action.payload.projectId}});
  } catch (e) {

  }
}

function* deleteRiskScenarioRequested(action) {
  try {
    let params = action.payload;
    yield all(params.solutions.reverse().map(solution => {
      return call(deleteRiskSolution, params.projectId, params.riskId, params.scenarioId, solution.id);
    }));
    
    yield deleteRiskScenario(params.projectId, params.riskId, params.scenarioId);
    yield put({type: GET_PROJECT_RISKS_REQUESTED, payload: {project_id: action.payload.projectId}});
  } catch (e) {
    
  }
}

function* addRiskSolutionRequested(action) {
  try {
    yield call(addRiskSolution, action.payload.projectId, action.payload.riskId, action.payload.scenarioId, action.payload.solution);
    yield put({type: GET_PROJECT_RISKS_REQUESTED, payload: {project_id: action.payload.projectId}});
  } catch (e) {

  }
}

function* deleteRiskSolutionRequested(action) {
  try {
    let params = action.payload;
    yield deleteRiskSolution(params.projectId, params.riskId, params.scenarioId, params.solutionId);
    yield put({type: GET_PROJECT_RISKS_REQUESTED, payload: {project_id: action.payload.projectId}});
  } catch (e) {

  }
}

export default function* root() {
  yield [
    takeEvery(scenarioActions.FETCH_SCENARIO_REQUESTED, getScenarioRequested),
    takeEvery(scenarioActions.ADD_RISK_SCENARIO_REQUESTED, addRiskScenarioRequested),
    takeEvery(scenarioActions.REMOVE_RISK_SCENARIO_REQUESTED, deleteRiskScenarioRequested),
    takeEvery(scenarioActions.ADD_RISK_SOLUTION_REQUESTED, addRiskSolutionRequested),
    takeEvery(scenarioActions.REMOVE_RISK_SOLUTION_REQUESTED, deleteRiskSolutionRequested),
    takeEvery(scenarioActions.UPDATE_RISK_SCENARIO_REQUESTED, updateRiskScenarioRequested),
  ]
}
