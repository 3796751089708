import React, { Component } from 'react';
import t from '../../i18n';
import addScenario from '../../img/add-scenario.svg';

export default class EditRiskOverlayMessage extends Component<any, any> {
    public render() {
        return (
            <div className="message">
                <img src={addScenario}/>
                {t('web.risk.missingScenario')}
            </div>
        );
    }
}