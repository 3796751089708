import AuthenticationContext from 'adal-angular';

const config = {
  instance: 'https://login.microsoftonline.com/',
  tenant: 'securitas.net',
  clientId: process.env.REACT_APP_CLIENT_ID_AUTH,
  redirectUri: window.location.origin,
  postLogoutRedirectUri: window.location.origin,
  cacheLocation: 'localStorage', // enable this for IE, as sessionStorage does not work for localhost.
  requireADLogin: true,
  endpoints: {
    sstapiUrl: process.env.REACT_APP_ENDPOINT_ID
  }
};

window.AuthenticationContext = AuthenticationContext;
const authenticationContext = new AuthenticationContext(config);

export const getAccessToken = () => {
  return new Promise((resolve, reject) => {
    authenticationContext.acquireToken(config.endpoints.sstapiUrl, function (error, token) {
      if (error) {
        console.error(error);
        authenticationContext.login();
        return reject(error);
      }

      return resolve(token);
    });
  })
}

export const checkAuthorization = () => {
  authenticationContext.handleWindowCallback();

  if (authenticationContext.isCallback(window.location.hash) && !authenticationContext.getLoginError()) {
    window.location = authenticationContext._getItem(authenticationContext.CONSTANTS.STORAGE.LOGIN_REQUEST);
    return;
  }

  if (!authenticationContext.getCachedUser()) {
    authenticationContext.login();
    return;
  }
}

export const authorizeUser = () => {
  authenticationContext.login();
}

export const signOut = () => {
  authenticationContext.logOut();
}

export const getAuthorizedUser = () => {
  return authenticationContext.getCachedUser();
}
