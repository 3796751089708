import { call, put, select, takeEvery, takeLatest } from 'redux-saga/effects';
import { getUserActions, updateUserActions } from './actions';
import { getAuthorizedUser } from '../auth/auth-helper';
import { fetchCurrentSignedInUserInfo, updateUserInfo } from '../services/api';
import { ADD_ERROR, SHOW_SPINNER, REMOVE_SPINNER } from '../app/actions';
import t from '../i18n';
import { Action, Success } from 'typescript-fsa';

function getFsaSuccessPayload<S, P=string>(payload: S, params?: P): Success<any, S> {
    return {
        params: params || "",
        result: payload
    }
}

function* getUserProfileStarted(action) {
    try {
        yield put({ type: SHOW_SPINNER });
        let currentUser = yield select((state: any) => state.user.user);
        if (!currentUser) {
            const userProfile = getAuthorizedUser();
            if (userProfile && userProfile.profile) {
                const apiUser = yield call(fetchCurrentSignedInUserInfo, userProfile.userName);
                let payload = getFsaSuccessPayload({ user: { adProfile: userProfile.profile, ...apiUser[0] } });
                yield put(getUserActions.done(payload));
            }
        }
        yield put({ type: REMOVE_SPINNER });
    } catch (e) {
        yield put({
            type: ADD_ERROR, payload: {
                title: t('web.common.errorTitleGeneric'),
                message: t('Something unexpected happened. Please try to refresh')
            }
        });
    }
}

function* updateUserStarted(action: Action<any>) {
    let user = yield call(updateUserInfo, action.payload.user);
    user.language = action.payload.user.language; //bug in api. Returns old value
    let payload = getFsaSuccessPayload({ user });
    yield put(updateUserActions.done(payload));
}

export default function* root() {
    yield [
        takeLatest(getUserActions.started, getUserProfileStarted),
        takeEvery(updateUserActions.started, updateUserStarted)
    ]
}
