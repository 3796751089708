import React, { Component } from 'react';
import { connect } from "react-redux";
import { SET_ACTIVE_AREA } from '../actions';
import { IRisk } from '../risk';
import { d } from '../../i18n';

interface IButtonProps {
    risk: IRisk;
    toggleEditRisks: (risk_id: number) => void;
    dispatchAction: (action: object) => void;
    segment: string;
}

class Button extends Component<IButtonProps, any> {

    onClick(e) {

        this.props.dispatchAction({
            type: SET_ACTIVE_AREA,
            payload: {
                activeAreaId: this.props.risk.area_id
            }
        });

        this.props.toggleEditRisks(this.props.risk.id);

        e.target.blur();
    }

    public render(): JSX.Element {
        return (
            <button className="btn btn--dark-gray" style={{ marginRight: "1em" }} onClick={(e) => {
                this.onClick(e)
            }}>{d("risk." + this.props.segment, this.props.risk.area_name)}</button>
        );
    }
}

const mapDispatchToProps = (dispatch) => ({
    dispatchAction: (action) => dispatch(action)
});

export default connect(null, mapDispatchToProps)(Button as any);