// @ts-nocheck
// TODO remove above
import * as React from 'react';
import Spinner from '../../spinner/spinner';

import "./page.css";
import { connect } from 'react-redux';
import { getProjectById } from '../../projects/reducer';

const Page = ({ title, children, project = null, includePadding = true, showSpinner = false }) => {
    let className = includePadding ? "page__default-container" : "page__nopadding-container"
    if (showSpinner) {
        children = <Spinner/>
    }
    return (
        <div className="page">
            <h1 className="page__title">
                {title}
                {project && <span className="page__project_name">{project.name}</span>}
            </h1>
            <div className={className}>
                {children}
            </div>
        </div>
    )
}

const mapStateToProps = ({ projects, routeParams }) => {
    // Depends on the structure of the URL, might fail in the future
    // TODO: Should refactor to make use of routeParams 
    const href = window.location.href.split('/');
    const idIndex = 4;
    const project = getProjectById(projects, href[idIndex]);
    return { project };
};

export default connect(mapStateToProps)(Page) as React.FC<any>;
