import * as React from 'react'
import List from "../common/list/list";

export class ContractsList extends React.Component<{ templates, contracts, addContractHandler, deleteContractHandler }> {
    state = {
        search: ''
    };

    constructor(props){
        super(props);

        this._updateSearch = this._updateSearch.bind(this);
    }

    private _updateSearch(event: any) {
        this.setState({search: event.target.value});
    }

    render() {
        const templates = this.props.templates || [];
        let uniqueTemplates = [];

        return (
            <div>
                <div className="search-container">
                    <input type="text" onChange={this._updateSearch} placeholder="Search..."/>
                </div>
                <List array={templates} itemTemplate={(item, key) => {
                        if (uniqueTemplates.includes(item.contract_id)) {
                            return null;
                        }
                        uniqueTemplates.push(item.contract_id);
                        const matchedContractId = this.props.contracts.find(contract => contract.contract_template_id === item.contract_id);
                        return (
                            <li
                                onClick={() => matchedContractId ?
                                    this.props.deleteContractHandler(matchedContractId) :
                                    this.props.addContractHandler(item)}
                                className={matchedContractId ? 'active' : null}
                                key={key}>
                                {item.name}
                                <div className="icon icon-check"/>
                            </li>
                        )}}
                    filter={item => this.state.search === '' ? true : item.name.toLowerCase().includes(this.state.search.toLowerCase())}
                />
            </div>
        );
    }
}

export default ContractsList;
