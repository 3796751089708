// @ts-nocheck
// TODO remove above
import React from 'react'
import {Link} from "react-router";
import classNames from "classnames";
import './bottom-menu-button.css';

const BottomMenuButton = ({isActive, isDisabled, tabTitle, IconElement, moduleLink}) => {
    const activeColor= "#8D5FFF";
    const notActiveColor= "#031F30";
    const notAvailableColor= "#b8b7b8";

    const color = isActive ? activeColor : isDisabled ? notAvailableColor : notActiveColor

    return (
    <Link className={classNames('bottom-menu__tabs', { 'bottom-menu__disabled-link': isDisabled })}
          to={`${moduleLink}`}>
        <IconElement fillColor={color}></IconElement>
        <span className={'bottom-menu__tab-text'} style={{color: color}}
        >{tabTitle}</span>
    </Link>
    )
}

export default BottomMenuButton;
