import { getUserActions, updateUserActions } from './actions';
import { isType } from "typescript-fsa";

const initialState = {
    user: null
};

export default function user(state = initialState, action) {
    if (isType(action, getUserActions.done)) {
        return { ...state, user: action.payload.result.user };
    }
    if (isType(action, updateUserActions.done)) {
        let updatedUser = { ...state.user, ...action.payload.result.user };
        return { ...state, user: updatedUser };
    }
    return state;
}
