// @ts-nocheck
// TODO remove above
import React, { Component } from 'react'
import { connect } from 'react-redux';

import { dispatchDeleteAction, dispatchEditAction, dispatchUpdateProjectProductsOrderAction } from '../products/saga';
import Calculation, { IProject } from './calculation';
import EditCalculation from './calculation-edit';
import { getProjectProductsActions } from '../products/actions';
import { getProjectProducts } from '../products/reducer';
import Page from '../common/page/page';
import t from '../i18n';
import { getCurrency } from '../utils/utils';
import { Product } from "./base-product";
import { convertProductToObject } from "./calculator";

interface ICalculationContainerProps {
    project: IProject;
    toggleWrapInScrollview: any;
    dispatchAction: any;
    user: any;
    params: any;
    editProducts: boolean;
}

interface ICalculationContainerState {
    currency: string;
    wrapInScrollView: boolean;
    productsLoaded: boolean;
}

class CalculationContainer extends Component<ICalculationContainerProps, ICalculationContainerState> {

    state = {
        productsLoaded: false,
        wrapInScrollView: false,
        currency: "",
    }
    handleToggleEditProducts = () => {
        this.setState({ wrapInScrollView: !this.state.wrapInScrollView });
    }
    handleEditProduct = (product) => {
        dispatchEditAction(
            this.props.dispatchAction,
            product
        );
    }
    handleDeleteProduct = (productId, projectProductId) => {
        dispatchDeleteAction(
            this.props.dispatchAction,
            this.props.params.id,
            productId,
            projectProductId
        );
    }
    handleUpdateProductOrder = (orderedProducts) => {
        let product_ids = orderedProducts.map(product => { return product.data.project_product_id });
        dispatchUpdateProjectProductsOrderAction(
            this.props.dispatchAction,
            this.props.params.id,
            product_ids
        );
    }

    componentWillReceiveProps(nextProps: ICalculationContainerProps) {
    }

    componentDidMount() {
        this.props.dispatchAction(getProjectProductsActions.started({ project_id: this.props.params.id }));
        let countryName = this.props.user.country.name;
        let currency = getCurrency(countryName);
        this.setState({ currency: currency });
    }

    render() {
        if (!this.state.wrapInScrollView) {
            return (
                <Page title={t('web.calculation.calculation')}>
                    <Calculation
                        project={this.props.project}
                        currency={this.state.currency}
                        toggleEditProducts={this.handleToggleEditProducts}
                        country ={this.props.user.country.name}/>
                </Page>
            )
        }

        if (this.state.wrapInScrollView) {
            return (
                <EditCalculation
                    project={this.props.project}
                    currency={this.state.currency}
                    editProduct={this.handleEditProduct}
                    deleteProduct={this.handleDeleteProduct}
                    updateProductOrder={this.handleUpdateProductOrder}
                    closeView={this.handleToggleEditProducts}
                    toggleEditProducts={this.handleToggleEditProducts}
                    country={this.props.user.country.name}/>
            )
        }
    }
}

const mapStateToProps = ({ products, user }, ownProps) => {
    let projectProducts = getProjectProducts(products, ownProps.params.id);
    let project = { ...ownProps.project, products: projectProducts }
    return { project, user: user.user }
}

const mapDispatchToProps = (dispatch) => ({
    dispatchAction: (action) => dispatch(action)
});

export default connect(mapStateToProps, mapDispatchToProps)(CalculationContainer);
