import React from 'react';
import classNames from 'classnames';

import t from '../i18n';
import './messages.css';
import cancel from '../img/cancel.svg';

const Message = ({message, removeError, isError}) =>
  <div className={classNames("messages__row", {'messages__row--error': isError})}>
    <span className="messages__row-title">{message.title || t('web.common.errorTitleGeneric')}</span>
    <p className="messages__row-message">{message.message}</p>
    <img className="messages__row-x-button" onClick={removeError} src={cancel} alt="cancel"/>
  </div>

export default Message;
