import t from '../i18n';
import {getAccessToken} from '../auth/auth-helper';

const API_ROOT = process.env.REACT_APP_API_ROOT;

export const Method = {
  GET: 'GET',
  POST: 'POST',
  PUT: 'PUT',
  DELETE: 'DELETE'
};

const settings = (method, body, token, contentType, acceptContent) => ({
  method: method,
  headers: {
    'Content-Type': contentType ? contentType : 'application/json',
    'Accept': acceptContent ? acceptContent : 'application/json',
    'Authorization': `Bearer ${token}`
  },
  body: body
});

const NOT_AUTHORIZED = 401;

const checkHTTPStatus = (response) => {
  return new Promise((resolve, reject) => {
    if (response.status === NOT_AUTHORIZED) {
      reject({message: t('web.common.unauthorizedUser')});
    }

    if (!response.ok) {
      const status = response ? response.status : 500
      reject({status: status});
      return;
    }

    resolve(response);
  });
};

export const getJsonBody = (body) =>
  body ? JSON.stringify(body) : null;

export const callApiRaw = (endpoint, method, body, contentType, acceptContent) => {
  const url = API_ROOT + endpoint;
  const httpBody = getJsonBody(body);
  return getAccessToken().then(token => {
    return new Promise((resolve, reject) => {
      fetch(url, settings(method, httpBody, token, acceptContent))
        .then(checkHTTPStatus)
        .then(response => resolve(response.arrayBuffer()))
        .catch(error => {
          console.error(error);
          reject(error);
        })
    })
  })
}

export default function callApi(endpoint, method, body) {
  const url = API_ROOT + endpoint;
  const httpBody = getJsonBody(body);
  return getAccessToken().then(token => {
    return new Promise((resolve, reject) => {
      fetch(url, settings(method, httpBody, token))
        .then(checkHTTPStatus)
        .then(response => resolve(response.json()))
        .catch(error => {
          console.error(error);
          reject(error);
        })
    })
  })
}
