import React, { Component } from 'react';
import { connect } from 'react-redux';
import { SET_ACTIVE_AREA } from '../../actions';
import { IRisk } from '../../risk';
import t, { d } from '../../../i18n';
import classNames from 'classnames';
import getRiskRatingColor from '../../../utils/get-risk-rating-color';

interface ITableRowProps {
    risk: IRisk;
    toggleEditRisks: (risk_id: number) => void;
    dispatchAction: (action: object) => void;
    segment: string;
    selectedRisks: number[];
}

class TableRow extends Component<ITableRowProps, any> {
    onClick(e) {
        this.props.dispatchAction({
            type: SET_ACTIVE_AREA,
            payload: {
                activeAreaId: -1,
            },
        });

        this.props.dispatchAction({
            type: SET_ACTIVE_AREA,
            payload: {
                activeAreaId: this.props.risk.area_id,
            },
        });
        this.props.toggleEditRisks(this.props.risk.id);

        e.target.blur();
    }

    public render(): JSX.Element {
        const [backgroundColor, text] = getRiskRatingColor(this.props.risk);
        return (
            <tr
                onClick={(e) => {
                    this.onClick(e);
                }}
                key={this.props.risk.risk_id}
                style={{ cursor: 'pointer' }}
                className={
                    this.props.selectedRisks.includes(this.props.risk.id)
                        ? 'selected'
                        : ''
                }
            >
                <td className={classNames('level ', backgroundColor)}>
                    {text}
                </td>
                <td>{this.props.risk.group_name}</td>
                <td>{this.props.risk.area_name}</td>
            </tr>
        );
    }
}

const mapDispatchToProps = (dispatch) => ({
    dispatchAction: (action) => dispatch(action),
});

export default connect(null, mapDispatchToProps)(TableRow as any);
