export const GET_PROJECTS_REQUESTED = 'GET_PROJECTS_REQUESTED';
export const GET_PROJECTS_SUCCEEDED = 'GET_PROJECTS_SUCCEEDED';
export const CHANGE_PROJECTS_VIEW_STYLE_REQUESTED = 'CHANGE_PROJECTS_VIEW_STYLE';
export const CHANGE_PROJECTS_VIEW_STYLE_SUCCEEDED = 'CHANGE_PROJECTS_VIEW_STYLE_SUCCEEDED';
export const CLEAR_PROJECTS_REQUESTED = 'CLEAR_PROJECTS_REQUESTED';

export const ADD_NEW_PROJECT_REQUESTED = 'ADD_NEW_PROJECT_REQUESTED';
export const ADD_NEW_PROJECT_SUCCEEDED = 'ADD_NEW_PROJECT_SUCCEEDED';
export const EDIT_PROJECT_REQUESTED = 'EDIT_PROJECT_REQUESTED';
export const EDIT_PROJECT_SUCCEEDED = 'EDIT_PROJECT_SUCCEEDED';
export const DELETE_PROJECT_REQUESTED = 'DELETE_PROJECT_REQUESTED';
export const DELETE_PROJECT_SUCCEEDED = 'DELETE_PROJECT_SUCCEEDED';
export const GET_SEGMENTS_REQUESTED = 'GET_SEGMENTS_REQUESTED';
export const GET_SEGMENTS_SUCCEEDED = 'GET_SEGMENTS_SUCCEEDED';

export const GET_SELLERS_REQUESTED = 'GET_SELLERS_REQUESTED';
export const GET_SELLERS_SUCCEEDED = 'GET_SELLERS_SUCCEEDED';
