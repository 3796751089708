import React from 'react';
import Scrollbars from 'react-custom-scrollbars-2';

import t, { d } from '../i18n';
import './scenario.css';
import '../risk/new-risk.css';
import Modal from '../common/modal/modal';
import { Checkbox } from '../input/standard-input';
import TextCounter from '../input/text-counter';
import GrowableTextArea from '../input/growable-textarea';
import add_area from '../img/risk/add-area.svg';
import deleteIcon from '../img/delete.svg'
import download_icon from "../img/download.svg";

export const getTranslatedSolutionName = (solution) => {
    const id1 = 'risk.solution';
    const id2 = 'risk.solution.' + solution.cat_name;
    const term = solution.riskTerm || solution.name;

    let text = d(id1, term);
    if (text === term) {
        text = d(id2, term);
    }

    return text;
};

const NewSolution = ({
    show,
    solutions,
    solutionToggled,
    onClose,
    scenarioIndex,
}) => {
    if (!show) {
        return null;
    }

    // Turn into categories
    const solutionCategories = [];
    solutions.forEach((solution) => {
        if (!solutionCategories[solution.cat_id]) {
            solutionCategories[solution.cat_id] = [];
        }
        solutionCategories[solution.cat_id].push(solution);
    });

    const getCategoryName = (categoryKey) => {
        const name = t(`web.solution.${categoryKey}`);

        if (name === 'web.solution.') {
            return '';
        }

        return name;
    };

    return (
        <div style={{ display: 'block' }}>
            <Modal
                title={t('web.risk.scenarioSolution')}
                onClose={onClose}
                closeOnClickOutside={true}
            >
                <div className="solutions-list">
                    {solutionCategories.map((solutions, cat) => {
                        return (
                            <div className="solutions-category" key={cat}>
                                <h2>
                                    {getCategoryName(solutions[0].cat_name)}
                                </h2>
                                {solutions.map((solution, index) => {
                                    return (
                                        <div
                                            key={index}
                                            className={
                                                'solution-item ' +
                                                (solution.checked
                                                    ? 'solution-checked'
                                                    : '')
                                            }
                                        >
                                            <Checkbox
                                                label={getTranslatedSolutionName(
                                                    solution
                                                )}
                                                value={solution.id}
                                                checked={solution.checked}
                                                onChange={() =>
                                                    solutionToggled(solution)
                                                }
                                            />
                                        </div>
                                    );
                                })}
                                <hr />
                            </div>
                        );
                    })}
                </div>
            </Modal>
        </div>
    );
};

const Solutions = ({
    solutions,
    allSolutions,
    scenarioIndex,
    toggleNewSolutionViewExpanded,
}) => {
    solutions = solutions.map((sol) => {
        const cat = allSolutions.find((s) => s.id === sol.solution_id);
        sol.cat_name = cat ? cat.cat_name : '';
        return sol;
    });

    return (
        <div>
            <div>
                <label className="subtitle scenario__question-group-label">
                    {t('web.risk.scenarioSolution')}
                </label>
                <input
                    className="scenario__question-group-solution"
                    type="image"
                    src={add_area}
                    onClick={() => toggleNewSolutionViewExpanded(scenarioIndex)}
                />
            </div>
            <div className="risk__solutions">
                <div className="centered-content">
                    {solutions.map((solution, index) => (
                        <button
                            key={++index}
                            onClick={() =>
                                toggleNewSolutionViewExpanded(scenarioIndex)
                            }
                            className="risk__solutions"
                        >
                            {getTranslatedSolutionName(solution)}
                        </button>
                    ))}
                </div>
            </div>
        </div>
    );
};

// TODO: Refactor. Is a result of hacking things to work.
class EditScenario extends React.Component<any, any> {
    //whenHiddenField;

    state = {
        modalVisible: false,
        isExpanded: true,
        statusText: this.props.scenario.status,
        occurences: this.props.scenario.occurence || [],
        id: this.props.scenario.id || -1,
    };

    occurences = [
        t('web.risk.scenario.weekdays'),
        t('web.risk.scenario.weekends'),
        t('web.risk.scenario.duringOpeningHours'),
        t('web.risk.scenario.outsideOpeningHours'),
    ];

    private closeModal = () => {
        this.setState({ modalVisible: false });
        return true;
    };

    private openModal = () => {
        this.setState({ modalVisible: true });
        return true;
    };

    private addOrRemoveOccurence = (index) => {
        if (this.state.occurences.indexOf(index) > -1) {
            this.removeOccurence(index);
        } else {
            this.addOccurence(index);
        }
        return this.updateScenario();
    };

    private addOccurence(value: any) {
        let occurences = this.state.occurences;
        occurences.push(value);
        this.setState({
            occurences,
        });
    }

    private removeOccurence(value: any) {
        let occurences = this.state.occurences;

        let newOccurences = occurences.filter(eachOccurence => eachOccurence !== value);
        this.setState({
            occurences: newOccurences,
        });
    }

    public componentWillReceiveProps = (nextProps) => {
        if (
            typeof nextProps.scenario != 'undefined' &&
            nextProps.scenario.id != this.state.id
        ) {
            this.setState({
                occurences: nextProps.scenario.occurence || [],
                statusText: nextProps.scenario.status,
                id: nextProps.scenario.id,
            });
        }
    };

    private occurenceIsChecked(value) {
        return this.state.occurences.indexOf(value) > -1;
    }

    private updateScenario = () => {
        setTimeout(() => {
            this.props.scenarioUpdated(
                this.props.scenario,
                this.state.statusText,
                this.state.occurences
            );
        }, 0)
        // TODO this is a quickfix under a hard deadline constraint.
        //  please refactor this.
        //  remove setTimeout, then trigger this.props.scenarioUpdated
        //  when the state is actually updated, not when this method is called.
        //  this method is called JUST BEFORE the state is actually updated,
        //  not after.
    };

    private statusTextChanged = (e) => {
        this.setState({
            statusText: e.target.value,
        });
    };

    public expand = () => {
        this.setState({ isExpanded: true });
    };

    public hide = () => {
        this.setState({ isExpanded: false });
    };

    public render() {
        let occurenceExists =
            this.props.scenario.occurence &&
            this.props.scenario.occurence.length > 0;

        if (!this.state.isExpanded) {
            return (
                <div
                    key={this.props.index}
                    className="scenario__question-group"
                    onClick={this.expand}
                >
                    <span
                        onClick={this.expand}
                        className="icon-white icon icon-arrow"
                    ></span>
                    <span className="scenario__question-group-title">
                        {d('risk.scenario', this.props.scenario.scenario_name)}
                    </span>
                </div>
            );
        }

        return (
            <div
                key={this.props.index}
                className="scenario__question-group expanded"
            >
                <span onClick={this.hide} className="icon-white icon icon-arrow"></span>
                <a
                    href="#"
                    className="removeScenario"
                    onClick={() => {
                        this.props.removeScenario(
                            this.props.scenario.scenario_id
                        );
                    }}
                >
                    <img src={deleteIcon}/>
                </a>
                <span
                    className="scenario__question-group-title"
                    onClick={this.hide}
                >
                    {d('risk.scenario', this.props.scenario.scenario_name)}{' '}
                </span>
                <div className="scenario__highlight" />
                <label className="subtitle scenario__question-group-label">
                    {t('web.risk.scenarioWhen')}
                </label>
                <div className="when-container">
                    {this.occurences.map((occurence, i) => {
                        return (
                            <div
                                key={`occurence-${i}`}
                                className={
                                    'when-checkbox-container ' +
                                    (this.occurenceIsChecked(i)
                                        ? 'is-checked'
                                        : '')
                                }
                            >
                                <Checkbox
                                    label={occurence}
                                    value={i}
                                    checked={this.occurenceIsChecked(i)}
                                    onChange={(e) => {
                                        this.addOrRemoveOccurence(i);
                                    }}
                                />
                            </div>
                        );
                    })}
                </div>
                <label className="subtitle scenario__question-group-label">
                    {t('web.risk.scenarioComment')}
                </label>

                <GrowableTextArea
                    maxLength={400}
                    className="scenario__question-group-comment"
                    value={this.state.statusText}
                    name="comment"
                    onBlur={this.updateScenario}
                    onChange={this.statusTextChanged}
                />
                <div style={{ paddingLeft: '20px' }}>
                    <TextCounter
                        length={this.state.statusText.length}
                        limit={400}
                    />
                </div>
                <div className="scenario__highlight" />
                <NewSolution
                    show={
                        this.props.newSolutionViewExpanded &&
                        this.props.index == this.props.scenarioIndex
                    }
                    onClose={() =>
                        this.props.toggleNewSolutionViewExpanded(
                            this.props.index
                        )
                    }
                    solutions={this.props.popOverSolutions}
                    solutionToggled={(solution) =>
                        this.props.solutionToggled(
                            solution,
                            this.props.scenario
                        )
                    }
                    scenarioIndex={this.props.index}
                />
                <Solutions
                    scenarioIndex={this.props.index}
                    solutions={this.props.selectedSolutions}
                    allSolutions={this.props.popOverSolutions}
                    toggleNewSolutionViewExpanded={
                        this.props.toggleNewSolutionViewExpanded
                    }
                />
            </div>
        );
    }
}

export default class Scenario extends React.Component<any, any> {
    public render() {
        return (
            <div className="scenario__question-group-list"
            >
                {this.props.scenarios.map((scenario, index) => {
                    let popOverSolutions = this.props.allSolutions.map(
                        (solution) => {
                            let isChecked =
                                scenario.solutions.filter(
                                    (selectedSolution) =>
                                        selectedSolution.solution_id ===
                                        solution.id
                                ).length > 0;
                            return { ...solution, checked: isChecked };
                        }
                    );
                    let selectedSolutions = scenario.solutions.map(
                        (selectedSolution) => {
                            return { ...selectedSolution };
                        }
                    );

                    let props = {
                        key: index,
                        selectedSolutions: selectedSolutions,
                        popOverSolutions: popOverSolutions,
                        scenario: scenario,
                        index: index,
                        ...this.props,
                    };
                    return (
                        <div className="full-padding-container">
                            <EditScenario {...props} />
                        </div>
                    );
                }).reverse()}
            </div>
        );
    }
}
