import React from 'react';
import addNewRiskButton from '../img/risk/add-new-risk.svg';
import t from '../i18n';
import { IRiskListEditingStateProps } from './interfaces';

export default function AddRisk({
    toggleRiskListEditingState,
}: IRiskListEditingStateProps) {
    return (
        <div
            style={{ backgroundColor: '#26224C' }}
            className="risk pure-u-1 pure-u-md-1-2"
        >
            <div
                onClick={toggleRiskListEditingState}
                style={{
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    height: '100%',
                }}
            >
                <input
                    style={{ height: '7em' }}
                    type="image"
                    src={addNewRiskButton}
                    alt={'Add new area button'}
                />
                <span
                    style={{
                        color: '#FC273F',
                        fontSize: '1.5em',
                        textAlign: 'center',
                        marginTop: '1em',
                    }}
                >
                    {t('web.risk.addRisk')}
                </span>
            </div>
        </div>
    );
}
