import React from 'react'

const Folder = ({fillColor}) => {
  return (
    <svg style={{margin: 'auto'}} xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
      <title>Folder</title>
      <path fill={fillColor || '#000000'}
            d="M22 23H2a2 2 0 0 1-2-2V3a2 2 0 0 1 2-2h8a2 2 0 0 1 2 2h10a2 2 0 0 1 2 2v16a2 2 0 0 1-2 2zm0-18H10V3H2v4h20V5zm0 4H2v12h20V9z"
            id="a"/>
    </svg>
  )
}

export default Folder;
