import React from 'react';
import classNames from 'classnames';
import { d } from '../../i18n/index';
import { IRiskNavigationBar } from '../interfaces';

export default class RiskNavigationBar extends React.Component<
    IRiskNavigationBar,
    any
> {
    constructor(props) {
        super(props);
    }

    public render() {
        return (
            <div className="risk-navigation edit-risk__navigation">
                {this.props.risks.map((risk, index) => (
                    <button
                        key={index}
                        className={classNames('btn btn--dark-gray risk__btn', {
                            'btn--toggled':
                                index === this.props.activeRiskIndex,
                            'btn--margin-left': index !== 0,
                        })}
                        onClick={(e) => {
                            this.props.changeActiveRisk(index);
                            (e.target as HTMLButtonElement).blur();
                        }}
                    >
                        {d('risk.risk_group', risk.group_name)}
                    </button>
                ))}
                <hr className="risk-navigation__bottom-border" />
            </div>
        );
    }
}
