import * as React from 'react';
import PdfPage from './pdf-page';

interface PDFViewerProps {
    pdf: any;
    scale: number;
}

export default class PDFViewer extends React.Component<PDFViewerProps, any> {
    render() {
        const { pdf } = this.props;
        const numPages = pdf ? pdf.numPages : 0;
        const fingerprint = pdf ? pdf.fingerprints : 'none';
        const pages = Array.apply(null, { length: numPages })
            .map((v, i) => (<PdfPage pdf={pdf} scale={this.props.scale} index={i + 1} key={`${fingerprint}-${i}`}/>));
        return (
            <div className='proposal'>
                <div className='proposal__pdf-viewer'>
                    {pages}
                </div>
            </div>
        );
    }
}
