import { combineReducers } from 'redux';
import optimist from 'redux-optimist';

import app from '../app/reducer';
import user from '../user/reducer';
import projects from '../projects/reducer';
// import newProject from '../new-project/reducer';
import attachments from '../attachment/reducer';
import risks from '../risk/reducer';
import survey from '../survey/reducer';
import products from '../products/reducer';
// import categories from '../categories/reducer';
import scenario from '../scenario/reducer';
import proposal from '../proposal/reducer';

export interface FsaAction {
    type: string;
    payload?: any;
    error?: any;
    meta?: any;
}

const rootReducer = optimist(combineReducers({
    app,
    user,
    projects,
    products,
    risks,
    attachments,
    scenario,
    proposal,
    survey
}));

export default rootReducer;
