import * as scenarioActions from "./actions";

const initialState = {
  isFetching: false,
  scenario: []
};

export const fetchScenarios = (dispatch) => {
  dispatch({
    type: scenarioActions.FETCH_SCENARIO_REQUESTED
  });
}

export default function scenario(state = initialState, action) {
  switch (action.type) {
    case scenarioActions.FETCH_SCENARIO_REQUESTED:
      return Object.assign({}, state, {
        isFetching: true,
        scenario: null
      });
    case scenarioActions.FETCH_SCENARIO_SUCCEEDED:
      return Object.assign({}, state, {
        scenarios: action.scenarios,
        isFetching: false
      });
    case scenarioActions.ADD_RISK_SCENARIO_SUCCEEDED:
      return {...state}
    default:
      return state;
  }
}
