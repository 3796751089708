import React, { Component } from 'react';
import classNames from 'classnames';
import t from '../i18n';
import { IRiskEvaluation } from './interfaces';

export default class RiskEvaluation extends Component<IRiskEvaluation, any> {
    private colors: Array<string> = ['green', 'yellow', 'orange', 'red'];

    public render() {
        const active = this.props.index === this.props.selectedIndex;

        return (
            <button
                disabled={this.props.disabled}
                className={classNames(
                    'edit-risk__evaulation btn risk__btn',
                    {
                        'btn--toggled':
                            this.props.index === this.props.selectedIndex,
                        'btn--margin-left': this.props.index !== 0,
                    },
                    active ? this.colors[this.props.index] : ''
                )}
                onClick={(e) => {
                    this.props.handleOnClickRiskEvaluation(
                        this.props.risk,
                        this.props.evalKey,
                        this.props.index
                    );
                    (e.target as HTMLButtonElement).blur();
                }}
            >
                {t(this.props.name)}
            </button>
        );
    }
}
