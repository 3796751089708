// @ts-nocheck
// TODO remove above
import * as React from 'react';
import { connect } from 'react-redux';
import Popover from 'react-popover';
import Scrollbars from 'react-custom-scrollbars-2';

import { getCategoriesActions, getProductsActions, getProjectProductsActions, } from './actions';
import { getProjectProducts } from './reducer';

import { dispatchAddAction, dispatchDeleteAction, dispatchEditAction } from './saga';
import Products from './products';
import cart from '../img/cart.svg';
import cartActive from '../img/cart-active.svg';
import toggl from '../img/toggl.svg';
import togglActive from '../img/toggl-active.svg';
import checklistImage from '../img/checklist.svg';
import checkmark from '../img/checkmark.svg';
import uncheckmarked from '../img/uncheckmarked.svg';
import ProjectProducts from './project-products';
import './products.css';
import t from '../i18n';
import { IRisk } from '../risk/risk';
import { IProduct } from '../calculation/product';
import { GET_PROJECT_RISKS_REQUESTED } from '../risk/actions';
import { flatten } from '../utils/utils';

export interface ProductsContainerProps {
    project: any;
    products: IProduct[];
    categories: any[];
    isFetching: boolean;
    isFetchingCategories: boolean;
    projectProducts: any[];
    user: any;
    risks: { areas: any[], projectRisks: IRisk[] };

    dispatchAction(any): any;
}

class ProductsContainer extends React.Component<ProductsContainerProps, any> {

    state = {
        searchValue: '',
        navigationStackCategories: [],
        showAllProducts: true,
        showChecklist: false,
        checkedRisks: []
    }
    handleToggleCart = () => {
        this.setState({
            showAllProducts: !this.state.showAllProducts
        })
    }
    handleToggleChecklist = () => {
        this.setState({
            showChecklist: !this.state.showChecklist
        });
    }
    handleSearchInputChange = (e) => {
        this.setState({
            searchValue: e.target.value
        });
    }
    handleAddProductToProject = (product) => {
        console.log('handleAddProductToProject');
        dispatchAddAction(
            this.props.dispatchAction,
            this.props.project.project_id,
            product
        );
    }
    handleDeleteProduct = (projectProduct) => {
        dispatchDeleteAction(
            this.props.dispatchAction,
            this.props.project.project_id,
            projectProduct.product_id,
            projectProduct.project_product_id
        );
    }
    handleFilterOnCategoryRoot = (categoryId, name) => {
        const currentRootCategory = this.state.navigationStackCategories[0];

        if (currentRootCategory) {
            const newRootCategoryArray = categoryId === currentRootCategory.id ?
                [] : [{ id: categoryId, name: name }];
            this.setState({
                navigationStackCategories: newRootCategoryArray
            });
        } else {
            this.setState({
                navigationStackCategories: [{ id: categoryId, name: name }]
            });
        }
    }
    handleNavigationStackCategoriesPush = (categoryId, name) => {
        this.state.navigationStackCategories.push({ id: categoryId, name: name });
        this.setState({
            navigationStackCategories: Object.assign([], this.state.navigationStackCategories)
        });
    }
    handleNavigationStackCategoriesPop = (indexForItemClicked) => {
        const navigationStack = this.state.navigationStackCategories;
        navigationStack.splice(indexForItemClicked + 1, navigationStack.length - indexForItemClicked);
        this.setState({ navigationStack: navigationStack });
    }
    handleAmountListener = (product, quantity) => {
        dispatchEditAction(
            this.props.dispatchAction,
            product
        );
    }

    componentDidMount() {
        this.props.dispatchAction(getCategoriesActions.started({ country_id: this.props.project.seller.country_id }));
        this.props.dispatchAction(getProductsActions.started({}));
        this.props.dispatchAction(getProjectProductsActions.started({ project_id: this.props.project.project_id }));
        this.props.dispatchAction({
            type: GET_PROJECT_RISKS_REQUESTED,
            payload: { project_id: this.props.project.project_id }
        });

        this.setState({
            checkedRisks: JSON.parse(localStorage.getItem(`${this.props.project.project_id}-solutions`)) || []
        });
    }

    componentWillReceiveProps() {
        if (this.props.categories.length > 0 && this.state.navigationStackCategories.length === 0) {
            const category = this
                .props
                .categories
                .filter((c) => c.parent_id === null)
                .sort((category1, category2) => category1.position < category2.position ? -1 : 1)[0];
            this.handleFilterOnCategoryRoot(category.id, category.name);
        }
    }

    handleSolutionCheck = name => {
        let risks = this.state.checkedRisks;
        if(risks.includes(name)) {
            risks = risks.filter(r => r !== name);
        } else {
            risks.push(name);
        }
        localStorage.setItem(`${this.props.project.project_id}-solutions`, JSON.stringify(risks));
        this.setState({
            checkedRisks: risks
        });
    }

    render() {
        const { products, categories, isFetching, isFetchingCategories, project, projectProducts, risks } = this.props;
        const { searchValue, navigationStackCategories } = this.state;
        let togglImage = this.state.showAllProducts ? toggl : togglActive;
        let cartImage = this.state.showAllProducts ? cart : cartActive;
        return (
            <div className="products">
                <div className="products__cartrow">
                    <Popover
                        isOpen={this.state.showChecklist}
                        onOuterAction={this.handleToggleChecklist}
                        place="left"
                        refreshIntervalMs={10}
                        body={
                            <RiskSolutions risks={risks.projectRisks} checkedRisks={this.state.checkedRisks} onCheck={this.handleSolutionCheck} />
                        }>
                        <img src={checklistImage} alt="Checklist" onClick={this.handleToggleChecklist}/>
                    </Popover>
                    <img src={cartImage} alt="{t('web.solutions.cartAlt')}" onClick={this.handleToggleCart}/>
                    <img src={togglImage} alt="cart" onClick={this.handleToggleCart}/>
                </div>
                {!this.state.showAllProducts && <ProjectProducts
                    projectProducts={projectProducts}
                    project={project}
                    handleDeleteProduct={this.handleDeleteProduct}
                    handleAmountListener={this.handleAmountListener}
                    handleAddProduct={this.handleAddProductToProject}/>
                }
                {this.state.showAllProducts && <Products
                    products={products}
                    projectProducts={projectProducts}
                    project={project}
                    categories={categories}
                    navigationStackCategories={navigationStackCategories}
                    isFetchingCategories={isFetchingCategories}
                    isFetching={isFetching}
                    searchValue={searchValue}
                    handleFilterOnCategoryRoot={this.handleFilterOnCategoryRoot}
                    handleNavigationStackCategoriesPush={this.handleNavigationStackCategoriesPush}
                    handleNavigationStackCategoriesPop={this.handleNavigationStackCategoriesPop}
                    handleAddProduct={this.handleAddProductToProject}
                    handleDeleteProduct={this.handleDeleteProduct}
                    handleAmountListener={this.handleAmountListener}
                    handleSearchInputChange={this.handleSearchInputChange}
                />
                }
            </div>
        );
    }
}

const mapStateToProps = ({ products, user, risks }, ownProps) => {
    let projectProducts = getProjectProducts(products, ownProps.project.project_id)
    return {
        products: products.products,
        categories: products.categories,
        isFetchingCategories: products.isFetching,
        isFetching: products.isFetching,
        projectProducts,
        user: user.user,
        risks: risks
    }
};

const mapDispatchToProps = (dispatch) => ({
    dispatchAction: (action) => dispatch(action)
});

export default connect<any, any, any>(mapStateToProps, mapDispatchToProps)(ProductsContainer);

interface IRiskSolutionsProps {
    risks: IRisk[];
    checkedRisks: string[],
    onCheck: Function
}

const RiskSolutions = ({ risks, checkedRisks, onCheck }: IRiskSolutionsProps) => {
    const scenarios = flatten(risks.map(risk => risk.scenarios));
    const solutions = flatten(scenarios.map(scenario => scenario.solutions));
    const names = Array.from(new Set<string>(solutions.map(s => s.name)));
    return (
        <Scrollbars style={{ 'borderRadius': '0.2rem' }} autoHeight={true} autoHeightMax={300}>
            <ul className="new-risk list">
                {names.length === 0 &&
                <li className="new-risk__list-item">
                    <span className="new-risk__name">{t('web.solutions.noSolutions')}</span>
                </li>}
                {names.map((name, i) => {
                    return (
                        <li className="new-risk__list-item" key={i} onClick={() => onCheck(name)}>
                            <img className="new-risk__checkmark" alt="checkmark"
                                 src={checkedRisks.includes(name) ? checkmark : uncheckmarked}/>
                            <span className="new-risk__name">{name}</span>
                        </li>
                    );
                })}
            </ul>
        </Scrollbars>
    );
};
