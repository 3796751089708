// @ts-nocheck
// TODO remove above
import * as React from 'react';
import { connect } from 'react-redux';

import NewProjectContainer from './new-project-container';
import './new-project.css';
import { getProjectById } from '../projects/reducer';

export interface EditProjectContainerProps {
    project?: any;
}

class EditProjectContainer extends React.Component<EditProjectContainerProps, any> {
    render() {
        return (
            this.props.project && <NewProjectContainer editing={true} isEdit={true} project={this.props.project}/>
        );
    }
}

const mapStateToProps = ({ ...state }, ownProps): EditProjectContainerProps => {

    let project = getProjectById(state.projects, ownProps.params.id);
    return {
        project,
    }
};

export default connect(mapStateToProps)(EditProjectContainer);
