// @ts-nocheck
// TODO remove above
import React, { Component } from 'react'

import './edit-risks.css';
import classNames from 'classnames';
import Scenario from '../../scenario/scenario-container';

import EditRiskContent from './edit-risk-content';
import { IEditRisk } from '../interfaces';

export default class EditRisk extends Component<IEditRisk, any> {
    erc;

    onScenarioChange() {
        this.setState({ a: "b" });
        this.erc.forceUpdate();
    }

    public render() {
        return (
            <div
                className={classNames("edit-risk", { "edit-risk--not-active": this.props.risk.id === this.props.activeRiskIndex })}>
                <EditRiskContent
                    risk={this.props.risk}
                    handleOnClickRiskEvaluation={this.props.handleOnClickRiskEvaluation}
                    activeRiskIndex={this.props.activeRiskIndex}
                    onChangeHandlerComment={this.props.onChangeHandlerComment}
                    editRiskHandler={this.props.editRiskHandler}
                    handleImageEvent={this.props.handleImageEvent}
                    onSelectSketchImage={this.props.onSelectSketchImage}
                    images={this.props.images}
                    overlay={(this.props.overlay)}
                    ref={(ref) => {
                        this.erc = ref;
                    }}
                />
                <Scenario risk={this.props.risk} onChange={this.onScenarioChange.bind(this)}
                          editRiskHandler={this.props.editRiskHandler}/>
            </div>
        );
    }
}




