import t from '../i18n';
import { IRisk } from '../risk/risk';

export default function getRiskRatingColor(risk: IRisk) {
    const level = (risk.impact + 1) * (risk.probability + 1);

    if (level > 9) return ['red', t('web.risk.matrixVeryHigh')];

    if (level > 4) return ['orange', t('web.risk.matrixHigh')];

    if (level > 2) return ['yellow', t('web.risk.matrixMedium')];

    if (level === 0) return ['none', 'N/A'];

    return ['green', t('web.risk.matrixLow')];
}
