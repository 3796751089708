// @ts-nocheck
// TODO remove above
import { AnyAction } from "redux";
import { all, call, put, select, takeEvery } from 'redux-saga/effects';
import { BEGIN, COMMIT, REVERT } from 'redux-optimist';

import {
    DELETE_SURVEY_ATTACHMENT_REQUESTED,
    DELETE_SURVEY_ATTACHMENT_SUCCEEDED,
    EDIT_ADVANCED_RISKS_CAN_REQUESTED,
    EDIT_ADVANCED_RISKS_CAN_SUCCEEDED,
    EDIT_QUESTIONARIES_REQUESTED,
    EDIT_QUESTIONARIES_SUCCEEDED,
    FETCH_ADVANCED_RISK_SCAN_REQUESTED,
    FETCH_ADVANCED_RISK_SCAN_SUCCEEDED,
    POST_SURVEY_ATTACHMENT_REQUESTED,
    POST_SURVEY_ATTACHMENT_SUCCEEDED,
    EDIT_SURVEY_ATTACHMENT_SUCCEEDED,
    EDIT_SURVEY_ATTACHMENT_REQUESTED,
    EDIT_SUMMARY_REQUESTED,
    EDIT_SUMMARY_SUCCEEDED
} from './actions';

import { ADD_ERROR } from '../app/actions';
import {
    deleteSurveyImage,
    fetchSurvey,
    fetchSurveySummaries,
    fetchSurveyAnswers,
    fetchSurveyImages,
    setSurveyAnswers,
    uploadSurveyImage,
    updateSurveyImage,
    updateSurveySummary
} from '../services/api';
import t from '../i18n/index';
import { SurveyState } from './reducer'
import { getAnswers, getImages, getSummaries } from './selectors';

export interface FsaAction extends AnyAction {
    payload: any;
    error?: boolean;
    meta?: any;
}

async function valuePromise(value) {
    return await value;
}

let productsNextTransactionId = 0;
const getNextTransactionId = () => productsNextTransactionId++;

function* getRiskScanRequested(action: FsaAction) {
    try {
        let projectId = action.payload.project_id;
        let surveyState: SurveyState = yield select(state => state.survey);

        // Only load data if they are not already loaded. Wrap existing data
        // in a promise so that we can still yield all in parallell
        let surveyPromise = (surveyState.survey.length === 0) ? fetchSurvey : valuePromise.bind(null, surveyState.survey);
        let currentAnswers = getAnswers(surveyState, projectId);
        let answerPromise = currentAnswers ? valuePromise.bind(null, currentAnswers) : fetchSurveyAnswers.bind(null, projectId);
        let currentImages = getImages(surveyState, action.payload.project_id);
        let imagesPromise = currentImages ? valuePromise.bind(null, currentImages) : fetchSurveyImages.bind(null, projectId);
        let currentSummaries = getSummaries(surveyState, action.payload.project_id);
        let summariesPromise = currentSummaries ? valuePromise.bind(null, currentSummaries) : fetchSurveySummaries.bind(null, projectId);
        let [survey, answers, images, summaries] = yield all([
            call(surveyPromise),
            call(answerPromise),
            call(imagesPromise),
            call(summariesPromise)
        ]);
        yield put({
            type: FETCH_ADVANCED_RISK_SCAN_SUCCEEDED,
            payload: { survey, answers, images, summaries, project_id: projectId }
        });
    } catch (e) {
        yield put({
            type: ADD_ERROR, payload: {
                title: t('web.common.errorTitleGeneric'),
                message: t('Something unexpected happened. Please try to refresh')
            }
        });
    }
}

function* editAdvancedRiskScanRequested(action) {
    try {
        yield put({ type: EDIT_ADVANCED_RISKS_CAN_SUCCEEDED, optimist: { type: COMMIT, id: action.optimist.id } });
    } catch (e) {
        yield put({
            type: ADD_ERROR,
            message: {
                title: t('web.common.errorTitleGeneric'),
                message: t('Something unexpected happened')
            },
            optimist: { type: REVERT, id: action.optimist.id }
        });
    }
}

export const editAdvancedRiskScan = (dispatch, payload) => {
    const transactionId = getNextTransactionId();

    dispatch({
        type: EDIT_ADVANCED_RISKS_CAN_REQUESTED,
        payload: payload,
        optimist: { type: BEGIN, id: transactionId }
    })
}

function* submitSurveyAnswers(action) {
    let answer = {
        project_id: action.payload.project_id,
        seller_id: action.payload.seller_id,
        questions_id: action.payload.question.id,
        note: action.payload.note,
    }
    const transactionId = getNextTransactionId();
    let answerOptions = [];
    if (action.payload.alternative) {
        answerOptions = [{
            option_id: action.payload.alternative.id,
            answer: action.payload.answer,
        }];
        //if the question is of type radiobutton we have to set all other alternatives to false
        if (action.payload.question.question_type === "radiobutton") {
            let clearedOptions = action.payload.question.alternatives.reduce((prev, curr) => {
                if (curr.id !== action.payload.alternative.id) {
                    let answer = {
                        option_id: curr.id,
                        answer: "False",
                    }
                    prev.push(answer);
                }
                return prev;
            }, []);
            answerOptions.push(...clearedOptions);
        }
    }
    answer = { ...answer, options: answerOptions }
    let answerArray = [];
    answerArray.push(answer)
    let updatedAnswer = yield call(setSurveyAnswers, action.payload.project_id, answerArray);
    // let updatedAnswers = yield call(fetchSurveyAnswers, action.payload.project_id);

    let payload = { answer: updatedAnswer, project_id: action.payload.project_id };
    yield put({
        type: EDIT_QUESTIONARIES_SUCCEEDED,
        payload: payload,
        optimist: { type: BEGIN, id: transactionId }
    });
}

function* submitSurveyImage(action) {
    try {
        yield call(uploadSurveyImage, action.payload.projectId, action.payload.questionId, action.payload.file);
        let images = yield call(fetchSurveyImages, action.payload.projectId)
        yield put({
            type: POST_SURVEY_ATTACHMENT_SUCCEEDED,
            payload: { images, project_id: action.payload.projectId }
        });
    } catch (e) {
        yield put({
            type: ADD_ERROR,
            message: {
                title: t('web.common.errorTitleGeneric'),
                message: t('failed to upload image')
            },
            optimist: { type: REVERT, id: action.optimist.id }
        });
    }
}

function* editSurveyImage(action) {
    try {
        yield call(updateSurveyImage, action.payload.projectId, action.payload.attachmentId, action.payload.file);
        let images = yield call(fetchSurveyImages, action.payload.projectId)
        yield put({
            type: EDIT_SURVEY_ATTACHMENT_SUCCEEDED,
            payload: { images, project_id: action.payload.projectId }
        });
    } catch (e) {
        yield put({
            type: ADD_ERROR,
            message: {
                title: t('web.common.errorTitleGeneric'),
                message: t('failed to upload image')
            },
            optimist: { type: REVERT, id: action.optimist.id }
        });
    }
}

function* deleteSurveyAttachmentRequested(action) {
    try {
        yield call(deleteSurveyImage, action.payload.projectId, action.payload.attachmentId);
        let images = yield call(fetchSurveyImages, action.payload.projectId)
        yield put({
            type: DELETE_SURVEY_ATTACHMENT_SUCCEEDED,
            payload: { images, project_id: action.payload.projectId }
        });
    } catch (e) {
        yield put({
            type: ADD_ERROR,
            message: {
                title: t('web.common.errorTitleGeneric'),
                message: t('failed to delete image')
            },
            optimist: { type: REVERT, id: action.optimist.id }
        });
    }
}

function* editSummaryRequested(action) {
    try {

        const project_id = action.payload.project_id;

        yield call(updateSurveySummary, project_id, action.payload.summary);
        const summaries = yield call(fetchSurveySummaries, project_id);

        yield put({
            type: EDIT_SUMMARY_SUCCEEDED,
            payload: { summaries, project_id }
        });
    } catch (e) {
        yield put({
            type: ADD_ERROR, payload: {
                title: t('web.common.errorTitleGeneric'),
                message: t('Something unexpected happened. Please try to refresh')
            }
        });
    }
}

export default function* root() {
    yield [
        takeEvery(FETCH_ADVANCED_RISK_SCAN_REQUESTED, getRiskScanRequested),
        takeEvery(EDIT_ADVANCED_RISKS_CAN_REQUESTED, editAdvancedRiskScanRequested),
        takeEvery(EDIT_QUESTIONARIES_REQUESTED, submitSurveyAnswers),
        takeEvery(POST_SURVEY_ATTACHMENT_REQUESTED, submitSurveyImage),
        takeEvery(EDIT_SURVEY_ATTACHMENT_REQUESTED, editSurveyImage),
        takeEvery(DELETE_SURVEY_ATTACHMENT_REQUESTED, deleteSurveyAttachmentRequested),
        takeEvery(EDIT_SUMMARY_REQUESTED, editSummaryRequested)
    ]
}
