// @ts-nocheck
// TODO remove above
import * as React from 'react';
import { fetchImageBlob } from "../services/api";

export interface BlobImageProps {
    src: string,
    isBlobUrl?: boolean,
    label?: string,
    clearCache?: boolean,

    [key: string]: any
}

export class BlobImage extends React.Component<BlobImageProps, any> {
    state = { blobUrl: "" }

    imageStyle = {
        boxShadow: "0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)",
        height: "80px",
        marginRight: "10px",
        display: "inline-block",
        textAlign: "center",
        padding: "0px"
    }

    componentWillReceiveProps(nextProps) {
        if(this.props.clearCache) {
            fetchImageBlob(nextProps.src, true).then(blobUrl => {
                this.setState({ blobUrl: blobUrl });
            });
        }
    }

    componentDidMount() {
        fetchImageBlob(this.props.src).then(blobUrl => {
            this.setState({ blobUrl: blobUrl });
        })
    }

    getLabel() {
        if (this.props.label) {
            return (
                <div style={{ backgroundColor: "#fff", padding: "6px" }}>{this.props.label}</div>
            )
        }
        return undefined;
    }

    render() {
        let label = this.getLabel();
        let props = {...this.props};
        delete props['clearCache']; // Removing the clearCache prop before passing the props to the img tag, because React says so
        return (
            <div style={this.imageStyle}>
                <img alt="" style={{ maxHeight: "80px" }}
                     {...props}
                     src={this.state.blobUrl}/>
                {label}
            </div>
        )
    }
}
