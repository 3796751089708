import * as React from 'react';
import { connect } from 'react-redux';
import BottomMenu from './bottom-menu';

export interface BottomMenuContainerProps {
    user: any;
    location: any;
    params: any;
}

class BottomMenuContainer extends React.Component<BottomMenuContainerProps, {}> {

    render() {
        const id = this.props.params.id || -1;
        return (
            <BottomMenu location={window.location} user={this.props.user} id={id}/>
        );
    }
}

const mapStateToProps = ({ user, app }) => ({
    user: user.user,
});

export default connect(mapStateToProps)(BottomMenuContainer as any);
