// @ts-nocheck
// TODO remove above
import React, { Component } from 'react';
import ReactDOM from 'react-dom';

import { DragDropContext, DragSource, DropTarget } from 'react-dnd';
import t from '../i18n';
import './calculation.css';
import trash from '../img/trash.svg';
import * as calcUtils from './calcUtils';
import { Checkbox, TextInput } from '../input/standard-input';
import close from '../img/close.svg';
import Page from '../common/page/page';
import SumTable from './sum-table';
import classNames from 'classnames';
import HTML5Backend from 'react-dnd-html5-backend';
import update from 'immutability-helper';
import { IProduct } from './product';
import { IProject, formatPriceToCurrency } from './calculation';
import { convertProductToObject } from "./calculator";

interface IEditCalculationProps {
    project: IProject;
    currency: string;
    editProduct: (updatedProduct: IProduct) => void;
    deleteProduct: (product_id: number, project_product_id: number) => void;
    updateProductOrder: (ordered_product_ids: number[]) => void;
    closeView: () => void;
    toggleEditProducts: () => void;
    country: string;
}

interface IEditCalculationState {
    products: IProduct[];
    projectDuration: number;
}

@DragDropContext(HTML5Backend)
export default class EditCalculation extends Component<IEditCalculationProps, IEditCalculationState> {

    state = {
        products: this.props.project.products.map(product => { return convertProductToObject(product, this.props.currency) }),
        projectDuration: this.props.country === 'Poland' ? 3 : 12
    }
    moveEditProduct = (dragIndex: number, hoverIndex: number) => {
        const products = this.state.products;
        const dragProduct = products[dragIndex];

        this.setState(update(this.state, {
            products: {
                $splice: [[dragIndex, 1], [hoverIndex, 0, dragProduct]]
            }
        }));
        this.setState({products: this.state.products.map(product => { return convertProductToObject(product, this.props.currency) })})
        this.props.updateProductOrder(this.state.products);
    }
    handleChangeDurationProject = (event) => {
        const newDuration = Number(event.target.value);
        this.setState({ projectDuration: newDuration });

        this.state.products.forEach(product => {
            if (newDuration === 0) {
                if (product.data.is_fixed_price) {
                    this.props.editProduct({
                        ...product.data,
                        lease_period: 0
                    });
                }
            } else {
                if (product.data.is_leased && product.data.lease_period !== newDuration) {
                    if ((newDuration == 12 && product.data.price_12_months_cents < 0) 
                        || (newDuration == 24 && product.data.price_24_months_cents < 0) 
                        || (newDuration == 36 && product.data.price_36_months_cents < 0) 
                        || (newDuration == 60 && product.data.price_60_months_cents < 0)) {
                        // Display alert
                        this.setState({ display_error: true });
                        console.log("Error yes");               
                    } else {
                        this.props.editProduct({
                            ...product.data,
                            lease_period: newDuration
                        }); 
                    }

                }
            }
        });
    }

    componentWillReceiveProps(nextProps: IEditCalculationProps) {
    }

    public render() {
        // TODO: t18n 'web.calculation.basePriceLabel' = 'Internal Price' 
        // TODO: t18n 'web.calculation.priceLabel' = 'Customer Price'
        const colWidth = {
            small: { width: "3rem" },
            medium: { width: "11rem" },
            large: { width: "13rem" }
        }

        return (
            <Page title={t('web.calculation.editHeader')}>
                <div className="calculation-edit calculation">
                    <div className="calculation__header">
                        <div className="calculation-edit__close">
                            <img className="modal__close" onClick={() => this.props.closeView()} src={close}
                                 alt={t('web.common.close')}/>
                        </div>
                    </div>
                    <div className="calculation-edit__project-duration">
                        <span className="">{t('web.calculation.projectDuration')}</span>
                        <SelectDuration
                            handleChangeDuration={this.handleChangeDurationProject}
                            value={`${this.state.projectDuration}`}
                            country={this.props.country}
                        />
                    </div>
                    <div className="calculation-edit__helper-labels-container">
                        <span style={colWidth.small}>{t('web.calculation.discountLabel')}</span>
                        <span style={colWidth.large}>{t('web.calculation.monthlyCost')}</span>
                        <span style={colWidth.large}>{t('web.calculation.initialCost')}</span>
                        <span style={colWidth.medium}>{t('web.calculation.durationLabel')}</span>
                        <span style={colWidth.small}>{t('web.calculation.amountLabel')}</span>
                    </div>
                    <ul className="list">
                        {
                            this.state.products && this.state.products.map((product, index) => {
                                if (!product) return null;
                                return (<EditProduct
                                    key={product.data.project_product_id}
                                    id={product.data.project_product_id}
                                    index={index}
                                    moveEditProduct={this.moveEditProduct}
                                    currency={this.props.currency.toLowerCase()}
                                    product={product}
                                    deleteProduct={this.props.deleteProduct}
                                    editProduct={this.props.editProduct}
                                    country={this.props.country}/> )}
                            )
                        }
                    </ul>
                    <SumTable products={this.state.products} currency={this.props.currency}/>
                </div>
            </Page>
        );
    }
}

export namespace EditProductDnD {

    // https://github.com/react-dnd/react-dnd/blob/master/examples/04%20Sortable/Simple/Card.js

    export const TYPE = 'EDIT_PRODUCT';

    export const SOURCE = {
        beginDrag(props) {
            return {
                id: props.id,
                index: props.index
            };
        }
    };

    export const TARGET = {
        hover(props, monitor, component) {
            const dragIndex = monitor.getItem().index;
            const hoverIndex = props.index;

            // Don't replace items with themselves
            if (dragIndex === hoverIndex)
                return;

            const hoverBoundingRect = ReactDOM.findDOMNode(component).getBoundingClientRect();
            const hoverMiddleY = (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;
            const clientOffset = monitor.getClientOffset();
            const hoverClientY = clientOffset.y - hoverBoundingRect.top;

            // Dragging downwards
            if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY)
                return;

            // Dragging upwards
            if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY)
                return;

            props.moveEditProduct(dragIndex, hoverIndex);
            monitor.getItem().index = hoverIndex;
        }
    };
}

interface IEditProductProps {
    product: IProduct;
    currency: string;
    editProduct: (updatedProduct: IProduct) => void;
    deleteProduct: (product_id: number, project_product_id: number) => void;
    id: any;
    index: number;
    moveEditProduct: (dragIndex: number, hoverIndex: number) => void;
    country: string;

    // react-dnd
    [label: string]: any;
}

@DropTarget(EditProductDnD.TYPE, EditProductDnD.TARGET, connect => ({
    connectDropTarget: connect.dropTarget()
}))
@DragSource(EditProductDnD.TYPE, EditProductDnD.SOURCE, (connect, monitor) => ({
    connectDragSource: connect.dragSource(),
    isDragging: monitor.isDragging()
}))
class EditProduct extends Component<IEditProductProps, {}> {

    fixedInputRef = null;
    monthlyInputRef = null;
    percentInputRef = null;

    state = {
        isEditingMonthly: false,
        isEditingFixed: false
    }

    handleEditPrice = (event) => {
        const newPrice = this._priceStringToNumber(event.target.value);
        let propertyName = "fixed_price_in_cents";
        let adjustedPropertyName = "adjusted_fixed_price_cents";
        
        if (this.props.product.data.lease_period > 0) {
            propertyName = `price_${this.props.product.data.lease_period}_months_cents`;
            adjustedPropertyName = "adjusted_" + propertyName;
        }
        //don't update if value has not changed or is equal to default price
        if ((this.props.product.data[propertyName] === newPrice && newPrice !== 0) || (this.props.product.data[adjustedPropertyName] === newPrice)) {
            return;
        }
        let updatedProduct = { ...this.props.product.data };
        updatedProduct[adjustedPropertyName] = newPrice;
        this.props.editProduct(updatedProduct);
    }

    handleEditFixedFee = (event) => {
        if (this.props.product.data.fixed_fee || (this.props.product.data.lease_period === 0)) {
            let newPrice = this._priceStringToNumber(event.target.value); 
            let propertyName = "fixed_price_in_cents";
            let adjustedPropertyName = "adjusted_fixed_price_cents";

            //don't update if value has not changed or is equal to default price
            if ((this.props.product[adjustedPropertyName] === newPrice && newPrice !== 0)) {
                return;
            }

            let updatedProduct = { ...this.props.product.data };
            updatedProduct[adjustedPropertyName] = newPrice;
            this.props.editProduct(updatedProduct);
        }
    }

    handleChangeDuration = (event) => {
        if (event.target.value != this.props.product.data.lease_period) {
            this.props.editProduct(
                Object.assign({}, this.props.product.data, {
                    'lease_period': Number(event.target.value)
                })
            );
        }
    }

    handleEditAmount = (event) => {
        if (event.target.value != this.props.product.data.quantity) {
            this.props.editProduct(
                Object.assign({}, this.props.product.data, {
                    'quantity': Number(event.target.value)
                })
            );
        }
    }

    handleEnableFixedFee = (new_state: boolean): void => {
        this.props.editProduct(
            {
                ...this.props.product.data,
                fixed_fee: new_state,
            });
    }

    handleEditDiscount = (event) => {
        let discount = event.target.value.replace(',', '.');
        discount = Number(discount);
        if (discount < 0 || discount > 100 || this.props.product.data.percentage_adjustment == event.target.value) {
            return
        }
        this.props.editProduct(
            Object.assign({}, this.props.product.data, {
                'percentage_adjustment': discount
            })
        );
    }

    getLeasePeriod = (product) => {
        return product.lease_period;
    }

    getVatText = (product) => {
        return product.price_includes_vat ? t("web.calculation.includingVAT") : t("web.calculation.excludingVAT")
    }

    getEditProduct() {
        const colWidth = {
            small: { width: "3rem" },
            medium: { width: "11rem" },
            large: { width: "13rem" }
        }
        const productType                       = calcUtils.getProductType(this.props.product.data);
        const is_combo_product: boolean         = productType === calcUtils.ProductType.ComboPrice;
        const is_fixed_product: boolean         = productType === calcUtils.ProductType.FixedPrice;
        const is_monthly_product: boolean       = productType === calcUtils.ProductType.Leasing;
        const lease_period: number              = this.getLeasePeriod(this.props.product.data);
        const is_fixed_selected: boolean        = lease_period === 0;
        const current_lease_address             = !is_fixed_selected ? `adjusted_price_${lease_period}_months_cents` : null;

        const original_monthly_price: number    = !is_fixed_selected ? this.props.product.getBasePrice().price / 100 : 0;
        const monthly_price: number             = !is_fixed_selected ? this.props.product.getEditPrice() / 100 : 0;
        const has_monthly_price_changed: boolean = this.props.product.data[current_lease_address] !== 0;

        const original_fixed_price: number      = this.props.product.data.fixed_price_in_cents / 100;
        const discount: number                  = this.props.product.data.percentage_adjustment;
        const discounted_fixed_price: number    = (1-(discount/100)) * original_fixed_price;
        const fixed_price: number               = this.props.product.data.adjusted_fixed_price_cents / 100 || discounted_fixed_price;
        const has_fixed_price_changed: boolean  = discounted_fixed_price !== fixed_price;
        const is_fixed_fee_active: boolean      = this.props.product.data.fixed_fee;
        const discounted_monthly_price: number  = (1-(discount/100)) * original_monthly_price;

        return (
            <li style={{ opacity: this.props.isDragging ? 0 : 1 }} className="calculation__item">
                <div className="calculation__item-amount-name-container  calculation-edit__item-amount-name-container">
                    <button className="btn--background-image products__delete-button" onClick={() => {
                        this.props.deleteProduct(this.props.product.data.product_id, this.props.product.data.project_product_id)
                    }}>
                        <img src={trash} alt={t('web.common.delete')}/>
                    </button>
                    <span className="calculation__item-name">{this.props.product.data.name}</span>
                </div>
                <table className="calculation-edit__input-container">
                    <tbody>
                    {/* First row */}
                    <tr>
                        <td>
                            {/* Units / amount */}
                            <div className="calculation-edit__item-container"
                                style={colWidth.small}>
                                <input
                                    className="calculation-edit__item calculation-edit__item-amount"
                                    maxLength={6}
                                    onBlur={this.handleEditAmount}
                                    type="number"
                                    min="1"
                                    pattern="[0-9]{1,6}"
                                    defaultValue={`${this.props.product.data.quantity}`}/>
                            </div>
                        </td>
                        <td>
                            {/* Duration */}
                            <div
                                className={classNames("calculation-edit__item-container", { "calculation-edit__item-container--disabled": productType === calcUtils.ProductType.FixedPrice })}
                                style={colWidth.medium}>
                                <SelectDuration
                                    handleChangeDuration={this.handleChangeDuration}
                                    disabled={productType === calcUtils.ProductType.FixedPrice}
                                    value={this.getLeasePeriod(this.props.product.data)}
                                    productType={productType}
                                    country={this.props.country}
                                    product={this.props.product.data}
                                />
                            </div>
                        </td>
                        <td className="text-center marked marked-dark">
                        {/* Original fixed prive */}
                        { is_monthly_product ? 
                            <span className="calculation-edit__item disabled">--</span> :
                            <div className={`text-center ${!(is_fixed_fee_active || is_fixed_selected) || has_fixed_price_changed ? 'disabled strikethrough' : ''}`}>
                                <span className={`calculation-edit__item ${!(is_fixed_fee_active || is_fixed_selected) || has_fixed_price_changed ? 'strikethrough' : ''}`}>
                                    {this._toPriceString(discounted_fixed_price)} 
                                </span> {this.props.currency.toUpperCase()} <span
                                    className="calculation-edit__item-vat">{this.getVatText(this.props.product.data)}</span>
                            </div>
                        }
                        </td>
                        <td className="text-center marked marked-dark">
                        {/* Original monthly price */}
                            { is_fixed_selected ?
                                <span className="calculation-edit__item disabled">--</span> :
                                <div className={`text-center ${has_monthly_price_changed ? 'disabled strikethrough' : ''}`}>
                                    <span className={`calculation-edit__item ${has_monthly_price_changed ? 'strikethrough':''}`}>
                                        {this._toPriceString(discounted_monthly_price)} 
                                    </span> {this.props.currency.toUpperCase()} <span
                                        className={`calculation-edit__item-vat ${has_monthly_price_changed ? '':''}`}
                                        >{this.getVatText(this.props.product.data)}</span>
                                </div>
                            }
                        </td>

                        <td className="marked marked-dark">
                            {/* Set discount by percent */}
                            <div 
                                className={`calculation-edit__item-container ${this.props.product.data.percentage_adjustment !== 0 ? 'active-discount' : ''}`}>
                                <input
                                    maxLength={5}
                                    className="calculation-edit__item calculation-edit__item-discount"
                                    onKeyPress={e => { if (e.key === 'Enter') this.handleEditDiscount(e)}}
                                    onClick={() => this.percentInputRef.select()}
                                    onBlur={this.handleEditDiscount}
                                    ref={percentInputRef => this.percentInputRef = percentInputRef}
                                    type="text"
                                    max="100"
                                    min="0"
                                    pattern="[0-9]{1,3}"
                                    defaultValue={this.props.product.data.percentage_adjustment}/>
                                <span>%</span>
                            </div>
                        </td>
                    </tr>
                    
                    {/* Second row */}
                    <tr>
                    <td></td>
                    <td>
                    {/* Fixed fee */}
                        {
                            productType === calcUtils.ProductType.ComboPrice && this.props.product.data.lease_period > 0 &&

                            <div className={'calculation-edit__fixedfee-container ' + (this.props.product.data.fixed_fee ? '' : 'off')}>
                                <Checkbox onChange={e => this.handleEnableFixedFee(e.target.checked)} style={{ float: 'right' }}
                                        label={t('web.calculation.initialCost')}
                                        checked={this.props.product.data.fixed_fee}/>
                            </div>
                        }
                    </td>

                    <td className="marked-dark">
                    {/* Fixed price */}
                        <div 
                            className={`calculation-edit__item-container ${is_monthly_product || !(is_fixed_fee_active || is_fixed_selected) ? 'no-border' : ''}`}
                            onClick={() => is_fixed_fee_active ? this.focusFixedPrice() : null}
                            style={colWidth.large}>
                            { is_monthly_product ?
                            <span className="calculation-edit__item disabled">--</span>
                            :
                                has_fixed_price_changed || this.state.isEditingFixed ?

                                <div className={is_fixed_fee_active || is_fixed_selected ? '':'disabled strikethrough'}>
                                    { has_fixed_price_changed && (is_fixed_fee_active || is_fixed_selected) &&
                                        <span className={`reset-button`}
                                            onClick={e => {
                                                e.stopPropagation(); // Preventing parent's onclick to fire
                                                this.handleEditFixedFee({target: {value: ''}});
                                                this.setState({isEditingFixed: false});
                                            }}>x</span>
                                    }
                                    <TextInput
                                        className={`calculation-edit__item ${is_fixed_fee_active || is_fixed_selected ? '' : 'strikethrough'} `}
                                        onBlur={this.setFixedPrice}
                                        type="number"
                                        maxLength="10"
                                        onKeyPress={e => { if (e.key === 'Enter') this.handleEditFixedFee(e)}}
                                        getRef={fixedInputRef => this.fixedInputRef = fixedInputRef}
                                        disabled={!(is_fixed_fee_active || is_fixed_selected)}
                                        style={{ textAlign: 'right' }}
                                        value={this._toPriceString(fixed_price)}/>
                                        {" "+this.props.currency.toUpperCase()} <span
                                    className="calculation-edit__item-vat">{this.getVatText(this.props.product.data)}</span>
                                </div>
                                :

                                is_fixed_fee_active || is_fixed_selected ?
                                
                                <div tabIndex={0} 
                                className="edit-price-btn"
                                onFocus={this.focusFixedPrice}>{t('web.calculation.manualEdit')}</div>
                                :
                                <span className="calculation-edit__item disabled">--</span>
                            } 
                        </div>
                    </td>

                    {/* Monthly price */}
                    <td className="marked-dark">
                        <div className={`calculation-edit__item-container ${is_fixed_selected ? 'no-border' : ''}`}
                            onClick={this.focusMonthlyPrice}
                            style={colWidth.large}>
                            { is_fixed_selected ?
                                <div>
                                    <span className="calculation-edit__item disabled">--</span>
                                </div> :
                                has_monthly_price_changed || this.state.isEditingMonthly ? 
                                <div>
                                    { has_monthly_price_changed &&
                                        <span className={`reset-button`}
                                            onClick={e => {
                                                e.stopPropagation(); // Preventing parent's onclick to fire
                                                this.handleEditPrice({target: {value: ''}});
                                                this.setState({isEditingMonthly: false});
                                            }}>x</span>
                                    }
                                    <TextInput
                                        className="calculation-edit__item"
                                        onBlur={this.setMonthlyPrice}
                                        type="number"
                                        maxLength="10"
                                        onKeyPress={e => { if (e.key === 'Enter') this.handleEditPrice(e)}}
                                        getRef={monthlyInputRef => this.monthlyInputRef = monthlyInputRef}
                                        style={{ textAlign: 'right' }}
                                        value={this._toPriceString(monthly_price)}/>
                                        {" "+this.props.currency.toUpperCase()} <span
                                        className="calculation-edit__item-vat">{this.getVatText(this.props.product.data)}</span>

                                </div> :
                                <div tabIndex={0}
                                    className="edit-price-btn"
                                    onFocus={this.focusMonthlyPrice}>{t('web.calculation.manualEdit')}</div>
                            }
                        </div>
                    </td>
                    <td className="marked-dark"></td>
                    </tr>

                    </tbody>
                </table>
            </li>
        );
    }

    public render() {
        const { connectDragSource, connectDropTarget } = this.props;
        return connectDragSource(connectDropTarget(this.getEditProduct()));
    }

    private _toPriceString(price: number): string{
        return price.toFixed(2);
    }

    private focusMonthlyPrice = (): void => {
        this.setState({isEditingMonthly: true}, () => {
            if (this.monthlyInputRef) {
                this.monthlyInputRef.focus();
                this.monthlyInputRef.select();
            }
        });
    }

    private setFixedPrice = (event): void => {
            const discount = this.props.product.data.percentage_adjustment;
            const original_price = this.props.product.data.fixed_price_in_cents / 100;
            const discounted_price = this._toPriceString((1-(discount/100)) * original_price);
            const new_price = event.target.value;
            if((discount !== 0) && (new_price === discounted_price)){
                //this.setState({isEditingFixed: false});
                return;
            } else if (new_price === discounted_price) {
                //this.setState({isEditingFixed: false});
                return;
            } else {
                this.handleEditFixedFee(event);
            }
    }

    private focusFixedPrice = (): void => {

        this.setState({isEditingFixed: true}, () => {
            if (this.fixedInputRef) {
                this.fixedInputRef.focus();
                this.fixedInputRef.select();
            }
        });
    }

    private setMonthlyPrice = (event): void => {
            const discount = this.props.product.data.percentage_adjustment;
            const original_price = this.props.product.getBasePrice().price / 100;
            const discounted_price = this._toPriceString((1-(discount/100)) * original_price);
            const new_price = event.target.value;
            if((discount !== 0) && (new_price === discounted_price)){
                //this.setState({isEditingMonthly: false});
                return;
            } else if (new_price === discounted_price) {
                //this.setState({isEditingMonthly: false});
                return;
            } else {
                this.handleEditPrice(event);
            }
    }


    // Converts the new number/price format (x.xx) to whole ints
    private _priceStringToNumber(price_string: string): number{
        const [whole_num, decimal] = price_string.split('.');
        
        if (!decimal){
            return Number(whole_num) * 100;
        } else {
            const len = decimal.length;
            if (len === 2) {
                return Number(whole_num + '' + decimal);
            } else if (len === 1) {
                return Number(whole_num + '' + decimal + '0');
            } else if (len > 2) {
                return Number(whole_num + '' + decimal[0] + '' + decimal[1]);
            } else {
                return Number(whole_num) * 100;
            }
        }
    }
}

interface ISelectDurationProps {
    handleChangeDuration: (event: any) => void;
    value: string;
    disabled?: boolean;
    productType?: calcUtils.ProductType;
    country: string;
    product: IProduct;
}

class SelectDuration extends Component<ISelectDurationProps, {}> {

    public static defaultProps: Partial<ISelectDurationProps> = {
        disabled: false,
        productType: calcUtils.ProductType.Unknown
    }
 
    public render() {
        const country = this.props.country;
        const product = this.props.product
        const m = t('web.calculation.monthsAbbreviated');

        let durationIntervals = [];
        if (product) {
            if (country === 'Poland') {
                if (product.price_12_months_cents >= 0) {
                    durationIntervals.push({ value: '12', name: '3' +m });
                }
                if (product.price_24_months_cents >= 0) {
                    durationIntervals.push({ value: '24', name: '13' +m });
                }
                if (product.price_36_months_cents >= 0) {
                    durationIntervals.push({ value: '36', name: '24' +m });
                }
                if (product.price_60_months_cents >= 0) {
                    durationIntervals.push({ value: '60', name: '36' +m });
                }             
            } else {
                if (product.price_12_months_cents >= 0) {
                    durationIntervals.push({ value: '12', name: '12' +m });
                }
                if (product.price_24_months_cents >= 0) {
                    durationIntervals.push({ value: '24', name: '24' +m });
                }
                if (product.price_36_months_cents >= 0) {
                    durationIntervals.push({ value: '36', name: '36' +m });
                }
                if (product.price_60_months_cents >= 0) {
                    durationIntervals.push({ value: '60', name: '60' +m });
                }  
            }            
        } else {
            if (country === 'Poland') {
                durationIntervals = [
                    { value: '12', name: '3' +m },
                    { value: '24', name: '13' +m },
                    { value: '36', name: '24' +m },
                    { value: '60', name: '36' +m }            
                ]; 
            } else {
                durationIntervals = [
                    { value: '12', name: '12' +m },
                    { value: '24', name: '24' +m },
                    { value: '36', name: '36' +m },
                    { value: '60', name: '60' +m }            
                ];   
            }
        }
                     
        if (this.props.productType === calcUtils.ProductType.FixedPrice) {
            return (
                <select className="calculation-edit__item calculation-edit__item-duration"
                        disabled={this.props.disabled} value={0}>
                    <option value="0">{t("web.calculation.fixedPrice")}</option>
                </select>
            );
        } else if (this.props.productType === calcUtils.ProductType.Leasing) {
            return (
                <select className="calculation-edit__item calculation-edit__item-duration"
                        disabled={this.props.disabled} value={this.props.value}
                        onChange={this.props.handleChangeDuration}>
                        {durationIntervals.map((e, key) => {
                            return <option key={key} value={e.value}>{e.name}</option>;
                        })}
                </select>
            );
        } else {
            return (
                <select className="calculation-edit__item calculation-edit__item-duration"
                        disabled={this.props.disabled} value={this.props.value}
                        onChange={this.props.handleChangeDuration}>
                        <option value="0">{t("web.calculation.fixedPrice")}</option>
                        {durationIntervals.map((e, key) => {
                            return <option key={key} value={e.value}>{e.name}</option>;
                        })}
                </select>
            );
        }
    }

}
