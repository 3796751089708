import React, { Component } from 'react';
import classNames from 'classnames';
import { IMatrixElement } from '../interfaces';

export default class RiskMatrixElement extends Component<IMatrixElement> {
    constructor(props) {
        super(props);
    }

    public render() {
        let isSelected = false;
        this.props.areas
            .map(({ id }) => id)
            .forEach((id) => {
                if (this.props.selectedRisks.includes(id)) {
                    isSelected = true;
                }
            });

        return (
            <div
                className={classNames(
                    'risk-matrix__element',
                    isSelected ? 'selected' : ''
                )}
                onClick={() => {
                    this.props.onClick(this.props.areas.map(({ id }) => id));
                }}
            >
                <div className={classNames('color-box', this.props.color)} />
                <div
                    className={classNames('count', {
                        hidden: this.props.count === 0,
                    })}
                >
                    {this.props.count}
                    <div>Risks</div>
                </div>
            </div>
        );
    }
}
