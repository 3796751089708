import React, { Component } from 'react';
import t from '../i18n';
import Search from '../search/search';
import NewRisk from './new-risk';
import Popover from 'react-popover';
import EditRisks from './edit/edit-risks';
import Risk, { IRisk } from './risk';
import classNames from 'classnames';
import riskIcon from '../img/risk-colored.svg';
import scenarioIcon from '../img/content-indicator/scenario.svg';
import Modal from '../common/modal/modal';
import Sketcher from '../common/sketcher/sketcher';
import RiskMatrix from './matrix/risk-matrix';
import { IEditRisksProps } from './edit/edit-risks';
import { IRiskListEditingStateProps } from './interfaces';
import { IImage } from '../image/imagelist';
import AddRisk from './add-risk';

interface IRiskGridProps extends IEditRisksProps, IRiskListEditingStateProps {
    newRiskViewExpanded: any;
    toggleSetNewRiskExpanded: (any) => any;
    risksForArea: IRisk[];
    activePane: string;
    visibleRisks: any;
    editRisks: boolean;
    clickOnListItemHandler: any;
    toggleEditRisks: any;
    handleOnClickRisk: any;
    projectRisks: IRisk[];
    allSolutions?: any[];
    projectAreas?: any[];
}

interface IRiskGridState {
    missingScenariosModalVisible: boolean;
    selectedSketchImage?: IImage;
}

export default class Grid extends Component<IRiskGridProps, IRiskGridState> {
    state = {
        missingScenariosModalVisible: false,
        selectedSketchImage: null,
    };

    doneEditing = (proxy) => {
        const activeRisk: IRisk =
            this.props.risksForArea[this.props.activeRiskIndex];

        if (activeRisk.scenarios.length > 0) {
            this.props.toggleEditRisks(0);
        } else {
            this.setState({ missingScenariosModalVisible: true });
        }
    };

    closeRisk = () => {
        this.returnToRisk();

        const activeRisk: IRisk =
            this.props.risksForArea[this.props.activeRiskIndex];

        this.props.editRiskHandler({
            ...activeRisk,
            probability: -1,
            impact: -1,
        });

        this.props.toggleEditRisks(0);
    };

    returnToRisk = () => {
        this.setState({ missingScenariosModalVisible: false });
    };

    onSaveSketchImage = (file: File) => {
        const activeRisk: IRisk =
            this.props.risksForArea[this.props.activeRiskIndex];
        this.props.handleImageEvent(
            [file],
            activeRisk.id,
            'UPDATE',
            this.state.selectedSketchImage.id
        );
        this.setState({ selectedSketchImage: null });
    };

    onSelectSketchImage = (image: IImage) => {
        this.setState({ selectedSketchImage: image });
    };

    public render() {
        const activeRisks = this.props.projectRisks.filter((risk) => {
            return (
                this.props.projectAreas.find(
                    (area) => area.area_id === risk.area_id
                ) != null
            );
        });

        const segment =
            this.props.risks[0] != null
                ? this.props.risks[0].segment_name
                : null;

        return (
            <div
                className={classNames(
                    'risks',
                    this.props.editRisks ? 'dark' : ''
                )}
            >
                {this.props.activePane === 'riskManagement' && (
                    <div className="risks__grid-container">
                        {this.props.editRisks && (
                            <div className="risks__actions--right">
                                <button
                                    className="btn btn--margin-left"
                                    style={{ float: 'right' }}
                                    onClick={this.doneEditing}
                                >
                                    {this.props.editRisks
                                        ? t('web.risk.doneEditing')
                                        : t('web.risk.editRisks')}
                                </button>
                            </div>
                        )}
                        {this.props.editRisks && (
                            <EditRisks
                                risks={this.props.visibleRisks}
                                activeRiskIndex={this.props.activeRiskIndex}
                                projectImages={this.props.projectImages}
                                handleImageEvent={this.props.handleImageEvent}
                                onSelectSketchImage={this.onSelectSketchImage}
                                changeActiveRiskState={
                                    this.props.changeActiveRiskState
                                }
                                editRiskHandler={this.props.editRiskHandler}
                                editAttemptedClosed={
                                    this.props.editAttemptedClosed
                                }
                                editedRisksWithoutSolutions={
                                    this.props.editedRisksWithoutSolutions
                                }
                            />
                        )}
                        {this.state.selectedSketchImage && (
                            <Sketcher
                                image={this.state.selectedSketchImage}
                                onSave={this.onSaveSketchImage}
                                onClose={() =>
                                    this.setState({ selectedSketchImage: null })
                                }
                            />
                        )}
                        {this.state.missingScenariosModalVisible && (
                            <Modal
                                title={
                                    <span>
                                        <img
                                            src={scenarioIcon}
                                            alt={t('web.risk.scenario')}
                                        />{' '}
                                        {t(
                                            'web.risk.scenario.modal.missingScenario'
                                        )}
                                    </span>
                                }
                                onClose={this.returnToRisk}
                                closeOnClickOutside={false}
                            >
                                <p>
                                    {t(
                                        'web.risk.scenario.modal.noScenariosWereAdded'
                                    )}
                                </p>
                                <div>
                                    <button
                                        className="btn"
                                        onClick={this.closeRisk}
                                        style={{ marginRight: '8px' }}
                                    >
                                        {t('web.risk.scenario.modal.closeRisk')}
                                    </button>
                                    <button
                                        className="btn"
                                        onClick={this.returnToRisk}
                                    >
                                        {t(
                                            'web.risk.scenario.modal.returnToRisk'
                                        )}
                                    </button>
                                </div>
                            </Modal>
                        )}
                        {!this.props.editRisks && (
                            <div className="grid">
                                <AddRisk
                                    toggleRiskListEditingState={
                                        this.props.toggleRiskListEditingState
                                    }
                                />
                                {this.props.visibleRisks.map((risk, index) => {
                                    const hasPicture =
                                        this.props.projectImages.find(
                                            (projectImage) =>
                                                projectImage.risk_id === risk.id
                                        ) != null;
                                    return (
                                        <Risk
                                            allSolutions={
                                                this.props.allSolutions
                                            }
                                            risk={risk}
                                            key={index}
                                            hasPicture={hasPicture}
                                            onClick={() =>
                                                this.props.handleOnClickRisk(
                                                    risk
                                                )
                                            }
                                        />
                                    );
                                })}
                            </div>
                        )}
                    </div>
                )}
                {this.props.activePane === 'riskMatrix' && (
                    <RiskMatrix
                        risks={activeRisks}
                        toggleEditRisks={this.props.toggleEditRisks}
                        segment={segment}
                    />
                )}
            </div>
        );
    }
}
