import { Product } from "./base-product";

export class ComboPriceProduct extends Product {
    getFixedPrice = (includeVat: boolean = true) => {
        if (!this.data["is_fixed_price"]) {
            return 0;
        }

        return this.calculatePrice(this.getBasePrice(true), includeVat);

    };

    getLeasingPrice = (includeVat: boolean = true) => {
        return this.calculatePrice(this.getBasePrice(false), includeVat);
    };

    calculatePrice = ({ price, adjustedPrice }, includeVat) => {
        let vat: number = this.data.value_added_tax_percentage;
        if (includeVat && this.data["value_added_tax_enabled"] && !this.data["price_includes_vat"]) {
            price = this.addVat(price, vat);
            adjustedPrice = this.addVat(adjustedPrice, vat);
        } else if (!includeVat &&  this.data["price_includes_vat"]) {
            price = this.removeVat(price, vat);
            adjustedPrice = this.removeVat(adjustedPrice, vat);
        }

        if (adjustedPrice > 0) {
            return adjustedPrice;
        }

        let percentageAdjustment = this.data["percentage_adjustment"] || 0;
        return this.calculateDiscount(price, percentageAdjustment);

    };

    getMonthlyVat = () => {
        return (this.getLeasingPrice(true) - this.getLeasingPrice(false)) / 100;
    };

    getFixedPriceVat = () => {
        return (this.getFixedPrice(true) - this.getFixedPrice(false)) / 100;
    };

    getEditPrice = () => {
        return this.getLeasingPrice();
    };

    getDisplayPrice = (includeVat: boolean = true) => {
        let leasingPrice = this.getLeasingPrice(includeVat);
        let fixedPrice = this.getFixedPrice() > 0 ? this.getFixedPrice() : null;
        return [leasingPrice, fixedPrice];
    };

}