import * as React from 'react';
import classNames from 'classnames';

import t, { d } from '../i18n';
import './new-project.css';
import Input, { TextArea } from '../input/input';
import user from '../img/login-username.svg';
import location from '../img/location.svg';
import information from '../img/information.svg';
import folder from '../img/folder.svg';
import NewCustomers from '../new-customer/new-customers';
import Select from '../input/select';
import TextCounter from '../input/text-counter';
import ConfirmDialog from '../common/confirm-dialog/confirm-dialog';


class NewProjectForm extends React.Component<any, any> {

    state = {
        showSegmentChangeConfirm: false,
        segmentId: 0
    }
    
    update = updateFunction => {

        const projectUpdated = this.props.projectUpdated;
        const project = { ...this.props.project };

        return function (event) {
            let updatedProject = project;
            updateFunction(event, updatedProject);
            projectUpdated(updatedProject);
        };
    };

    onSegmentChangeConfirmed = () => {
        const updatedProject = { ...this.props.project };
        updatedProject.segment_id = this.state.segmentId;
        this.props.projectUpdated(updatedProject);
        this.setState({
            showSegmentChangeConfirm: false    
        });
    }

    onSegmentChange = (segmentId) => {
        
        if(!this.props.editing) {
            const updatedProject = { ...this.props.project };
            updatedProject.segment_id = segmentId;
            this.props.projectUpdated(updatedProject);
            return;
        }

        this.setState({
            segmentId,
            showSegmentChangeConfirm: true
        });
    }

    onCancel = () => {
        this.setState({
            showSegmentChangeConfirm: false
        });
    }

    public render() {

        let translatedSegments = this.props.segments.map(segment => { 
            return {
                segment_id: segment.segment_id, 
                segment_name: d('risk', segment.segment_name)
            }
        });

        translatedSegments.sort((a,b) => a.segment_name.localeCompare(b.segment_name));

        const project = this.props.project;

        return (
            <div>
            <form className="new-project__form" onSubmit={this.props.submitProject}>
                <Input
                    placeholder={t('web.newProject.title')}
                    supressEnter={true}
                    value={project.name}
                    name="title"
                    onBlur={this.update((e, project) => {
                        project.name = e.target.value;
                    })}
                    image={information}
                    required={false}/>
                <Input
                    placeholder={t('web.newProject.client')}
                    supressEnter={true}
                    value={project.client.name}
                    name="client"
                    onBlur={this.update((e, project) => {
                        project.client.name = e.target.value;
                    })}
                    image={user}
                    required={true}/>
                <NewCustomers
                    customers={project.client.contacts}
                    customersUpdated={this.update((updatedCustomers, project) => project.client.contacts = updatedCustomers)}
                    client_id={project.client_id}
                    project_id={project.project_id}/>
                <div className="new-project__form-client">
                    <div className="new-project__adresse-container">
                        <Input
                            conditionalClasses="new-project__adresse"
                            placeholder={t('web.newProject.visitingAddress')}
                            supressEnter={true}
                            value={project.client.streetAddressOne}
                            onBlur={this.update((e, project) => project.client.streetAddressOne = e.target.value)}
                            name="visitingAddress"
                            image={location}
                            />
                        <Input
                            conditionalClasses="new-project__postalcode"
                            placeholder={t('web.newProject.postalCode')}
                            supressEnter={true}
                            value={project.client.postalCodeOne}
                            onBlur={this.update((e, project) => project.client.postalCodeOne = e.target.value)}
                            name="visitingAddressPostalCode"
                            />
                        <Input
                            conditionalClasses="new-project__city"
                            placeholder={t('web.newProject.city')}
                            supressEnter={true}
                            value={project.client.cityOne}
                            onBlur={this.update((e, project) => project.client.cityOne = e.target.value)}
                            name="visitingAddressCity"
                            />
                    </div>
                    <div className="new-project__adresse-container">
                        <Input
                            conditionalClasses="new-project__adresse"
                            placeholder={t('web.newProject.billingAddress')}
                            supressEnter={true}
                            value={project.client.streetAddressTwo}
                            onBlur={this.update((e, project) => project.client.streetAddressTwo = e.target.value)}
                            name="billingAddress"
                            image={location}
                            />
                        <Input
                            conditionalClasses="new-project__postalcode"
                            placeholder={t('web.newProject.postalCode')}
                            supressEnter={true}
                            value={project.client.postalCodeTwo}
                            onBlur={this.update((e, project) => project.client.postalCodeTwo = e.target.value)}
                            name="billingAddressPostalCode"
                            />
                        <Input
                            conditionalClasses="new-project__city"
                            placeholder={t('web.newProject.city')}
                            supressEnter={true}
                            value={project.client.cityTwo}
                            onBlur={this.update((e, project) => project.client.cityTwo = e.target.value)}
                            name="billingAddressCity"
                            />
                    </div>
                    <Select
                        onChange={(e) => this.onSegmentChange(e.target.value)}
                        label={t('web.newProject.valueChain')}
                        placeholder={t('web.newProject.chooseSegment')}
                        value={project.segment_id}
                        values={translatedSegments}
                        image={user}
                        required={true}/>
                    <Input
                        placeholder={t('web.newProject.companyRegistrationNumber')}
                        supressEnter={true}
                        value={project.client.organisationNumber}
                        onBlur={this.update((e, project) => project.client.organisationNumber = e.target.value)}
                        name="companyRegistrationNumber"
                        image={folder}
                        />
                    <TextArea
                        placeholder={t('web.newProject.projectDescription')}
                        autoResize={true}
                        value={project.client.clientDescription}
                        onChange={this.update((e, project) => project.client.clientDescription = e.target.value)}
                        name="description"
                        image={information}
                        maxLength={350}
                        />
                    <p className="new-project__text-counter"><TextCounter length={project.client.clientDescription.length} limit={250} /></p>
                </div>
                <div className="new-project__form-buttonrow">
                    <button
                        type="submit"
                        disabled={project.isFetching}
                        className={classNames("btn new-project__form-done-button", { "btn--spinner": project.isFetching })}>
                        {t('web.newProject.saveNewProject')}
                    </button>
                    <button
                        disabled={this.props.isFetching}
                        onClick={this.props.onCancelAddNewProject}
                        className={classNames("btn btn--dark-gray new-project__form-done-button", { "btn--spinner": this.props.isFetching })}>
                        {t('web.common.cancel')}
                    </button>
                    <div className="spacer"></div>
                    {
                        project.project_id > 0 &&
                        <a onClick={(e) => this.props.onDeleteProject(project)}>
                            {t('web.projects.deleteproject')}
                        </a>
                    }
                </div>
            </form>
            {this.state.showSegmentChangeConfirm && 
                <ConfirmDialog
                    title={t("web.projects.segmentChange.title")}
                    message={t("web.projects.segmentChange.message")}
                    onCancel={this.onCancel}
                    onOK={this.onSegmentChangeConfirmed}/>
            }
        </div>
        );
    }
}

export default NewProjectForm;
