import ReactGa from 'react-ga4';

	export const startAnalytics = (trackers, user, pathname, search) => {
		if(trackers.length > 0) {
      return; 
    }

    if(user && process.env.NODE_ENV === 'production') {

			const countryTracker = user.country.ga_key;
			const defaultTracker = process.env.GOOGLE_ANALYTICS_ID || 'UA-40013591-1';
			const trackers = ['defaultTracker', 'countryTracker'];
      const username_lowercase = user.adProfile.unique_name.toLowerCase();

			window.reactGa = ReactGa;
      window.reactGa.initialize([
        {
          trackingId: defaultTracker,
          debug: false,
          gaOptions: {
            name: 'defaultTracker',
            userId: username_lowercase
          }
        },
        {
          trackingId: countryTracker,
          debug: false,
          gaOptions: {
            name: 'countryTracker',
            userId: username_lowercase
          }
        }
      ]);

      // Send the username as a custom dimension to all the trackers
      window.reactGa.gtag("set", "user_properties", {
         username: username_lowercase,
         appName: 'SST-Web'
      });

      window.reactGa.send("pageview");
      return trackers;
    }
    return [];	
	}
  
		export const trackPage = (trackers, pathname, search) => {
			if(trackers.length > 0 && process.env.NODE_ENV === 'production') {
        // window.reactGa.gtag("set", "user_properties", {
        //    username: username_lowercase,
        //    appName: 'SST-Web'
        // });
				window.reactGa.send("pageview");
			}
		}

		export const trackEvent = (eventName) => {
      if(process.env.NODE_ENV === 'production') {
        const trackers = ['defaultTracker', 'countryTracker'];
        // window.reactGa.gtag("set", "user_properties", {
        //    username: username_lowercase,
        //    appName: 'SST-Web'
        // });
        window.reactGa.event({
          category: 'Stats',
          action: eventName
        }, trackers); 
			}
		}

