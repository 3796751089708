import * as React from 'react';
import placeholderDark from '../img/placeholder-dark.svg';
import Modal from "../common/modal/modal";
import { endsWith } from '../utils/utils';

const getProductImageUrl = (product) => {
    let allowedFormats = ['.png', '.gif', '.jpg', 'jpeg', '.bmp', '.tiff', '.tif'];
    let returnValue = placeholderDark;
    if (product.main_photo) {
        allowedFormats.forEach(format => {
            if (endsWith(product.main_photo.url, format)) {
                returnValue = product.main_photo.url;
            }
        });
    }
    return returnValue;
}

const ProductImageDialog = ({ product, closeDialog }) => {
    return (
        <Modal title="" onClose={closeDialog}>
            <div style={{ display: 'flex', justifyContent: 'space-around' }}>
                <img onClick={closeDialog} className="calculation__item-image-full" src={getProductImageUrl(product)}/>
            </div>
        </Modal>
    );
}

export default ProductImageDialog;
