// @ts-nocheck
// TODO remove above
import React, { Component } from 'react';
import RiskMatrixElement from './risk-matrix-element';
import { IRisk } from '../risk';
import { IMatrixRow } from '../interfaces';

export default class RiskMatrixRow extends Component<IMatrixRow, any> {
    private getCountByImpact = (impact) => {
        let risks = this.props.data;
        let count = 0;

        for (let risk in risks) {
            if (risks[risk].impact === impact) {
                count++;
            }
        }
        return count;
    };
    private getRisksByImpact = (impact: number): IRisk[] => {
        let risks = this.props.data;
        let result = [];

        for (let risk in risks) {
            if (risks[risk].impact === impact) {
                result.push(risks[risk]);
            }
        }
        return result;
    };

    constructor(props) {
        super(props);
        this.props = props;
    }

    public render() {
        return (
            <div className="risk-matrix__row" data-impact={this.props.impact}>
                {this.generateRow()}
            </div>
        );
    }

    private generateRow(): Array<RiskMatrixElement> {
        let elements = [];
        const layoutArray = this.props.layout;
        if (!layoutArray) return;
        for (let i = 0; i < layoutArray.length; i++) {
            elements.push(
                <RiskMatrixElement
                    selectedRisks={this.props.selectedRisks}
                    key={i}
                    color={layoutArray[i]}
                    count={this.getCountByImpact(i)}
                    areas={this.getRisksByImpact(i)}
                    onClick={this.props.onElementClicked}
                />
            );
        }

        return elements;
    }
}
