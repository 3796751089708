import {
    DELETE_SURVEY_ATTACHMENT_SUCCEEDED,
    EDIT_ADVANCED_RISKS_CAN_REQUESTED,
    EDIT_QUESTIONARIES_SUCCEEDED,
    FETCH_ADVANCED_RISK_SCAN_REQUESTED,
    FETCH_ADVANCED_RISK_SCAN_SUCCEEDED,
    POST_SURVEY_ATTACHMENT_SUCCEEDED,
    EDIT_SURVEY_ATTACHMENT_SUCCEEDED,
    EDIT_SUMMARY_SUCCEEDED
} from './actions';

export interface SurveyState {
    isFetching: boolean,
    survey: any[],
    answers: { [key: number]: any[]; },
    images: { [key: number]: any[]; },
    summaries: { [key: number]: any[]}
}

const initialState: SurveyState = {
    isFetching: false,
    survey: [],
    answers: {},
    images: {},
    summaries: {}
};

export default function riskAdvanced(state = initialState, action) {
    switch (action.type) {
        case FETCH_ADVANCED_RISK_SCAN_REQUESTED:
            return { ...state, isFetching: true };
        case FETCH_ADVANCED_RISK_SCAN_SUCCEEDED: {
            let newAnswers = { ...state.answers };
            newAnswers[action.payload.project_id] = action.payload.answers;
            let newImages = { ...state.images };
            newImages[action.payload.project_id] = action.payload.images;

            let newSummaries = { ...state.summaries };
            newSummaries[action.payload.project_id] = action.payload.summaries;

            // TODO: Remove when/if backend is updated
            // The database has some extra data that adds a question_group to this survey by mistake
            // This causes the render to be wrong, so I'm simply removing the question group in this case

            if(action.payload.survey[0].id === 9) {
                action.payload.survey[0].question_groups = [];
            }

            if(action.payload.survey[1].id === 10) {
                action.payload.survey[1].question_groups = [];
            }

            return {
                ...state,
                ...action.payload,
                answers: newAnswers,
                images: newImages,
                summaries: newSummaries,
                isFetching: false
            };
        }
        case EDIT_ADVANCED_RISKS_CAN_REQUESTED:
            return {
                ...state,
                riskScan: action.payload.riskScan
            }
        case POST_SURVEY_ATTACHMENT_SUCCEEDED: {
            let newImages = { ...state.images };
            newImages[action.payload.project_id] = action.payload.images;
            return { ...state, images: newImages }
        }
        case EDIT_SURVEY_ATTACHMENT_SUCCEEDED: {
            let newImages = { ...state.images };
            newImages[action.payload.project_id] = action.payload.images;
            return { ...state, images: newImages }
        }
        case DELETE_SURVEY_ATTACHMENT_SUCCEEDED: {
            let newImages = { ...state.images };
            newImages[action.payload.project_id] = action.payload.images;
            return { ...state, images: newImages }
        }
        case EDIT_QUESTIONARIES_SUCCEEDED: {
            let payloadAnswer = action.payload.answer[0];
            let updatedAnswers = { ...state.answers };
            let newProjectAnswers = state.answers[action.payload.project_id] || [];
            newProjectAnswers = state.answers[action.payload.project_id].filter(answer => {
                return answer.questions_id !== payloadAnswer.questions_id;
            });
            newProjectAnswers.push(payloadAnswer);
            updatedAnswers[action.payload.project_id] = newProjectAnswers;
            return { ...state, answers: updatedAnswers }
        }
        case EDIT_SUMMARY_SUCCEEDED: {

            let newSummaries = { ...state.summaries };
            newSummaries[action.payload.project_id] = action.payload.summaries;

            return { ...state, summaries: newSummaries }
        }
        default:
            return state;
    }
}
