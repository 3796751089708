import {ADD_ERROR, CHANGE_VIEW_STATE, REMOVE_ERROR, REMOVE_ERRORS, REMOVE_SPINNER, SHOW_SPINNER} from './actions';

export const RightSideContainerState = {
  ShowProducts: 0,
  ShowCalculation: 1,
  None: 3
}

export const ViewState = {
  Project: 1,
  RiskAssesment: 2,
  Survey: 3,
  Proposal: 4,
  None: 5,
}

const initialState = {
  isFetching: false,
  errorMessages: [],
  activeView: ViewState.RiskAssesment,
  rightSideContainerState: RightSideContainerState.showProducts,
  showAdvanced: false
};


export const setNewActiveView = (dispatch, viewState) =>
  dispatch({
    type: CHANGE_VIEW_STATE,
    payload: viewState
  })

export default function app(state = initialState, action) {
  switch (action.type) {
    case CHANGE_VIEW_STATE:
      return Object.assign({}, state,
        action.payload
      )
    case ADD_ERROR:
      return Object.assign({}, state, {
        errorMessages: state.errorMessages.concat([action.payload]),
        isFetching: false
      });
    case REMOVE_ERRORS:
      return Object.assign({}, state, {
        errorMessages: []
      });
    case REMOVE_ERROR:
      return Object.assign({}, state, {
        errorMessages: []
      });
    case SHOW_SPINNER:
      return Object.assign({}, state, {
        isFetching: true
      });
    case REMOVE_SPINNER:
      return Object.assign({}, state, {
        isFetching: false
      });
    default:
      return state;
  }
}
