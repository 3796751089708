import React from 'react';

import t from '../i18n';

const RouteNotFound = () =>
  <div>
    <h3 className="h3">{t('web.common.404heading')}</h3>
    <p>{t('web.common.404text')}</p>
  </div>;

export default RouteNotFound;
