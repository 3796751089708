// @ts-nocheck
// TODO remove above
import * as React from 'react';
import Dropzone from 'react-dropzone';

import './customer-image.css';
import cameraPhoto from '../img/photo.svg';
import { Image } from '../image/image';

const CustomerImage = ({ postAttachment, imageUrl, ...props }) => {
    return (
        <Dropzone className="customer-image__dropzone" onDrop={postAttachment}>
            {!imageUrl &&
            <img src={cameraPhoto} role="presentation"/>
            }
            {imageUrl &&
            <Image src={imageUrl} className="customer-image__image" role="presentation"/>
            }
        </Dropzone>
    );
}

export default CustomerImage;
