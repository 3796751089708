import React from 'react';

const TextCounter = ({length = 0, limit = 250}) => {

  if(length === 0) {
    return null;
  }

  const style={
    color: 'grey',
    fontSize: '0.7em'
  };
  return (<span style={style}>{length} / {limit}</span>)
}

export default TextCounter;