// @ts-nocheck
// TODO remove above
///<reference path="interfaces.d.ts"/>
import React, { Component } from 'react';

import './risk.css';

import RiskNavigation from './navigation/risk-navigation';
import Grid from './grid';
import { IRisksProps, IRisksState } from './interfaces';
import { d } from './../i18n/index';

class Risks extends Component<IRisksProps, IRisksState> {
    constructor(props) {
        super(props);
        this.state = {
            searchValue: '',
            newRiskViewExpanded: false,
            editRisks: false,
            activeRiskIndex: 0,
            visibleRisks: [],
            activePane: 'riskManagement',
            editAttemptedClosed: false,
            editedRisksWithoutSolutions: [],
        };
    }

    componentDidMount(): void {
        this.setState({ visibleRisks: this.getVisibleRisks(this.props) });
    }

    componentWillReceiveProps(nextProps): void {
        this.setState({ visibleRisks: this.getVisibleRisks(nextProps) });
    }

    public getVisibleRisks(props, searchValue = undefined): any | Array<any> {
        searchValue =
            searchValue === undefined ? this.state.searchValue : searchValue;
        if (!searchValue || searchValue.length < 1) {
            return props.risksForArea || [];
        }

        const searchValueLower = searchValue.toLowerCase();
        return props.risksForArea.filter((risk) => {
            return (
                d('risk.risk_group', risk.group_name)
                    .toLowerCase()
                    .indexOf(searchValueLower) > -1
            );
        });
    }

    public searchInputChangeHandler: (e) => any = (e) => {
        this.setState({
            searchValue: e.target.value,
            visibleRisks: this.getVisibleRisks(this.props, e.target.value),
        });
    };

    private toggleSetNewRiskExpanded: () => any = () => {
        this.setState({ newRiskViewExpanded: !this.state.newRiskViewExpanded });
    };

    private changeActiveRiskState: (index) => any = (index) => {
        this.setState({
            activeRiskIndex: index,
        });
    };

    private toggleEditRisks: (riskId) => any = (riskId) => {
        const index = this.state.visibleRisks.findIndex(
            (risk) => risk.id === riskId
        );
        this.props.toggleHideRightSideBar(!this.state.editRisks);

        this.setState({
            editRisks: !this.state.editRisks,
            activeRiskIndex: index >= 0 ? index : 0,
        });
    };

    handleClickOnRisc = (risk) => {
        if (!this.state.editRisks) {
            this.toggleEditRisks(risk.id);
        }
    };

    updatePaneState = (event) => {
        this.updatePaneStateByString(event.target.getAttribute('data-pane'));
    };

    updatePaneStateByString = (string) => {
        this.setState({ activePane: string });
    };

    private riskIsUnchanged(risk: any) {
        return (
            risk.scenarios.length < 1 &&
            (risk.impact > -1 || risk.probability > -1 || risk.note)
        );
    }

    render() {
        const {
            allRisks,
            clickOnListItemHandler,
            editRiskHandler,
            handleImageEvent,
            projectImages,
            toggleRiskListEditingState,
            project,
            projectAreas,
        } = this.props;
        return (
            <div>
                {!this.state.editRisks && (
                    <div className="risks__actions pure-menu pure-menu-horizontal">
                        <RiskNavigation
                            activePane={this.state.activePane}
                            updateFunction={this.updatePaneState}
                            allRisks={allRisks}
                            clickOnListItemHandler={clickOnListItemHandler}
                            project={project}
                            user={this.props.user}
                        />
                    </div>
                )}
                <Grid
                    newRiskViewExpanded={this.state.newRiskViewExpanded}
                    projectAreas={projectAreas}
                    allSolutions={this.props.allSolutions}
                    toggleSetNewRiskExpanded={this.toggleSetNewRiskExpanded}
                    toggleRiskListEditingState={toggleRiskListEditingState}
                    risksForArea={this.props.risksForArea}
                    activePane={this.state.activePane}
                    visibleRisks={this.state.visibleRisks}
                    editRisks={this.state.editRisks}
                    risks={allRisks}
                    activeRiskIndex={this.state.activeRiskIndex}
                    editRiskHandler={editRiskHandler}
                    projectRisks={this.props.projectRisks}
                    projectImages={projectImages}
                    changeActiveRiskState={this.changeActiveRiskState}
                    handleImageEvent={handleImageEvent}
                    clickOnListItemHandler={clickOnListItemHandler}
                    toggleEditRisks={this.toggleEditRisks}
                    handleOnClickRisk={this.handleClickOnRisc}
                    editAttemptedClosed={this.state.editAttemptedClosed}
                    editedRisksWithoutSolutions={
                        this.state.editedRisksWithoutSolutions
                    }
                />
            </div>
        );
    }
}

export default Risks;
