import React, { Component } from 'react';

import './edit-risks.css';
import t, { d } from '../../i18n';
import classNames from 'classnames';
import doc from '../../img/doc.svg';
import EditRiskComment from './edit-risk-comment';
import EditRiskOverlayMessage from './edit-risk-overlay-message';
import RiskEvaluationContainer from '../risk-evaluation-container';
import ImageList from '../../image/imagelist';
import { API_ROOT } from '../../services/api';
import { IEditRisk } from '../interfaces';
import circleVeryHigh from '../../img/risk/circle-very-high.svg';
import circleHigh from '../../img/risk/circle-high.svg';
import circleMedium from '../../img/risk/circle-medium.svg';
import circleLow from '../../img/risk/circle-low.svg';
import circleEmpty from '../../img/risk/circle-empty.svg';

export default class EditRiskContent extends Component<IEditRisk, any> {
    constructor(props) {
        super(props);
        this.state = {
            noteLength: 0
        };
    }

    private get circleIndicatorImage() {
        const risk = this.props.risk;
        const level = (risk.impact + 1) * (risk.probability + 1);

        if (level === 0) {
            return (
                <img
                    src={circleEmpty}
                    className="risk-indicator edit-risk__risk__image-content"
                    alt="risk indicator"
                />
            );
        }

        if (level > 9)
            return (
                <img
                    src={circleVeryHigh}
                    className="risk-indicator edit-risk__risk__image-content"
                    alt="risk indicator"
                />
            );

        if (level > 4)
            return (
                <img
                    src={circleHigh}
                    className="risk-indicator edit-risk__risk__image-content"
                    alt="risk indicator"
                />
            );

        if (level > 2)
            return (
                <img
                    src={circleMedium}
                    className="risk-indicator edit-risk__risk__image-content"
                    alt="risk indicator"
                />
            );

        return (
            <img
                src={circleLow}
                className="risk-indicator edit-risk__risk__image-content"
                alt="risk indicator"
            />
        );
    }

    private get indicatorText() {
        const { risk } = this.props;
        if (risk.impact === -1 || risk.probability === -1) {
            return '';
        }

        const level = (risk.impact + 1) * (risk.probability + 1);

        if (level > 9) return t('web.risk.matrixVeryHigh');

        if (level > 4) return t('web.risk.matrixHigh');

        if (level > 2) return t('web.risk.matrixMedium');

        return t('web.risk.matrixLow');
    }

    getImpactColor(level) {
        if (level === -1) {
            return '';
        }

        if (level >= 3) return 'red';

        if (level >= 2) return 'orange';

        if (level >= 1) return 'yellow';

        return 'green';
    }

    getImpactFraction(level) {
        if (level === -1) {
            return '';
        }

        if (level >= 3) return '100%';

        if (level >= 2) return '75%';

        if (level >= 1) return '50%';

        return '25%';
    }

    public render() {
        let images = this.props.images.map((image) => {
            let src = `${API_ROOT}projects/${image.project_id}/risks/images/${image.id}/content`;
            return { ...image, src };
        });
        return (
            <div
                className={classNames(
                    'edit-risk__content',
                    this.props.overlay ? 'overlay' : '',
                    {
                        'edit-risk--not-active':
                            this.props.risk.id === this.props.activeRiskIndex,
                    }
                )}
            >
                {this.props.overlay ? <div className="overlay-2" /> : ''}
                <div className="edit-risk__risk">
                    {this.props.overlay ? <EditRiskOverlayMessage /> : ''}
                    <div className="edit-risk__risk-inner-container">
                        <div className="edit-risk__risk__image">
                            {this.circleIndicatorImage}
                            <span className="edit-risk__risk__warning">
                                {this.indicatorText}
                            </span>
                        </div>
                        <div className="risk-impact-selector">
                            <div className="risk-section">
                                <span className="edit-risk__subtitle">
                                    {t('web.risk.probability')}
                                </span>
                                <div
                                    style={{
                                        backgroundColor: 'lightgray',
                                        height: '14px',
                                        width: '93%',
                                        borderRadius: '150px',
                                        marginTop: '10px',
                                    }}
                                >
                                    <div
                                        style={{
                                            width: this.getImpactFraction(
                                                this.props.risk.probability
                                            ),
                                            height: '14px',
                                            borderRadius: '150px',
                                            transition:
                                                'width 0.3s, background-color 0.5s',
                                        }}
                                        className={this.getImpactColor(
                                            this.props.risk.probability
                                        )}
                                    />
                                </div>
                                <RiskEvaluationContainer
                                    risk={this.props.risk}
                                    evalKey="probability"
                                    handleOnClickRiskEvaluation={
                                        this.props.handleOnClickRiskEvaluation
                                    }
                                />
                            </div>
                            <div className="impact-section">
                                <span className="edit-risk__subtitle">
                                    {t('web.risk.impact')}
                                </span>
                                <div
                                    style={{
                                        backgroundColor: 'lightgray',
                                        height: '14px',
                                        width: '93%',
                                        borderRadius: '150px',
                                        marginTop: '10px',
                                    }}
                                >
                                    <div
                                        style={{
                                            width: this.getImpactFraction(
                                                this.props.risk.impact
                                            ),
                                            height: '14px',
                                            borderRadius: '150px',
                                            transition:
                                                'width 0.3s, background-color 0.5s',
                                        }}
                                        className={this.getImpactColor(
                                            this.props.risk.impact
                                        )}
                                    />
                                </div>
                                <RiskEvaluationContainer
                                    risk={this.props.risk}
                                    evalKey="impact"
                                    handleOnClickRiskEvaluation={
                                        this.props.handleOnClickRiskEvaluation
                                    }
                                />
                            </div>
                        </div>
                        <div className="edit-risk__comment_title">
                            {/*<img src={doc} alt="doc" />*/}
                            <span className="edit-risk__comment_title_title">{t('web.risk.addComment')}</span>
                            <span className="edit-risk__comment_title_top_right_info">{this.state.noteLength}/400 {t('web.common.characterCount')}</span>
                        </div>
                        <EditRiskComment
                            risk={this.props.risk}
                            onChangeHandlerComment={
                                this.props.onChangeHandlerComment
                            }
                            onLengthChange={(length) => {
                                this.setState({noteLength: length})
                            }}
                            disableCharacterCount={true}
                        />
                        <div className="edit-risk__comment_title">
                            <span className="edit-risk__comment_title_title">{t('web.risk.images')}</span>
                            <span className="edit-risk__comment_title_top_right_info">{this.props.images.length} {t('web.common.imageUploadCount')}</span>
                        </div>
                        <div className="edit-risk__images_background">
                            <ImageList
                                images={images}
                                onDrop={(data) =>
                                    this.props.handleImageEvent(
                                        data,
                                        this.props.risk.id
                                    )
                                }
                                onSelectSketchImage={this.props.onSelectSketchImage}
                                onDelete={(image) => {
                                    this.props.handleImageEvent(
                                        null,
                                        this.props.risk.id,
                                        'DELETE',
                                        image.id
                                    );
                                }}
                            />
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
