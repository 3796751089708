import * as React from 'react'

import './alternatives.css';
import { Checkbox, RadioButton, TextInput } from "../input/standard-input";
import TextCounter from '../input/text-counter';
import GrowableTextArea from '../input/growable-textarea';

const getValue = (answer, optionId) => {
    let options = answer ? answer.options : [];
    let option = options.filter(option => {
        return optionId === option.option_id;
    })[0];
    if (option) {
        return option.answer;
    }
    return "";
}

const getBooleanValue = (answer, optionId) => {
    let value = getValue(answer, optionId);
    if (value === "True" || value === "true")
        return true;

    return false;
}

const handleAnswerChangedLocal = (question, alternative, handleAnswerChanged, note, event) => {
    let answer = event.target.checked ? "True" : "False";
    if (event.target.type === "textarea") {
        answer = event.target.value;
    }
    handleAnswerChanged(question, alternative, answer, note);
}

const Alternatives = ({ answer, handleAnswerChanged, ...question }) => {
    return <div className="alternatives">
        {
            question.alternatives && question.alternatives.map((alt, index) => {
                let note = answer ? answer.note : undefined;
                let handleAnswerPartial = handleAnswerChangedLocal.bind(null, question, alt, handleAnswerChanged, note);
                return <div className="alternatives__input" key={index}>
                    {
                        question.question_type === 'radiobutton' &&
                        <RadioButton value={alt.id} label={alt.label} checked={getBooleanValue(answer, alt.id)}
                                     onChange={(e) => handleAnswerPartial(e)}/>
                    }
                    {['text', 'textlabel'].includes(question.question_type) &&
                        <div>
                            <GrowableTextArea
                                key={index}
                                className="place__input place__input__textarea" 
                                defaultValue={getValue(answer, alt.id)} 
                                placeholder={alt.label}
                                maxLength={1100}
                                defaultSize={2}
                                keepExpanded={true}
                                onBlur={(e) => handleAnswerPartial(e)}
                            />
                            <div style={{ paddingLeft: '7px'}}><TextCounter length={getValue(answer, alt.id).length} limit={1100} /></div>
                        </div>   
                    }
                    {question.question_type === 'checkbox' &&
                    <Checkbox label={alt.label} checked={getBooleanValue(answer, alt.id)}
                              onChange={(e) => handleAnswerPartial(e)}/>
                    }
                </div>
            })
        }
    </div>
}

export default Alternatives
