// @ts-nocheck
// TODO remove above
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { getProjectById } from '../projects/reducer';
import Products from "../products/products-container";
import Page from '../common/page/page';
import t from '../i18n';

class SolutionAssistantContainer extends Component<any, any> {
    public render() {
        return (
            <Page title={t('web.solutions.solutions')}>
                <Products project={this.props.project}/>
            </Page>
        );
    }
}

const mapStateToProps = ({ risks, projects }, { routeParams }) => {
    let project = getProjectById(projects, routeParams.id);
    return {
        ...risks,
        project,
    }
};

const mapDispatchToProps = (dispatch) => ({
    dispatchAction: (action) => dispatch(action)
});

export default connect(mapStateToProps, mapDispatchToProps)(SolutionAssistantContainer);