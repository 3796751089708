import React, { Component } from "react";
import t from '../../i18n';
import TextCounter from '../../input/text-counter';
import {IEditRiskComment} from "../interfaces";


// We use local state here to control the value of the textarea, because otherwise we would run into issues with the value not being updated properly when changing Areas
export default class EditRiskComment extends Component<IEditRiskComment, any> {
    constructor(props) {
        super(props);
        this.state = { 
            note: props.risk.note || "",
            id: props.risk.id
        };
        this.props.onLengthChange(props.risk.note.length)
    }

    public componentWillReceiveProps = (nextProps) => {
        if (typeof nextProps.risk != 'undefined' && nextProps.risk.id != this.state.id) {
            this.setState({
                note: nextProps.risk.note,
                id: nextProps.risk.id
            });
        }
    }

    public handleChange = (e) => {
        this.setState({
            note: e.target.value
        });
        this.props.onLengthChange(e.target.value.length)
    }

    public handleSubmit = (e) => {
        this.props.onChangeHandlerComment(e, this.props.risk);
    }

    public render() {
        return (
            <div>
                <textarea
                    className="edit-risk__comment"
                    value={this.state.note}
                    name="note"
                    placeholder={t('web.risk.addComment')}
                    onChange={this.handleChange}
                    maxLength={400}
                    onBlur={this.handleSubmit}/>

                {!this.props.disableCharacterCount &&
                <TextCounter length={this.state.note.length} limit={400} />
            }
            </div>
        );
    }
}