import React, { Component } from 'react';
import { Checkbox } from '../input/standard-input';
import { d, makeIdentifier } from '../i18n';


export default class EditArea extends Component<IEditArea, any> {
    public render() {
        return (
            <li className="new-area__list-item">
                <Checkbox label={d("risk." + makeIdentifier(this.props.area.segment_name), this.props.area.area_name)} checked={this.props.checked} onChange={(e) => this.props.onSelectHandler(e, this.props.area)} />
            </li>
        );
    }
}
