import * as React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import { signOut } from '../auth/auth-helper';
import { getUserActions, updateUserActions } from '../user/actions';
import Settings from './settings'
import { setLanguage, reloadTranslations } from '../i18n/index';
import { clearTranslationCache } from '../i18n/translations';

class SettingsContainer extends React.Component<any, any> {
    static propTypes = {
        dispatchAction: PropTypes.func.isRequired,
        user: PropTypes.object
    }

    clearLanguageCache = () => {
        clearTranslationCache();
        if(this.props.user) {
            reloadTranslations(this.props.user.language);
        }
        
    }

    languageUpdated = (e, user) => {
        let newLanguage = e.target.value;
        if (newLanguage) {
            setLanguage(newLanguage).then(() => {
                let updatedUser = { ...user, language: newLanguage };
                this.props.dispatchAction(updateUserActions.started({ user: updatedUser }));
            });
        }
    }

    componentDidMount() {
        this.props.dispatchAction(getUserActions.started({}));
    }

    render() {
        const { user } = this.props;
        return (
            <div>
                {
                    user && <Settings user={user} signOut={signOut} clearLanguageCache={this.clearLanguageCache} handleLanguageUpdate={this.languageUpdated}/>
                }
            </div>
        );
    }
}

const mapStateToProps = ({ user }) => ({
    user: user.user
});

const mapDispatchToProps = (dispatch) => ({
    dispatchAction: (action) => dispatch(action)
});

export default connect(mapStateToProps, mapDispatchToProps)(SettingsContainer as any);
