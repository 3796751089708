// @ts-nocheck
// TODO remove above
import * as React from 'react';
import { Link } from 'react-router';
import classNames from 'classnames';

import './bottom-menu.css';
import Folder from '../img/folder';
import Risk from '../img/risk';
import Contract from '../img/contract';
import Survey from '../img/survey';
import Solution from '../img/solution';
import securitas from '../img/Securitas_Logotype_RedNavyBlue_RGB.svg';
import profile from '../img/profile.svg';
import Calc from '../img/calc';
import BottomMenuButton from "./bottom-menu-button";
import t from '../i18n';


const BottomMenu = ({ user, id, location }) => {
    const name = user && user.name ? user.name : '';

    const isProjectView = location.pathname === "/" || location.pathname.indexOf("/edit") > 0 || location.pathname.indexOf("new") > 0;
    const isRiskView = location.pathname.indexOf("/risk") > 0;
    const isSolutionAssistantView = location.pathname.indexOf("/solutionAssistant") > 0;
    const isSurveyView = location.pathname.indexOf("/survey") > 0;
    const isCalcView = location.pathname.indexOf("/calculation") > 0;
    const isProposalView = location.pathname.indexOf("/proposal") > 0;
    const isSettingsView = location.pathname.indexOf("/settings") > -1;

    let isDisabled = isProjectView || isSettingsView;

    return (
        <nav className="bottom-menu">
            <Link className="bottom-menu__logo" to="/">
                <img src={securitas} role="presentation"/>
            </Link>
            <div className="bottom-menu__tabs-container">
                <BottomMenuButton
                    isActive={isProjectView}
                    isDisabled={false}
                    moduleLink={'/'}
                    tabTitle={t('web.projects.title')}
                    IconElement={Folder}
                ></BottomMenuButton>
                <BottomMenuButton
                    isActive={isSurveyView}
                    isDisabled={isDisabled}
                    moduleLink={'/project/'+id+'/survey'}
                    tabTitle={t('web.survey.title')}
                    IconElement={Survey}
                ></BottomMenuButton>
                <BottomMenuButton
                    isActive={isRiskView}
                    isDisabled={isDisabled}
                    moduleLink={'/project/'+id+'/risk'}
                    tabTitle={t('web.risk.title')}
                    IconElement={Risk}
                ></BottomMenuButton>
                <BottomMenuButton
                    isActive={isSolutionAssistantView}
                    isDisabled={isDisabled}
                    moduleLink={'/project/'+id+'/solutionAssistant'}
                    tabTitle={t('web.solutions.solutions')}
                    IconElement={Solution}
                ></BottomMenuButton>
                <BottomMenuButton
                    isActive={isCalcView}
                    isDisabled={isDisabled}
                    moduleLink={'/project/'+id+'/calculation'}
                    tabTitle={t('web.calculation.calculation')}
                    IconElement={Calc}
                ></BottomMenuButton>
                <BottomMenuButton
                    isActive={isProposalView}
                    isDisabled={isDisabled}
                    moduleLink={'/project/'+id+'/proposal'}
                    tabTitle={t('web.review.title')}
                    IconElement={Contract}
                ></BottomMenuButton>
            </div>
            {user &&
            <Link to="/settings" className="bottom-menu__link">
                <span className="bottom-menu__link-text">{name}</span> <img src={profile}/>
            </Link>
            }
        </nav>
    );
}

export default BottomMenu;
