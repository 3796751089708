import React, { Component } from 'react';
import t, { d } from '../../i18n';
import EditRiskListItem from './edit-risk-list-item';
import Search from '../../search/search';
import { IEditRiskList } from '../interfaces';
import { IRisk } from '../risk';

interface IEditRiskListState {
    searchValue: string;
    allRisks: IRisk[];
    recommendedRisks: IRisk[];
}

export default class EditRiskList extends Component<
    IEditRiskList,
    IEditRiskListState
> {
    constructor(props) {
        super(props);

        this.state = {
            searchValue: '',
            allRisks: this.filterAllRisks(
                props.allRisks,
                props.recommendedRisks
            ),
            recommendedRisks: props.recommendedRisks,
        };
    }

    componentDidMount() {
        let recommendedRiskNames = this.props.recommendedRisks.map(
            (risk) => risk.group_name
        );
        let allRisks = this.props.allRisks.filter((risk) => {
            return !recommendedRiskNames.includes(risk.group_name);
        });

        this.setState({
            allRisks: allRisks,
        });
    }

    searchInputChangeHandler(e) {
        function searchFilter(risk) {
            return d('risk.risk_group', risk.group_name)
                .toLowerCase()
                .includes(e.target.value.toLowerCase());
        }

        let recommendedRisks = this.props.recommendedRisks.filter(searchFilter);
        let allRisks = this.filterAllRisks(
            this.props.allRisks.filter(searchFilter),
            recommendedRisks
        );

        this.setState({
            searchValue: e.target.value,
            allRisks: allRisks,
            recommendedRisks: recommendedRisks,
        });
    }

    filterAllRisks = (allRisks, recommendedRisks) => {
        let recommendedRiskNames = recommendedRisks.map(
            (risk) => risk.group_name
        );
        return allRisks.filter((risk) => {
            return !recommendedRiskNames.includes(risk.group_name);
        });
    };

    public render() {
        const sortedRecommendedRisks = this.state.recommendedRisks.sort(
            (a, b) =>
                d('risk.risk_group', a.group_name).localeCompare(
                    d('risk.risk_group', b.group_name)
                )
        );
        const sortedAllRisks = this.state.allRisks.sort((a, b) =>
            d('risk.risk_group', a.group_name).localeCompare(
                d('risk.risk_group', b.group_name)
            )
        );

        return (
            <div className="edit-risk-list">
                <div style={{ display: 'flex', width: '100%' }}>
                    <div
                        style={{
                            display: 'flex',
                            width: '100%',
                            alignItems: 'center',
                        }}
                    >
                        <h2>Risks</h2>
                        <div className="risks__search-container">
                            <Search
                                placeholder={t('web.risk.search.risk')}
                                searchValue={this.state.searchValue}
                                handleChange={this.searchInputChangeHandler.bind(
                                    this
                                )}
                            />
                        </div>
                    </div>
                    <button className="close btn" onClick={this.props.onClose}>
                        {t('web.common.close')}
                    </button>
                </div>
                <div className="edit-risk-list-container">
                    {sortedAllRisks.length === 0 && (
                        <h3>{t('web.risk.search.noRisksFound')}</h3>
                    )}
                    <div className="risk-list-header">
                        <h3>{t('web.risk.recommended')}</h3>
                    </div>
                    {sortedRecommendedRisks.map((risk, i) => {
                        return (
                            <EditRiskListItem
                                risk={risk}
                                onSelectHandler={this.props.onSelectHandler}
                                key={i}
                            />
                        );
                    })}
                    <div className="risk-list-header">
                        <h3>{t('web.risk.all')}</h3>
                    </div>
                    {sortedAllRisks.map((risk, i) => {
                        return (
                            <EditRiskListItem
                                risk={risk}
                                onSelectHandler={this.props.onSelectHandler}
                                key={i}
                            />
                        );
                    })}
                </div>
            </div>
        );
    }
}
