import React from 'react';
import PropTypes from 'prop-types';

import './search.css';
import searchIcon from '../img/risk/search--navy-blue.svg';

const Search = ({ searchValue, handleChange, placeholder }) => {
    return (
        <div className="search">
            <input
                type="text"
                className="search__input"
                onChange={handleChange}
                value={searchValue}
                placeholder={placeholder}
            />
            <img className="search__icon" src={searchIcon} alt="search" />
        </div>
    );
};

Search.propTypes = {
    searchValue: PropTypes.string.isRequired,
    handleChange: PropTypes.func.isRequired,
};

export default Search;
