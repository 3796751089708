// @ts-nocheck
// TODO remove above
import React from 'react';
import Slider from 'react-slick';
import classNames from 'classnames';

import './edit-risks.css';
import EditRisk from './edit-risk';
import RiskNavigatonBar from '../navigation/risk-navigation-bar';
import { IRisk } from '../risk';
import { IImage } from '../../image/imagelist';

let slickSettings = {
    dots: false,
    arrows: false,
    accessibility: true,
    draggable: false,
    swipe: false,
    infinite: false,
    initialSlide: 0,
    slidesToShow: 1
};

export interface IEditRisksProps {
    risks: IRisk[];
    activeRiskIndex: number | string;
    editRiskHandler: any;
    projectImages: any;
    changeActiveRiskState: any;
    handleImageEvent: any;
    onSelectSketchImage: (image: IImage) => void;
    editAttemptedClosed: boolean;
    editedRisksWithoutSolutions: any;
}

export default class EditRisks extends React.Component<IEditRisksProps, {}> {

    private changeActiveRiskHandler: (index) => any = (index) => {
        this.props.changeActiveRiskState(index);
    };

    private handleOnClickRiskEvaluation: (risk, name, value) => any = (risk, name, value) => {
        this.props.editRiskHandler(Object.assign({}, risk, {
            [name]: value
        }));
    };

    private onChangeHandlerComment: (e, risk) => any = (e, risk) => {
        this.handleOnClickRiskEvaluation(risk, e.target.name, e.target.value);
    };

    private sortRiskArray: (riskA, riskB) => number = (riskA, riskB) => {
        return riskA.id - riskB.id;
    };

    public render() {
        const { risks, activeRiskIndex, editRiskHandler, projectImages } = this.props;
        if (!risks || risks.length <= 0) {
            return null;
        }

        slickSettings['initialSlide'] = Number(activeRiskIndex);

        return (
            <div className="edit-risks">
                <RiskNavigatonBar risks={risks} activeRiskIndex={activeRiskIndex}
                                  changeActiveRisk={this.changeActiveRiskHandler}/>
                <Slider ref="slider" {...slickSettings} slickGoTo={activeRiskIndex}
                        afterChange={this.props.changeActiveRiskState}>
                    {
                        risks.sort(this.sortRiskArray).map((risk, index) => {
                            let images = projectImages.filter(image => {
                                return image.risk_id === risk.id;
                            });
                            let overlay = false;
                            for (let r in this.props.editedRisksWithoutSolutions) {
                                if (this.props.editedRisksWithoutSolutions[r].id === risk.id) {
                                    overlay = true;
                                }
                            }

                            return (
                                <div className={classNames({ "edit_risk__container": index !== activeRiskIndex })}
                                     key={index} onClick={() => this.changeActiveRiskHandler(index)}>
                                    <EditRisk
                                        risk={risk}
                                        activeRiskIndex={activeRiskIndex}
                                        images={images}
                                        handleOnClickRiskEvaluation={this.handleOnClickRiskEvaluation}
                                        onChangeHandlerComment={this.onChangeHandlerComment}
                                        handleImageEvent={this.props.handleImageEvent}
                                        onSelectSketchImage={this.props.onSelectSketchImage}
                                        editRiskHandler={editRiskHandler}
                                        overlay={(this.props.editAttemptedClosed && overlay) as boolean}/>
                                </div>
                            );
                        })
                    }
                </Slider>
            </div>
        )
    }
}
