// @ts-nocheck
// TODO remove above
import React, { Component } from 'react';
import t from '../i18n';
import Dropzone from 'react-dropzone';
import photo from '../img/photo.svg';
import close from '../img/close.svg';
import { BlobImage } from '../image/blobimage';
import './image.css';

export interface IImage {
    id: number;
    risk_id: number;
    seller_id: number;
    project_id: number;
    name: string;
    src: string;
    url: string;
    content_type: string;
}

interface IImageListProps {
    images: IImage[];
    onDrop: (acceptedFiles: File[], rejectedFiles: File[]) => void;
    onSelectSketchImage: (image: IImage) => void;
    onDelete: (image: IImage) => void;
}

interface IImageListState {
    error: string
}

export default class ImageList extends Component<IImageListProps, IImageListState> {

    uploadSize : number = 10000000;
    state = { error: null }

    public handleReject = () => {
        this.setState({ error: t('web.image.tooLarge') });
    }

    public handleDrop = (data) => {
        this.setState({ error: null });
        this.props.onDrop(data);
    }

    public handleErrorClick = () => {
        this.setState({ error: null});
    }

    public render() {
        const { images, onDelete } = this.props;
        const imageComponents = images.map(image => {
            return <OneImage
                key={image.id}
                image={image}
                imageClicked={this.props.onSelectSketchImage}
                deleteClicked={() => onDelete(image)}/>
        });
        return (
            <div className="place__photo imagelist">
                <div className="imagelist__images" onClick={(e) => e.stopPropagation()}>
                    {imageComponents}
                </div>
                <Dropzone className="camera__dropzone" activeClassName="dropzone__active" disableClick={false}
                    onDrop={this.handleDrop} maxSize={this.uploadSize} onDropRejected={this.handleReject}>
                    <div className="dropzone__content">
                        <img src={photo} role="presentation"/><span>{t('web.risk.addPhoto')}</span>
                    </div>
                </Dropzone>
                {this.state.error && <div className="image-upload-error" onClick={this.handleErrorClick}>{this.state.error}</div>}
                
            </div>
        );
    }
}

interface IOneImageProps {
    image: IImage;
    imageClicked: (image: IImage) => void;
    deleteClicked: () => void;
}

const OneImage = ({ image, imageClicked, deleteClicked }: IOneImageProps) => {
    return (
        <div className="imagelist__image">
            <BlobImage key={image.id} src={image.src} onClick={() => imageClicked(image)} clearCache={true}/>
            <img src={close} role="presentation" className="imageList__deleteimage" onClick={deleteClicked}/>
        </div>
    );
};
