import * as React from 'react';

import t, { translationOptions } from '../i18n';
import './settings.css';

const Settings = ({ user, signOut, clearLanguageCache, handleLanguageUpdate }) =>
    <div className="container">
        <div className="padding-container"/>
        <h3 className="h3">{t('web.settings.settings')}</h3>
        <span className="title">{t('web.settings.fullName')} {user && user.name}</span>
        <span className="title">{t('web.settings.username')}: {user && user.username}</span>
        <span className="title">{t('web.settings.userId')}: {user && user.id}</span>
        <span className="title">{t('web.settings.phone')}: {user && user.phone}</span>
        <span className="title">{t('web.settings.country')}: {user && user.country.name}</span>
        <span className="title">{t('web.settings.company')}: {user && user.company && user.company.office_name}</span>
        <span className="title">{t('web.settings.language')}:
            <select style={{ display: "inline-block", maxWidth: "200px" }} className="input-form__select"
                    value={user.language}
                    onChange={(e) => handleLanguageUpdate(e, user)}>
                    {Object.keys(translationOptions).map((key, index) => {
                        return <option key={index} value={key}>{translationOptions[key]}</option>
                    })}
            </select>
        </span>
        <button className="settings__sign-out btn" onClick={signOut}>{t('web.settings.signOut')}</button>
        <div><button className="settings__sign-out btn" onClick={clearLanguageCache}>{t('web.settings.refreshTranslations')}</button></div>
    </div>

export default Settings;
