import React, { Component } from 'react'
import t from '../i18n/index';
import './survey.css';
import arrow from '../img/arrow.svg';
import download from '../img/download.svg';
import Place from './place';
import { getAnswersForQuestions, getQuestionsInPlace } from './selectors';

const Places = ({ selectedPlace, places, answers, images, summaries, handleClickOnPlace, handleImageEvent, handleAnswerChanged, handleSummaryChanged, onSelectSketchImage, downloadPdf }) => {
    const DownloadButton = ({ place, onClick }) => {
        return (
            <button className="survey__list-item-download-btn" onClick={onClick}>
                <img src={download} alt={t('web.survey.download')}/>
            </button>
        );
    };

    const showQuestions = selectedPlace && selectedPlace.question_groups.length > 0;
    return (
        <div>
            {
                !showQuestions && places
                    .sort((a, b) => a.position - b.position)
                    .map((place, index) => {
                    const questions = getQuestionsInPlace(place);
                    if (questions.length === 0)
                        return null;

                    const connectedAnswers = getAnswersForQuestions(questions, answers);
                    const isLeaf = place.question_groups.length > 0;

                    return (
                        <div key={index} className="survey__list-item" onClick={(node) => { handleClickOnPlace(place, index) }}>
                            <div className="survey__list-inner-item">
                                <div>
                                    <span className="survey__list-item-title">{place.name}</span>
                                    <span className="survey__list-item-subtitle">{place.description}</span>
                                </div>
                                <div>
                                    {isLeaf ? <DownloadButton place={place} onClick={(proxy) => { proxy.stopPropagation(); downloadPdf(proxy, place.id) }} /> : null}
                                    <AnswerCount numberOfQuestions={questions.length} numberOfAnswers={connectedAnswers.length} />
                                    <img className="survey__list-item-arrow" src={arrow} alt="arrow" />
                                </div>
                            </div>
                        </div>
                    );
                })
            }
            <div className="places">
                {
                    showQuestions && <Place
                        place={selectedPlace}
                        answers={answers}
                        images={images}
                        summaries={summaries}
                        handleAnswerChanged={handleAnswerChanged}
                        handleSummaryChanged={handleSummaryChanged}
                        handleImageEvent={handleImageEvent} 
                        onSelectSketchImage={onSelectSketchImage}
                        />

                }
            </div>
        </div>
    )
}

const AnswerCount = ({ numberOfQuestions, numberOfAnswers }) => {
    return <div className="place__count">{numberOfAnswers}/{numberOfQuestions}</div>;
}


export default Places;
