import { call, put, select, takeEvery, takeLatest } from 'redux-saga/effects';
import * as actions from './actions';
import { GET_PROJECTS_REQUESTED } from '../projects/actions';
import t from '../i18n';
import {
    addContract,
    deleteContract,
    fetchContractPDF,
    fetchContractTemplates,
    fetchOfferletterData,
    fetchOfferletters,
    fetchProjectContracts,
    fetchProposalPDF,
    setNewOfferletter,
    signContract,
    signOfferletter,
    getAllMediaFiles,
    addMediaFile,
    removeMediaFile
} from '../services/api';
import { GET_MEDIAFILES_REQUESTED } from '../attachment/actions';
import { ADD_ERROR } from '../app/actions';
import { pdfjs } from 'react-pdf';
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.min.js`;

export const OFFERLETTER_TYPE = 'offerletter';
export const CONTRACT_TYPE = 'contracts';

export const getTypeBasedOnViewShowOffer = (viewShowOffer) =>
    viewShowOffer ? OFFERLETTER_TYPE : CONTRACT_TYPE;

function getDocument(data) {
    const loadingTask = pdfjs.getDocument(data);
    return loadingTask.promise;
}

function* getStatusForOfferLetter(action) {
    try {
        let offerLetterTemplate = yield select((state: any) => state.proposal.offerLetterTemplate);
        if (!offerLetterTemplate || offerLetterTemplate.project_id != action.payload.project_id) {
            offerLetterTemplate = yield call(fetchOfferletterData, action.payload.project_id);
        }
        yield put({
            type: actions.GET_PROPOSAL_STATUS_SUCCEEDED,
            payload: {
                offerLetterTemplate: offerLetterTemplate
            }
        });
    } catch (e) {
        if (e.status === 404 || e.status === 500) {
            yield put({
                type: actions.GET_PROPOSAL_STATUS_SUCCEEDED, payload: {
                    offerLetterTemplate: null
                }
            });
            return;
        }
        yield put({
            type: ADD_ERROR, payload: {
                title: t('web.common.errorTitleGeneric'),
                message: t('Something unexpected happened while fetching offer PDF')
            }
        });
    }
}

function* getStatusForContracts(action) {
    try {
        let contracts = undefined;
        if (!action.payload.reload) { // if we do not specify reloading we try to fetch from current state first
            contracts = yield select((state: any) => state.proposal.contracts);
        }
        if (!contracts || contracts.length === 0 || contracts[0].project_id != action.payload.project_id) {
            contracts = yield call(fetchProjectContracts, action.payload.project_id);
        }
        yield put({
            type: actions.GET_CONTRACTS_STATUS_SUCCEEDED,
            payload: {
                contracts: contracts
            }
        });
    } catch (e) {
        if (e.status === 404 || e.status === 500) {
            yield put({
                type: actions.GET_CONTRACTS_STATUS_SUCCEEDED, payload: {
                    contracts: null
                }
            });
            return;
        }
        yield put({
            type: ADD_ERROR, payload: {
                title: t('web.common.errorTitleGeneric'),
                message: t('Something unexpected happened while fetching contracts')
            }
        });
    }
}

function* getOfferLetterTemplateData(action) {
    try {
        let offerLetterTemplate = yield select((state: any) => state.proposal.offerLetterTemplate);
        offerLetterTemplate = yield call(fetchOfferletterData, action.payload.project_id);
        yield put({
            type: actions.GET_PROPOSAL_STATUS_SUCCEEDED,
            payload: {
                offerLetterTemplate: offerLetterTemplate
            }
        })
    } catch (e) {
        yield put({
            type: ADD_ERROR, payload: {
                title: t('web.common.errorTitleGeneric'),
                message: t('Something unexpected happened while fetching offer PDF')
            }
        });
    }
}

function* getProposalPdf(action) {
    try {
        const data = yield call(fetchProposalPDF, action.payload.projectId, action.payload.email);
        const pdf = yield call(getDocument, data);
        yield put({ type: actions.GET_PDF_SUCCEEDED, payload: { pdf: pdf } });
    } catch (e) {
        if (e.status === 404) {
            yield put({
                type: actions.GET_PDF_SUCCEEDED, payload: {
                    pdf: 404
                }
            });

            return;
        }
        yield put({ type: actions.GET_PDF_FAILED });
        yield put({
            type: ADD_ERROR, payload: {
                title: t('web.common.errorTitleGeneric'),
                message: t('Something unexpected happened while fetching PDF')
            }
        });
    }
}

function* getContractPdf(action) {
    try {
        const data = yield call(fetchContractPDF, action.payload.project_id, action.payload.contract_id);
        const pdf = yield call(getDocument, data);
        yield put({ type: actions.GET_PDF_SUCCEEDED, payload: { contractPdf: pdf } });
        yield put({
            type: actions.UPDATE_CONTRACT_URL, payload: {
                ...action.payload
            }
        });
    } catch (e) {
        if (e.status === 404) {
            yield put({
                type: actions.GET_PDF_SUCCEEDED, payload: {
                    pdf: 404
                }
            });

            return;
        }

        yield put({ type: actions.GET_PDF_FAILED });
        yield put({
            type: ADD_ERROR, payload: {
                title: t('web.common.errorTitleGeneric'),
                message: t('Something unexpected happened while fetching PDF')
            }
        });
    }
}

function* getAllMediafiles(action) {
    try {
        const mediafiles = yield call(getAllMediaFiles);
        yield put({ type: actions.GET_ALL_MEDIAFILES_SUCCEEDED, payload: { mediafiles } });
    } catch (e) {
        yield put({
            type: ADD_ERROR, payload: {
                title: t('web.common.errorTitleGeneric'),
                message: t('Something unexpected happened while fetching all media files')
            }
        });
    }
}

function* addProjectMediaFile(action) {
    try {
        yield call(addMediaFile, action.payload.projectId, action.payload.mediaId);
        yield put({
            type: GET_MEDIAFILES_REQUESTED, 
            payload: {
                projectId: action.payload.projectId
            } 
        });
    } catch (e) {

    }
}

function* removeProjectMediaFile(action) {
    try {
        yield call(removeMediaFile, action.payload.projectId, action.payload.mediaId);
        yield put({
            type: GET_MEDIAFILES_REQUESTED, 
            payload: {
                projectId: action.payload.projectId
            } 
        });
    } catch (e) {

    }
}

function* getTemplatesRequested(action) {
    try {
        let templates = yield select((state: any) => state.proposal.templates);
        if (!templates || templates.length === 0) {
            templates = yield call(fetchOfferletters);
        }
        yield put({ type: actions.GET_TEMPLATES_SUCCEEDED, payload: { templates } });
    } catch (e) {
        yield put({
            type: ADD_ERROR, payload: {
                title: t('web.common.errorTitleGeneric'),
                message: t('Something unexpected happened while fetching offer letter templates')
            }
        });
    }
}

function* getContractTemplatesRequested(action) {
    try {
        let contractTemplates = yield select((state: any) => state.proposal.contractTemplates);
        if (!contractTemplates || contractTemplates.length === 0) {
            contractTemplates = yield call(fetchContractTemplates);
        }
        yield put({ type: actions.GET_TEMPLATES_SUCCEEDED, payload: { contractTemplates } });
    } catch (e) {
        yield put({
            type: ADD_ERROR, payload: {
                title: t('web.common.errorTitleGeneric'),
                message: t('Something unexpected happened while fetching offer letter templates')
            }
        });
    }
}

function* setNewTemplateRequested(action) {
    try {
        yield call(setNewOfferletter, action.payload.project_id, action.payload);
        yield put({
            type: actions.GET_PDF_REQUESTED, payload: {
                projectId: action.payload.project_id
            }
        });
        yield call(getOfferLetterTemplateData, action);
        yield put({ type: actions.SET_TEMPLATE_SUCCEEDED });
    } catch (e) {
        yield put({
            type: ADD_ERROR, payload: {
                title: t('web.common.errorTitleGeneric'),
                message: t('Something unexpected happened while updating template')
            }
        });
    }
}

function* addContractRequested(action) {
    try {
        let contract = yield call(addContract, action.payload.project_id, action.payload.contract_id);
        yield put({ type: actions.ADD_CONTRACT_SUCCEEDED, payload: { contract } });
        yield put({ type: actions.GET_CONTRACT_PDF_REQUESTED, payload: {...contract}});
    } catch (e) {
        yield put({
            type: ADD_ERROR, payload: {
                title: t('web.common.errorTitleGeneric'),
                message: t('Something unexpected happened while adding contract')
            }
        });
    }
}

function* deleteContractRequested(action) {
    try {
        let result = yield call(deleteContract, action.payload.project_id, action.payload.contract_id);
        yield put({ type: actions.DELETE_CONTRACT_SUCCEEDED, payload: { contract_id: action.payload.contract_id } });
    } catch (e) {
        yield put({
            type: ADD_ERROR, payload: {
                title: t('web.common.errorTitleGeneric'),
                message: t('Something unexpected happened while deleting contract')
            }
        });
    }
}

function* signOfferletterRequested(action) {
    try {
        yield call(signOfferletter, action.payload.projectId);
        yield put({ type: actions.START_OFFER_LETTER_SIGN_IN_PROCESS_SUCCEEDED });
    } catch (e) {
        yield put({
            type: ADD_ERROR, payload: {
                title: t('web.common.errorTitleGeneric'),
                message: t('Something unexpected happened while starting signing process')
            }
        });
    }
}

function* signContractRequested(action) {
    try {
        let result = yield call(signContract, action.payload.project_id, action.payload.contract_id);
        // reload contracts to show updated status in ui. 
        yield put({
            type: actions.GET_CONTRACTS_STATUS_REQUESTED,
            payload: { project_id: action.payload.project_id, reload: true }
        });
        // Reload projects since project status also updates when signing contract is started
        // should only reload one project but don't have a action for that at the moment
        yield put({
            type: GET_PROJECTS_REQUESTED,
            payload: { reload: true }
        });

        // yield put({ type: actions.START_CONTRACT_SIGN_IN_PROCESS_SUCCEEDED });
    } catch (e) {
        yield put({
            type: ADD_ERROR, payload: {
                title: t('web.common.errorTitleGeneric'),
                message: t('Something unexpected happened while starting signing process')
            }
        });
    }
}

function* changeProposalViewRequested(action) {
    try {
        yield put({
            type: actions.GET_TEMPLATES_REQUESTED,
            payload: {
                isTemplateTypeShowOffer: action.payload.isTemplateTypeShowOffer
            }
        });
        yield put({
            type: actions.GET_PDF_REQUESTED,
            payload: {
                projectId: action.payload.projectId,
                type: action.payload.isTemplateTypeShowOffer ? OFFERLETTER_TYPE : CONTRACT_TYPE
            }
        });
    } catch (e) {
        yield put({
            type: ADD_ERROR, payload: {
                title: t('web.common.errorTitleGeneric'),
                message: t('Something unexpected happened while fetching offer PDF')
            }
        });
    }
}

export const dispatchProposalStatusRequested = (dispatch, projectId) =>
    dispatch({
        type: actions.GET_PROPOSAL_STATUS_REQUESTED,
        payload: { project_id: projectId }
    })

export const dispatchContractsStatusRequested = (dispatch, project_id, reload = false) =>
    dispatch({
        type: actions.GET_CONTRACTS_STATUS_REQUESTED,
        payload: { project_id: project_id, reload: reload }
    })


export const dispatchStartOfferLetterSignInProcess = (dispatch, projectId) => {
    dispatch({
        type: actions.START_OFFER_LETTER_SIGN_IN_PROCESS,
        payload: { projectId: projectId }
    })
}

export const dispatchStartContractSignInProcess = (dispatch, project_id, contract_id) => {
    dispatch({
        type: actions.START_CONTRACT_SIGN_IN_PROCESS,
        payload: { project_id, contract_id }
    })
}

export default function* root() {
    yield [
        takeEvery(actions.GET_TEMPLATES_REQUESTED, getTemplatesRequested),
        takeEvery(actions.SET_TEMPLATE_REQUESTED, setNewTemplateRequested),
        takeEvery(actions.GET_PDF_REQUESTED, getProposalPdf),
        takeEvery(actions.GET_CONTRACT_PDF_REQUESTED, getContractPdf),
        takeEvery(actions.GET_PROPOSAL_STATUS_REQUESTED, getStatusForOfferLetter),
        takeEvery(actions.GET_CONTRACTS_STATUS_REQUESTED, getStatusForContracts),
        takeEvery(actions.GET_CONTRACT_TEMPLATES_REQUESTED, getContractTemplatesRequested),
        takeEvery(actions.ADD_CONTRACT_REQUESTED, addContractRequested),
        takeEvery(actions.DELETE_CONTRACT_REQUESTED, deleteContractRequested),
        takeEvery(actions.GET_ALL_MEDIAFILES_REQUESTED, getAllMediafiles),
        takeEvery(actions.ADD_PROJECT_MEDIAFILE_REQUESTED, addProjectMediaFile),
        takeEvery(actions.REMOVE_PROJECT_MEDIAFILE_REQUESTED, removeProjectMediaFile),
        takeLatest(actions.START_CONTRACT_SIGN_IN_PROCESS, signContractRequested),
        takeLatest(actions.START_OFFER_LETTER_SIGN_IN_PROCESS, signOfferletterRequested),
        takeLatest(actions.CHANGE_PROPOSAL_VIEW_REQUESTED, changeProposalViewRequested),
    ]
}
