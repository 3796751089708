// @ts-nocheck
// TODO remove above
import React from 'react';
import Dropzone from 'react-dropzone';

import './camera.css';
import cameraPhoto from '../img/photo.svg';
import { Image } from '../image/image';

const Camera = ({ postAttachment, imageUrl, ...props }) => {
    let className = props.className ? "camera " + props.className : "camera";
    if (imageUrl)
        console.log("Camera imageurl", imageUrl);
    return (
        <div className={className} {...props} >
            {imageUrl &&
            <Image src={imageUrl} className="camera__image" role="presentation"/>
            }
            <div className="camera__container">
                <Dropzone className="camera__dropzone" onDrop={postAttachment}>
                    <div className="camera__circle">
                        <img className="camera__logo" src={cameraPhoto} role="presentation"/>
                    </div>
                </Dropzone>
            </div>
        </div>
    );
}

export default Camera;
