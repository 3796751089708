import * as React from 'react';
import { connect } from 'react-redux';
import { browserHistory } from 'react-router';

import NewProject from './new-project';
import './new-project.css';
import {
    ADD_NEW_PROJECT_REQUESTED,
    DELETE_PROJECT_REQUESTED,
    EDIT_PROJECT_REQUESTED,
    GET_SEGMENTS_REQUESTED
} from '../projects/actions';
import { getProjectImageUrl } from '../services/api';
import { getImageBlobUrl } from '../utils/utils';
import { setImageCache } from '../image/image';
import t from '../i18n';
import ConfirmDialog from '../common/confirm-dialog/confirm-dialog';

export interface NewProjectContainerProps {

}

class NewProjectContainer extends React.Component<any, any> {

    state =
        {
            project: {
                project_id: 0,
                client_id: 0,
                name: '',
                client: {
                    name: '',
                    contacts: [
                        {
                            firstName: '',
                            lastName: '',
                            email: '',
                            phone1: '',
                            phone2: ''
                        }
                    ],
                    streetAddressOne: '',
                    streetAddressTwo: '',
                    organisationNumber: '',
                    clientDescription: ''
                },
                segment_id: null,
            },
            segments: [],
            image: undefined,
            imageUrl: undefined,
            imageFile: null,
            showConfirmDialog: false,
        };
    onCancelAddNewProject = (e) => {
        if (this.state.imageFile) {
            window.URL.revokeObjectURL(this.state.imageFile.preview);
        }
        e.preventDefault();
        browserHistory.goBack();
    }
    onDeleteProject = (project) => {
        this.setState({ showConfirmDialog: true })
    }
    onDeleteAccepted = (project) => {
        this.props.dispatchAction({
            type: DELETE_PROJECT_REQUESTED,
            payload: {
                ...project,
            }
        });
    }
    onDeleteCanceled = (project) => {
        this.setState({ showConfirmDialog: false })
    }
    submitProject = (event) => {
        event.preventDefault();
        const action = this.isEdit() ? EDIT_PROJECT_REQUESTED : ADD_NEW_PROJECT_REQUESTED;

        let payload = {
            ...this.state.project,
            seller: undefined,
            imageFile: this.state.imageFile
        }
        if (this.state.imageFile) {
            const imageUrl = getProjectImageUrl(this.state.project.project_id);
            const blobImageUrl = getImageBlobUrl(this.state.imageFile);
            setImageCache(imageUrl, blobImageUrl);
        }
        this.props.dispatchAction({
            type: action,
            payload: payload
        });
    }
    didSelectImage = (imageData) => {
        const file = imageData[0];
        const imageUrl = getImageBlobUrl(file);
        this.setState({ imageFile: file, imageUrl: imageUrl });
    }
    projectUpdated = (updatedProject) => {
        this.setState({ project: updatedProject });
    }

    componentWillMount() {
        this.props.dispatchAction({
            type: GET_SEGMENTS_REQUESTED
        });
    }

    componentDidMount() {
        this.setStateFromProps(this.props);
    }

    componentWillReceiveProps(props) {
        this.setStateFromProps(props);
    }

    setStateFromProps(props) {
        if (props.project) {
            let projectImageUrl = props.project.image ? getProjectImageUrl(props.project.project_id) : undefined;
            this.setState({ project: props.project, imageUrl: projectImageUrl });
        }
    }

    isEdit() {
        return this.state.project.project_id > 0;
    }

    render() {

        // check that the project exists before rendering, or else the segment select input will be wrong
        if(this.props.editing && !this.state.project.segment_id) {
            return null;
        }

        return (
            <div>
                <NewProject
                    projectUpdated={this.projectUpdated}
                    submitProject={this.submitProject}
                    onCancelAddNewProject={this.onCancelAddNewProject}
                    onDeleteProject={this.onDeleteProject}
                    didSelectImage={this.didSelectImage}
                    segments={this.props.segments}
                    project={this.state.project}
                    imageUrl={this.state.imageUrl}
                    editing={this.props.editing}
                />
                {
                    this.state.showConfirmDialog &&
                    <ConfirmDialog
                        title={t("web.projects.deletedialog.title")}
                        message={t("web.projects.deletedialog.message")}
                        onCancel={this.onDeleteCanceled}
                        onOK={() => this.onDeleteAccepted(this.state.project)}/>
                }
            </div>
        );
    }
}

const mapStateToProps = ({ projects }) => {
    return {
        segments: projects.segments
    }
};

const mapDispatchToProps = (dispatch) => ({
    dispatchAction: (action) => dispatch(action)
});

export default connect(mapStateToProps, mapDispatchToProps)(NewProjectContainer as any);
