// @ts-nocheck
// TODO remove above
import * as React from 'react';
import ProjectsGridItem from './projects-grid-item';
import t from '../i18n';
import NewProjectButton from './new-project-button';

const shareProject = (project) => {

};

const getProjectsItems = (projects, didClickOnItem, didClickEditProject, didClickDownloadProject, Item, shareClickListener) =>
    projects
        .map((project, index) =>
            <Item
                key={index}
                project={project}
                clickListener={didClickOnItem}
                editClickListener={(e) => { e.stopPropagation(); didClickEditProject(project); }}
                downloadClickListener={(e) => { e.stopPropagation(); didClickDownloadProject(project); }}
                shareClickListener={shareClickListener}
            />);

const getGridView = (projects, didClickOnItem, didClickEditProject, didClickDownloadProject, shareClickListener) =>
    <div className="grid projects__grid">
        {
            getProjectsItems(projects, didClickOnItem, didClickEditProject, didClickDownloadProject, ProjectsGridItem, shareClickListener)
        }
    </div>

export interface ProjectsProps {
    projects: any[];
    showList: boolean;
    didClickOnItem: (event) => any;
    didClickNewProject: (event) => any;
    didClickEditProject: (event) => any;
    didClickDownloadProject: (event) => any;
    switchPresentationListenerToList: (event) => any;
    switchPresentationListenerToGrid: (event) => any;
        shareClickListener: (event) => any;
}

const Projects = ({
    projects,
    showList,
    didClickOnItem,
    didClickNewProject,
    didClickEditProject,
    didClickDownloadProject,
    switchPresentationListenerToList,
    switchPresentationListenerToGrid,
    shareClickListener
}: ProjectsProps) => {
    const projectStatusComplete = 2;
    const completedProjects = projects.filter(project => project.progress_status === projectStatusComplete);
    const activeProjects = projects.filter(project => project.progress_status < projectStatusComplete);
    return (
        <div className="projects">
            <h2 className="projects__headline">{t('web.projects.title')}</h2>
            <div className="projects__sub-headline-container">
                <h3 className="projects__sub-headline">{t('web.projects.incomplete')}</h3>
                <NewProjectButton clickListener={didClickNewProject} editing={false}/>
            </div>
            {
                getGridView(activeProjects, didClickOnItem, didClickEditProject, didClickDownloadProject, shareClickListener)
            }
            <div className="projects__completed">
                <h3 className="projects__sub-headline">{t('web.projects.completed')}</h3>
                {
                    getGridView(completedProjects, didClickOnItem, didClickEditProject, didClickDownloadProject, shareClickListener)
                }
            </div>
        </div>
    )
}

export default Projects;
