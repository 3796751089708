import React from 'react';

const Select = ({image, label, onChange, value, values, placeholder, required}) => {
  // TODO: select.default.placeholder i18n text reference needs to be created
  placeholder = placeholder || "Please select an option";
  value = value || "";
  values = values || [];
  label = label || "";
  required = required || false;

  const Placeholder = () => <option value="" disabled>{placeholder}</option>
  
  return (
    <div className="input-form input-form--select">
      <div className="input-form__image">
        <img src={image} role="presentation"/>
      </div>
      <div className="input-form__group">
        <label className="input-form__select-label">{label}</label>
        <select className="input-form__select" value={value} onChange={onChange} required={required}>
          <Placeholder></Placeholder>
          {values.map(oneValue => {
            return <option value={oneValue.segment_id} key={oneValue.segment_id}
                           id={oneValue.segment_id}>{oneValue.segment_name}</option>
          })}
        </select>
      </div>
    </div>
  );
}

export default Select;
