// @ts-nocheck
// TODO remove above
interface ProductInterface {
    id: number;
    productType: string;
    data: Object<any>;
    fixedFee: boolean;
    leasePeriod: number;
}

export class Product implements ProductInterface {

    constructor(id: number, data: Object<any>, currency) {
        this._id = id;
        this._data = data;
        this._currency = currency;
    }

    private _id: number;
    private _data: Object<any>;
    private _currency: string;

    get id(): number {
        return this._id;
    }

    set id(value: number) {
        this._id = value;
    }

    get data(): Object<any> {
        return this._data;
    }

    set data(value: Object<any>) {
        this._data = value;
    }

    get currency(): string {
        return this._currency;
    }

    set currency(value: string) {
        this._currency = value;
    }

    addVat = (price: number, vat: number) => {
        return price * (1.0 + vat);
    };

    removeVat = (price: number, vat: number) => {
        return price / (1.0 + vat);
    };

    getBasePrice = (fixedPrice: boolean) => {
        return fixedPrice ?
            {
                price: this.data["fixed_price_in_cents"],
                adjustedPrice: this.data["adjusted_fixed_price_cents"]
            } :
            {
                price: this.data[`price_${this.data.lease_period}_months_cents`],
                adjustedPrice: this.data[`adjusted_price_${this.data.lease_period}_months_cents`]
            }
    };

    calculateDiscount = (price, discount) => {
        return discount > 0 ? price * (1.0 - (discount / 100)) : price;
    };

    adjustPrice(price) {
        if(this.data['percentage_adjustment'] > 0) {
            return price - (price * this.data['percentage_adjustment'] / 100);
        }
        return price;
    }

    getVatAmount = (fixedPrice: boolean) => {

        if(fixedPrice && (!this.data['is_fixed_price'])) {
            return 0;
        }
        
        if(this.data['value_added_tax_enabled']) {            
            const details = this.getBasePrice(fixedPrice);
            details.price = this.adjustPrice(details.price);
            if (this.data['price_includes_vat']) {
                const price = details.adjustedPrice > 0 ? details.adjustedPrice : details.price;
                const value = price - (price / (this.data['value_added_tax_percentage'] +1));
                return !isNaN(value) ? value : 0; 
            }
            const value = (details.adjustedPrice > 0 ? details.adjustedPrice : details.price) * this.data['value_added_tax_percentage'];
            return !isNaN(value) ? value : 0;

        }

        return 0;

    }

    getFixedVatAmount = () => {
        return this.getVatAmount(true);
    }

    getMonthlyVatAmount = () => {
        return this.getVatAmount(false);
    }
}