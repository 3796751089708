import React from 'react';

const Solution = ({ fillColor }) => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="26" viewBox="0 0 24 26">
            <path fill={fillColor} id="a"
                  d="M7.292 4.167h10.416c.576 0 1.042.466 1.042 1.041v9.034H6.25V5.208c0-.575.466-1.041 1.042-1.041zM2.812 8.02v15.208h19.375V8.021H16.98V6.248H8.02V8.02H2.812zm5.209 6.217h8.958v-8.3H8.021v8.3zM2.083 6.25h20.834c.575 0 1.041.466 1.041 1.042v16.666c0 .576-.466 1.042-1.041 1.042H2.083a1.042 1.042 0 0 1-1.041-1.042V7.292c0-.576.466-1.042 1.041-1.042zM12.5 4.167h2.396a2.396 2.396 0 1 0-4.792 0H12.5zm-1.042 5.208a1.042 1.042 0 0 1 2.084 0v2.083h-2.084V9.375zM12.5 4.167H8.333a4.167 4.167 0 1 1 8.334 0H12.5zM2.812 14.27v8.958h19.375v-8.958H2.813zM2.083 12.5h20.834c.575 0 1.041.466 1.041 1.042v10.416c0 .576-.466 1.042-1.041 1.042H2.083a1.042 1.042 0 0 1-1.041-1.042V13.542c0-.576.466-1.042 1.041-1.042z"/>
        </svg>
    );
};

export default Solution;