import * as actions from './actions';

const initialState = {
    templates: [],
    contractTemplates: [],
    viewShowOffer: true,
    pdf: null,
    offerLetterTemplate: null,
    isFetchingPdf: false,
    isFetchingTemplates: false,
    url: null,
    contracts: [],
    mediafiles: []
};

export default function proposal(state = initialState, action) {
    switch (action.type) {
        case actions.UPDATE_CONTRACT_URL:
            return {
                ...state,
                url: action.payload
            }
        case actions.GET_TEMPLATES_REQUESTED:
            return {
                ...state,
                isFetchingTemplates: true
            }
        case actions.GET_TEMPLATES_SUCCEEDED:
            return {
                ...state,
                ...action.payload,
                isFetchingTemplates: false
            }
        case actions.GET_CONTRACT_TEMPLATES_SUCCEEDED:
            return {
                ...state,
                ...action.payload
            }
        case actions.GET_CONTRACTS_STATUS_SUCCEEDED:
            return {
                ...state,
                ...action.payload
            }
        case actions.GET_TEMPLATES_FAILED:
            return {
                ...state,
                templates: null,
                isFetchingTemplates: false
            };
        case actions.SET_TEMPLATE_REQUESTED:
            return {
                ...state,
                isFetchingPdf: true,
                pdf: null
            };
        case actions.SET_TEMPLATE_SUCCEEDED:
            return {
                ...state,
                isFetchingPdf: false
            };
        case actions.GET_PDF_REQUESTED:
            return {
                ...state,
                isFetchingPdf: true,
                pdf: null
            };
        case actions.GET_CONTRACT_PDF_REQUESTED:
            return {
                ...state,
                isFetchingPdf: true
            };
        case actions.GET_PDF_SUCCEEDED:
            return {
                ...state,
                ...action.payload,
                // pdf: action.payload.pdf,
                isFetchingPdf: false
            };
        case actions.GET_PDF_FAILED:
            return {
                ...state,
                isFetchingPdf: false,
                pdf: null
            };
        case actions.GET_PROPOSAL_STATUS_REQUESTED:
            return {
                ...state,
                offerLetterTemplate: null
            };
        case actions.GET_PROPOSAL_STATUS_SUCCEEDED:
            return {
                ...state,
                offerLetterTemplate: action.payload.offerLetterTemplate
            };
        case actions.CHANGE_PROPOSAL_VIEW_REQUESTED:
            return {
                ...state,
                viewShowOffer: action.payload.isTemplateTypeShowOffer
            };
        case actions.EDIT_TEMPLATE_REQUESTED:
            return {
                ...state,
                offerLetterTemplate: Object.assign({}, state.offerLetterTemplate, action.payload.offerLetterTemplate)
            };
        case actions.ADD_CONTRACT_SUCCEEDED: {
            let newContracts = [...state.contracts, action.payload.contract];
            return { ...state, contracts: newContracts }
        }
        case actions.DELETE_CONTRACT_REQUESTED: {
            return { ...state, isDeletingContract: true }
        }
        case actions.DELETE_CONTRACT_SUCCEEDED: {
            let newContracts = state.contracts.filter(contract => contract.contract_id !== action.payload.contract_id);
            return { ...state, contracts: newContracts, isDeletingContract: false}
        }

        case actions.START_OFFER_LETTER_SIGN_IN_PROCESS_SUCCEEDED:
            let offerLetterTemplate = state.offerLetterTemplate;
            offerLetterTemplate.document_status = "WAITING";
            return { ...state, offerLetterTemplate }
        
        case actions.GET_ALL_MEDIAFILES_SUCCEEDED: {
            return {
                ...state,
                mediafiles: action.payload.mediafiles
            }
        }

        default:
            return state;
    }
}
